import { Component, OnInit,NgZone } from '@angular/core';
import { TranslateService } from '../../../_services/translate.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router' 
import { ApiService } from '../../../_services/api.services';
declare var $: any;
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-header-landing-about',
  templateUrl: './header-landing-about.component.html',
  styleUrls: ['./header-landing-about.component.scss'],
  styles: [`.router-link-active { background-color: red; }`]
})
export class HeaderLandingAboutComponent implements OnInit {
  currentLang: any;
  loginvalue: any;
  id: any;
  home: boolean;
  about: boolean;
  login: boolean;
  response: any;
  show_dash: boolean;
  url: any;
 

  constructor(private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngxUiLoaderService: NgxUiLoaderService,
    private ngZone:NgZone) { 
    if (this.authenticationService.currentUserValue) {
      // console.log('atheda vannedaaaaaaaaaa');
      this.loginvalue = this.authenticationService.currentUserValue;
      console.log(this.loginvalue);
      if (this.loginvalue) {
        this.show_dash = true;
      }else{
        this.show_dash = false;
      }
    }
    // const currentUrl = this.router.routerState.snapshot.url;
    // const componentName = currentUrl.split('/').pop();
    // console.log(componentName);
    
    const currentUrl = this.router.routerState.snapshot.url;
    console.log(currentUrl);
    const componentName = currentUrl.split('/').pop();
    console.log(componentName);
    if(componentName == 'login' || componentName == 'forgot-password'){
      this.login = false;
      console.log('qwerere');
      
    }else{
      this.login = true;
      
    }
  }

  ngOnInit() {

    this.currentLang = localStorage.getItem('language') || "fr";
    if (this.currentLang == 'fr') {
      $('.checkbox').prop('checked', false);
    } else {
      $('.checkbox').prop('checked', true);
    }
    if (this.currentLang == 'fr') {
      $('.myCheckbox').prop('checked', true);
      $("#lang").text("FR");
      console.log("language is french")
    } else {
      $('.myCheckbox').prop('checked', false);
      $("#lang").text("EN");
      console.log("language is english")
    }
    this.route.params.subscribe(params => {
      console.log(params);
      
      // this.id = params['id'];
      // console.log(this.id)
    });
    
    this.ngZone.run(()=> {
      this.url = window.location.pathname.split('/')[1];
      console.log(window.location.pathname)
      console.log(this.url);
      
    });
    
  }
  getChangelang(e) {
    console.log(e);
    
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
  }
  setLang(lang) {
    console.log('aaa')
    // console.log(lang.target.value)
    // console.log(lang.detail.value)
    // console.log(lang)
    this.currentLang = lang;
    console.log("69", lang)
    var useLang = { language: this.currentLang };
    console.log(useLang)
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);
    this.changeLang();
  }
  changeLang() {
    this.translate.use(this.currentLang);
    console.log(this.translate.currentLanguageValue)
  }
  logoutModal(){
    $('#logoutModal').modal('show');
  }
  closelogoutModal(){
    $('#logoutModal').modal('hide');
  }
  logOut() {
    this.ngxUiLoaderService.start();
    var data = {

    }
    this.api.logOut(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.response = data;
      if(this.response.status == "200"){
        setTimeout(() => {
          this.authenticationService.logout();
        this.show_dash = false;
        console.log(this.show_dash);
        $('#logoutModal').modal('hide')
        }, 100)
        this.ngxUiLoaderService.stop()
      } else {
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error=>{
      this.ngxUiLoaderService.stop();
    })

    
  }
  openNav() {
    $("#mySidenav").css("width", "305px");
    // document.getElementById("section_all").style.marginRight = "0px";	  
  }
  closeNav() {
    $('.uploadOptionsDrop').removeClass('drop')
    $("#mySidenav").css("width", "0");
  }

}
