import { Injectable } from '@angular/core';
import { HttpClient , HttpErrorResponse} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, Subject, throwError  } from 'rxjs';
// import { User } from '@app/_models';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })

  export class ApiService {

    private dataSubject = new Subject<string>();
    data$ = this.dataSubject.asObservable();
    
    constructor(private httpService: HttpClient,private http: HttpClient) { }


    private post(url, postData,options=null){
      if(options){
        var headerOptions = this.setHeaderOptions(options);
      }
      return this.httpService.post(url, postData,headerOptions)
          .pipe(map(data => {
            return data;
          })
      );
    }
    // post(url, postData, options = null): Observable<Blob> {
    //   const headerOptions = options ? this.setHeaderOptions(options) : {};
    //   return this.http.post(url, postData, { responseType: 'blob', headers: headerOptions }).pipe(
    //     catchError(this.handleError)
    //   );
    // }
    
    // private handleError(error: HttpErrorResponse) {
    //   console.error('An error occurred:', error);
    //   return throwError('Something bad happened; please try again later.');
    // }

    get(url, options=null){
      if(options){
        var headerOptions = this.setHeaderOptions(options);
      }
      return this.httpService.get<any>(url, headerOptions);
    }
    delete(url, id){
      return this.httpService.delete(url+id);
    }


    setHeaderOptions(paramsArray){
      let headers = new HttpHeaders();
  
      for(var key in paramsArray){
          if (paramsArray.hasOwnProperty(key)) {
             
                headers = headers.append(key, paramsArray[key]); 
          
          }
      }
      var headerArray = {headers};
      return headerArray;
    }

    signUp(data) {
      return this.post(`${environment.apiUrl}auths/register`,data);
    }
    login(data) {
      return this.post(`${environment.apiUrl}auths`,data);
    }
    choosePlan(data) {
      return this.post(`${environment.apiUrl}accounts/choose-plan`,data);
    }
    employeeCountForBusiness(data) {
      return this.post(`${environment.apiUrl}business-users`,data);
    }
    haveDomain(data) {
      return this.post(`${environment.apiUrl}business-users`,data);
    }
    getAccounts() {
      return this.get(`${environment.apiUrl}accounts`);
    }
    getBusinessAccountDetails() {
      return this.get(`${environment.apiUrl}business-users/get-business-user`);
    }
    forgotPasswordEmailSubmit(data) {
      return this.post(`${environment.apiUrl}auths/forgot-password`,data);
    }
    submitOtp(data) {
      return this.post(`${environment.apiUrl}auths/validate-otp`,data);
    }
    submitNewpassword(data) {
      return this.post(`${environment.apiUrl}auths/reset-password`,data);
    }
    logOut(data) {
      return this.post(`${environment.apiUrl}accounts/logout`,data);
    }
    addNewUser(data) {
      return this.post(`${environment.apiUrl}catalogues/add-user`,data);
    }
    deleteUser(data) {
      return this.post(`${environment.apiUrl}catalogues/remove-user`,data);
    }
    addroles(data) {
      return this.post(`${environment.apiUrl}permissions/add-roles`,data);
    }
    deleteRole(data) {
      return this.post(`${environment.apiUrl}permissions/delete-roles`,data);
    }
    savePermissions(data) {
      return this.post(`${environment.apiUrl}permissions`,data);
    }
    onSubmitmetaData(data) {
      return this.post(`${environment.apiUrl}templates`,data);
    }
    onCreatenewProject(data) {
      return this.post(`${environment.apiUrl}business-projects/create-project`,data);
    }
    updateProjectMembers(data) {
      return this.post(`${environment.apiUrl}business-projects/add-project-members`,data);
    }
    getProjects() {
      return this.get(`${environment.apiUrl}accounts/project-list`);
    }
    getCatdsDetails() {
      return this.get(`${environment.apiUrl}projects/recycle-view`);
    }
    getUsers() {
      return this.get(`${environment.apiUrl}catalogues/user-view`);
    }
    getGroups() {
      return this.get(`${environment.apiUrl}catalogues/group-view`);
    }
    getDesignations() {
      return this.get(`${environment.apiUrl}permissions/list-roles`);
    }
    getPermissions() {
      return this.get(`${environment.apiUrl}permissions`);
    }
    
    getDashboardDetails() {
      return this.get(`${environment.apiUrl}accounts/index-page`);
    }
    getProjectData(id) {
      return this.get(`${environment.apiUrl}business-projects/`+id);
    }
    getProjectSettings(id) {
      return this.get(`${environment.apiUrl}business-projects/project-setting-page/`+id);
    }
    getProjectMembers(data) {
      return this.get(`${environment.apiUrl}business-projects/list-project-members`, data);
    }
    
    deleteFolderandFile(folder_uuid: string) {
      return this.httpService.delete(`${environment.apiUrl}projects/` +folder_uuid);
    }
    upload(data) {
      return this.post(`${environment.apiUrl}users/upload`,data);
    }
    updateProfile(data) {
      return this.post(`${environment.apiUrl}users`,data);
    }
    deleteGroups(data){
      return this.httpService.delete(`${environment.apiUrl}catalogues/`+data);
    }
    editGroupdetails(groupid){
      return this.get(`${environment.apiUrl}catalogues/group-list/`+groupid);
    }
    getMetaData() {
      return this.get(`${environment.apiUrl}templates`);
    }
    deleteTemplate(uuid){
      return this.httpService.delete(`${environment.apiUrl}templates/`+uuid);
    }
    deleteForever(data){
      return this.post(`${environment.apiUrl}projects/delete-forever`,data);
    }
    restoreDeletedFilesorFolders(data){
      return this.post(`${environment.apiUrl}projects/restore-file`,data);
    }
    bulkDeletefiles(FileId){
      return this.post(`${environment.apiUrl}projects/bulk-delete`,FileId);
    }
    deleteProject(project_Id){
      return this.httpService.delete(`${environment.apiUrl}business-projects/`+project_Id);
    }
    restoreLater(data){
      return this.post(`${environment.apiUrl}projects/restore-later`,data);
    }
    getrestoreLater(){
      return this.get(`${environment.apiUrl}projects/restore-page`);
    }
    bulkRestore(data){
      return this.post(`${environment.apiUrl}projects/bulk-restore`,data);
    }
    getTemplate(){
      return this.get(`${environment.apiUrl}accounts/template-list`);
    }
    getDestructionReports(){
      return this.get(`${environment.apiUrl}projects/destruction-file-report`);
    }
    updategroup(data,groupid){
      return this.post(`${environment.apiUrl}catalogues/${groupid}`,data);
    }
    createGroup(data){
      return this.post(`${environment.apiUrl}catalogues/add-group`,data);
    }
    createNewFolder(data){
      return this.post(`${environment.apiUrl}business-projects/create-folder`,data);
    }
    getProject(){
      return this.get(`${environment.apiUrl}accounts/project-list`);
    }
    getTag(){
      return this.get(`${environment.apiUrl}accounts/tag-list`);
    }
    getViewDetails(id) {
      return this.get(`${environment.apiUrl}business-projects/project-file-preview`,{fileId:id });
    }
    getFolderFileDetail(uuid) {
      return this.get(`${environment.apiUrl}folders/folder-structure/`+uuid);
    }
    getFolderFileDetail1(uuid) {
      return this.get(`${environment.localApiUrl}cards.json`);
    }
    sidebarfileUpload(data){
      return this.post(`${environment.apiUrl}files/upload`,data)
    }
    copyProjectFile(data){
      return this.post(`${environment.apiUrl}projects/copy-file`,data)
    }
    getActiveUser(data){
      return this.get(`${environment.apiUrl}catalogues/user-view`,data);
    }
    // moveProjectFiles(data){
    //   return this.post(`${environment.apiUrl}projects/move-file`,data)
    // }
    resetChangePassword(data) {
      return this.post(`${environment.apiUrl}accounts/change-password`,data);
    }
    markAllread(data){
      return this.post(`${environment.apiUrl}notifications/mark-read`,data)
    }
    moveProjectFolderOrfile(data){
      return this.post(`${environment.apiUrl}projects/move-file`,data)
    }
    renameFile(data){
      return this.post(`${environment.apiUrl}projects/rename-file`,data)
    }
    // downloadFIles(data){
    //   return this.post(`${environment.apiUrl}files/download`,data,{ responseType: 'blob' })
    // }

    serchFilesorfolder(data){
      return this.post(`${environment.apiUrl}accounts/search-file`,data)
    }

    getNotification(){
      return this.get(`${environment.apiUrl}notifications/notification-page`);
    }
    onCreatenewProjectType(data) {
      return this.post(`${environment.apiUrl}business-projects/add-project-type`,data);
    }
    getProjectType(){
      return this.get(`${environment.apiUrl}business-projects/list-project-type`);
    }
    getTemplateDetails(uuid){
      return this.get(`${environment.apiUrl}templates/`+uuid);
    }
    applyFilterType(data){
      return this.post(`${environment.apiUrl}accounts/filter`,data);
    }
    getListDirectories(){
      return this.get(`${environment.apiUrl}projects/list-directories`);
    }
    getSharedView(data){
      return this.get(`${environment.apiUrl}projects/shared-file-view`,data);
    }
    getProjectDetails(){
      return this.get(`${environment.apiUrl}projects/list-directories`);
    }

    bulkDeletePermenently(data){
      return this.post(`${environment.apiUrl}projects/bulk-archive`,data);
    }

    submitProjectupdate(data,uuid){
      return this.post(`${environment.apiUrl}business-projects/`+uuid,data);
    }
    addGroupmember(data){
      return this.post(`${environment.apiUrl}business-projects/add-project-members`,data);
    }
    getReport(){
      return this.get(`${environment.apiUrl}reports`);
    }
    shareProjectFileFolder(data){
      return this.post(`${environment.apiUrl}projects/share-file-create`,data);
    }
    removeProjectTag(data){
      return this.post(`${environment.apiUrl}business-projects/remove-tag`,data);
    }
    deleteUserGroup(data) {
      return this.post(`${environment.apiUrl}business-projects/remove-members`,data);
    }
    getProjectLink(data){
      // return this.get(`${environment.apiUrl}business-projects/get-link/`+uuid);
      return this.post(`${environment.apiUrl}business-projects/get-link`,data);
    }
    saveUploaddetails(data){
      return this.post(`${environment.apiUrl}business-projects/save-file`,data);
    }
    getSignatureRequestSendList(){
      return this.get(`${environment.apiUrl}signatures/signature-request-send-list`);
    }
    getSignatureIncomingRequest(){
      return this.get(`${environment.apiUrl}signatures/signature-incoming-request`);
    }
    gettemplateData(uuid){
      return this.get(`${environment.apiUrl}templates/`+uuid);
    }
    signatureRequest(data){
      return this.post(`${environment.apiUrl}signatures/signature-request`,data);
    }
    rejectSignature(data){
      return this.post(`${environment.apiUrl}signatures/reject-signature`,data);
    }
    addSignature(data){
      return this.post(`${environment.apiUrl}signatures/add-signature`,data);
    }
    deleteSignature(data){
      return this.post(`${environment.apiUrl}signatures/delete-signature`,data);
    }
    cancelSignatureRequest(data){
      return this.post(`${environment.apiUrl}signatures/cancel-signature-request`,data);
    }
    getFolderallList(){
      return this.get(`${environment.apiUrl}folders`);
    }
    deleteProjecttype(data){
      return this.post(`${environment.apiUrl}business-projects/remove-project-type`,data);
    }
    getFilterNotification(data){
      return this.post(`${environment.apiUrl}notifications/notification-filter`,data);
    }
    createPriority(data){
      return this.post(`${environment.apiUrl}business-projects/add-priority`,data);
    }
    getPrioritydata(){
      return this.get(`${environment.apiUrl}business-projects/list-priority`);
    }
    deletePriority(data){
      return this.post(`${environment.apiUrl}business-projects/delete-priority`,data);
    }
    getArchievedFiles(){
      return this.get(`${environment.apiUrl}archives/archive-list`);
    }
    archievedFiles(data){
      return this.post(`${environment.apiUrl}archives/archive-file`,data);
    }
    uploadContactData(data){
      return this.post(`${environment.apiUrl}auths/add-contacts`,data);
    }
    passwordModal(data){
      return this.post(`${environment.apiUrl}archives/verify-password`,data);
    }
    getArchievedData(data){
      return this.get(`${environment.apiUrl}archives/`+data);
    }
    restoreArchivedFiles(data){
      return this.post(`${environment.apiUrl}archives/restore-arcived-item`,data);
    }

    updateFolderTimeline(data){
      return this.post(`${environment.apiUrl}folders/edit-time-line`,data);
    }
    onReorderFIles(data){
      return this.post(`${environment.apiUrl}files/file-reordering`,data);
    }
    
    checkOutfile(data){
      return this.post(`${environment.apiUrl}business-projects/checkout`,data);
    }
    updateROles(data){
      return this.post(`${environment.apiUrl}permissions/update-role`,data);
    }
    customisedReport(data){
      return this.post(`${environment.apiUrl}reports/total-count-report`,data);
    }
    downloadFile(data){
      return this.post(`${environment.apiUrl}files/download`,data);
    }
  


    setData(data: string) {
      this.dataSubject.next(data);
      return;
    }
    
  }