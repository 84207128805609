import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd  } from '@angular/router';
import { ApiService } from '../_services/api.services';
import { AuthenticationService } from '../_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Unauthorised implements CanActivate {
    
    isConnection: any;
    userkycdata: any;
    response:any;
    url:any;
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;
    
    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private api: ApiService,
                private http: HttpClient,
                private snackBar: MatSnackBar,
                private ngZone:NgZone,
                ) { 
                    // console.log("online + " +  navigator.onLine)
                    // if (navigator.onLine) {
                    //     this.isConnection = true;
                    // } else {
                    //     this.isConnection = false;
                    // }
                    this.currentUrl = this.router.url;
                    router.events.subscribe(event => {
                      if (event instanceof NavigationEnd) {
                        this.previousUrl = this.currentUrl;
                        this.currentUrl = event.url;

                        console.log(this.previousUrl, " dsdsd ", this.currentUrl)
                      };
                    });
                }

                canActivate() {
                    const currentUser = this.authenticationService.currentUserValue;
                    console.log(currentUser)
                    if (currentUser) {
                        console.log("hiAzeez");
                        this.api.getAccounts().subscribe(data => {
                            this.response = data;
                            if(this.response.status == "200") {
                              console.log(this.response.results.stage,"sadsadsdsadddddd");
                              if(this.response.results.stage == '400') {
                                console.log(this.response.results.stage,"hiAzeez000");
                                return true;
                              }else {
                                // this.ngZone.run(()=> {
                                //   this.url = window.location.pathname.split('/')[1];
                                //   console.log(this.url)
                                // });
                                this.router.navigate(['/']);
                              }
                            }else {
                              // this.snackBar.open(this.response.message, "Alerte", {
                              //   duration: 2000,
                              // });
                            }
                          },error => {
                          });
                    }else {
                      console.log("line 61")
                      this.router.navigate(['/']);
                      return false;
                    }
            
                    // not logged in so redirect to login page with the return url
                    // this.router.navigate(['/']);
                    
                }
                
                
                businessComplete() {
                  const currentUser = this.authenticationService.currentUserValue;
                  console.log(currentUser)
                  if (currentUser) {
                      console.log("hiAzeez");
                      this.api.getAccounts().subscribe(data => {
                          this.response = data;
                          if(this.response.status == "200") {
                            console.log(this.response.results.stage,"sadsadsdsadddddd");
                            if(this.response.results.stage == '400') {
                              console.log(this.response.results.stage,"hiAzeez000");
                              this.router.navigate(['/dashboard']);
                              return false;
                            }else {
                              // this.ngZone.run(()=> {
                              //   this.url = window.location.pathname.split('/')[1];
                              //   console.log(this.url)
                              // });
                              // this.router.navigate(['/']);
                              return true;
                            }
                          }else {
                            this.snackBar.open(this.response.message, "Alerte", {
                              duration: 2000,
                            });
                          }
                    
                        },error => {
                        });



                      
                  }else {
                    console.log("line 61");
                    this.router.navigate(['/']);
                    return false;
                  }
          
                  // not logged in so redirect to login page with the return url
                  // this.router.navigate(['/']);
                  
              }  
                
                
}   