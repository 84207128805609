import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { FormGroup, FormBuilder,  Validators  } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.services';
import 'aos/dist/aos.css';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from './../../../_services/translate.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthenticationService } from '../../../_services/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public emailForm : FormGroup;
  public otpForm : FormGroup;
  public newpasswordForm : FormGroup;
  emailSubmitted = false;
  response:any;
  emailform = true;
  otpform = false;
  passwordform = false;
  otpSubmitted = false;
  newpassSubmitted = false;
  token: any;
  currentLang: any;
  showPasswordIcon = "fa-solid fa-eye-slash";
  showPasswordIcon2 = "fa-solid fa-eye-slash";
  showPassword2 = false;
  showPassword = false;
  passwordFieldType2: string = "password";
  passwordFieldType: string = "password";
 

  constructor(public formBuilder: FormBuilder,
              public snackBar : MatSnackBar,
              public api : ApiService,
              private router: Router,
              private translate: TranslateService,
              private ngxUiLoaderService: NgxUiLoaderService,
              private authenticationService: AuthenticationService ) {
                if (this.authenticationService.currentUserValue) { 
                  this.router.navigate(['/dashboard']);
                }
              }

  ngOnInit() {

    this.emailForm = this.formBuilder.group({
      email : ['',[Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
    })
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]],
    })

    this.newpasswordForm = this.formBuilder.group({
      password: ['', [Validators.required,Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[A-Za-z\\d$@$!%*?#&](?!.*\\s).{7,}$")]],
      repeatnewpass: ['', [Validators.required]]
    },{
      validators: this.passwordMatchValidator
    })
    

    AOS.init({ disable: 'mobile'});
    $('#emailSubmit').click(function(){
      // $("#forgot_1").css("display", "none");
      // $("#forgot_2").css("display", "block");
      // $("#forgot_2").css("opacity", "1");
      // $("#forgot_2").css("visibility", "initial");
      
      setTimeout(function() { 
        AOS.init({
          disable: function() {
            var maxWidth = 600;
            return window.innerWidth < maxWidth;
          }
        });
        console.log("sdafsafdsf")
      }, 1000);
    });

    $('#otpSubmit').click(function(){
          $("#forgot_1").css("display", "none");
          $("#forgot_2").css("display", "none");
          $("#forgot_3").css("display", "block");
          AOS.refresh();
    });
    this.currentLang = localStorage.getItem('language') || "fr";
    if (this.currentLang == 'fr') {
      $('.checkbox').prop('checked', false);
    } else {
      $('.checkbox').prop('checked', true);
    }
    if (this.currentLang == 'fr') {
      $('.myCheckbox').prop('checked', true);
      $("#lang").text("FR");
      console.log("language is french")
    } else {
      $('.myCheckbox').prop('checked', false);
      $("#lang").text("EN");
      console.log("language is english")
    }
  }

  getErrorMessage(repeatnewpass: string): string {
    const control = this.newpasswordForm.get(repeatnewpass);
    if (control) {
      if (control.hasError('required')) {
        return 'You must enter a value';
      }
      if (control.hasError('pattern')) {
        return 'Password must meet complexity requirements';
      }
      if (repeatnewpass === 'repeatnewpass' && this.newpasswordForm.hasError('passwordMismatch')) {
        return 'Passwords do not match';
      }
    }
    return '';
  }
  
  passwordMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.controls['password'];
    console.log(passwordControl);
    
    const repeatPasswordControl = formGroup.controls['repeatnewpass'];
    console.log(repeatPasswordControl);
    

    if (passwordControl.value !== repeatPasswordControl.value) {
      repeatPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      // repeatPasswordControl.setErrors(null);
    }
  }

  getChangelang(e) {
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
  }

  setLang(lang) {
    console.log('aaa')
    // console.log(lang.target.value)
    // console.log(lang.detail.value)
    // console.log(lang)
    this.currentLang = lang;
    console.log("69", lang)
    var useLang = { language: this.currentLang };
    console.log(useLang)
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);
    this.changeLang();
  }
  changeLang() {
    this.translate.use(this.currentLang);
    console.log(this.translate.currentLanguageValue)
  }

  get emailError() {
    return this.emailForm.controls;
  }
  get h() {
    return this.otpForm.controls;
  }
  get b() {
    return this.newpasswordForm.controls;
  }

  onSubmitEmailform() {
    console.log(this.emailForm.value);
    if(this.emailForm.invalid) {
      this.emailSubmitted = true;
      return
    }
    this.emailSubmitted = true;
    // this.otpform = true;

    this.ngxUiLoaderService.start();
    this.api.forgotPasswordEmailSubmit(this.emailForm.value).subscribe(data=>{
      this.response = data;
      console.log(this.response)
      if(this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        this.otpform = true;
        this.emailform = false;
        this.passwordform = false;
      } 
      else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })

      }
      
    },error => {

    });
  }

  onSubmitOtpform() {
    // this.emailform = false;
    // this.otpform = false;
    
    console.log(this.otpForm.value,'777777777777777777');
    
    if(this.otpForm.invalid) {
      this.otpSubmitted = true;
      return
    }
    this.otpSubmitted = true;
    var data = {
      otp: this.h.otp1.value + this.h.otp2.value + this.h.otp3.value + this.h.otp4.value,
      email: this.emailForm.value.email
    }
    this.ngxUiLoaderService.start();
    this.api.submitOtp(data).subscribe(data=>{
      this.response = data;
      console.log(this.response,'66666666666666')
      if(this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        this.token = this.response.results.token;
        console.log(this.token,'11111111111111111');
        this.passwordform = true;
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
        this.emailform = false;
        this.otpform = false;
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {

    });
  }

  onSubmitNewPasswordform() {
    console.log(this.newpasswordForm);
   
    if(this.newpasswordForm.invalid) {
      this.newpassSubmitted = true;
      return
    }
    this.newpassSubmitted = true;
    var data = this.newpasswordForm.value;
    data['email'] = this.emailForm.value.email;
    data['token'] = this.token;
    this.ngxUiLoaderService.start();
    this.api.submitNewpassword(data).subscribe(data=>{
      this.response = data;
      console.log(this.response)
      if(this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        // this.router.navigate(['/login'])
        this.router.navigate(['/'])
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
        
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
        
      }
      
    })
  }


  getBack() {
    if(this.emailform == true) {
      // this.router.navigate(['/login']);
      this.router.navigate(['/']);
    }else if(this.otpform == true) {
      this.emailform = true;
      this.passwordform = false;
      this.otpform = false;
    }else {
      this.otpform = true;
      this.emailform = false;
      this.passwordform = false;
    }
    
  }

  

  moveToNext(event: any, index: number) {
    const inputLength = event.target.value.length;
    // if (inputLength === 1) {
    //   const nextInput = document.querySelector(`input[formControlName="otp${index + 1}"]`) as HTMLInputElement;
    //     if (nextInput) {
    //         nextInput.focus();
    //     }
    // }
    const nextIndex = index + 1;

    if (event.inputType === 'deleteContentBackward' && inputLength === 0) {
      const previousIndex = index - 1;
      if (previousIndex >= 0) {
        const previousInput = document.querySelector(`input[formControlName="otp${previousIndex}"]`) as HTMLInputElement;
        if (previousInput) {
          previousInput.focus();
        }
      }
    } else if (inputLength === 1) {
      const nextInput = document.querySelector(`input[formControlName="otp${nextIndex}"]`) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
}
setShowPassword(id){
  if(id == "a") {
    this.showPassword = (this.showPassword)?false:true;
    if(this.showPassword){
        this.passwordFieldType= "text";
        this.showPasswordIcon = "fa-solid fa-eye";
    }else{
        this.passwordFieldType = "password";
        this.showPasswordIcon = "fa-solid fa-eye-slash";
    }
  }else {
    this.showPassword2 = (this.showPassword2)?false:true;
    if(this.showPassword2){
        this.passwordFieldType2= "text";
        this.showPasswordIcon2 = "fa-solid fa-eye";
    }else{
        this.passwordFieldType2 = "password";
        this.showPasswordIcon2 = "fa-solid fa-eye-slash";
    }
  }
  
}

routeLanding(){
  this.router.navigateByUrl('/');
}

}


