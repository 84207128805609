import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('repeatnewpass').value;

    if (!confirmPassword && password) {
      control.get('confirmPassword').setErrors({ required: true });
    } else if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      if (control.get('confirmPassword').errors) {
        if (!control.get('confirmPassword').errors.required || control.get('confirmPassword').errors.ConfirmPassword) {
          control.get('confirmPassword').setErrors(null);
        }
      }
    }
    return null;
  }
}
export class ConfirmPasswordValidator2 {
  static MatchPassword2(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('confirm_password').value;

    if (!confirmPassword && password) {
      control.get('confirm_password').setErrors({ required: true });
    } else if (password !== confirmPassword) {
      control.get('confirm_password').setErrors({ ConfirmPassword: true });
    } else {
      if (control.get('confirm_password').errors) {
        if (!control.get('confirm_password').errors.required || control.get('confirm_password').errors.ConfirmPassword) {
          control.get('confirm_password').setErrors(null);
        }
      }
    }
    return null;
  }
}

export class ConfirmPasswordValidatorMpin {
  static MatchPassword1(control: AbstractControl) {
    let password = control.get('mpin1').value;
    let confirmPassword = control.get('mpin2').value;
    if (password != confirmPassword) {
      control.get('mpin2').setErrors({ ConfirmPassword: true });
    } else {
      if (control.get('mpin2').value) {
        control.get('mpin2').setErrors(null);
        return null;
      } else {
        return null;
      }
    }
  }
}




// export class ConfirmPasswordValidator {
//   static MatchPassword(control: AbstractControl) {
//     let password = control.get('password').value;
//     let confirmPassword = control.get('confirmPassword').value;
//     if (password != confirmPassword) {
//       control.get('confirmPassword').setErrors({ ConfirmPassword: true });
//     } else {
//       if (control.get('confirmPassword').value ) {
//         control.get('confirmPassword').setErrors(null);
//         return null;
//       } else {
//         return null;
//       }
//     }
//   }
// }
// export class ConfirmPasswordValidator {
//   static MatchPassword(control: AbstractControl) {
//     let password = control.get('password').value;
//     let confirmPassword = control.get('confirmPassword').value;

//     if (!confirmPassword && password) {
//       control.get('confirmPassword').setErrors({ required: true });
//     } else if (password !== confirmPassword) {
//       control.get('confirmPassword').setErrors({ ConfirmPassword: true });
//     } else {
//       if (control.get('confirmPassword').errors) {
//         if (!control.get('confirmPassword').errors.required || control.get('confirmPassword').errors.ConfirmPassword) {
//           control.get('confirmPassword').setErrors(null);
//         }
//       }
//     }
//     return null;
//   }
// }
// export class ConfirmPasswordValidatorMpin {
//   static MatchPassword1(control: AbstractControl) {
//     let password = control.get('mpin1').value;
//     let confirmPassword = control.get('mpin2').value;
//     if (password != confirmPassword) {
//       control.get('mpin2').setErrors({ ConfirmPassword: true });
//     } else {
//       if (control.get('mpin2').value) {
//         control.get('mpin2').setErrors(null);
//         return null;
//       } else {
//         return null;
//       }
//     }
//   }
// }
