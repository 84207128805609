<app-sidebar></app-sidebar>
<app-header></app-header>
<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">
                
                <div class="catalogueFunction mb-4">
                   
                    <div class="toggle_screen">
                        <div class="one tabOption" (click)="clickTab('user')">
                            <h5 [ngClass]="{'color_blue': userSection == true}">{{'Documents' | translate}}</h5>
                            <div [ngClass]="{'lineExtent1': userSection == true, 'line': userSection == false}" ></div>
                        </div>
                        <div class="two tabOption" (click)="clickTab('group')">
                            <h5 [ngClass]="{'color_blue': groupSection == true}">{{'Requests' | translate}}</h5>
                            <div [ngClass]="{'lineExtent2': groupSection == true, 'line': groupSection == false}" ></div>
                        </div>
                    </div>
                </div>



                <div *ngIf="groupSection" class="catalogueUser" id="tab-2">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--4cols Rtable--collapse"  id="table-main">
                            <div class="Rtable-row Rtable-row--head">
                                <div class="Rtable-cell name-cell column-heading">{{'Documents' | translate}}</div>
                                <div class="Rtable-cell email-cell column-heading" [ngClass]="{'removeItem': showSideBar == true}">{{'Requested by' | translate}}</div>
                                <div id="phone-id" class="Rtable-cell phone-cell column-heading" >{{'Requested on' | translate}}</div>
                                <div id="actions" class="Rtable-cell action-cell column-heading">{{'Actions' | translate}}</div>
                            </div>
                            
                            <div class="Rtable-row is-striped" *ngFor="let items of SignatureIncomingRequestList index as i">
                                <div  class="Rtable-cell email-cell" id="emailId" >
                                    <div class="Rtable-cell--heading">{{'Documents' | translate}}</div>
                                    <div class="Rtable-cell--content replay-link-document">{{items.document}}</div>

                                </div>

                                <div class="Rtable-cell name-cell d-flex" id="nameId" [ngClass]="{'removeItem': showSideBar == true}">
                                    <div class="Rtable-cell--heading">{{'Name' | translate}}</div>
                                    <div class="requestedUserImg1"><img src="{{items.requested_to.profile_pic}}" alt=""></div>
                                    <div class="nameEmail-section">
                                        <div class="Rtable-cell--content name-content">{{items.requested_to.name}}</div>
                                        <div class="Rtable-cell--content replay-link-content">{{items.requested_to.email}}</div>
                                    </div>
                                </div>
                               
                                <div class="Rtable-cell phone-cell" id="phoneId" [ngClass]="{'removeItem': showSideBar == true}" >
                                    <div class="Rtable-cell--heading">{{'Requested on' | translate}}</div>
                                    <div class="Rtable-cell--content date-content"><span class="">{{items.requested_on}}</span></div>
                                </div>
                            
                                <div *ngIf="items.status === 2; else addAction" id="add-reject" class="Rtable-cell action-cell">
                                    <div id="tick-id" class="Rtable-cell action-cell actionCenter">
                                        <div class="Rtable-cell--heading">{{ 'Action' | translate }}</div>
                                        <div class="Rtable-cell--content access-link-content">
                                            <div class="d-flex align-items-center">
                                                <div class="rejected-red">{{ 'Rejected' | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <ng-template #addAction>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">{{ 'Action' | translate }}</div>
                                        <div class="Rtable-cell--content access-link-content">
                                            <div class="button-add-reject align-items-center d-block">
                                                <button id="add-button" class="btn shadow-none" (click)="addSignaturerequest()">{{ 'Add Signature' | translate }}</button>
                                                <button (click)="rejectModal(items.uuid)" id="reject-button" class="btn shadow-none">{{ 'Reject' | translate }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            
                            </div>

                            <div *ngIf="SignatureIncomingRequestList && SignatureIncomingRequestList.length == 0" class="text-center mt-5 w-100">
                                <img src="assets/images/nosign.jpg" class="img-fluid mb-2">
                                <p>{{'No Requests Found' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="userSection" class="catalogueUser" id="tab-1">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--5cols Rtable--collapse"  id="table-main">
                            <div class="Rtable-row Rtable-row--head">
                                <div class="Rtable-cell name-cell column-heading ">{{'Documents' | translate}}</div>
                                <div class="Rtable-cell email-cell column-heading remove_user" >{{'Requested to' | translate}}</div>
                                <div [ngClass]="{'request_sec': isSidebarOpen}" class="Rtable-cell phone-cell column-heading remove-item">{{'Requested on' | translate}}</div>
                                <div id="actions1" class="Rtable-cell role-cell  column-heading columnWithnoContent  text-center">{{'Status' | translate}}</div>
                                <div id="actions" class="Rtable-cell action-cell column-heading ">{{'Actions' | translate}}</div>
                            </div>
                            
                            <div class="Rtable-row is-striped" *ngFor="let items of SignatureRequestDataList index as i" >
                                <div  class="Rtable-cell name-cell">
                                    <div class="Rtable-cell--heading">{{'Documents' | translate}}</div>
                                    <div class="Rtable-cell--content replay-link-document">{{items.document}}</div>

                                </div>
                                <div class="Rtable-cell name-cell d-flex remove_user" >
                                    <div class="Rtable-cell--heading remove_user" >{{'Name' | translate}}</div>
                                    <div class="requestedUserImg remove_user" ><img src="{{items.requested_to.profile_pic}}" alt=""></div>
                                    <div class="nameEmail-section" >
                                        <div class="Rtable-cell--content name-content remove_user" >{{items.requested_to.name}}</div>
                                        <div class="Rtable-cell--content replay-link-content remove_user" >{{items.requested_to.email}}</div>
                                    </div>
                                </div>
                                
                                <div class="Rtable-cell phone-cell remove-item" [ngClass]="{'request_sec': isSidebarOpen}">
                                    <div class="Rtable-cell--heading">{{'Requested on' | translate}}</div>
                                    <div class="Rtable-cell--content date-content"><span class="">{{items.requested_on}}</span></div>
                                </div>

                                <ng-container *ngIf="items.status == 1" >
                                    <div id="download-cell" class="Rtable-cell role-cell ">
                                        <div class="Rtable-cell--heading remove_head">{{'Status' | translate}}</div>
                                        <div class="Rtable-cell--content access-link-content">
                                            <div class="d-flex align-items-center ">
                                                <div class="requested-yellow">{{ 'Requested' | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="items.status == 2" >
                                    <div id="download-cell" class="Rtable-cell role-cell">
                                        <div class="Rtable-cell--heading remove_head">{{'Status' | translate}}</div>
                                        <div class="Rtable-cell--content access-link-content">
                                            <div id="error-id" class="d-flex align-items-center pulse" #tooltip="matTooltip"
                                            matTooltip="{{items.reason}}"
                                            matTooltipHideDelay="500">
                                                <img class="mr-12 delete-icon" src="assets/images/mark.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="items.status == 3" >
                                    <div id="download-cell" class="Rtable-cell role-cell">
                                        <div class="Rtable-cell--heading remove_head">{{'Status' | translate}}</div>
                                        <div class="Rtable-cell--content access-link-content">
                                            <div class="d-flex align-items-center">
                                                <div class="download-green mr-12">{{'Download' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="items.status == 1">
                                    <div id="cancel-request-id" class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">{{ 'Action' | translate }}</div>
                                        <div class="Rtable-cell--content access-link-content">
                                            <div class="d-flex align-items-center">
                                                <button class="cancels btn shadow-none" (click)="cancelSignaturedata(items.uuid)">{{ 'Cancel Request' | translate }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                    <ng-container *ngIf="items.status == 2">
                                        <div id="tick-id" class="Rtable-cell action-cell">
                                            <div class="Rtable-cell--heading">{{ 'Action' | translate }}</div>
                                            <div class="Rtable-cell--content access-link-content">
                                                <div class="d-flex align-items-center">
                                                    <div class="rejected-red">{{ 'Rejected' | translate }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="items.status == 3">
                                        <div id="tick-id" class="Rtable-cell action-cell">
                                            <div class="Rtable-cell--heading">{{ 'Action' | translate }}</div>
                                            <div class="Rtable-cell--content access-link-content">
                                                <div class="d-flex align-items-center">
                                                    <img class="mr-12 share-icon" src="assets/images/tick.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                            </div>
                            <div *ngIf="SignatureRequestDataList && SignatureRequestDataList.length == 0" class="text-center mt-5 w-100">
                                <img src="assets/images/nosign.jpg" class="img-fluid mb-2">
                                <p>{{'No Documents Found' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-file-preview (sidebarOpened)="onSidebarOpened($event)"></app-file-preview>
        </div>
       
        

        <div class="modal fade" id="addUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>{{'Add New User' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="input-container">
                        <div class="input">
                            <label class="label-test" for="name">{{'First Name' | translate}}</label>
                            <input type="text" value="John">
                        </div>
                        <div class="input">
                            <label class="label-test" for="name">{{'Last  Name' | translate}}</label>
                            <input type="text" value="Luther">
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input">
                            <label class="label-test" for="name">{{'Job Position' | translate}}</label>
                            <input type="text" value="Marketing Executive">
                        </div>
                        <div class="input">
                            <label class="label-test" for="name">{{'Date of Birth' | translate}}</label>
                            <mat-form-field class="example-full-width" appearance="none">
                                <input matInput [matDatepicker]="dp1" [matDatepicker]="picker" readonly >
                                <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input">
                            <label class="label-test" for="name">{{'Email Address' | translate}}</label>
                            <input type="text" value="johnluther00@gmail.com">
                        </div>
                        <div class="input">
                            <label class="label-test" for="phone">{{'Mobile Number' | translate}}</label>
                            <input  name="phone" type="text" id="phone" value="7464 73662">
                        </div>
                    </div>
                    <button class="btn_primary bg_blue"data-toggle="modal" data-bs-toggle="modal" data-bs-target="#inviteSuccessModal">{{'Invite' | translate}}</button>
                  
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Understood</button>
                </div> -->
              </div>
            </div>
          </div>


          <div class="modal fade" id="inviteSuccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <!-- <h5>Add New User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <img src="assets/images/success_pic1.png" alt="assets/images/success_pic1.png">
                    <div class="text">{{'Your invite for' | translate}} <span>johnluther00@gmail.com</span> <br>{{'has been sent successfully' | translate}}</div>
                    <button class="btn_primary bg_blue" data-bs-dismiss="modal" aria-label="Close">{{'Go Home' | translate}}</button>
                </div>
                
              </div>
            </div>
          </div>

          <div class="modal fade" id="create-group" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="create-groupLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>{{'Create Group' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="container">
                        <div class="main">
                            <div class="row mt-3">
                                <div class="col-md-12 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">{{'First Name' | translate}}</label>
                                        <input type="text" value="John">
                                    </div>
                                </div>
            
                                <div class="col-md-12 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">{{'Last  Name' | translate}}</label>
                                        <input type="text" value="Luther">
                                    </div>
                                </div>
                            </div>
                            <div class="row">


                                <div class="col-md-12">
                                    <div class="addMember">

                                        <div class="dropdown show">
                                            {{'Add Member' | translate}}s <i class="fa-solid fa-angle-down"></i>
                                          </div>

                                          <div class="d-flex align-items-center">
                                            <ul class="p-0">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                            </ul>
                                        </div>

                                    </div>


                                    <button data-bs-dismiss="modal" aria-label="Close" id="business-info-change" class="btn-primary">{{'Create Group' | translate}}</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Understood</button>
                </div> -->
              </div>
            </div>
          </div>


          <div class="modal fade" id="createProjectModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalToggleLabel">{{'Add Group' | translate}}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="gro_name-container">
                        <div class="title mt-3">{{'Group Name' | translate}}</div>
                        <input type="text">
                    </div>
                  
                    <div class="input_style ">
                        <label for="exampleInputEmail1" class="title mt-4 mb-2">{{'Templates' | translate}}</label>

                        <div class="tagArea">
                            <input type="text" id="tag-input1">
                        </div>
                    </div>
              
                    <div class="title mt-4 mb-2">{{'Group Members' | translate}}</div>
                    <div class="membersList">
                        <div class="members_container">
                            <div class="members">
                                <div><img class="" src="assets/images/addedMembers1.png" alt="">Peter parker</div>
                                <img class="" src="assets/images/close_sm.png" alt="">
                            </div>
                            <div class="members">
                                <div><img class="" src="assets/images/addedMembers2.png" alt="">Peter parker</div>
                                <img class="" src="assets/images/close_sm.png" alt="">
                            </div>
                            <div class="members">
                                <div><img class="" src="assets/images/addedMembers3.png" alt="">Peter parker</div>
                                <img class="" src="assets/images/close_sm.png" alt="">
                            </div>
                            <div class="members">
                                <div><img class="" src="assets/images/addedMembers4.png" alt="">Peter parker</div>
                                <img class="" src="assets/images/close_sm.png" alt="">
                            </div>
                            <div class="members">
                                <div><img class="" src="assets/images/addedMembers3.png" alt="">Peter parker</div>
                                <img class="" src="assets/images/close_sm.png" alt="">
                            </div>
                            <div class="members">
                                <div><img class="" src="assets/images/addedMembers4.png" alt="">Peter parker</div>
                                <img class="" src="assets/images/close_sm.png" alt="">
                            </div>
                        </div>
                    </div>
               
                    <div class="title mt-4 mb-2">{{'Add Users' | translate}}</div>
                    <div class="addUserList">
                        <div class="groups">
                            <div class="list">
                                <div class="logoArea">
                                    <img class="" src="assets/images/dev1.png" alt="">
                                    <div>Mars kony <span>marskony@gmail.com</span></div>
                                </div>
                                <button class="btn_primary bg_blue" >{{'Add'| translate}}</button>
                            </div>
                            <div class="list">
                                <div class="logoArea">
                                    <img class="" src="assets/images/dev2.png" alt="">
                                    <div>James bellward <span>jamesbell@gmail.com</span></div>
                                </div>
                                <button class="btn_primary bg_blue">{{'Add' | translate}}</button>
                            </div>
                            <div class="list">
                                <div class="logoArea">
                                    <img class="" src="assets/images/dev3.png" alt="">
                                    <div>Casper kay <span>casperkay@gmail.com</span></div>
                                </div>
                                <button class="btn_primary bg_blue">{{'Add' | translate}}</button>
                            </div>
                            <div class="list">
                                <div class="logoArea">
                                    <img class="" src="assets/images/dev1.png" alt="">
                                    <div>Mars kony <span>marskony@gmail.com</span></div>
                                </div>
                                <button class="btn_primary bg_blue">{{'Add' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn_primary bg_blue" data-bs-toggle="modal" href="">{{'Create Group' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
    
    </div>

    <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Add New Project' | translate}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="projectNameAdd">
                <div class="modal-body">
                    <div class="mt-5">
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Datas">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                    <button id="folderCreate" (click)="folderCreate()" class="btn-primary btn-style" >{{'Create' | translate}}</button>
                </div>
            </div>

            <div id="folderName_And_Tagsection">
                <div class="modal-body">
                    <div class="mt-2">
                        <label for="">{{'Folder Name' | translate}}</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Offer Letter">
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="">{{'Tags' | translate}}</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Tags" name="" id="">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                    <button id="folderCreate" class="btn-primary btn-style"  data-bs-dismiss="modal" aria-label="Close">{{'Create' | translate}}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal (when reject button is clicked)-->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" *ngFor="let items of SignatureIncomingRequestList index as i">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" id="custom-padding">
                <div>
                    <div class="modal-header" id="modal_header_id">
                        <h1 class="reject-h1 modal-title fs-5 w-100 text-center" id="staticBackdropLabel">{{'Reject Signature Request' | translate}}</h1>
                        <button type="button" id="button_close" class="btn-close me-5" data-bs-dismiss="modal" (click)="closseModal()" aria-label="Close"></button>
                    </div>
                    <form [formGroup]="rejectReasonForm" >
                        <div class="modal-body1">
                          <div class="Reject_heading">  
                            <p>{{'Reason for Rejection' | translate}}</p>
                          </div>
                            <textarea  type="text" formControlName="reason">  </textarea>
                                <div *ngIf="usersubmitted && f.reason.errors" class="Reason_message">
                                    <div class="error_message Reason_error text-danger" *ngIf="f.reason.errors.required">{{'Reason for Rejection' | translate}} {{'is required' | translate}}</div>
                                </div>
                            <div class="save1 mt-4">
                                <button (click)="rejectRequestdata()" class="btn text-light">{{'Reject Request' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>      
            </div>
        </div>
</div> 
 <!-- modal (when reject request button is clicked)-->
<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content" id="custom-padding1">
            <div class="modal-border">
            <div class="modal-body2">
             <div class="text-center">  
                <img src="assets/images/reject.png" alt="">
                <div class="document-text1">{{'The document' | translate}}</div>
                <div  class="document-text2"> {{'has be rejected successfully' | translate}}</div>
                <div class="save">
                    <button class="btn mt-4"  routerLink="/signature"  data-bs-dismiss="modal" >{{'Go to home' | translate}}</button>
                </div>
            </div>
            </div>
        </div>      
            </div>
    </div>
</div> 
<div  class="modal fade" id="deleteModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Cancel' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to Cancel this Signature Request' | translate}}</div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id"  class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red"  (click)="cancelSignatureRequest()">{{'Cancel Request' | translate}}</button>
        </div>      
      </div>
    </div>
  </div>
  <div  class="modal fade" id="deleteModal4" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Reject' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            
          <div class="text-center">{{'Do you want to Reject' | translate}}</div>
        </div>
        
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id"  class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red"  (click)="rejectRequest()">{{'Reject' | translate}}</button>
        </div>   
    
      </div>
    </div>
  </div>
  <div class="modal fade" id="addUser-signature" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal_header">
            <button type="button" class="btn-close"  (click)="saveSignature()"></button>
        </div>
        <div class="signatureDiv">
            <div class="docPreview">
               <iframe  [src]="signature" frameborder="0"></iframe>
            </div>
            <div class="signPreview">
                <div class="signaturePic">
                    <h5>{{'Your Signature' | translate}}</h5>
                    <div>
                        <img src="assets/images/isolation_text.png" alt=""> 
                    </div>
                    
                </div>
                <div class="buttonGroup">
                    <button  class="btn shadow-none firstButton" (click)="addSignature()">{{'Place Signature' | translate}}</button>
                    <button  class="btn shadow-none " [ngClass]="{'secondButton': button1Clicked, 'fadeButton': !button1Clicked}" [disabled]="!button1Clicked" (click)="saveSignature()">{{'Save Changes' | translate}}</button>
                </div>
            </div>
        </div>
        
      </div>
    </div>
</div>
</main>
 