import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from '../../../_services/api.services';
import { ActivatedRoute,Router,Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';

@Component({
  selector: 'app-domain-permission',
  templateUrl: './domain-permission.component.html',
  styleUrls: ['./domain-permission.component.scss']
})
export class DomainPermissionComponent implements OnInit {
  response:any;
  constructor(private ngxUiLoaderService: NgxUiLoaderService,
              private router: Router,
              private snackBar: MatSnackBar,
              private api: ApiService,) { }

  ngOnInit() {
  }

  haveAccount(key) {
    this.ngxUiLoaderService.start();
    let data = {
      option : key,
      stage_key : 300
    }
    this.api.haveDomain(data).subscribe(data =>{
      this.ngxUiLoaderService.stop();
      this.response = data;
      if(this.response.status == '200'){
        if(key == '1'){
          this.router.navigate(['/domain-search']);
        }else {
          this.router.navigate(['/domain-search']);
        }
          
      }else {
        this.snackBar.open(this.response.message, "Alerte", {
          duration: 2000,
        });
      }
    }, error=>{
      this.ngxUiLoaderService.stop();
    })
  }
}
