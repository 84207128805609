<div class="domain-search mb-5">
    <div class="container_1">
        <div class="main">
            <div class="profile-sec">
            <div class="heading-main">Let’s find a domain for your business</div>
            <div class="subText">Search for a domain that reflects your business’s <br>name or brand - this professional touch helps you build <br>an online presence.</div>
    
    
                <div class="searchInput">
                    <input type="text" placeholder="Search domain names" formControlName="domain_name">
                    <i class="fa-solid fa-magnifying-glass search_icon"></i>
                    <small>Try searching for your business name</small>
                </div>
    
                <button routerLink="/business-info" class="btn-primary mt-5">NEXT <i class="fa-solid fa-arrow-right"></i></button>
       
    
            <!-- <div class="btn_grp">
                <button class="btn-1">Yes, I have one that i can use</button>
                <button class="btn-2">No, I need one</button>
            </div> -->
        </div>
        </div>
    
    </div>
    </div>
