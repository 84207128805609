import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(private http: HttpClient) {}

  downloadFile(url: string, filename: string) {
    console.log(url,filename);
    
    this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
        console.log(blob);
        
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(objectUrl);
      console.log('downloaddddddeedd',objectUrl);
      
    });
  }
}