import { Component, OnInit, HostListener, ViewChild, AfterViewInit, ViewContainerRef, EventEmitter, ElementRef, Output, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as AOS from 'aos';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import 'aos/dist/aos.css';
import '../../_models/tags-input';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
declare var $: any;
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Location } from '@angular/common';
import { log } from 'console';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ModalService } from '../../_services/modal.service';
import { TranslateService } from '../../_services/translate.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkDragDrop, CdkDropListGroup, CdkDragMove, CdkDrag, CdkDragStart, CdkDragEnd, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ViewportRuler } from "@angular/cdk/overlay";
import * as moment from 'moment-timezone';
import {FileDownloadService} from '../../_services/download.service';


interface FoodNode {
  name ? : string;
  image ? : string
  children ? : FoodNode[];
}
const TREE_DATA: FoodNode[] = [
  // {
  //   name: 'Projects',
  //   children: [{name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}],
  // },
  // {
  //   name: '1000 - Project 1',
  //   children: [
  {
    name: '1100 - Etagère 1',
    children: [{
        name: '1110 - Boite A',
        children: [{ name: 'Wheels Reports-pendin..', image: 'file_sm_icon1.png' }, { name: 'Updation-List', image: 'file_sm_icon1.png' }],
      },
      {
        name: '1120 - Boite B',
        children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
      },
    ]
  },
  {
    name: '1200 - Etagère 2',
    children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
  },
  {
    name: '1300 - Etagère 3',
    children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
  },
  {
    name: '1400 - Etagère 4',
    children: [{
        name: '1410 - Boite C',
        children: [{ name: 'Wheels Reports-pendin..', image: 'file_sm_icon1.png' }, { name: 'Updation-List', image: 'file_sm_icon1.png' }],
      },
      {
        name: '1420 - Boite D',
        children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
      },
      {
        name: '1430 - Boite E',
        children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
      },
    ],
  },
  {
    name: '1500 - Etagère 5',
    children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
  },
  {
    name: '1600 - Etagère 6',
    children: [{ name: 'salary-report.xlsx', image: 'file_sm_icon1.png' }],
  },
  {
    name: '1700 - Etagère 7',
    children: [{ name: 'Updation-List', image: 'file_sm_icon1.png' }],
  }

  //   ],
  // },
];
@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit {
  @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup < CdkDropList > ;
  @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;
  selectedOption = "";
  selectedOption1 = "";
  gridView = true;
  response: any
  urlId: any;
  showSideBar = true;
  uploadPreview = false;
  infoSection = true;
  previewInfo = false;
  activitySection = false;
  overlayRef: OverlayRef | null;
  sub: Subscription;
  projectTree = true;
  private tagInput: any;
  startDate = new Date();
  endDate = new Date();
  dragAreaMain: any;
  uploadBlur = false;
  fragment: any;
  myMediaQuery = window.matchMedia('(max-width: 900px)');
  uuid: any;
  projectFolders: any;
  projectFiles: any;
  folderdetail: any;
  projectFolderDetails: any;
  listFolderDetails: any;
  folder_uuid: any;
  viewDetailsList: any;
  viewDetailsList2: any;
  viewDetailsData: any;
  activityDetails: any;
  uuidDetails: any;
  id: any;
  showCheckBox = false;
  FolderFileList: any;
  FolderFileData: any;
  FolderList: any;
  FolderData: any;
  loading = false;
  uploadedFiles: any;
  signatureRequestdetails: any;
  eesubscription: any;
  private touchTimeout: any;
  options = [{
      "name": "Date picker",
      "image": "assets/images/Vector (3).png",
      "value": "a"
    },
    {
      "name": "Text",
      "image": "assets/images/Vector (2).png",
      "value": "b"
    },
  ];
  deleteResponse: any;
  projectFolderUuidDetails: any;
  selectedFilesId: any;
  projectFilesID: any[] = [];
  BulkdeleteResponse: any;
  dataSubscription: any;
  copyProjectFileResponse: any;
  currentLang: string;
  responseuploadFile: any;
  moveProjectFileResponse: any;
  project_id: any;
  moveProjectFolderResponse: any;
  file_Id: any;
  renameResponse: any;
  renameForm: FormGroup;
  renameSubmitted = false;
  name: any;
  type: any;
  filename: any;
  uRl: any;
  numOfFiles: any;
  private tagInput3: any;
  shareProfileResponse: any;
  access: any;
  setTings: any;
  folder: any;
  myFiles: string[] = [];
  templateData: any;
  templateList: any;
  projectGetlinkresponse: any;
  getlink: any;
  closebutton: any;
  restoreLaterResponse: any;
  currentValue: any;
  bulkarchieFilesID: any[] = [];
  usersubmitted = false;
  public addUserForm: FormGroup;
  sidebarHide: any;
  folderDetils: any;
  signature = false;
  responseActivelist: any;
  activeUserlist: any;
  grouMembersuuid: any[] = [];
  grouMembers: any[] = [];
  groupSubmitted = false;
  saveResponse: any;
  templateresponse: any;
  templateDetails: any;
  delete = false;
  userDataObject: any;
  storageType: any;
  metadataDetails: any;
  metadatName: any;
  metadataDetailsDate: any;
  metadataId: any;
  profileForm: FormGroup;
  templatFileresponse: any;
  templateFileDetails: any;
  updateMetadataResponse: any;
  isDisabled: boolean = true;
  isDatePickerDisabled: boolean = true;
  isInputDisabled: boolean = true;
  isReadOnly: boolean = true;
  isButtonHidden: boolean = false;
  isButtonHiddenCancel: boolean = true;
  isButtonHiddenSubmitt: boolean = true;
  templateUuid: any;
  metdata_data: { file_id: any;meta: any;metadatadetails: any; };
  UpdateMetadataResponse: any;
  userDetials: any;
  NometadataAdded: any;
  addMetadatForm: FormGroup;
  NometadataforFolder: any;
  keyHide: boolean;
  isSelected: boolean;
  d: any;
  isDragging = false;
  hoveredElement: HTMLElement | null = null;
  // MultiSelect variables
  longPressTime = 500; // in ms unit
  multiSelectActive = false;
  verifyLongPress: any;
  // multiSelect = false;
  verifyDragStarted = false;
  ctrlMode = false;
  firstContainer: HTMLElement | null = null;
  selectedItems: any[] = [];
  responseMovedFiles: any;
  responseReorderdFiles:any;
  // MultiDrag variables
  dragList: any[] = [];
  dragListCopy: any[] = [];
  folder_id: any;
  movedFiles_id: any;
  isDroppedOverFolder = false;
  dragErase = Symbol("DragErase");
  folderdetailsdata: any;
  timelineForm: FormGroup;
  todayDate: any;
  timelineResponse: any;
  reorderedFiles: [] = [];
  getLinkForm: FormGroup;
  sharename: any;
  archieveUuid: any[];
  get_fileId: any;
  downloadResponse: any;
  path_url: any;
  path_name: any;
  blob: Blob;
  uu: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(this.dragAreaMain);
    this.removeDragOverClass(this.dragAreaMain);
  }
  // @HostListener('document:mousemove', ['$event'])
  onMouseOver(event: MouseEvent, uuid: string): void {
    this.isDroppedOverFolder = true;
    console.log(event)
    console.log(this.isDragging)
    if (this.isDragging) {
      console.log(this.isDragging)
      if (uuid) {
        this.folder_id = uuid;
        this.isDroppedOverFolder = true;
        console.log('Folder id to send in api:', this.folder_id);
      } else {
        this.folder_id = null;
      }
    }
  }

  onMouseOut(event: MouseEvent): void {
    console.log('Mouse out event triggered', event);
 
    this.isDroppedOverFolder = false;
       console.log(this.isDroppedOverFolder);
       console.log(this.folder_id);
       console.log(this.selectedItems);
  }
  @ViewChild('uploadModalAction', { static: false }) uploadModalAction: any;
  treeControl = new NestedTreeControl < FoodNode > (node => node.children);
  dataSource = new MatTreeNestedDataSource < FoodNode > ();
  constructor(private api: ApiService,
    private router: Router,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    private _location: Location,
    private elementRef: ElementRef,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private modalService: ModalService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private viewportRuler: ViewportRuler,
    private fileDownloadService : FileDownloadService
  ) {
    this.dataSource.data = [];
    this.dataSubscription = this.api.data$.subscribe((data: string) => {
      console.log(data)
      this.getProjectData('retetetetetete');
      //this.dataSubscription.unsubscribe();
      return
    });
  }
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   setTimeout(() => {
  //     if (this.route.snapshot.paramMap.get('id')) {
  //       this.uuid = this.route.snapshot.paramMap.get('id');
  //       console.log(this.uuid)
  //     }
  //     this.getProjectData();
  //   }, 100)
  // }
  @ViewChild('userMenu', { static: false }) userMenu: any;
  @Output() filesChanged: EventEmitter < FileList > ;
  ngOnDestroy() {
    console.log('Service destroy')
    this.dataSubscription.unsubscribe();
    this.eesubscription.unsubscribe();
  }
 ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }
  ngOnInit() {
    this.openStyle()
    this.infoSection = true;
    this.folder_uuid = null;
    this.folderdetailsdata =null;
    setTimeout(function() {
      AOS.init({ disable: 'mobile' });
    }, 100);
    this.route.params.subscribe((params: Params) => {
      console.log("params", params)
      this.uuid = params['id'];
      this.getProjectData();
    });
    console.log("$('#fileupload')", $('#fileupload'))
    $('#imageUpload').change((event) => {
      console.log('{{{{{')
      this.readFileInfoAndPreview(event.target.files);
    });
    $('#fileupload').change((event) => {
      console.log("fgfgfdgfdgfdgfdgfdgdfgfdgdfgfdg")
      this.readFileInfoAndPreview(event.target.files);
    });
    $('#folderUpload').change((event) => {
      this.readFileInfoAndPreview(event.target.files);
    });
    $('#uploadDetailModal').modal({
      backdrop: 'static',
      keyboard: false
    })
    //this.getProjectData()

    this.tagInput3 = new TagsInput({
      selector: 'tag-input3',
      duplicate: false,
      max: 10,
      icon: "assets/images/blue_cross.png"
    });

    this.eesubscription = this.modalService.ee.subscribe(data => {
      if (data == 'folder') {
        console.log('adsdasddsdas');

        this.getProjectData();
      }
      //this.eesubscription.unsubscribe();
    });

    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)

    this.addUserForm = this.formBuilder.group({
      // project_start_date:{ disabled: true, value: '' },
      // project_end_date:{ disabled: true, value: '' },
      metadata: this.formBuilder.array([''])
    })


    console.log(this.startDate);

    let data = localStorage.getItem("currentUserDetails");
    this.userDataObject = JSON.parse(data);
    this.storageType = this.userDataObject.user_details
    console.log(this.storageType.user_type, 'dssdsasadasdsdsadsadsdsad');

    this.profileForm = this.formBuilder.group({
      metadatadetails: this.formBuilder.array([''])
    });

    this.addMetadatForm = this.formBuilder.group({
      metadata: this.formBuilder.array([''])
    })

    this.timelineForm = this.formBuilder.group({
      name:[''],
      folderuuid:[''],
      start_date:[''],
      end_date:['']

    })

    this.getLinkForm = this.formBuilder.group({
      comment : ['',[Validators.pattern(/^\S*$/)]],
      uuid:['']
    });

  }

  get c(){
    return this.timelineForm.controls;
  }
  get p() { return this.addMetadatForm.get('metadatadetails')['controls']; }
  get q() { return this.addMetadatForm.controls.metadata as FormArray; }

  get f() { return this.addUserForm.get('metadatadetails')['controls']; }
  get m() { return this.addUserForm.controls.metadata as FormArray; }
  get z() { return this.profileForm.controls.metadatadetails as FormArray; }

  get b() {
    return this.addUserForm.controls;
  }


  test(e, id) {
    console.log("223")
  }
  openStyle() {
    let myMediaQuery = window.matchMedia('(max-width: 900px)');
    if (myMediaQuery.matches) {
      console.log('LJJIKI');
      this.openSideMenu('close');
    }
  }



  readFileInfoAndPreview(event) {
    this.loading = true;
    console.log(event);
    let input;
    if (event.target) {
      input = event.target.files
    } else {
      input = event
    }
    console.log(input)
    if (input && input.length > 0) {
      this.projectTree = false;
      this.showSideBar = true;
      this.uploadPreview = true;
      this.previewInfo = false;
      $('.img_preview_wrap').empty();
      for (let i = 0; i < input.length; i++) {
        console.log('LLLLAAAAA')
        const file = input[i];
        console.log(file);
        const reader = new FileReader();
        console.log(reader);
        reader.onload = (e) => {
          const previewItem = $('<div>').addClass('preview-item');
          const img = $('<img>').addClass('preview-image').attr('src', 'assets/images/file_sm_icon3.png');
          switch (file.type) {
            case 'application/vnd.android.package-archive':
              img.attr('src', 'assets/images/fileXm.png');
              break;
            case 'text/csv':
              img.attr('src', 'assets/images/file_sm_icon1.png');
              break;
            case 'image/png':
              img.attr('src', 'assets/images/file_sm_icon3.png');
              break;
            default:
              img.attr('src', 'assets/images/fileXm.png');
          }
          const fileNamePara = $('<p>').addClass('file-name').text(file.name);
          // const loadingSpinner = $('<div>').addClass('loading-spinner spinner-border text-primary').attr('role', 'status');
          previewItem.append(img, fileNamePara);
          $('.img_preview_wrap').append(previewItem);
          setTimeout(() => {
            // loadingSpinner.hide();
            // const tickImg = $('<img>').attr('src', 'assets/images/greenTick.png').addClass('tick-image');
            // previewItem.append(tickImg);
          }, 1000);
        };
        reader.readAsDataURL(file);
      }
      this.uploadimage(event);
    }
  }

  uploadimage(event) {
    if (event.target) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.myFiles.push(event.target.files[i]);
      }
    }
    this.ngxUiLoaderService.start();
    const formData = new FormData();
    let target;
    if (event.target) {
      target = event.target.dataset.target;
    }
    console.log(this.myFiles)
    formData.append('target', target);
    formData.append('project_id', this.uuid);
    if (this.myFiles.length != 0) {
      for (var i = 0; i < this.myFiles.length; i++) {
        console.log(this.myFiles[i])
        formData.append("file[]", this.myFiles[i]);
      }
    } else {
      for (var i = 0; i < event.length; i++) {
        console.log(event[i])
        formData.append("file[]", event[i]);
      }
    }
    console.log(formData);
    this.api.sidebarfileUpload(formData).subscribe(data => {
      this.ngxUiLoaderService.stop();
      console.log(data, '0000000000000000000000');
      this.responseuploadFile = data;
      console.log(this.responseuploadFile, '12112');
      if (this.responseuploadFile.status == 200) {
        this.loading = false;
        this.uploadedFiles = this.responseuploadFile.uploadedFiles.details;
        console.log(this.uploadedFiles);

        for (let key in this.uploadedFiles) {
          var metadatas = this.addUserForm.controls.metadata as FormArray;
          console.log(metadatas)
          console.log(metadatas.controls[key])
          metadatas.controls[key] = this.formBuilder.group({
            file_id: ['', [Validators.required]],
            meta: '',
            metadatadetails: this.formBuilder.array([])
          })
          metadatas.controls[key].controls.file_id.setValue(this.uploadedFiles[key].uuid)
          // this.m.at(val).get('meta').setValue(value);
          console.log(this.m)
        }
        $('.preview-item').each(function() {
          const tickImg = $('<img>').attr('src', 'assets/images/greenTick.png').addClass('tick-image');
          $(this).append(tickImg);
        })
        //  this.addUserForm.get('project_start_date').setValue(this.responseuploadFile.uploadedFiles.project_start_date);
        //  this.addUserForm.get('project_end_date').setValue(this.responseuploadFile.uploadedFiles.project_end_date);
        this.templateDetails = '';
        $('#uploadDetailModal').modal('show');
        this.myFiles = [];
        this.getTemplateFilter();
        this.getProjectData();
        this.snackBar.open(this.responseuploadFile.message, "Dismiss", {
          duration: 3000,
        });
      } else {
        this.snackBar.open(this.responseuploadFile.message, "Dismiss", {
          duration: 3000,
        });
      }
      this.fileInput.nativeElement.value = '';
    }, error => {
      this.openSideMenu('open');
      this.ngxUiLoaderService.stop();
    });
  }


  uploadCompleted() {
    if (this.FolderFileList.children.length != 0) {
      this.openSideMenu('open');
    } else {
      this.openSideMenu('close');
    }
    this.usersubmitted = false;
    this.addUserForm.reset();
    $('#uploadDetailModal').modal('hide');
  }

  closeGroupmodal() {
    $('#createProjectModal').modal('hide');
    this.groupSubmitted = false;
  }



  getProjectData(va = null) {
    console.log(va)
    this.ngxUiLoaderService.start();
    this.api.getProjectData(this.uuid).subscribe(data => {

      this.response = data;
      console.log(data);
      if (this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        this.projectFolders = this.response.results.folder;
        this.FolderFileDetails(this.response.results.project_uuid);
        this.uuid = this.uuid
        this.setTings = this.response.results.settings
        console.log(this.projectFolders);
        this.projectFiles = this.response.results.file;
        console.log(this.projectFiles);
        this.file_Id = null;
        this.projectFolderDetails = this.response.results.project;
        this.folderDetils = this.response.results.folder_details;
        console.log(this.folderDetils);
        console.log(this.projectFolderDetails, '11111222222');
        this.projectFilesID = [];
      } else {



        this.snackBar.open(this.response.message, "Dismiss", {
          duration: 3000,
        })
        this.ngxUiLoaderService.stop();
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }


  gridActive(key) {
    if (key == "listActive") {
      this.gridView = false;
    } else {
      this.gridView = true;
    }
  }

  openFolder(data,timeline,checkout_status,checkout_access) {
    console.log(data.uuid,data)
    if(timeline == 1){
      console.log(timeline);
      console.log(data.uuid);
      if (!this.showCheckBox) {
        this.folderdetailsdata = data;
        this.folder_uuid = this.folderdetailsdata.uuid;
        console.log(this.folderdetailsdata);
        
      $('#deleteModalforArchieve_open').modal('show');
      }
      
    }else{
      console.log('vayo');
      
      if (!this.showCheckBox) {
        if(checkout_status == 1 && checkout_access == 0){
          // alert('gsgdsagdvsg')
        }else{
          this.router.navigate(['/folders', data.uuid])
        }
        
      }
    }
  }

  openSideMenu(key, user = null) {
    console.log(user, 'dd')
    if (user) {
      this.uuidDetails = user.uuid
      this.id = user.id
      console.log(this.id);
    }
    switch (key) {
      case 'open':
        console.log(key, 'dd')
        this.showSideBar = true;
        this.projectTree = true;
        this.uploadPreview = false;
        break;
      case 'close':
        console.log('close')
        this.showSideBar = false;
        this.projectTree = false;
        this.previewInfo = false;
        this.uploadPreview = false;
        break;
      case 'previewInfo':
        console.log('previewInfo')
        this.showSideBar = true;
        this.projectTree = false;
        this.previewInfo = true;
        this.uploadPreview = false;
        this.close()
        break;
      case 'uploadPreview':
        console.log('previefsfswInfo');
        setTimeout(() => {
          this.projectTree = false;
          this.showSideBar = true;
          this.uploadPreview = true;
          this.previewInfo = false;
        }, 1000)
        break;
      default:
        this.showSideBar = true;
        this.projectTree = true;
    }
    if (user) {
      console.log(user);

      console.log(this.id);
      let data = this.file_Id;
      console.log(data);
      this.ngxUiLoaderService.start();
      this.api.getViewDetails(data).subscribe(data => {
        this.ngxUiLoaderService.stop();
        console.log(data, 'joooooooooooooooooooooooo');
        this.viewDetailsData = data;
        console.log(this.viewDetailsData);
        if (this.viewDetailsData.status == 200) {
          console.log(this.viewDetailsData);
          this.viewDetailsList = this.viewDetailsData.file_Preview_result.file_list;
          console.log(this.viewDetailsList);
          this.metadataDetails = this.viewDetailsData.file_Preview_result.metadata_details

          console.log(this.metadataDetails);
          this.NometadataAdded = this.viewDetailsData.file_Preview_result.key;
          console.log(this.NometadataAdded);
          if (this.NometadataAdded == 1) {
            this.keyHide = true;
          }

          this.NometadataforFolder = this.viewDetailsData.file_Preview_result.folder_key;
          console.log(this.NometadataforFolder, 'aaaaaaaaaaaaaaaa');


          if (this.metadataDetails) {
            this.metadataDetailsDate = this.metadataDetails.keyword;
            this.metadatName = this.metadataDetails.template_details;
            this.metadataId = this.metadataDetails.template_uuid;
          } else {
            this.metadatName = '';
          }

          console.log(this.metadataId);
          if (this.metadataId && this.metadataDetails && this.metadataDetails.keyword) {
            console.log(this.metadataDetails);
            this.updateMetadetailsPreview(this.metadataDetailsDate)
          }
          console.log(this.z.value)
          console.log(this.metadatName);
          this.getTemplateFilter();
          this.viewDetailsList2 = this.viewDetailsData.file_Preview_result.shared_user;
          console.log(this.viewDetailsList2);
          this.activityDetails = this.viewDetailsData.file_Preview_result.activiy;


          if (this.NometadataAdded == 1) {
            console.log("this.z", this.z.controls)
            this.z.clear();
          }


        } else {
          this.snackBar.open(this.viewDetailsData.message, "Dismiss", {
            duration: 3000,
          })
        }
      }, error => {
        this.ngxUiLoaderService.stop();
      });
    }
  }

  updateMetadetailsPreview(val, preview = null) {
    console.log("fds", val)
    this.profileForm.reset();
    var metadatas = this.profileForm.controls.metadatadetails as FormArray;
    metadatas.clear();
    for (let key in val) {
      console.log(metadatas)
      console.log(metadatas.controls)
      console.log(metadatas.controls)
      metadatas.controls[key] = this.formBuilder.group({
        [val[key].id]: ['', [Validators.required]],
        field_type: ['', [Validators.required]],
        id: ['', [Validators.required]],
        keyword: ['', [Validators.required]],
        keyword_label: ['', [Validators.required]]
      })
      metadatas.controls[key].get('field_type').setValue(val[key].field_type)
      metadatas.controls[key].get('id').setValue(val[key].id)
      metadatas.controls[key].get(val[key].id).setValue(val[key].field_value)
      metadatas.controls[key].get('keyword').setValue(val[key].keyword)
      metadatas.controls[key].get('keyword_label').setValue(val[key].keyword_label)
    }
    this.profileForm.controls.metadatadetails.updateValueAndValidity();
    console.log(this.z.value)
  }

  show(key) {
    if (key == 'info') {
      this.infoSection = true;
      this.activitySection = false;
    } else {
      this.infoSection = false;
      this.activitySection = true;
    }
  }

  open(event: MouseEvent, user) {

    if( user.checkout_status == 1 && user.checkout_access == 0){
      return
    }

    console.log(event)
    event.preventDefault();
    console.log(user, 'AAAAA');
    this.folder_uuid = user.uuid;
    this.filename = user.file_name;
    this.name = user.folder_name;
    this.sharename = user.name;
    console.log(this.sharename);
    
    this.type = user.type;
    this.file_Id = user.uuid;
    this.get_fileId = user.uuid;
    console.log(this.file_Id);
    
    this.bulkarchieFilesID.push(this.file_Id)
    this.folder = user;
    console.log(this.folder);
    if (!this.showCheckBox){
      console.log('hahaha');
      
      this.file_Id = [user.uuid];
    }else{
      console.log('buhahah');
      
      this.file_Id = this.projectFilesID;
    }
    


    if (this.folder.pdf_type) {
      this.signature = true;
    } else {
      this.signature = false;
    }
    if (this.folder.file_name) {
      if (this.currentValue.permissions.deleteFile) {
        this.delete = true;
      } else {
        this.delete = false;
      }
    } else {
      if (this.currentValue.permissions.deleteFolder) {
        this.delete = true;
      } else {
        this.delete = false;
      }
    }
    console.log(this.file_Id);
    console.log(this.sharename);
    
    this.close();
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: isNearBottom ? 'bottom' : 'top',
      }]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));
    this.sub = fromEvent < MouseEvent > (document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }


  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }


  goBack() {
    this._location.back();
  }




  onDragOver(event: Event) {
    console.log(event);

    if (this.currentValue.permissions.createFile) {
      console.log('sddasdsada')
      event.preventDefault();
      event.stopPropagation();
      this.dragAreaMain = event;
      let tag = document.getElementsByClassName('drag-area')[0];
      console.log(tag);
      this.addDragOverClass(event);
    }

  }

  onDragLeave(event: Event) {
    console.log(event, 'sddasdsada  131')
    event.preventDefault();
    event.stopPropagation();
    let tag = document.getElementsByClassName('drag-area')[0];
    console.log(tag.classList.contains('drag-over'))
    if (!tag.classList.contains('drag-over')) {
      this.addDragOverClass(event);

    }
    // else{
    //   this.removeDragOverClass(event);
    //   this.uploadBlur = false;
    // }

  }

  onDrop(event: DragEvent) {
    console.log('sddasdsada   1338', event)

    if (this.currentValue.permissions.createFile) {
      event.preventDefault();
      event.stopPropagation();
      this.removeDragOverClass(event);
      const droppedItem = event.dataTransfer.items[0];
      console.log(droppedItem);

      console.log(droppedItem.kind)
      console.log(droppedItem.type)
      if (droppedItem && !droppedItem.type) {
        // Reject folders
        console.log('Folders are not allowed.');
        const currentLang = this.translate.currentLanguageValue;
        console.log(currentLang)
        if (currentLang.language == 'fr') {
          this.snackBar.open('Les dossiers ne sont pas autorisés.', "Dismiss", {
            duration: 3000,
          })
        } else {
          this.snackBar.open('Folders are not allowed.', "Dismiss", {
            duration: 3000,
          })
        }
        return;
      }
      console.log(event.target);
      const files = (event.dataTransfer && event.dataTransfer.files) || undefined;
      console.log(files);

      if (files && files.length > 0) {
        console.log(event.target);
        this.readFileInfoAndPreview(event.dataTransfer.files)
      }
    }


  }

  private addDragOverClass(event: Event) {
    if (this.currentValue.permissions.createFile) {
      const dragArea = event.currentTarget as HTMLElement;
      this.uploadBlur = true;
      dragArea.classList.add('drag-over');
    }

  }

  private removeDragOverClass(event: Event) {
    let tag = document.getElementsByClassName('drag-area')[0];
    console.log(tag.classList.remove('drag-over'))
    const dragArea = event.currentTarget as HTMLElement;
    this.uploadBlur = false;
    console.log(dragArea)
    tag.classList.remove('drag-over');
  }

  getProjectsettings() {
    this.router.navigate(['/project-settings', this.uuid])
  }

  goBackbutton() {
    this._location.back();
    this.projectFilesID = [];
    this.showCheckBox = false;
    // this.toggleCheckboxVisibility();
  }

  projectDeleteFolder() {
    let FileId = {files: this.file_Id};
    this.ngxUiLoaderService.start();
    this.api.bulkDeletefiles(FileId).subscribe(data => {
      console.log(data, '111');

      this.deleteResponse = data;
      console.log(this.deleteResponse, "2323232323232");
      if (this.deleteResponse.status == 200) {
        $('#deleteModal3').modal('hide')
        $(".modal-backdrop").remove();
        // console.log('file deleted');
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.getProjectData()
        this.ngxUiLoaderService.stop();


      } else {
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.ngxUiLoaderService.stop();
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }

  toggleCheckboxVisibility() {
    this.close();
    this.showCheckBox = !this.showCheckBox;
    console.log('uuuuu');
  }

  // selectAll(){
  //   console.log('dsfdfs');
  //   this.isSelected = !this.isSelected;
  //   console.log(this.projectFolders);
  //   if (Array.isArray(this.projectFolders)) {
  //     this.projectFolders.forEach(file => {
  //       console.log(file);
  //       if (this.isSelected == false) {
  //         return
  //       }
  //       file.selected = true;
  //       this.projectFilesID.push(file.uuid);
  //       console.log(this.projectFilesID);

  //     })
  //   }
  //   if (Array.isArray(this.projectFiles)) {
  //     this.projectFiles.forEach(file => {
  //       file.selected = true;
  //       this.projectFilesID.push(file.uuid);
  //     });
  //   }
  //   console.log(this.projectFilesID);

  // }
  selectAll() {
    console.log('dsfdfs');
    this.isSelected = !this.isSelected;
    console.log(this.projectFolders);

    if (Array.isArray(this.projectFolders)) {
      this.projectFolders.forEach(file => {
        console.log(file);
        if (this.isSelected == false) {
          return
        }
        file.selected = true;
        this.projectFilesID.push(file.uuid);
        console.log(this.projectFilesID);
      })
    }

    if (Array.isArray(this.projectFiles)) {
      this.projectFiles.forEach(file => {
        file.selected = true;
        this.projectFilesID.push(file.uuid);
      });
    }
    console.log(this.projectFilesID);
  }

  deselectAll() {
    this.isSelected = false;
    this.showCheckBox = false;
    this.projectFilesID = [];

    if (Array.isArray(this.projectFolders)) {
      this.projectFolders.forEach(file => {
        file.selected = false;
      });
    }

    if (Array.isArray(this.projectFiles)) {
      this.projectFiles.forEach(file => {
        file.selected = false;
      });
    }

    console.log(this.projectFolders);
    console.log(this.projectFiles);
    console.log(this.projectFilesID);
  }





  // deselectAll() {
  //   this.isSelected = false; // Ensure isSelected flag is false
  //   this.projectFilesID = []; // Clear the projectFilesID array

  //   if (Array.isArray(this.projectFolders)) {
  //     this.projectFolders.forEach(file => {
  //       file.selected = false; // Deselect the folder
  //     });
  //   }

  //   if (Array.isArray(this.projectFiles)) {
  //     this.projectFiles.forEach(file => {
  //       file.selected = false; // Deselect the file
  //     });
  //   }

  //   console.log(this.projectFolders);
  //   console.log(this.projectFiles);
  //   console.log(this.projectFilesID);
  // }

  bulkDelete(e, fileid, i = null) {
    this.selectedFilesId = fileid;
    console.log(this.selectedFilesId, 'adich keriva');

    if (e.target.checked) {
      this.projectFilesID.push(fileid);
      console.log(this.projectFilesID);
    } else {
      this.projectFilesID = this.projectFilesID.filter(id => id !== fileid);
    }
  }

  bulkDeleteselectedfiles() {
    // let dataArray= this.projectFilesID
    let FileId = { files: this.projectFilesID };
    console.log(FileId, '203');
    this.ngxUiLoaderService.start();
    this.api.bulkDeletefiles(FileId).subscribe(data => {
      this.BulkdeleteResponse = data;
      if (this.BulkdeleteResponse.status == 200) {
        console.log(this.BulkdeleteResponse, 'fdfdfdf');
        this.snackBar.open(this.BulkdeleteResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.ngxUiLoaderService.stop();
        $('#deleteModal4').modal('hide');
        this.getProjectData();
        this.projectFilesID = [];
        this.showCheckBox = !this.showCheckBox;
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.BulkdeleteResponse.message, "Dismiss", {
          duration: 3000,
        })

      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }
  FolderFileDetails(uuid) {
    // this.uuid = params['id'];
    console.log('hhhhhhh', uuid);
    this.api.getFolderFileDetail(uuid).subscribe(data => {
      console.log(data);
      this.FolderFileData = data;
      console.log(this.FolderFileData);
      if (this.FolderFileData.status == 200) {
        console.log(this.FolderFileData);
        this.FolderFileList = this.FolderFileData.result;
        if (this.FolderFileList.length == 0) {
          console.log('fdsfsd');
          return

        }
        console.log(this.FolderFileList);
        // this.project_id = this.FolderFileData.result.folder.project_uuid
        console.log(this.FolderFileList.children);
        this.sidebarHide = this.FolderFileList.children;
        console.log(this.dataSource);

        this.dataSource.data = this.FolderFileList.children;
        if (this.FolderFileList.children && this.FolderFileList.children.length == 0 && !this.uploadPreview) {
          console.log("midilaj")
          this.showSideBar = false;
          this.projectTree = false;
        }
        console.log("dataSource", this.dataSource.data);
        console.log(this.projectFolderDetails, '11111111');
      } else {
        this.snackBar.open(this.FolderFileData.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {

    });
  }

  copyProjectsFile() {
    this.close();
    var data = { file_id: this.folder_uuid }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.copyProjectFile(data).subscribe(data => {
      console.log(data);
      this.copyProjectFileResponse = data;
      if (this.copyProjectFileResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyProjectFileResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.getProjectData()
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyProjectFileResponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {});

  }

  upload(event: any): void {
    this.readFileInfoAndPreview(event);
  }


  toShare() {
    this.close();
    var data = {
      entity_type: 2,
      id: this.file_Id,
      name:this.sharename
    }
    this.modalService.openModal(data, 'folder');

  }

  moveProjectsFileorFolder(projectFilesID = null) {
    this.close();
    console.log(projectFilesID);
    if (projectFilesID) {
      console.log('xx');
      this.file_Id = projectFilesID
    } 
    console.log(this.file_Id);

   
    var data = {
      entity_type: 1,
      id: this.file_Id,
      name:this.sharename
    }
    console.log(data);
    this.modalService.openModal(data, 'folder');
  }

  rename() {
    this.close();
    var data = {
      entity_type: 3,
      id: this.folder_uuid,
      data: this.folder
    }
    console.log(data);
    this.modalService.openModal(data, 'folder');
  }

  clossRenamemodal() {
    this.renameSubmitted = false;
    $('#renameCreateModal').modal('hide');
    $(".modal-backdrop").remove();
  }

  onSelectAccess(selectedValue: string): void {
    // console.log(selectedValue);
    this.access = selectedValue;
    console.log(this.access)

  }

  restoreLater() {
    this.close();
    console.log(this.projectFilesID);
    // this.folderdetailsdata.uuid = this.folder_uuid;
    if (this.projectFilesID.length > 0) {
      console.log('xx');
      this.d = this.projectFilesID
    } else {
      console.log('yy');
      this.d = [this.folder_uuid]
    }
    console.log(this.d);
    var data = {
      entity_type: 6,
      id: this.d
    }

    console.log(data);
    this.modalService.openModal(data, 'folder');
    $('#deleteModalforArchieve_project').modal('hide');
    // $('#createArchieveModal').modal('show');

    // console.log(this.bulkarchieFilesID);

    // var data = {
    //   files: [this.file_Id],
    //   entity_type: 2
    // }
    // console.log(data,"clicked");
    // this.ngxUiLoaderService.start();
    // this.api.bulkDeletePermenently(data).subscribe(data=>{
    // this.restoreLaterResponse = data;
    // console.log(this.restoreLaterResponse);

    // if(this.restoreLaterResponse.status == 200){
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
    //   this.getProjectData();
    //   $('#deleteModalforArchieve_project').modal('hide');

    // }else{
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })

    // }

    // },error=> {
    //   this.ngxUiLoaderService.stop();
    // });

    // console.log(data, "clicked");
    // this.ngxUiLoaderService.start();
    // this.api.bulkDeletePermenently(data).subscribe(data => {
    //   this.restoreLaterResponse = data;
    //   console.log(this.restoreLaterResponse);

    //   if (this.restoreLaterResponse.status == 200) {
    //     this.ngxUiLoaderService.stop();
    //     this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //       duration: 3000,
    //     })
    //     this.getProjectData();
    //     $('#deleteModalforArchieve_project').modal('hide');

    //   } else {
    //     this.ngxUiLoaderService.stop();
    //     this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //       duration: 3000,
    //     })

    //   }

    // }, error => {
    //   this.ngxUiLoaderService.stop();
    // });

  }

  onSubmitModal() {
    var data = {
      shared_with: this.tagInput3.arr,
      access: this.access,
      file_id: this.file_Id
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.shareProjectFileFolder(data).subscribe(data => {
      console.log(data);
      this.shareProfileResponse = data;
      if (this.shareProfileResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        console.log(this.shareProfileResponse);
        this.snackBar.open(this.shareProfileResponse.message, "Dismiss", {
          duration: 3000,
        })
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.shareProfileResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getImagePreview(image) {
    console.log(image);
    this.close();

    if (!this.showCheckBox) {
      var data = {
        entity_type: 4,
        data: image
      }
      this.modalService.openModal(data);
    }
  }

  getTemplateFilter() {
    // this.ngxUiLoaderService.start();
    this.api.getTemplate().subscribe(data => {
      console.log(data);
      this.templateData = data;
      if (this.templateData.status == 200) {
        // this.ngxUiLoaderService.stop();
        this.templateList = this.templateData.results;
        console.log(this.templateList);

      } else {
        this.ngxUiLoaderService.stop();
        console.log('wqerttys');

        // this.snackBar.open(this.templateData.message, "Dismiss", {
        //   duration:3000,
        // })
      }
    }, error => {
      console.log('pending');
      this.ngxUiLoaderService.stop();
    });
  }

  deleteModal() {
    console.log('clicked');
    $('#deleteModal3').modal('show');
    this.close()
  }
  openDeleteModal() {
    console.log('clicked');
    $('#deleteModal4').modal('show');
  }


  
  getlinkModal(){
    this.close();
    $('#getLinkCreateModal').modal('show');
    
  }
  
  closseGetlink(){
    // this.getLinkSubmitted = false;
    $('#getLinkCreateModal').modal('hide');
    this.getLinkForm.reset();
  }








  getLink() {
    // let uuid = this.file_Id;
   console.log(this.file_Id);

    let data = this.getLinkForm.value;
    data['uuid'] = this.get_fileId;
    console.log(data);


    console.log(this.getLinkForm.value);
    
    this.ngxUiLoaderService.start();
    this.api.getProjectLink(data).subscribe(data => {
      console.log(data);
      this.projectGetlinkresponse = data;
      if (this.projectGetlinkresponse.status == 200) {
        this.ngxUiLoaderService.stop();

        console.log(this.projectGetlinkresponse);
        this.getlink = this.projectGetlinkresponse.result.link;
        console.log(this.getlink);

        // var tempInput = $("<input>");
        // tempInput.val(this.getlink);
        // console.log(tempInput);
        // $("body").append(tempInput);
        // tempInput.select();
        // document.execCommand("copy");
        // tempInput.remove();
        if (navigator.clipboard) {
          navigator.clipboard.writeText(this.getlink).then(function() {
              console.log('Link copied to clipboard successfully!');
          })
        }
         else {
        console.error('Clipboard not copied...!');
        }
        
        this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
          duration: 3000,
        })
        $('#getLinkCreateModal').modal('hide');
        this.getLinkForm.reset();

      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }

  onSelectionChange(event, key: any, preview = null) {
    this.keyHide = false;
    console.log(event)
    this.gettemplateData(event, key, preview);
  }

  gettemplateData(value, val, preview) {
    console.log(preview)
    this.ngxUiLoaderService.start();
    this.api.gettemplateData(value).subscribe(data => {
      console.log(data);
      this.templateresponse = data;
      if (this.templateresponse.status == 200) {
        this.ngxUiLoaderService.stop();
        console.log(this.templateresponse.template_details);
        this.templateDetails = this.templateresponse.template_details.keyword;
        console.log(this.templateDetails);
        if (!preview) {
          var metadatadetails = this.m.at(val).get('metadatadetails') as FormArray;
          while (metadatadetails.length) {
            metadatadetails.removeAt(0);
          }
          for (let key in this.templateDetails) {
            console.log(key);
            let jey = key
            console.log(this.m);
            console.log(this.m.at(val))
            var metadatadetails = this.m.at(val).get('metadatadetails') as FormArray;
            console.log(metadatadetails)
            console.log(this.templateDetails)
            metadatadetails.push(this.formBuilder.group({
              [this.templateDetails[key].id]: ['', [Validators.required]],
              id: this.templateDetails[key].id,
              field_type: this.templateDetails[key].field_type,
              keyword_label: this.templateDetails[key].keyword_label
            }));
            this.m.updateValueAndValidity()
            console.log(metadatadetails.controls[key]);
            console.log(this.m.at(val))
            this.m.at(val).get('meta').setValue(value);
            this.m.updateValueAndValidity();
            console.log(this.addUserForm)
          }
          this.addUserForm.patchValue(this.addUserForm.value);
          console.log(this.addUserForm);
        } else {
          this.updateMetadetailsPreview(this.templateDetails, preview);
        }
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.templateresponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  checknullvalues(data){
    console.log(data.metadata)
    for (const entry of data.metadata) {
      for (const detail of entry.metadatadetails) {
          // Check if any value in the detail object is empty
          const hasEmptyValue = Object.values(detail).some(value => value === null || value === '');
          if (hasEmptyValue) {
              // Set all values in the detail object to empty strings
              for (const key in detail) {
                  detail[key] = '';
              }
          }
      }
  }
  }


  uploaddetailsSave() {
    console.log(this.addUserForm.getRawValue())
    console.log(this.addUserForm)

    this.usersubmitted = true;
    console.log(this.addUserForm.value)
    let data = {
      ...this.addUserForm.getRawValue()
    };
    this.checknullvalues(data)
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.saveUploaddetails(data).subscribe(data => {
      console.log(data);
      this.saveResponse = data;
      if (this.saveResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.saveResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.usersubmitted = false;
        $('#uploadDetailModal').modal('hide');
        console.log("asdsadsadsadsaddsdsadsadsadsadasddsadsadsadsadsadsadsadsadsad")
        this.openSideMenu('open');
        this.getProjectData();
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.saveResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }

  getSignatureRequest() {
    this.close();
    var data = {
      entity_type: 5,
      id: this.folder_uuid,
      data: this.folder
    }
    console.log(data);
    this.modalService.openModal(data, 'signature');
  }

  getSignatureRequest1() {
    this.getActiveUsers();
    this.grouMembers = [];
    this.grouMembersuuid = [];
    this.close();
  }


  getActiveUsers() {
    var data = {
      entityType: 1,
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.getActiveUser(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.responseActivelist = data;
      if (this.responseActivelist.status == 200) {
        this.activeUserlist = this.responseActivelist.results;
        console.log(this.activeUserlist);
        $('#createProjectModal').modal('show');
      } else {
        this.snackBar.open(this.responseActivelist.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  adduser(user) {
    console.log('clicked');
    const isUserPresent = this.grouMembers.some(member => member.id === user.id);
    if (!isUserPresent) {
      if (this.grouMembers.length == 1) {
        this.snackBar.open("Impossible d'ajouter plus d'un membre du groupe", "Dismiss", {
          duration: 3000,
        });
        return;
      }
      user['notinuser'] = 1;
      this.grouMembers.push(user);
      this.grouMembersuuid.push(user.uuid);
      console.log(this.grouMembersuuid, '22222222222');
    }
  }

  removeUser(index: number, uuid, member) {
    console.log(member)
    this.grouMembersuuid = this.grouMembersuuid.filter(function(item) {
      // console.log(this.grouMembersuuid);
      return item !== uuid
    })
    console.log(this.grouMembersuuid)
    this.grouMembers.splice(index, 1);
  }

  ngSubmitrequestSugnature() {
    this.groupSubmitted = true;
    if (this.grouMembers.length == 0) {
      console.log('asdsaddssdasasdsadadaddsadsdsad')
      return;
    }
    var data = {
      receiver_user_id: this.grouMembersuuid,
      document: this.file_Id
    }

    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.signatureRequest(data).subscribe(data => {
      console.log(data);
      this.signatureRequestdetails = data;
      if (this.signatureRequestdetails.status == 200) {
        this.ngxUiLoaderService.stop();
        console.log(this.signatureRequestdetails);
        this.snackBar.open(this.signatureRequestdetails.message, "Dismiss", {
          duration: 3000,
        })
        $('#createProjectModal').modal('hide');
        this.groupSubmitted = false;
        this.modalService.emit();
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.signatureRequestdetails.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();

    });

  }

  changemetaData(val, key) {
    console.log(val)
    console.log(key);
    this.templateUuid = val;
    console.log(this.templateUuid);
  }

  getMetadataDetails() {
    console.log(this.profileForm.getRawValue().metadata)
    // this.metdata_data = {
    //   file_id:this.file_Id,
    //   meta:this.metadataId,
    //   entityType : "1",
    //   ...this.profileForm.getRawValue()
    // }
    const dataArray = [{
      file_id: this.file_Id,
      meta: this.metadataId,
      ...this.profileForm.getRawValue()
    }];
    console.log(this.NometadataAdded);
    if (this.NometadataAdded) {
      var entityType = '';
    } else {
      var entityType = '1';
    }
    let data = {
      metadata: dataArray,
      entityType: entityType
    }
    this.ngxUiLoaderService.start();
    this.api.saveUploaddetails(data).subscribe(data => {
      console.log(data);
      this.UpdateMetadataResponse = data;
      if (this.UpdateMetadataResponse.status == 200) {
        this.ngxUiLoaderService.stop()
        this.snackBar.open(this.UpdateMetadataResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.openSideMenu('close');
        this.cancelButton();

      } else {
        this.ngxUiLoaderService.stop()
        this.snackBar.open(this.UpdateMetadataResponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {
      this.ngxUiLoaderService.stop();

    });
  }

  enableSelect() {
    this.isDisabled = false;
    this.isDatePickerDisabled = false;
    this.isInputDisabled = false;
    this.isReadOnly = !this.isReadOnly;
    this.isButtonHidden = true;
    this.isButtonHiddenCancel = false;
    this.isButtonHiddenSubmitt = false;
  }

  cancelButton() {
    this.isDisabled = true;
    this.isDatePickerDisabled = true;
    this.isInputDisabled = true;
    this.isReadOnly = true;
    this.isButtonHidden = false;
    this.isButtonHiddenCancel = true;
    this.isButtonHiddenSubmitt = true;
    this.profileForm.patchValue(this.z.value);
  }

  openArchieveModal() {
    console.log('clicked');
    this.close();
    console.log('clicked',this.file_Id);
    if(this.projectFilesID.length > 0){
      this.archieveUuid = this.projectFilesID;
    }else{
      this.archieveUuid = this.file_Id;
    }
    $('#deleteModalforArchieve_open').modal('hide');

    $('#deleteModalforArchieve_project').modal('show');
    
   
    
  }

  onTouchStart(event, data): void {
    console.log("onTouchStart", data)
    this.touchTimeout = setTimeout(() => {
      this.open(event, data);
    }, 500); // 500ms for long press
  }

  onTouchEnd(event: TouchEvent, data): void {
    clearTimeout(this.touchTimeout);
  }

  openFoldernode(uuid,exceeded,checkout_status,checkout_access) {
    console.log(uuid,exceeded);
    if (exceeded == '1') {
      this.folder_uuid = uuid;
      $('#deleteModalforArchieve_open').modal('show');
    }else{
      if(checkout_status == 1 && checkout_access == 0){
        return
      }else{
        this.router.navigate(['/folders', uuid])
      }
      return
    }
  }
  openFoldernodeChild(uuid,exceeded,parent,checkout_status,checkout_access) {
    console.log(parent);

    if(parent == '1'){

    }else{
      console.log(uuid,exceeded);
      if (exceeded == '1') {
        this.folder_uuid = uuid;
        $('#deleteModalforArchieve_open').modal('show');
      }else{
        console.log( checkout_status);
        console.log(checkout_access);

        if(checkout_status == 1 && checkout_access == 0){
          return
        }else{
          this.router.navigate(['/folders', uuid])
        }
        return
      }
    }
     
  }

  drop(event: CdkDragDrop < string[] > ) {
    // If current element has ".selected"
    console.log('1780')
    if (event.item.element.nativeElement.classList.contains("selected")) {
      this.multiDrag.dropListDropped(event);
    }
    // If dont have ".selected" (normal case)
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }

  dragMoved(e: CdkDragMove, data = null) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }
  toggleSelection(uuid: string) {
    console.log("1708", uuid);
    console.log("1710", this.multiSelectActive);
    console.log("Current selectedItems: ", this.selectedItems);

    if (this.verifyDragStarted || this.multiSelectActive) {
      console.log("1", this.verifyDragStarted, this.multiSelectActive)
      const index = this.selectedItems.findIndex(item => item.uuid === uuid);

      if (index !== -1) {
        this.selectedItems.splice(index, 1);
        console.log(`Removed ${uuid} from selectedItems`, this.selectedItems);
      } else {
        this.selectedItems.push({ uuid });
        console.log(`Added ${uuid} to selectedItems`, this.selectedItems);
      }
      this.movedFiles_id = this.selectedItems.map(item => item.uuid);
      console.log("Files to send to API: ", this.movedFiles_id);
    }
    console.log("Updated selectedItems: ", this.selectedItems);
  }
  selectDrag(el: HTMLElement): HTMLElement {
    while (!el.classList.contains("cdk-drag")) {
      el = el.parentElement as HTMLElement;
    }
    return el;
  }

  multiSelect = {
      mouseDown: (e: Event) => {
          console.log(e);
          let target = this.selectDrag(e.target as HTMLElement);
          let ctrlKey = (e as KeyboardEvent).ctrlKey;
          console.log(ctrlKey);
          console.log("1795", target)
          if (this.multiSelectActive) {
            let allSelected = document.querySelectorAll(".selected").length;
            if (allSelected == 1 && target.classList.contains("selected") && (this.ctrlMode ? ctrlKey : true)) {
              target.classList.remove("selected", "last");
              this.multiSelectActive = false;
              console.log('2');
            }
          } else {
            let addSelected = () => {
              this.multiSelectActive = true;
              this.firstContainer = target.parentElement as HTMLElement;
              target.classList.add("selected", "last");
              console.log('3');
              this.selectedItems = [];
            };

            // If using CTRL
            if (ctrlKey) {
              this.ctrlMode = true;
              addSelected();
            }

            // If using longPress
            this.verifyLongPress = <any>setTimeout(() => {
          this.ctrlMode = false;
          addSelected();
        }, this.longPressTime);
      }
    },

    mouseUp: (e: Event) => {
      console.log(e);
      clearTimeout(this.verifyLongPress);
      if (this.multiSelectActive  && !this.verifyDragStarted) {
        console.log("1831",this.multiSelectActive, this.verifyDragStarted) 
        let target = this.selectDrag(e.target as HTMLElement);
        let allSelected = document.querySelectorAll(".selected");
        let ctrlKey = (e as KeyboardEvent).ctrlKey;
        let last = document.querySelector(".last");
        if (last && (e as KeyboardEvent).shiftKey) {
          console.log('4');
          let containerLast = Array.from(last.parentElement!.children);
          let lastIndex = containerLast.indexOf(last);
          let currIndex = containerLast.indexOf(target);
          let max = Math.max(lastIndex, currIndex);
          let min = Math.min(lastIndex, currIndex);

          for (let i = min; i <= max; i++) {
            if (i != lastIndex) {
              containerLast[i].classList.toggle("selected");
            }
            console.log('5');
          }

          if (target.classList.contains("selected")) {
            last && last.classList.remove("last");
            target.classList.add("last");
            console.log('6');
          }
        } else {
           console.log('7');
          if (target.classList.contains("selected") && allSelected.length > 1 && (this.ctrlMode ? ctrlKey : true)) {
            target.classList.remove("selected");
            target.classList.remove("last");
            console.log('8');
          } else {
             console.log('9',this.firstContainer);
             console.log("1903",this.ctrlMode ? ctrlKey : true);
             console.log("1904",target.parentElement);
            if (this.firstContainer == target.parentElement && (this.ctrlMode ? ctrlKey : true)) {
              last && last.classList.remove("last");
              target.classList.add("selected", "last");
              console.log('10');
            } else if (this.ctrlMode ? ctrlKey : true) {
              console.log(allSelected)
              allSelected.forEach((el) => { 
               el.classList.remove("last");
               
               // console.log(classList)
              });
              this.firstContainer = target.parentElement as HTMLElement;
              target.classList.add("selected", "last");
                    console.log('11');
            }
          }
        } 
      }
    },

  dragStarted: (uuid: string) => {
    this.isDragging = true;
    console.log(this.isDragging);
    console.log('UUID of dragging item:', uuid);
    this.verifyDragStarted = true;
    clearTimeout(this.verifyLongPress);
    console.log(this.verifyLongPress)
    console.log("multiSelectActive",this.multiSelectActive)
    // Utilize the existing toggleSelection function to add the UUID to selectedItems
     if(this.verifyDragStarted && !this.multiSelectActive){
       this.selectedItems = [];
      console.log('1821', this.verifyDragStarted, this.multiSelectActive);
      console.log('1820', uuid);
      this.toggleSelection(uuid)
    }
    console.log('1819', this.verifyDragStarted, this.multiSelectActive);
   
  },

    dragEnded: (event: DragEvent) => {
      this.isDragging = false;
      console.log(this.isDragging);
      this.hoveredElement = null;
      this.verifyDragStarted = false;
      console.log('5');
      this.selectedItems = [];
      this.activeContainer = '';
      if (!this.isDroppedOverFolder) {
        console.log("1958")
        this.folder_id = null; 
         let allSelected = document.querySelectorAll(".selected");
            console.log(allSelected)  
          allSelected.forEach((el) => { 
          el.classList.remove("hide");
        });
      }

     this.onFileMove();
    },

    dropListDropped: (e: CdkDragDrop<string[]>) => {
      console.log('1928')
      let el = e.item.element.nativeElement;
      if (el.classList.contains("selected")) {
        this.multiSelectActive  = false; // disable multiSelect
      }
    },
  };

  multiDrag = {
    dragStarted: (e: CdkDragStart) => {
      if (e.source.element.nativeElement.classList.contains("selected")) {
        let listData = e.source.dropContainer.data;
        console.log(e);
        this.dragList = [];
        this.dragListCopy = [...listData];
        let DOMdragEl = e.source.element.nativeElement;
        let DOMcontainer = Array.from(DOMdragEl.parentElement!.children);
        let DOMdragElIndex = DOMcontainer.indexOf(DOMdragEl);
        let allSelected = document.querySelectorAll(".selected");

        allSelected.forEach((eli) => {
          let CurrDOMelIndexi = DOMcontainer.indexOf(eli);
          this.dragList.push(listData[CurrDOMelIndexi]);
          this.dragListCopy[CurrDOMelIndexi] = this.dragErase;
          if (DOMdragElIndex !== CurrDOMelIndexi) {
            eli.classList.add("hide");
          }
        });
      }
    },

    dropListDropped: (e: CdkDragDrop<string[]>) => {
      if (e.previousContainer === e.container) {
        console.log("1996")
        let posAdjust = e.previousIndex < e.currentIndex ? 1 : 0;
        this.dragListCopy.splice(e.currentIndex + posAdjust, 0, ...this.dragList);
        this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase));

        for (let i = 0; i < e.container.data.length; i++) {
          e.container.data[i] = this.dragListCopy[i];
        }
      } else {
        console.log("2005")
        this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase));

        for (let i = 0; i < e.previousContainer.data.length; i++) {
          e.previousContainer.data[i] = this.dragListCopy[i];
        }
        for (let i = 0; i < this.dragList.length; i++) {
          e.previousContainer.data.pop();
        }

        let otherListCopy = [...e.container.data];
        otherListCopy.splice(e.currentIndex, 0, ...this.dragList);

        for (let i = 0; i < otherListCopy.length; i++) {
          e.container.data[i] = otherListCopy[i];
        }
      }

      let allHidden = document.querySelectorAll(".hide");
      allHidden.forEach((el) => {
        el.classList.remove("hide");
        console.log("2026",allHidden)
      });
      console.log("2028")
      setTimeout(() => {
        let allSelected = document.querySelectorAll(".selected");
        allSelected.forEach((el) => {
          el.classList.remove("selected", "last");
        });
      }, 300);

      this.dragListCopy = [];
      this.dragList = [];
    },
  };

  dropListDropped(event: CdkDragDrop<number[]>) {
    // console.log(this.target)
    // console.log(this.projectFilesID) 
    // if (this.selectedItems.length > 0) {
    //   const movedItems = this.selectedItems.map(i => this.projectFiles[i]);
    //   console.log(movedItems)
    //   const remainingItems = this.projectFiles.filter((_, i) => this.selectedItems.indexOf(i) === -1);
    //   console.log(remainingItems)

    //   let targetIndex = event.currentIndex;

    //   console.log("targetIndex", this.targetIndex)
    //   console.log(movedItems)
    //   console.log(remainingItems.slice(targetIndex))
    //   // Insert the selected items into the new location
    //   this.projectFiles = [
    //     ...remainingItems.slice(0, this.targetIndex),
    //     ...movedItems,
    //     ...remainingItems.slice(this.targetIndex)
    //   ];
    //   this.selectedItems = [];
    // } else {
    //   console.log("54321")
    //   moveItemInArray(this.projectFiles, event.previousIndex, event.currentIndex);
    // }
    // this.projectFiles = [...this.projectFiles];
    // console.log("this.projectFiles", this.projectFiles)
    // return;
    // this.projectFilesID.clear();
    if (!this.target){
      console.log("2080")
      return;
    }
    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;
    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);
    this.target = null;
    this.source = null; 
     console.log("2091")
    if (this.sourceIndex != this.targetIndex){
      console.log("2093")
      moveItemInArray(this.projectFiles, this.sourceIndex, this.targetIndex);
       this.reorderedFiles = this.projectFiles.map((file, index) => ({
          uuid: file.uuid,
          order: index
        }));
    }
    if(this.reorderedFiles && this.reorderedFiles.length >=0){
      this.onReorder();
    }
    console.log("2102",this.reorderedFiles)
    
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder){
      return true;
    }
    if (drop != this.activeContainer){
      return false;
    }
    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;
    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);
    console.log("2114 source", this.source ) 
    if (!this.source) {
      console.log("2116")
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;
      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';
      console.log(sourceElement.parentElement)
      sourceElement.parentElement.removeChild(sourceElement);
    }
    this.targetIndex = dropIndex;
    this.target = drop;
    console.log(this.target)
    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex 
      ? dropElement.nextSibling : dropElement));
    console.log("2130")
    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }
    getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
        const scrollPosition = this.viewportRuler.getViewportScrollPosition();
        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top
        };
  }
  onReorder(){
    if(!this.reorderedFiles || this.reorderedFiles.length < 1){
      console.log('2152');
      
      return
    }
    var data = {
      reorderedFiles: this.reorderedFiles
    }
    console.log('api data',data);
    // this.ngxUiLoaderService.start();
    this.api.onReorderFIles(data).subscribe(data => {
      this.responseReorderdFiles = data;
      console.log(data); 
      if (this.responseReorderdFiles.status == 200) {
        // this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.responseReorderdFiles.message, "Dismiss", {
        //   duration: 3000,
        // })
        this.multiSelectActive =false;
        this.folder_id = null;
        this.selectedItems = [];
        this.movedFiles_id = null;
        console.log(this.activeUserlist);
        // this.getProjectData();
      } else {
        // this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.responseReorderdFiles.message, "Dismiss", {
        //   duration: 3000,
        // })
      }
    }, error => {
      // this.ngxUiLoaderService.stop();
    });
  }
  onFileMove(){
    if(!this.folder_id){
      console.log('hoi');
      
      return
    }
    var data = {
      folder_id: this.folder_id,
      file_id: this.movedFiles_id
    }
    console.log('api data',data);
    this.ngxUiLoaderService.start();
    this.api.moveProjectFolderOrfile(data).subscribe(data => {
      this.responseMovedFiles = data;
      console.log(data); 
      if (this.responseMovedFiles.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseMovedFiles.message, "Dismiss", {
          duration: 3000,
        })
        this.multiSelectActive =false;
        this.folder_id = null;
        this.selectedItems = [];
        this.movedFiles_id = null;
        console.log(this.activeUserlist);
        this.getProjectData();
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseMovedFiles.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  updateEndDate() {
    if (new Date(this.c.end_date.value) < new Date(this.c.start_date.value)) {
      this.timelineForm.get('end_date').setValue(this.c.start_date.value);
    }
  }

  updateTimeline(edit=null){
    $('#deleteModalforArchieve_open').modal('hide');
    console.log(this.folder);
    
    if (edit == '1') {
      this.folderdetailsdata = this.folder;
    }

    console.log(this.folderdetailsdata);
    console.log(this.folderdetailsdata.start_date);
    this.todayDate = this.folderdetailsdata.start_date;

    this.timelineForm.get('name').setValue(this.folderdetailsdata.name);
    this.timelineForm.get('folderuuid').setValue(this.folderdetailsdata.uuid);
    this.timelineForm.get('start_date').setValue(this.folderdetailsdata.start_date);
    this.timelineForm.get('end_date').setValue(this.folderdetailsdata.end_date);
    $('#folderEdit_modal').modal('show');
    
  }
  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  updatefolderTimeline(){
    let value= this.timelineForm.value;
    console.log(moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.timelineForm.value.end_date))
    if (moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.timelineForm.value.end_date);
    } 
    if (moment(this.timelineForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.timelineForm.value.start_date);
    } 
    console.log( this.timelineForm.value);

    let data = this.timelineForm.value;
    this.ngxUiLoaderService.start();
    this.api.updateFolderTimeline(data).subscribe(data=>{
      console.log(data);
      this.timelineResponse = data;
      if(this.timelineResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
        $('#folderEdit_modal').modal('hide');
        this.showSideBar = false;
        this.projectTree = false;
        this.getProjectData();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
    
  }


  closeTimelinemodal(){
    $('#folderEdit_modal').modal('hide');
  }





  checkoutModal(){
    this.close();
    var data = {
      entity_type: 7,
      id: this.folder_uuid,
      name:this.sharename
    }

    console.log(data);
    this.modalService.openModal(data, 'folder');
  }

  downloadFile(){
    console.log('hello');
    let data ={
      file_id:this.get_fileId
    }
    this.api.downloadFile(data).subscribe(data =>{
      console.log(data);
      this.downloadResponse = data;
      if(this.downloadResponse.status == 200){
        this.path_url =this.downloadResponse.path;
        this.path_name =this.downloadResponse.name;
        console.log(this.path_url);
        
        this.fileDownloadService.downloadFile(this.path_url, this.path_name);
      }
    })
    
  }

  // downloadFile() {
  //   console.log('hello');
  //   let data = {
  //     file_id: this.get_fileId
  //   };
  
  //   this.api.downloadFile(data).subscribe(response => {
  //     console.log(response);
  //     this.downloadResponse = response;
      
  //     if (this.downloadResponse.status === 200) {
  //       // Extract the filename from the path
  //       const path = this.downloadResponse.path;
  //       const filename = path.substring(path.lastIndexOf('/') + 1);
  
  //       // Create a Blob from the response data
  //       const blob = new Blob([this.downloadResponse.fileData], { type: this.downloadResponse.fileType || 'application/octet-stream' });
  //       const downloadURL = window.URL.createObjectURL(blob);
  
  //       // Create a link element and set the href and download attributes
  //       const link = document.createElement('a');
  //       link.href = downloadURL;
  //       link.download = filename;  // Use the extracted filename with extension
  //       link.click();
  
  //       // Clean up the object URL
  //       window.URL.revokeObjectURL(downloadURL);
  //     } else {
  //       console.error('Download failed:', this.downloadResponse.status);
  //     }
  //   }, error => {
  //     console.error('Error downloading file:', error);
  //   });
  // }

}


function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right; 

}
 

