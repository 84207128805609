import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import * as AOS from 'aos';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from 'src/app/_services/api.services';
import 'aos/dist/aos.css';
import { ActivatedRoute,Router,Params } from '@angular/router';
import { AuthenticationService } from '../../../_services/authentication.service';
import { GoogleSignInService } from '../../../_services/google-signin.service';
import { TranslateService } from './../../../_services/translate.service';
import { log } from 'console';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signInForm : FormGroup;
  isSubmitted = false;
  response:any;
  responsegoogle: any;
  showPassword = false;
  showPasswordIcon = "fa-solid fa-eye-slash";
  passwordFieldType: string = "password";
  currentLang: any;

  constructor(public formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private api : ApiService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private ngxUiLoaderService: NgxUiLoaderService,
              private googleSignInService: GoogleSignInService,
              private translate: TranslateService){
                if (this.authenticationService.currentUserValue) {
                  console.log(this.authenticationService.currentUserValue, 'yezzzz');
                  
                  this.router.navigate(['/dashboard']);
                }
              }
  ngOnInit() {
    AOS.init({ disable: 'mobile', startEvent: 'load'});
    this.signInForm = this.formBuilder.group({
      email : ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
      password  : ['', [Validators.required]]
    })
    this.currentLang = localStorage.getItem('language') || "fr";
    if (this.currentLang == 'fr') {
      $('.checkbox').prop('checked', false);
    } else {
      $('.checkbox').prop('checked', true);
    }
    if (this.currentLang == 'fr') {
      $('.myCheckbox').prop('checked', true);
      $("#lang").text("FR");
      console.log("language is french")
    } else {
      $('.myCheckbox').prop('checked', false);
      $("#lang").text("EN");
      console.log("language is english")
    }

  }

  alreadySignedUser() {
    this.googleSignInService.alreadySignedUser().subscribe(user => {
      console.log(user)
      if (user && user.authToken) {
        this.responsegoogle = user;
        console.log(this.responsegoogle,"jjjjjjj")
      }
    });
  }




  get loginValidate() {
    return this.signInForm.controls;
  }

  setShowPassword(id){
      this.showPassword = (this.showPassword)?false:true;
      if(this.showPassword){
          this.passwordFieldType= "text";
          this.showPasswordIcon = "fa-solid fa-eye";
      }else{
          this.passwordFieldType = "password";
          this.showPasswordIcon = "fa-solid fa-eye-slash";
      }
    
  }



  onSubmit() {
    //this.router.navigate(['/dashboard']);
    this.isSubmitted = true;
    console.log(this.signInForm.value);
    if(this.signInForm.invalid){
      return;
    }
    this.ngxUiLoaderService.start();
    let data = this.signInForm.value;
    this.authenticationService.login(data).subscribe(data =>{
      this.response = data;
      if(this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        localStorage.setItem('currentUser', JSON.stringify(this.response));
        this.authenticationService.currentUserSubject.next(this.response);
        // this.router.navigate(['/dashboard']);
        this.getAccounts();
        // this.unauthorised.canActivate();
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error=> {
      this.ngxUiLoaderService.stop();
    })
  }

  signUpWithGoogle() {
    console.log("asdsadsadasdasdasd")
    this.googleSignInService.signInWithGoogle();
    // let data = {
    //   email : this.responsegoogle.email,
    //   authToken :this.responsegoogle.authToken,
    //   type:2

    // }
    // this.authenticationService.login(data).subscribe(data =>{
    //   this.response = data;
    //   if(this.response.status == 200) {
    //     localStorage.setItem('currentUser', JSON.stringify(this.response));
    //     this.authenticationService.currentUserSubject.next(this.response);
    //     // this.router.navigate(['/dashboard']);
    //     this.getAccounts();
    //     // this.unauthorised.canActivate();
    //   }else{
    //     this.ngxUiLoaderService.stop();
    //     this.snackBar.open(this.response.message, "Dismiss", {
    //       duration:3000,
    //     })
    //   }
    // }, error=> {
    //   this.ngxUiLoaderService.stop();
    // })
  }
  

  getAccounts() {
    this.api.getAccounts().subscribe(data => {
      this.response = data;
      console.log(this.response)
      if(this.response.status == "200") {
        this.ngxUiLoaderService.stop();
        localStorage.setItem('currentUserDetails', JSON.stringify(this.response.results))
        this.router.navigate(['/dashboard']);

        
      }else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.response.message, "Alerte", {
          duration: 2000,
        });
      }

    },error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getChangelang(e) {
    console.log(e);
    
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
  }

  setLang(lang) {
    console.log('aaa')
    // console.log(lang.target.value)
    // console.log(lang.detail.value)
    // console.log(lang)
    this.currentLang = lang;
    console.log("69", lang)
    var useLang = { language: this.currentLang };
    console.log(useLang)
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);
    this.changeLang();
  }
  changeLang() {
    this.translate.use(this.currentLang);
    console.log(this.translate.currentLanguageValue)
  }
  routeLanding(){
    this.router.navigateByUrl('/');
  }
}

