
<!-- <nav>
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/About-nav.png" alt="">
        <ul>

            <li class="mr-4" routerLink="/">Home</li> -->
            <!-- <li class="mr-4">Features</li> -->
            <!-- <li class="mr-4" routerLink="/about">About</li> -->
            <!-- <li class="mr-4">En</li> -->
            <!-- <li routerLink="/signup" class="mr-4">Sign Up</li>
            <li routerLink="/login" class="mr-4">Login</li> -->
                
        <!-- </ul>
    </div>

</nav> -->
<app-header-landing-about></app-header-landing-about>
<div class="business-contact mt-4 mb-5">
<div class="">
    <div class="main">
        <div data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500">
        <div class="heading-main">{{'What’s your contact info?' | translate}}</div>
        <div class="subText">{{'You’ll be the Account Admin since you’re creating the account' | translate}}</div>
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row mt-3">
                <div class="col-md-5 col-12">
                    <div class="input">
                        <label class="label-test" for="name">{{'First Name' | translate}}</label>
                        <input type="text" maxlength="20" formControlName="first_name">
                    </div>
                    <div class="mt-2" *ngIf = "submitted && contactFormGet.first_name.errors">
                        <div class="error_message" *ngIf="contactFormGet.first_name.errors.required">{{'First name is required' | translate}}</div>
                        <div class="error_message" *ngIf="contactFormGet.first_name.errors.pattern">{{'First name shoud valid' | translate}}</div>
                    </div>
                </div>

                <div class="offset-md-1 col-md-5 col-12">
                    <div class="input">
                        <label class="label-test" for="name">{{'Last Name' | translate}}</label>
                        <input type="text" maxlength="20" formControlName="last_name">
                    </div>

                    <div class="mt-2" *ngIf = "submitted && contactFormGet.last_name.errors">
                        <div class="error_message" *ngIf="contactFormGet.last_name.errors.required">{{'Last name is required' | translate}}</div>
                        <div class="error_message" *ngIf="contactFormGet.last_name.errors.pattern">{{'Last name shoud valid' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-5 col-12">
                    <div class="input">
                        <label class="label-test" for="name">{{'Current email address' | translate}}</label>
                        <input type="email" class="text-lowercase" maxlength="45" formControlName="email">
                    </div>

                    <div class="mt-2" *ngIf = "submitted && contactFormGet.email.errors">
                        <div class="error_message" *ngIf="contactFormGet.email.errors.required">{{'Email is required' | translate}}</div>
                        <div class="error_message" *ngIf="contactFormGet.email.errors.pattern">{{'Email shoud valid' | translate}}</div>
                    </div>
                </div>

                <div class="offset-md-1 col-md-5 col-12">
                    <div class="input">
                        <label class="label-test" for="phone">{{'Business Phone Number' | translate}}</label>
                        <input  name="phone" minlength="8" maxlength="10" type="phone" id="phone" formControlName="mobile" >
                    </div>

                    <div class="mt-2" *ngIf = "submitted && contactFormGet.mobile.errors">
                        <div class="error_message" *ngIf="contactFormGet.mobile.errors.required">{{'Phone number is required' | translate}}</div>
                        <div class="error_message" *ngIf="contactFormGet.mobile.errors.pattern || contactFormGet.mobile.errors.minlength">{{'Phone number shoud valid' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-11"><button type="submit" id="business-contact-change" class="btn-primary">{{'NEXT' | translate}} <i class="fa-solid fa-arrow-right"></i></button></div>
                
            </div>
        </form>
    </div>
        

    </div>
</div>
</div>

<!-- <div class="domain-permission mb-5">
<div class="container_1">
    <div class="main">
        <div class="profile-sec">
            <div class="heading-main">Do you have a business domain?</div>
            <div class="subText">You’ll need a domain, like example.com, to setup <br>email and a business account.</div>

            <div class="btn_grp">
                <button class="btn-1" routerLink="/dashboard">Yes, I have one that i can use</button>
                <button class="btn-2">No, I need one</button>
            </div>
        </div>
    </div>

</div>
</div> -->


<!-- <div class="domain-search mb-5">
<div class="container_1">
    <div class="main">
        <div class="profile-sec">
        <div class="heading-main">Let’s find a domain for your business</div>
        <div class="subText">Search for a domain that reflects your business’s <br>name or brand - this professional touch helps you build <br>an online presence.</div>


        <div class="searchInput">
            <input type="text" placeholder="Search domain names">
            <i class="fa-solid fa-magnifying-glass search_icon"></i>
            <small>Try searching for your business name</small>
        </div>

        <button routerLink="/business-info" class="btn-primary mt-5">NEXT <i class="fa-solid fa-arrow-right"></i></button>

        <div class="btn_grp">
            <button class="btn-1">Yes, I have one that i can use</button>
            <button class="btn-2">No, I need one</button>
        </div>
    </div>
    </div>

</div>
</div> -->