import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../_models/user'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '../_services/api.services';
@Injectable({ providedIn: 'root' })


export class AuthenticationService {

    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    returnData :any
    response: any;
    constructor(private http: HttpClient,
                private router: Router,
                private api: ApiService) { 
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
          this.currentUser = this.currentUserSubject.asObservable();
      }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(data){
        return this.http.post<any>(`${environment.apiUrl}auths`, data)
          .pipe(map(user => {
            this.returnData = user;
            console.log(user,"555588888855");
            if(user.status == 200){
              if(user.device_bind == 1) { 
                
              } else {
                console.log(user.status,"555555")
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject = new BehaviorSubject<User>(user);
                this.currentUser = this.currentUserSubject.asObservable();
                this.currentUserSubject.next(user);
              }
            //this.getUserDetails();
            }
            return user;
          }));
    }


    logout() {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserDetails');
      // localStorage.removeItem('info');
      this.currentUserSubject.next(null);
      this.router.navigate(['/']);
    }

    getAccounts() {
      console.log("sadadsad")
      this.api.getAccounts().subscribe(data => {
        this.response = data;
        console.log(this.response)
        if(this.response.status == "200") {
          localStorage.setItem('currentUserDetails', JSON.stringify(this.response.results))
        }
      },error => {
      });
    }
  

}
