
<nav data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/logolg2.png" alt="">
        <ul>

                <li class="mr-4">Home</li>
                <li class="mr-4">Features</li>
                <li class="mr-4">About</li>
                <li class="mr-4">En</li>
                <li class="mr-4">Sign Up</li>
                <li class="mr-4" routerLink="/">Login</li>
                
        </ul>
    </div>

</nav>

<div class="authentications mt-5">
<div class="container_custom mb-5">
    <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-6 order-1">
            <div class="section_1" id="parent">
                <div class="d-flex flex-column justify-content-center">
                    <img class="logo img-fluid" src="assets/images/logo_lg.png" alt="" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1500">
                    <div class="d-flex justify-content-center mt-5">
                        <div class="content_main">
                            <h4 class="heading_1 mb-15" data-aos="fade-left" data-aos-delay="400" data-aos-duration="1500">Create Account</h4>

                            <form [formGroup]="signupForm">
                                <div class="input-style" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500">
                                    <img src="assets/images/user.png" alt="">
                                    <input type="text" class="text-capitalize" placeholder="Full Name" maxlength="36" formControlName="full_name">
                                </div>

                                <div *ngIf = "isSubmitted && signupValidate.full_name.errors">
                                    <div class="error_message" *ngIf="signupValidate.full_name.errors.required">Name is required</div>
                                    <div class="error_message" *ngIf="signupValidate.full_name.errors.pattern">Name shoud valid</div>
                                </div>

                                <div class="input-style" data-aos="fade-left" data-aos-delay="800" data-aos-duration="1500">
                                    <img src="assets/images/mail.png" alt="">
                                    <input type="text" placeholder="Email address" formControlName="email">
                                </div>

                                <div *ngIf = "isSubmitted && signupValidate.email.errors">
                                    <div class="error_message" *ngIf="signupValidate.email.errors.required">Email is required</div>
                                    <div class="error_message" *ngIf="signupValidate.email.errors.pattern">Email shoud valid</div>
                                </div>

                                <div class="input-style" data-aos="fade-left" data-aos-anchor="#parent" data-aos-delay="1000" data-aos-duration="1500">
                                    <img src="assets/images/password.png" alt="">
                                    <input type="{{ passwordFieldType }}" placeholder="Password" maxlength="25" formControlName="password">
                                    <!-- <ion-icon id="eye-icon" name="eye" [name]="passwordIcon2" (click)="toggleShow('a')"></ion-icon> -->
                                    <span (click)="setShowPassword('a')" class="eye_Icon"><i class="{{ showPasswordIcon }} eye_Icon"></i></span>
                                </div>

                                <div *ngIf = "isSubmitted && signupValidate.password.errors">
                                    <div class="error_message" *ngIf="signupValidate.password.errors.required">Password is required</div>
                                    <div class="error_message" *ngIf="signupValidate.password.errors.pattern">Password is not strong enough .Password should be 8 characters minimum,  a mixture of both uppercase and lowercase letters, a mixture of letters and numbers ,should have at least one special character, e.g., ! @ # ? ], No space allowed</div>
                                </div>

                                <div class="input-style" data-aos="fade-left" data-aos-anchor="#parent" data-aos-delay="1200" data-aos-duration="1500">
                                    <img src="assets/images/password.png" alt="">
                                    <input type="{{ passwordFieldType2 }}" placeholder="Confirm Password" formControlName="confirmPassword">
                                    <span (click)="setShowPassword('b')" class="eye_Icon"><i class="{{ showPasswordIcon2 }}"></i></span>
                                </div>

                                <div *ngIf = "isSubmitted && signupValidate.confirmPassword.errors">
                                    <div class="error_message" *ngIf="signupValidate.confirmPassword.errors.required">Confrm password is required</div>
                                    <div class="error_message" *ngIf="signupValidate.confirmPassword.errors.ConfirmPassword">Password not matching</div>
                                </div>

                                <!-- <div class="form-check mt-2" data-aos="fade-left" data-aos-delay="1400" data-aos-duration="1500">
                                    <input class="form-check-input" (change)="checkValue($event.currentTarget.checked)" type="checkbox" value="1" id="flexCheckDefault" formControlName="i_agree">
                                    <label class="form-check-label" for="flexCheckDefault"> 
                                        I agree with <span class="blue_letter">Terms</span> and<span class="blue_letter">Privacy</span>
                                    </label>
                                </div> -->

                                <section class="example-section mt-2" data-aos-anchor="#parent" data-aos="fade-left" data-aos-delay="1400" data-aos-duration="1500">
                                    <mat-checkbox class="example-margin" formControlName="i_agree" [ngModel]="checked">I agree with <span class="blue_letter">Terms</span> and<span class="blue_letter">Privacy</span></mat-checkbox>
                                </section>

                                <div *ngIf = "isSubmitted && signupValidate.i_agree.errors" class="mt-2">
                                    <div class="error_message" *ngIf="signupValidate.i_agree.errors.checkBoxIsForbidden">Please accept checkbox</div>
                                </div>

                                <button type="submit" class="btn-primary mt-5" data-aos-anchor="#parent" data-aos="fade-left" data-aos-delay="1600" data-aos-duration="1500" (click)="onSubmit()">SIGN UP</button>
                                <button *ngIf="!responsegoogle" class="btn-secondary mt-2" data-aos-anchor="#parent" data-aos="fade-left" data-aos-delay="1800" data-aos-duration="1500" (click)="signUpWithGoogle()"><img src="assets/images/google_icon.png" alt=""> Sign Up with Google</button>
                                <button *ngIf="responsegoogle" class="btn-secondary google mt-2" data-aos-anchor="#parent" data-aos="fade-left" data-aos-delay="1800" data-aos-duration="1500" (click)="signUpWithGoogle()">
                                    <div class="row">
                                        <div class="col-md-2 col">
                                            <img class="img-fluid user" src="{{ responsegoogle.photoUrl }}" >
                                        </div>
                                        <div class="col-md-8 col">
                                            <p>Continue as {{ responsegoogle.firstName }}</p>
                                            <h6>{{ responsegoogle.email }}</h6>
                                        </div>
                                        <div class="col-md-2 col">
                                            <img class="img-fluid g-1" src="assets/images/google.png" >
                                        </div>
                                    </div>
                                </button>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 order-2">
            <div class="bg_area">
                <img class="img-fluid" src="assets/images/cloudStorage.png" alt="" data-aos-anchor="#parent" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
            </div>
        </div>
    </div>
</div>
</div>
