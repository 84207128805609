<app-sidebar></app-sidebar>
    
<app-header></app-header>

<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main">
              
                <div id="packageContainor">
                    <div class="packages transition active">
                        <div class="datas"> 
                            <div class="price">
                                <h4>19</h4>
                                <h6>for 1 months</h6>
                            </div>
                            <div class="details">

                                <div class="main_text">Starter</div>
                                <h6>Unleash the power of <br>automation</h6>

                                <div class="specs">

                                    <div class="d-flex align-items-center">
                                        <i class="fa-solid fa-check"></i>
                                        Multi-step 
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        3 Premium Apps
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        2 Users team
                                    </div>

                                </div>

                            </div>
                            <button>Choose plan</button>
                        </div>
                    </div>

                    <div class="packages transition">
                        <div class="datas"> 
                            <div class="price">
                                <h4>54</h4>
                                <h6>for 6 months</h6>
                            </div>
                            <div class="details">

                                <div class="main_text">Intermediate</div>
                                <h6>Advanced tools to take your <br> work to the next level.</h6>

                                <div class="specs">

                                    <div class="d-flex align-items-center">
                                        <i class="fa-solid fa-check"></i>
                                        Multi-step 
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        Unlimited Premium
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        50 Users team
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        Shared Workspace
                                    </div>

                                </div>

                            </div>
                            <button>Choose plan</button>
                        </div>
                    </div>

                    <div class="packages transition">
                        <div class="special_offer">
                            MOST POPULAR
                        </div>
                        <div class="datas"> 
                            <div class="price">
                                <h4>89</h4>
                                <h6>for 12 months</h6>
                            </div>
                            <div class="details">

                                <div class="main_text">Professional</div>
                                <h6>Automation plus <br> enterprise-grade features.</h6>

                                <div class="specs">

                                    <div class="d-flex align-items-center">
                                        <i class="fa-solid fa-check"></i>
                                        Multi-step 
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        Unlimited Premium
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        Unlimited Users Team
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        Advanced Admin
                                    </div>
                                    <div class="d-flex">
                                        <i class="fa-solid fa-check"></i>
                                        Custom Data Retention
                                    </div>

                                </div>

                            </div>
                            <button>Choose plan</button>
                        </div>
                    </div>
                </div>
               

            </div>
            <aside class="sidebar-second">
                <div class="heading_1">
                    <h4 class="text-center">Cloud Storage</h4>
                </div>
                <div class="d-flex align-items-center flex-column storage">
                    <img class="img-fluid" src="assets/images/storage.png" alt="">
                    <div class="text_sm mt-4">
                        13.6 GB of 50 GB used
                    </div>
                </div>

                <div class="storage p-0 mt-4">
                    <div class="card1">
                        <div class="d-flex p-15 align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/doc.png" alt="">
                                <div>
                                    <div class="subText">Document</div>
                                    <div class="smText">112 Files</div>
                                </div>
                            </div>
                            <div class="text_sm">2.2 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex p-15 align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/image.png" alt="">
                                <div>
                                    <div class="subText">Photos</div>
                                    <div class="smText">112 Files</div>
                                </div>
                            </div>
                            <div class="text_sm">1.3 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex p-15 align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/video.png" alt="">
                                <div>
                                    <div class="subText">Videos</div>
                                    <div class="smText">112 Files</div>
                                </div>
                            </div>
                            <div class="text_sm">4 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex p-15 align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/music.png" alt="">
                                <div>
                                    <div class="subText">Audios</div>
                                    <div class="smText">112 Files</div>
                                </div>
                            </div>
                            <div class="text_sm">3.3 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex p-15 align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/download.png" alt="">
                                <div>
                                    <div class="subText">Downloads</div>
                                    <div class="smText">112 Files</div>
                                </div>
                            </div>
                            <div class="text_sm">2.8 GB</div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
       
        

        <div class="modal fade" id="addUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>Add New User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="container">
                        <div class="main">
                            <div class="row mt-3">
                                <div class="col-md-5 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">First Name</label>
                                        <input type="text" value="John">
                                    </div>
                                </div>
            
                                <div class="offset-md-1 col-md-5 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">Last  Name</label>
                                        <input type="text" value="Luther">
                                    </div>
                                </div>
                            </div>
            
                            <div class="row mt-3">
                                <div class="col-md-5 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">Job Position</label>
                                        <input type="text" value="Marketing Executive">
                                    </div>
                                </div>
            
                                <div class="offset-md-1 col-md-5 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">Date of Birth</label>
                                        <input type="number" value="17th January 1992">
                                    </div>
                                </div>
                            </div>
            
                            <div class="row mt-3">
                                <div class="col-md-5 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">Email Address</label>
                                        <input type="text" value="johnluther00@gmail.com">
                                    </div>
                                </div>
            
                                <div class="offset-md-1 col-md-5 col-12">
                                    <div class="input">
                                        <label class="label-test" for="phone">Mobile Number</label>
                                        <input  name="phone" type="text" id="phone" value="09164 73662">
                                    </div>
                                </div>
                            </div>
            
                            <div class="row">
                                <div class="col-md-11"><button data-bs-dismiss="modal" aria-label="Close" id="business-info-change" class="btn-primary">Add User</button></div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Understood</button>
                </div> -->
              </div>
            </div>
          </div>

          <div class="modal fade" id="create-group" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="create-groupLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>Create Group</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="container">
                        <div class="main">
                            <div class="row mt-3">
                                <div class="col-md-12 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">First Name</label>
                                        <input type="text" value="John">
                                    </div>
                                </div>
            
                                <div class="col-md-12 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">Last  Name</label>
                                        <input type="text" value="Luther">
                                    </div>
                                </div>
                            </div>
                            <div class="row">


                                <div class="col-md-12">
                                    <div class="addMember">

                                        <div class="dropdown show">
                                            Add Members <i class="fa-solid fa-angle-down"></i>
                                          </div>

                                          <div class="d-flex align-items-center">
                                            <ul class="p-0">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            </ul>
                                        </div>

                                    </div>


                                    <button data-bs-dismiss="modal" aria-label="Close" id="business-info-change" class="btn-primary">Create Group</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Understood</button>
                </div> -->
              </div>
            </div>
          </div>
    
    </div>
    <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>Add New Project</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="projectNameAdd">
                <div class="modal-body">
                    <div class="mt-5">
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Datas">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button id="folderCreate" (click)="folderCreate()" class="btn-primary btn-style" >Create</button>
                </div>
            </div>

            <div id="folderName_And_Tagsection">
                <div class="modal-body">
                    <div class="mt-2">
                        <label for="">Folder Name</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Offer Letter">
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="">Tags</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Tags" name="" id="">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button id="folderCreate" class="btn-primary btn-style"  data-bs-dismiss="modal" aria-label="Close">Create</button>
                </div>
            </div>
          </div>
        </div>
      </div>


</main>
