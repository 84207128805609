import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from 'src/app/_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { data } from 'jquery';
import { MatSnackBar } from '@angular/material';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
declare var $: any;
import { Location } from '@angular/common';

@Component({
  selector: 'app-archieved-files',
  templateUrl: './archieved-files.component.html',
  styleUrls: ['./archieved-files.component.scss']
})
export class ArchievedFilesComponent implements OnInit {
  uuid: Params;
  archievedResponse: any;
  Archievedfiles: any;
  overlayRef: OverlayRef | null;
  @ViewChild('userMenu', { static: false }) userMenu;
  sub: Subscription;
  fileId: any;
  restoreResponse: any;
  bulkDeleteresponse: any;
  fileIdd: any;
  currentValue: any;
  selection: boolean;
  
  folderOrFilesID: any[];
  isSelected: boolean;

  constructor(
    private api: ApiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private location: Location
  ) {
    this.route.params.subscribe((params: Params) => {
      console.log("params", params)
      this.uuid
       = params['id'];
      
      this.ngOnInit()
    });
   }


  ngOnInit() {
    this.archievedData();
    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)
  }


  archievedData() {
    console.log('dsdd');
    
    let data = this.uuid;
    this.ngxUiLoaderService.start();
    this.api.getArchievedData(data).subscribe(data => {
      console.log(data);
      this.archievedResponse = data;
      if (this.archievedResponse.status == 200) {
        console.log('fdsf');
        this.ngxUiLoaderService.stop();
        this.Archievedfiles = this.archievedResponse.results;
        console.log(this.Archievedfiles);
      } else {
        this.location.back();
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.archievedResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  open(event: MouseEvent, user) {
    this.close();
    // const positionStrategy = this.overlay.position()
    //   .flexibleConnectedTo({ x, y })
    //   .withPositions([
    //     {
    //       originX: 'end',
    //       originY: 'bottom',
    //       overlayX: 'end',
    //       overlayY: 'top',
    //     }
    //   ]);
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: isNearBottom ? 'bottom' : 'top',
      }]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }
  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
  restoreFileorFolder(data) {
    console.log('clicked1', data);
    this.fileId = data;
    // this.dataTorestore = data
    $('#deleteModal2').modal('show');
    this.close();
    // this.closebutton2.nativeElement.click();
  }
  deleteForever(data){
    // this.folderOrFilesID.push(data)
    // this.dataToDelete = data;
    console.log(data,'clicked');
    this.fileIdd = data; 
    $('#deleteModal').modal('show');
    this.close();
    // this.closebutton2.nativeElement.click();

  }

  restoreFiles(e=null){
    if(e == 'bulk'){
      console.log('yz',e);
      
      this.fileId = this.folderOrFilesID;

    }else{
      console.log('No');
      this.fileId = [this.fileId]
    }

    let data = {
      item_id : this.fileId,
      archived_id : this.uuid,
      entityType : '1'
    }
    this.ngxUiLoaderService.start();
    this.api.restoreArchivedFiles(data).subscribe(data=>{
      console.log(data);
      this.restoreResponse = data;
      if(this.restoreResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.restoreResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.fileId = null;
        this.folderOrFilesID = [];
        this.isSelected = false;
        $('#deleteModal2').modal('hide');
        $('#deleteModal5').modal('hide');
        this.archievedData();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.restoreResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }
  bulkdeletePermanentandLater(e=null){
    if(e == 'bulk'){
      this.fileIdd = this.folderOrFilesID;

    }else{
      this.fileIdd = [this.fileIdd]
    }
    
    let data = {
      item_id : this.fileIdd,
      archived_id : this.uuid,
      entityType : '2'
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.restoreArchivedFiles(data).subscribe(data=>{
      console.log(data);
      this.bulkDeleteresponse = data;
      if(this.bulkDeleteresponse.status == 200){
        console.log(this.bulkDeleteresponse);
        this.ngxUiLoaderService.stop();   
        // this.selection = false;
        this.snackBar.open(this.bulkDeleteresponse.message, "Dismiss", {
          duration:3000,
        })
        
        $('#deleteModal').modal('hide');
        $('#deleteModal6').modal('hide');
        this.isSelected = false;
        this.archievedData();
        // setTimeout(() => {
        //   $('#deleteModal').modal('hide');
        //   this.getEmployees();
        //   this.selection = false;
        //   this.folderOrFilesID = [];
        // }, 100); 
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.bulkDeleteresponse.message, "Dismiss", {
          duration:3000,
        })
      }
    },error=> {
      this.ngxUiLoaderService.stop();
    });
  }

  closeModaal(){
    $('#deleteModal2').modal('hide');
  }

  selectAction() {
    this.selection = true;
    this.folderOrFilesID = [];
    if (Array.isArray(this.Archievedfiles)) {
      this.Archievedfiles.forEach(file => {
        console.log(file);
        
        file.completed = false;
      });
    }
  }
  updateSingleFile(data: any,e): void {
    console.log(e);
    console.log('Single File Selected:',data.uuid);
    if(e == true){
      if (!this.folderOrFilesID.includes(data.uuid)) {
        this.folderOrFilesID.push(data.uuid);
        console.log(this.folderOrFilesID);
      }
    } else {
      if (this.folderOrFilesID.includes(data.uuid)) {
        const index = this.folderOrFilesID.indexOf(data.uuid);
        this.folderOrFilesID.splice(index, 1);
      }
    }
    console.log(this.folderOrFilesID)
  }

  selectAll(){
    this.isSelected = true;
    console.log(this.Archievedfiles)
    this.folderOrFilesID = [];
  
    if (Array.isArray(this.Archievedfiles)) {
      this.Archievedfiles.forEach(file => {
        if (!this.isSelected) {
          return
        }
        console.log(file);
        file.completed = true;
        this.folderOrFilesID.push(file.uuid);
        console.log(this.folderOrFilesID);
        
      });
    }
  }
  cancel() {
    this.selection = false;
    this.folderOrFilesID = [];
    this.isSelected = !this.isSelected;
    console.log(this.folderOrFilesID);
    
  }
  openRestoreModal(){
    $('#deleteModal2').modal('show');
  }
  closeModal(){
    $('#deleteModal2').modal('hide');
  }
  // opendeleteModal(){
  //   $('#deleteModal').modal('show');
  // }
  // closeDModal(){
  //   $('#deleteModal').modal('hide');
  // }
  openRestorebulkModal(){
    $('#deleteModal5').modal('show');
  }
  opendeletePermanentModal(){
    $('#deleteModal6').modal('show');
  }
  closeRModal(){
    $('#deleteModal5').modal('hide');
  }
  

}
