import { Component, OnInit, NgZone, ViewChild, TemplateRef, ViewContainerRef, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { NgxUiLoaderService } from "ngx-ui-loader";
import { fromEvent, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
// import { ToggleButtonComponent } from '../../../_helpers/toggle-button.component';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { TemplatePortal, ComponentPortal } from '@angular/cdk/portal';
import { TranslateService } from './../../../_services/translate.service';
import { Overlay, OverlayRef, CdkOverlayOrigin } from '@angular/cdk/overlay';
declare var $ : any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.services';
import { MatSnackBar } from '@angular/material';
import { ModalService } from '../../../_services/modal.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import * as moment from 'moment-timezone';
import { log } from 'console';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    "(window:click)": "dismissOpenItems()"
  },
 
})


export class HeaderComponent implements OnInit {
  @ViewChild('closebutton', {static: false}) closebutton;
  login:any;
  currentLang: any;
  url:any;
  isOpen = false;
  isOpen2 = false;
  triggerOrigin: any;
  sub: Subscription;
  triggerOrigin2: any;
  public languageForm: FormGroup;
  checked:boolean;
  showLoading:boolean = false;
  isMenuOpen: boolean = false;
  showOptions = false;
  templateData:any;
  templateList : any;
  projectData : any;
  projectList : any;
  tagList : any;
  tagData : any;
  selectedProject= null;
  selectedTemplate=null;
  selectedTag =  null;
  @ViewChild('qryButton3', { static: false }) private qryButton3: ElementRef<
  HTMLButtonElement
>;
  @ViewChild('overlayMenuList', { static: false }) overlayMenuList: TemplateRef<
  HTMLElement
>;
  overlayRef3: OverlayRef;
  prpicUrl: any;
  serchResponse: any;
  serchedDatas: any;
  searchedfileDataEvent: any;
  filterResponse: any;
  filteredData: any;
  currentValue: any;
  response: any;
  createNewfolderForm: FormGroup;
  folderSubmitted: boolean;
  startDate: any;
  endDate: any;
  createNewFolderResponse: any;
  projectId: any;
  routeSub: any;
  priorityResponse: any;
  priority: any;
  todayDate:Date = new Date();
  
  constructor(private ngZone:NgZone,
              private overlay: Overlay,
              private ngxUiLoaderService: NgxUiLoaderService,
              private viewContainerRef: ViewContainerRef,
              private router: Router,
              private translate: TranslateService,
              private formBuilder: FormBuilder,
              private api: ApiService,
              public snackBar : MatSnackBar,
              private modalService: ModalService,
              private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              
            ) { 
    this.login = localStorage.getItem('login');
    // this.login = 'business';
   
  }

  ngOnInit() {

    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) 
      this.projectId = params['id'];
      console.log(this.projectId) 
    });

    AOS.init({ disable: 'mobile'});
    // this.login = 'business';
    console.log(this.login)
    this.ngZone.run(()=> {
      this.url = window.location.pathname.split('/')[1];
      console.log(window.location.pathname)
    });
    console.log(this.checked)

    $("#inputSearch2").keyup(function () {
      if ($(this).val()) {
         $("#search_button2").show();
         $("#close-search2").show();
      }
      else {
         $("#search_button2").hide();
      }
   });
    $("#inputSearch").keyup(function () {
      if ($(this).val()) {
         $("#search_button").show();
         $("#close-search").show();
      }
      else {
         $("#search_button").hide();
      }
   });
   $("#close-search").click(function () {
      $("#inputSearch").val('');
      $(this).hide();
      $("#search_button").hide();
   });

   $("#close-search2").click(function () {
    $("#inputSearch2").val('');
    $(this).hide();
    $("#search_button2").hide();
  });
  this.languageForm = this.formBuilder.group({
    lang: ['', []],
  });
  this.currentLang = localStorage.getItem('language') || "fr";
  this.languageForm.get('lang').setValue(this.currentLang);
  if (this.currentLang == 'fr') {
    $('.checkbox').prop('checked', false);
  } else {
    $('.checkbox').prop('checked', true);
  }
  if (this.currentLang == 'fr') {
    $('.myCheckbox').prop('checked', true);
    $("#lang").text("FR");
    console.log("language is french")
  } else {
    $('.myCheckbox').prop('checked', false);
    $("#lang").text("EN");
    console.log("language is english")
  }
  // this.getTemplateFilter();
  // this.getProjectFilter();
  // this.getTagFilter();

  this.dataFromLocalstorage()
  this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)

    this.createNewfolderForm = this.formBuilder.group({
      folder_name : ['',[Validators.required]],
      tag:['',[Validators.required]],
      start_date:['',[Validators.required]],
      end_date:['',[Validators.required]]
    });

    this.startDate = new Date();
    console.log("this.startDate", this.startDate)
    this.startDate = moment(this.startDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.startDate", this.startDate)

   
    let currentDate = new Date();
    // let currentDate = new Date();
    
    this.endDate = new Date(currentDate);
    this.endDate.setFullYear(currentDate.getFullYear() + 1);
     console.log(this.endDate)
    // this.endDate =  new Date(currentDate.getTime() + (24 * 60 * 60 * 1000));
    this.endDate = moment(this.endDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.endDate", this.endDate)

    this.getPriority();

  }
  get a() {
    return this.createNewfolderForm.controls;
  }
  closseModal(){
    $('#folderCreateModal1').modal('hide')
    
    const tagsWrapper = document.querySelector('.tags-input-wrapper');
      if (tagsWrapper) {
        const spanTags = tagsWrapper.querySelectorAll('span');
        const inputElement = tagsWrapper.querySelector('input');
        if (inputElement) {
          inputElement.value = '';
        }
        spanTags.forEach(span => {
            span.parentNode.removeChild(span);
        });
      }
    this.folderSubmitted = false
    this.createNewfolderForm.reset();
  }


  ngAfterViewInit(): void {
    // this.queryCtrlOverlayConfig();
    // this.queryCtrl2OverlayConfig();
    console.log("333333333333333333")
    if (this.isSectionVisible()) {
      this.queryCtrl3OverlayConfig();   
    }
  }

  updateEndDate() {
    if (new Date(this.a.end_date.value) < new Date(this.a.start_date.value)) {
      this.createNewfolderForm.get('end_date').setValue(this.a.start_date.value);
    }
  }


  toggle(trigger: any) {
    console.log(trigger)
    this.triggerOrigin = trigger;
    this.isOpen = !this.isOpen
  }
  toggle2(trigger: any) {
    this.triggerOrigin2 = trigger;
    this.isOpen2 = !this.isOpen2
  }
  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".card-1").css("z-index", "-1");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }
  
  showNotification(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".notification_messages").css("display", "block");
        $(".profile_settings").css("display", "none");
        $(".filter").css("display", "none");
    } else {
      $(".notification_messages").css("display", "none");
      $(".profile_settings").css("display", "none");
      $(".filter").css("display", "none");
    }
  }
  
  showProfile(key:any) {
    this.router.navigate(['/profile-settings']);
  }

  openNav() {
    $("#mySidenav").css("width", "305px");
    // document.getElementById("section_all").style.marginRight = "0px";	  
  }

  closeNav() {
    $('.uploadOptionsDrop').removeClass('drop')
    $("#mySidenav").css("width", "0");
  }

  showSearch() {
      $('.hideSearchOpen').css('display', 'none');
      $('.mobile_search_field').css('display', 'block');
  }

  removeSearch() {
      $('.hideSearchOpen').css('display', 'flex');
      $('.mobile_search_field').css('display', 'none');
  }
  
  // showUpload() {
  //     $('.uploadOptionsDrop').addClass('drop')
  // }

  showUpload(event: MouseEvent):void {
    event.stopPropagation(); 
    console.log(this.showOptions,"line 135")
    if (this.showOptions == true) {
      this.showOptions = false;
    }else {
      this.showOptions = true;
    }
   // Drop down propagation
  }

  logOut() {
    this.ngxUiLoaderService.start();
    var data = {

    }
    this.api.logOut(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.response = data;
      if(this.response.status == "200"){
        $('#logoutModal').modal('hide');
        this.authenticationService.logout();
      } else {
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error=>{
      this.ngxUiLoaderService.stop();
    })

    
  }

  changed(e) {
    console.log(e)
  }

  submit_handler(key){
    let myInput:any = document.getElementById(key);
    if (myInput.value != "") {
      let data = {
        file_name : myInput.value
      }
      console.log(data);
      
      
      this.ngxUiLoaderService.start();
      this.api.serchFilesorfolder(data).subscribe(data=>{
        console.log(data);
        this.serchResponse = data;
        console.log(this.serchResponse);
        
        if(this.serchResponse.status == 200){
          console.log(this.serchResponse);
          this.serchedDatas =  this.serchResponse.result;
          // console.log(this.serchedDatas);
          this.api.setData(this.serchedDatas);
          this.ngxUiLoaderService.stop();
         
          // this.searchedfileDataEvent.emit(this.serchedDatas);
          
        }
        else{
          setTimeout(()=> {
            this.ngxUiLoaderService.stop();
            console.log('jjj')
            $('#searchNullModal').modal('show');
          }, 2000);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
      });
        
      //   setTimeout(function () {
      //     $('#searchNullModal').modal('hide');
      // },5000);
      
    }else {

    }
    
  }

  queryCtrl3OverlayConfig() {
    console.log(this.qryButton3)
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.qryButton3.nativeElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]);

    this.overlayRef3 = this.overlay.create({
      positionStrategy: strategy
    });
  }


  // displayMenu3() {
  //   console.log(this.overlayRef3);
  //   if (this.overlayRef3 && this.overlayRef3.hasAttached()) {
  //     console.log('LLLLL')
  //     this.overlayRef3.detach();
      
  //   } else {
  //     console.log('HHHH', this.overlayRef3)
  //     this.overlayRef3.attach(
  //       new TemplatePortal(this.overlayMenuList, this.viewContainerRef)
  //     );
  //   }
  //   this.sub = fromEvent<MouseEvent>(document, 'mousedown')
  //     .pipe(
  //       filter(mouseEvent => {
  //         const clickTarget = mouseEvent.target as HTMLElement;
  //         return !!this.overlayRef3 && !this.overlayRef3.overlayElement.contains(clickTarget);
  //       }),
  //       take(1)
  //     )
  //     .subscribe((e) => {
  //       console.log(e)
  //       const clickTarget = e.target as HTMLElement;
  //       if (!clickTarget.classList.contains('mat-calendar-body-cell-content'))
  //       this.close()
  //     });
  // }
  close() {
    console.log('aaa')
    this.overlayRef3.detach();
  }

  getChangelang(e) {
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
  }

  setLang(lang) {
    console.log('aaa')
    // console.log(lang.target.value)
    // console.log(lang.detail.value)
    // console.log(lang)
    this.currentLang = lang;
    console.log("69", lang)
    var useLang = { language: this.currentLang };
    console.log(useLang)
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);
    this.changeLang();
  }
  changeLang() {
    this.translate.use(this.currentLang);
    console.log(this.translate.currentLanguageValue)
  }

  dismissOpenItems(){
    if (this.showOptions == true) {
      this.showOptions = false;
    }
  }


  getTemplateFilter(){
    // this.ngxUiLoaderService.start();
    this.api.getTemplate().subscribe(data=>{
      console.log(data);
      this.templateData = data;
      if(this.templateData.status== 200 ){
        // this.ngxUiLoaderService.stop();
        this.templateList = this.templateData.results
        console.log(this.templateList);
        
      }else{
        // this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.templateData.message, "Dismiss", {
        //   duration:3000,
        // })
      }
    }, error => {

    });
  }

  getProjectFilter(){
    // this.ngxUiLoaderService.start();
    this.api.getProject().subscribe(data=>{
      this.projectData = data;
       if(this.projectData.status == 200){
        // this.ngxUiLoaderService.stop();
        this.projectList = this.projectData.results;
        console.log(this.projectList,'qwqwqwqwqwq');
        
      }else{
        // this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.projectData.message, "Dismiss", {
        //   duration:3000,
        // })
      }
    }, error => {

    });
  }
 
  getTagFilter(){
    // this.ngxUiLoaderService.start();
    this.api.getTag().subscribe(data=>{
      this.tagData = data;
       if(this.tagData.status ==  200){
        // this.ngxUiLoaderService.stop();
        this.tagList = this.tagData.results;
        console.log(this.tagList,'qwqwqwqw'); 
      }else{
        // this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.tagData.message, "Dismiss", {
        //   duration:3000,
        // })
      }

    }, error => {
      
    });
  }

  applyFilters() {

    console.log('Selected Project:', this.selectedProject);
    console.log('Selected Template:', this.selectedTemplate);
    console.log('Selected Tag:', this.selectedTag);

    let data = {
      template_id:this.selectedTemplate,
      tag_id:this.selectedTag,
      project_id:this.selectedProject,
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.applyFilterType(data).subscribe(data=>{
      console.log(data);
      this.filterResponse = data;
      if(this.filterResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.filteredData = this.filterResponse.results;
        this.snackBar.open(this.filterResponse.message, "Dismiss", {
          duration:3000,
        })
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.filterResponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }


dataFromLocalstorage(){
  let data = localStorage.getItem("currentUserDetails");
   let userDataObject = JSON.parse(data);
   console.log(userDataObject);
   this.prpicUrl = userDataObject.user_details.profile_pic;

  }

  navigateSerchedfile(val){
    console.log('clicked');
    this.router.navigate(["/searched-files"], { state: { hello: 'world' } });
    this.modalService.search1(val);
    return
  }
  navigateSerchedfile1(){

    console.log('clicked');
    this.router.navigate(["/searched-files"], { state: {key:'1'} })
    
  }
  
  isSectionVisible(): boolean {
    return this.router.url !== '/searched-files';
  }

  logoutModal(){
    $('#logoutModal').modal('show');
  }
  closelogoutModal(){
    $('#logoutModal').modal('hide');
  }

  createFolder(s) {
    console.log(s,'ccccccccccccccccccccccccc',this.createNewfolderForm);
    
    this.createNewfolderForm.get('tag').setValue('1');
    this.createNewfolderForm.get('start_date').setValue(this.startDate);
    this.createNewfolderForm.get('end_date').setValue(this.endDate);
    $('#folderCreateModal1').modal('show');
    this.getPriority();
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }


  createNewfolder() {
    console.log('clicked',this.createNewfolderForm.value);
    let value = this.createNewfolderForm.value
    console.log(this.createNewfolderForm.value.start_date)
    console.log(this.createNewfolderForm.value.end_date)
    console.log(moment(this.createNewfolderForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.createNewfolderForm.value.end_date))
    if (moment(this.createNewfolderForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.createNewfolderForm.value.end_date);
    } 
    if (moment(this.createNewfolderForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.createNewfolderForm.value.start_date);
    } 

    console.log(value);
    
    this.folderSubmitted = true;
  
    if (this.createNewfolderForm.invalid) {
      return;
    }
  
    let data = {
      ...this.createNewfolderForm.value,
      project_id: this.projectId
    };
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.createNewFolder(data).subscribe(data => {
      console.log(data);
      this.createNewFolderResponse = data;
      if (this.createNewFolderResponse.status == 200) {
        this.api.setData("Data from Component A");
        this.createNewfolderForm.reset();
        this.ngxUiLoaderService.stop();
        this.folderSubmitted = false;
        setTimeout(() => {
          $('#folderCreateModal1').modal('hide');
        }, 50);
        this.snackBar.open(this.createNewFolderResponse.message, "Dismiss", {
          duration: 3000,
        });
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.createNewFolderResponse.message, "Dismiss", {
          duration: 3000,
        });
      }
    });
  } 

  getPriority(){
    this.ngxUiLoaderService.start();
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

}

