<aside class="sidebar-second"  [ngClass]="{'width-side-second': showSideBar == true, 'h-100': showSideBar == false}" id="storageDetils" >
    <img class="close_storage" *ngIf="showSideBar" (click)="getInfo('1')"
        src="assets/images/arrow_right.png" alt="">
    <img *ngIf="(!showSideBar) && (storageType.user_type == '2')" class="close_storage2" (click)="getInfo('2')"
        src="assets/images/angle_left.png" alt="">
<div *ngIf="storagesectionHide && (storageType.user_type == '2')" >
    <div class="heading_1">
        <h4 class="text-center mt-4">{{'Cloud Storage of Business' | translate}}</h4>
    </div>
    
    <div class="d-flex align-items-center flex-column storage" *ngIf="dashboardRecentStoragedetails && chartOptions">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [plotOptions]="chartOptions.plotOptions"
          [labels]="chartOptions.labels"
          [stroke]="chartOptions.stroke"
          [fill]="chartOptions.fill"
          id="chart1"
        ></apx-chart>
        <div class="text_sm mt-3">
           {{dashboardRecentStoragedetails.used}} of {{dashboardRecentStoragedetails.total}} {{'used' | translate}}
        </div>
    </div>
    
    <div class="storage p-0 mt-4" [ngClass]="{'d-none': showSideBar == false}">
        <div class="card1"  *ngFor="let details of dashboardRecentdetails">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <img class="mr-12" src="{{details.image}}" alt="">
                    <div>
                        <div class="subText">{{details.name | translate}}</div>
                        <div class="smText">{{details.no_of_file}} {{'Files' | translate}}</div>
                    </div>
                </div>
                <div class="text_sm">{{details.file_size}}</div>
            </div>
        </div>
    </div>
</div>    


<div *ngIf="detailsSection" id="detailsBar">
    <div class="d-flex align-items-center justify-content-between">
      <div class="heading_1 w-100 d-flex align-items-center justify-content-center position-relative">
        <h4 class="text-center">{{'Preview' | translate}}</h4>
        <!-- <i _ngcontent-bnd-c3="" class="fa-solid fa-xmark closeNav" (click)="openSideMenu('open')"></i> -->
      </div>
    </div>
    <div class="preview_img" *ngIf="viewDetailsList">
      <img src="{{viewDetailsList.file_url}}" alt="" class="img-fluid">
    </div>
    <div class="size-col" *ngIf="viewDetailsList">
      <div class="">
        <img src="{{viewDetailsList.file_image}}" alt="">
        <span>{{viewDetailsList.name}}</span>
      </div>
      <h5>{{viewDetailsList.file_size}}</h5>
    </div>
    <div class="tabs">
      <div class="position-relative tabOption cursor_pointer" data-tab="tab-1" id="infoShow" (click)="showPreview('info')">
        <h6>{{'Info' | translate}}</h6>
        <div class="line" [ngClass]="{'lineExtent': infoSection == true}"></div>
      </div>
      <div class="position-relative tabOption cursor_pointer" data-tab="tab-2" id="activityShow" *ngIf="storageType.user_type == '2'" (click)="showPreview('activity')">
        <h6>{{'Activity' | translate}}</h6>
        <div class="line" [ngClass]="{'lineExtent': activitySection == true}"></div>
      </div>
    </div>
    <div class="tabs_contents">
      <div class="info_content tab-content" *ngIf="infoSection">
        <div class="text_sm">{{'Who can access' | translate}}</div>
        <div class="d-flex justify-content-between info-1 margin-lr14">
          <div class="d-flex justify-content-between align-items-center w-100 share-option">
            <button class="share" (click)="toShare()">
              <img src="assets/images/upload-sm.png" alt="">
              {{'Share' | translate}}
            </button>
            <!-- <div class="users" id="shared_user_id">
              <ul>
                <li *ngFor="let img of viewDetailsList2 index as i" class="d-inline"><img class="ms-1" src="{{img.profile_pic}}" alt=""></li>
              </ul>
            </div> -->
          </div>
        </div>
        <div class="line"></div>
        <div class="text_sm">{{'File properties' | translate}}</div>
        <form [formGroup]="profileForm">
          <div class="margin-lr14 info-2 details-div" *ngIf="viewDetailsList">
            <div class="grid">
              <div class="grid-child ">
                <h5 class="black">{{'Saved location' | translate}}</h5>
              </div>
              <div class="grid-child">
                <h5 class="text-capitalise">{{viewDetailsList.saved_location}}</h5>
              </div>
              <div class="grid-child">
                <h5 class="black">{{'Size' | translate}}</h5>
              </div>
              <div class="grid-child">
                <h5>{{viewDetailsList.file_size}}</h5>
              </div>
              <div class="grid-child">
                <h5 class="black">{{'Modified' | translate}}</h5>
              </div>
              <div class="grid-child">
                <h5>{{viewDetailsList.modified}}</h5>
              </div>
              <div class="grid-child">
                <h5 class="black">{{'Type' | translate}}</h5>
              </div>
              <div class="grid-child">
                <h5>{{viewDetailsList.type}}</h5>
              </div>
              <div class="grid-child" *ngIf="viewDetailsList.priority_name">
                <h5 class="black">{{'Priority' | translate}}</h5>
              </div>
              <div class="grid-child" *ngIf="viewDetailsList.priority_name">
                <h5>{{viewDetailsList.priority_name}}</h5>
              </div>
            </div>
            <div class="row timeline-div" *ngIf="(viewDetailsList.start_date) && (viewDetailsList.end_date)">
              <div class="timeline-firstdiv">
                <h5 class="black">{{'Timeline' | translate}}</h5>
                <button class="btn btn-primary" *ngIf="(NometadataforFolder == 1)" (click)="updateTimeline('1')">{{'Edit' | translate}}</button>
              </div>
              <div class="timeline-secondiv">
                <h5 class="h5-metadata"><p>{{viewDetailsList.start_date}}</p> &nbsp;<span>-</span>&nbsp;<p>{{viewDetailsList.end_date}}</p></h5>
              </div>
            </div>
            <!-- *ngIf="metadatName" -->
            <div class="row metadata-sec" *ngIf="!(NometadataforFolder == 1)">
              <div class="col-md-6">
                <h5 class="black">{{'Metadata' | translate}}</h5>
              </div>
              <div class="col-md-6" (click)="enableSelect()"  [hidden]="isButtonHidden"><button class="btn edit-metadata-text">{{'Edit' | translate}}</button></div>
              <div class="select-div">
                <mat-form-field class="custom-form-field3" appearance="none">
                  <mat-select (selectionChange)="onSelectionChange($event.value, 'key', 'preview')" [(value)]="metadataId" [disabled]="isDisabled" placeholder="{{'Select Template' | translate}}">
                    <mat-option value="" disabled selected>{{'Select Template' | translate}}</mat-option>
                    <mat-option [value]="template.uuid" *ngFor="let template of templateList">{{ template.template_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="outer-div-metadata mt-3" *ngIf="!keyHide">
                  <ng-container formArrayName="metadatadetails">
                    <div *ngFor="let item of z.value;let i = index" [hidden]="z.value[0] == ''">
                      <div [formGroupName]="i">
                        <div class="bottom-padd black">{{item.keyword_label}}</div>
                        <div class="border-filedvalue">
                          <input class="ng-inpuut-field" *ngIf="item.field_type == '2'" formControlName="{{item.id}}" [readonly]="isReadOnly">
                          <mat-form-field class="example-full-width metadata-loopsec" appearance="none" *ngIf="item.field_type == '1'">
                            <input matInput [matDatepicker]="dp31" placeholder="{{'Select date' | translate}}" formControlName="{{item.id}}" readonly [disabled]="isDatePickerDisabled">
                            <mat-datepicker-toggle matIconSuffix [for]="dp31"></mat-datepicker-toggle>
                            <mat-datepicker #dp31></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="mt-3 btn_grp_metadata">
                  <button type="submit" class="btn bg-light text-dark" [hidden]="isButtonHiddenCancel" (click)="cancelButton()">{{'Cancel' | translate}}</button>
                  <button type="submit" class="btn btn-primary" (click)="getMetadataDetails()" [hidden]="isButtonHiddenSubmitt">{{'Update' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="activity_content tab-content" *ngIf="activitySection">
        <div *ngFor="let activity of activityDetails index as i">
          <div class="d-flex align-items-center margin-lr14 shared_1">
            <img src="{{activity.profile_pic}}" alt="">
            <div class="user_text">{{activity.message}}
            <h6>{{activity.created_at}}</h6>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div class="noactivity" *ngIf="activityDetails && activityDetails.length == 0">
          {{'There is no activity against this file.' | translate }}
        </div>
      </div>
    </div>
  </div>
</aside>

<!-- <aside class="sidebar-second" id="preview_info" [ngClass]="{'width-side-second': showSideBar == true}" *ngIf="showStoragesection">
    <img class="close_storage" *ngIf="showSideBar" (click)="openSideMenu('close')" src="assets/images/arrow_right.png" alt="">
    <img *ngIf="!showSideBar" class="close_storage2" (click)="openSideMenu('open')" src="assets/images/angle_left.png" alt="">
    
</aside> -->

<div class="modal fade" id="folderEdit_modal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="shareModalLabel" aria-hidden="true">
  <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
          <h5>{{'Folder details' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form [formGroup]="timelineForm">
      <div class="modal-body">

        <div class="input_style mt-4">
                            
          <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
          <input class="input" type="email" aria-describedby="emailHelp" placeholder="{{'Folder Name' | translate}}" readonly formControlName="name">
        </div>
        
        <div class="container_input_div">
          <div class="input_style mt-4 d-flex align-items-center">
              <div class="datePicker-modal">
                  <div class="dateDiv">
                      <label for="">{{'Starts from' | translate}}</label>
                      <mat-form-field class="example-full-width" appearance="none">
                          <input matInput [matDatepicker]="dp10"  [min]="todayDate"  (dateChange)="updateEndDate()"  [matDatepicker]="picker" readonly   formControlName="start_date">
                          <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                          <mat-datepicker #dp10></mat-datepicker>
                      </mat-form-field>
                      <!-- <div *ngIf="folderSubmitted && a.start_date.errors" class="m-2">
                          <div class="error_message" *ngIf="a.start_date.errors.required">{{'start date' | translate}} {{'is required' | translate}} (dateChange)="updateEndDate()"</div>                                 
                      </div> -->
                  </div>
                  <div>
                      <img class="minus" src="assets/images/minus.png" alt="">
                  </div>
                  <div class="dateDiv">
                      <div>
                          <label for="">{{'Ends on' | translate}}</label>
                          <mat-form-field class="example-full-width" appearance="none">
                              <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly   [min]="c.start_date.value" formControlName="end_date">
                              <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                              <mat-datepicker #dp2></mat-datepicker>
                          </mat-form-field>
                          <!-- <div *ngIf="folderSubmitted && a.end_date.errors" class="m-2">
                              <div class="error_message" *ngIf="a.end_date.errors.required">{{'end date' | translate}} {{'is required' | translate}} [min]="a.start_date.value" formControlName="end_date"</div>
                          </div> -->
                      </div>
                  </div> 
              </div>
          </div>
        </div>


      </div>
      <div id="delete_id" class="btnGrp mt-4">
        <button id="button-id" class="btn_primary bg_red" (click)="closeTimelinemodal()">{{'Cancel' | translate}}</button>
        <button id="button-id" class="btn_primary bg_blue "   (click)="updatefolderTimeline()">{{'Update' | translate}}</button>
      </div>
    </form>    
    </div>
  </div>
</div>