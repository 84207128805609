
<app-sidebar></app-sidebar>
    
<app-header></app-header>

<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">
                
                <div class="heading_1 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <img class="mr-12" src="assets/images/3users.png" alt="">
                        <h4>Shared Files</h4>
                    </div>
                    <div class="d-flex align-items-center">

                        <img class="mr-12" id="lineView" src="assets/images/3line.png"  alt="">
                        <img id="gridViewBtn" src="assets/images/grid.svg" alt="">

                    </div>
                </div>
                <div class="sharedTable">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--5cols Rtable--collapse" id="table-main">
                            <div class="Rtable-row Rtable-row--head">
                                <div class="Rtable-cell topic-cell column-heading">Name <i class="fa-solid fa-arrow-up"></i></div>
                                <!-- <div class="Rtable-cell replay-link-cell column-heading">File Size</div> -->
                                
                                <div class="Rtable-cell shared-cell column-heading">Shared by</div>
                                <div class="Rtable-cell date-cell column-heading">Share date</div>
                                <div class="Rtable-cell column-noDate"></div>
                                <!-- <div class="Rtable-cell column-nodata column-heading"></div>
                                <div class="Rtable-cell access-link-cell column-heading">Location</div> -->
                            </div>
                        
                            <div class="share_time">
                                <div class="time">Today</div>
                                <div class="Rtable-row is-striped" (click)="getInfo('1')">
                                    <div class="Rtable-cell topic-cell">
                                        <div class="Rtable-cell--content title-content">Artificial Intelligence Feedback  <img src="assets/images/users.png" alt=""></div>
                                    </div>
                                    <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                        <div class="Rtable-cell--heading">Shared by</div>
                                        <div class="Rtable-cell--content shared-content"> 
                                            <div class="d-flex align-items-center">
                                                <img class="mr-7" src="assets/images/sharePic.png" alt="">  David Finch
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Share date</div>
                                        <div class="Rtable-cell--content date-content"><span class="">January 10, 2023</span></div>
                                    </div>
                                    <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                                </div>
                            

                                <div class="Rtable-row is-striped" (click)="getInfo('1')">
                                    <div class="Rtable-cell topic-cell">
                                        <div class="Rtable-cell--content title-content">Leave Policies <img src="assets/images/users.png" alt=""></div>
                                    </div>
                                    <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                        <div class="Rtable-cell--heading">Shared by</div>
                                        <div class="Rtable-cell--content shared-content"> 
                                            <div class="d-flex align-items-center">
                                                <img class="mr-7" src="assets/images/pic-lg4.png" alt="">  Peter Aaron
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Share date</div>
                                        <div class="Rtable-cell--content date-content"><span class="">January 06, 2023</span></div>
                                    </div>
                                    <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                                </div>
                            </div>

                        <div class="share_time">
                            <div class="time">Last Week</div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell topic-cell">
                                <div class="Rtable-cell--content title-content">New Microsoft Access Database Datas <img src="assets/images/users.png" alt=""></div>
                            </div>  
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared by</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <img class="mr-7" src="assets/images/pic-lg5.png" alt="">  Steve Smith
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell date-cell">
                                <div class="Rtable-cell--heading">Share date</div>
                                <div class="Rtable-cell--content date-content"><span class="">December 28, 2022</span></div>
                            </div>
                            <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                        </div>

                

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell topic-cell">
                                <div class="Rtable-cell--content title-content">Design Feedaback <img src="assets/images/users.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared by</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <img class="mr-7" src="assets/images/pic-lg7.png" alt="">  Karl James
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell date-cell">
                                <div class="Rtable-cell--heading">Share date</div>
                                <div class="Rtable-cell--content date-content"><span class="">December 17, 2022</span></div>
                            </div>
                            <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell topic-cell">
                                <div class="Rtable-cell--content title-content">The mystical adventures of Sir Reginald Tiberius III <img src="assets/images/users.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared by</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <img class="mr-7" src="assets/images/pic-lg8.png" alt="">  Johnson
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell date-cell">
                                <div class="Rtable-cell--heading">Share date</div>
                                <div class="Rtable-cell--content date-content"><span class="">December 13, 2022</span></div>
                            </div>
                            <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell topic-cell">
                                <div class="Rtable-cell--content title-content">Trends and inspirations in UI/UX design <img src="assets/images/users.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared by</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <img class="mr-7" src="assets/images/pic-lg9.png" alt="">  James Fincher
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Share date</div>
                            <div class="Rtable-cell--content date-content"><span class="">October 10, 2022</span></div>
                            </div>
                            <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                        </div>



                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell topic-cell">
                                <div class="Rtable-cell--content title-content">Financial App.fig <img src="assets/images/users.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared by</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <img class="mr-7" src="assets/images/pic-lg10.png" alt="">  Emma watson
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell date-cell">
                                <div class="Rtable-cell--heading">Share date</div>
                                <div class="Rtable-cell--content date-content"><span class="">October 8, 2022</span></div>
                            </div>
                            <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell topic-cell">
                                <div class="Rtable-cell--content title-content">The life and times of the notorious pirate Blackbea. <img src="assets/images/users.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared by</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <img class="mr-7" src="assets/images/pic-lg11.png" alt="">  Michale
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell date-cell">
                                <div class="Rtable-cell--heading">Share date</div>
                                <div class="Rtable-cell--content date-content"><span class="">August 28, 2022</span></div>
                            </div>
                            <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                        </div>
                        </div>
                        
                        <div class="share_time">
                            <div class="time">Last month</div>
                            <div class="Rtable-row is-striped" (click)="getInfo('1')">
                                <div class="Rtable-cell topic-cell">
                                    <div class="Rtable-cell--content title-content">Artificial Intelligence Feedback  <img src="assets/images/users.png" alt=""></div>
                                </div>
                                <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                    <div class="Rtable-cell--heading">Shared by</div>
                                    <div class="Rtable-cell--content shared-content"> 
                                        <div class="d-flex align-items-center">
                                            <img class="mr-7" src="assets/images/sharePic.png" alt="">  David Finch
                                        </div>
                                    </div>
                                </div>
                                <div class="Rtable-cell date-cell">
                                    <div class="Rtable-cell--heading">Share date</div>
                                    <div class="Rtable-cell--content date-content"><span class="">August 25, 2022</span></div>
                                </div>
                                <div class="Rtable-cell column-noDate"><i class="fa-solid fa-ellipsis-vertical"></i></div>
                            </div>
                        </div>





                        </div>
                    </div>
                </div>



                <!-- GRID VIEW  -->
                <div id="gridView" class="gridView">
                    <div class="hFlex">
                        <div class="card1 p-15" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" data-aos="fade-right" data-aos-delay="800" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                                
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- GRID VIEW CLOSE -->


            </div>
            <aside class="sidebar-second" id="preview_info">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="heading_1">
                        <h4 class="">File preview</h4>
                    </div>
                    <img class="close" (click)="getInfo('2')" src="assets/images/cross.png" alt="">
                </div>
                <div>
                    <img src="assets/images/pic_2.png" class="img-fluid" alt="">
                </div>

                <div class="size-col">
                    <div class="">
                        <img src="assets/images/image.png" alt="">
                        <span>illustration.ai</span>
                    </div>

                    <h5>1.2 MB</h5>
                </div>

                <div class="tabs">
                    <div class="position-relative tabOption current" data-tab="tab-1" id="infoShow">
                        <h6>Info</h6>
                        <div class="line lineExtent"></div>
                    </div>
                    <div class="position-relative tabOption" data-tab="tab-2" id="activityShow">
                        <h6>Activity</h6>
                        <div class="line"></div>
                    </div>
                    <div class="position-relative tabOption" data-tab="tab-3" id="cmtShow">
                        <h6>Comments</h6>
                        <div class="line"></div>
                    </div>
                </div>


                <div class="tabs_contents">
                    <div class="info_content tab-content current" id="tab-1">
                        
                        <div class="d-flex justify-content-between info-1 margin-lr14 padding-top34 sharedInfo">
                            <div class="d-flex justify-content-between align-items-end w-100 share-option">
                                <div class="text_sm">Shared by</div>
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/sharePic.png" alt=""> <h6 class="shareInfoName">David Finch</h6>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="text_sm">File properties</div>

                        <!-- <div class="margin-lr14 info-2">
                            <div class="d-flex justify-content-between">
                                <h5>Saved location</h5>
                                <h5>My files/illustrations</h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h5>Size</h5>
                                <h5>1.2 MB</h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h5>Modified</h5>
                                <h5>January 04, 2023</h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h5>Type</h5>
                                <h5>Image</h5>
                            </div>
                        </div> -->
                        <div class="margin-lr14 info-2">
                            <div class="row">
                                <div class="col-md-6"> <h5 class="black">Saved location</h5></div>
                                <div class="col-md-6"><h5>My files/illustrations</h5></div>
                                <div class="col-md-6"><h5 class="black">Size</h5></div>
                                <div class="col-md-6"><h5>1.2 MB</h5></div>
                                <div class="col-md-6"><h5 class="black">Modified</h5></div>
                                <div class="col-md-6"><h5>January 04, 2023</h5></div>
                                <div class="col-md-6"><h5 class="black">Type</h5></div>
                                <div class="col-md-6"><h5>Image</h5></div>

                            </div>
                            <!-- <div class="row">
                                <div class="col-md-6"><h5>Size</h5></div>
                                <div class="col-md-6"><h5>1.2 MB</h5></div>
                            </div>
                            <div class="row">
                                <div class="col-md-6"><h5>Modified</h5></div>
                                <div class="col-md-6"><h5>January 04, 2023</h5></div>
                            </div>
                            <div class="row">
                                <div class="col-md-6"><h5>Type</h5></div>
                                <div class="col-md-6"><h5>Image</h5></div>
                            </div> -->
                        </div>
                    </div>

                    <div class="activity_content tab-content" id="tab-2">
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                You shared this file
                                <h6>Jan 21th 2023, 03:14 pm</h6>
                            </div>
                        </div>
                        <div class="d-flex shared_2">
                            <img src="assets/images/link.png" alt="">
                            Anyone with the link can view this file
                        </div>
                        <div class="line"></div>
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                You added to favourites
                                <h6>Jan 21th 2023, 03:14 pm</h6>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                You uploaded <span>illustration.ai</span>
                                <h6>Jan 17th 2023, 10:47 am</h6>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>


                    <div class="comments_content tab-content" id="tab-3">
                        <div class="view1">
                            <div class="margin-lr14 mt-24">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/pic-lg1.png" alt="">
                                        <div class="user_text">
                                            James Cameroon
                                            <h5>Cool design & great colors</h5>
                                        </div>
                                    </div>
                                    <i class="fa-regular fa-heart"></i>
                                </div>
                                <div class="d-flex justify-content-between suboptions">
                                    <h6>1 week ago</h6> 
                                    <h6>Like</h6> 
                                    <h6>Reply</h6> 
                                </div>
                            </div>

                            <div class="margin-lr14 mt-24">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/pic-lg3.png" alt="">
                                        <div class="user_text">
                                            Michael Ben Patrick
                                            <h5>so clean & smooth</h5>
                                        </div>
                                    </div>
                                    <i class="fa-regular fa-heart"></i>
                                </div>
                                <div class="suboptions">
                                    <div class="d-flex justify-content-between ">
                                        <h6>2 week ago</h6> 
                                        <h6>Like</h6> 
                                        <h6>Reply</h6> 
                                    </div>

                                    <div class="reply position-relative">
                                        <img class="reply_arrow" src="assets/images/arrow-reply.png" alt="">
                                        <div class="d-flex align-items-center ml-5">
                                            <img src="assets/images/pic-lg.png" alt="">
                                            <div class="user_text">
                                                Jesse Walter
                                                <h5>Thank you</h5>
                                                <div class="d-flex justify-content-between ">
                                                    <h6>1 hour ago</h6> 
                                                    <h6>Like</h6> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="margin-lr14 mt-24">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/pic-lg2.png" alt="">
                                        <div class="user_text">
                                            Ben Affleck
                                            <h5>Awesome Man</h5>
                                        </div>
                                    </div>
                                    <i class="fa-regular fa-heart"></i>
                                </div>
                                <div class="d-flex justify-content-between suboptions">
                                    <h6>2 week ago</h6> 
                                    <h6>Like</h6> 
                                    <h6>Reply</h6> 
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </aside>
        </div>
        
    
    </div>
    <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>Add New Project</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="projectNameAdd">
                <div class="modal-body">
                    <div class="mt-5">
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Datas">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button id="folderCreate" (click)="folderCreate()" class="btn-primary btn-style" >Create</button>
                </div>
            </div>

            <div id="folderName_And_Tagsection">
                <div class="modal-body">
                    <div class="mt-2">
                        <label for="">Folder Name</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Offer Letter">
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="">Tags</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Tags" name="" id="">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button id="folderCreate" class="btn-primary btn-style"  data-bs-dismiss="modal" aria-label="Close">Create</button>
                </div>
            </div>
          </div>
        </div>
      </div>
</main>

<div class="mobleViewPreviewSidebar" id="preview_info_mobile">
    <div class="d-flex align-items-center justify-content-between">
        <div class="heading_1">
            <h4 class="">File preview</h4>
        </div>
        <img class="close" (click)="getInfo('2')" src="assets/images/cross.png" alt="">
    </div>
    <div>
        <img src="assets/images/pic_2.png" class="img-fluid" alt="">
    </div>

    <div class="size-col">
        <div class="">
            <img src="assets/images/image.png" alt="">
            <span>illustration.ai</span>
        </div>

        <h5>1.2 MB</h5>
    </div>

    <div class="tabs">
        <div class="position-relative tabOption current" data-tab="tab-1-mobile" id="infoShow">
            <h6>Info</h6>
            <div class="line lineExtent"></div>
        </div>
        <div class="position-relative tabOption" data-tab="tab-2-mobile" id="activityShow">
            <h6>Activity</h6>
            <div class="line"></div>
        </div>
        <div class="position-relative tabOption" data-tab="tab-3-mobile" id="cmtShow">
            <h6>Comments</h6>
            <div class="line"></div>
        </div>
    </div>


    <div class="tabs_contents">
        <div class="info_content tab-content current" id="tab-1-mobile">
            
            <div class="d-flex justify-content-between info-1 margin-lr14 padding-top34 sharedInfo">
                <div class="d-flex justify-content-between align-items-end w-100 share-option">
                    <div class="text_sm">Shared by</div>
                    <div class="d-flex align-items-center">
                        <img src="assets/images/sharePic.png" alt=""> <h6 class="shareInfoName">David Finch</h6>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="text_sm">File properties</div>
            <div class="margin-lr14 info-2">
                <div class="row">
                    <div class="col-md-6 col-6"> <h5 class="black">Saved location</h5></div>
                    <div class="col-md-6 col-6"><h5>My files/illustrations</h5></div>
                    <div class="col-md-6 col-6"><h5 class="black">Size</h5></div>
                    <div class="col-md-6 col-6"><h5>1.2 MB</h5></div>
                    <div class="col-md-6 col-6"><h5 class="black">Modified</h5></div>
                    <div class="col-md-6 col-6"><h5>January 04, 2023</h5></div>
                    <div class="col-md-6 col-6"><h5 class="black">Type</h5></div>
                    <div class="col-md-6 col-6"><h5>Image</h5></div>

                </div>
            </div>
        </div>

        <div class="activity_content tab-content" id="tab-2-mobile">
            <div class="d-flex align-items-center margin-lr14 shared_1">
                <img src="assets/images/pic-lg.png" alt="">
                <div class="user_text">
                    You shared this file
                    <h6>Jan 21th 2023, 03:14 pm</h6>
                </div>
            </div>
            <div class="d-flex shared_2">
                <img src="assets/images/link.png" alt="">
                Anyone with the link can view this file
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center margin-lr14 shared_1">
                <img src="assets/images/pic-lg.png" alt="">
                <div class="user_text">
                    You added to favourites
                    <h6>Jan 21th 2023, 03:14 pm</h6>
                </div>
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center margin-lr14 shared_1">
                <img src="assets/images/pic-lg.png" alt="">
                <div class="user_text">
                    You uploaded <span>illustration.ai</span>
                    <h6>Jan 17th 2023, 10:47 am</h6>
                </div>
            </div>
            <div class="line"></div>
        </div>


        <div class="comments_content tab-content" id="tab-3-mobile">
            <div class="view1">
                <div class="margin-lr14 mt-24">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <img src="assets/images/pic-lg1.png" alt="">
                            <div class="user_text">
                                James Cameroon
                                <h5>Cool design & great colors</h5>
                            </div>
                        </div>
                        <i class="fa-regular fa-heart"></i>
                    </div>
                    <div class="d-flex justify-content-between suboptions">
                        <h6>1 week ago</h6> 
                        <h6>Like</h6> 
                        <h6>Reply</h6> 
                    </div>
                </div>

                <div class="margin-lr14 mt-24">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <img src="assets/images/pic-lg3.png" alt="">
                            <div class="user_text">
                                Michael Ben Patrick
                                <h5>so clean & smooth</h5>
                            </div>
                        </div>
                        <i class="fa-regular fa-heart"></i>
                    </div>
                    <div class="suboptions">
                        <div class="d-flex justify-content-between ">
                            <h6>2 week ago</h6> 
                            <h6>Like</h6> 
                            <h6>Reply</h6> 
                        </div>

                        <div class="reply position-relative">
                            <img class="reply_arrow" src="assets/images/arrow-reply.png" alt="">
                            <div class="d-flex align-items-center ml-5">
                                <img src="assets/images/pic-lg.png" alt="">
                                <div class="user_text">
                                    Jesse Walter
                                    <h5>Thank you</h5>
                                    <div class="d-flex justify-content-between ">
                                        <h6>1 hour ago</h6> 
                                        <h6>Like</h6> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="margin-lr14 mt-24">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <img src="assets/images/pic-lg2.png" alt="">
                            <div class="user_text">
                                Ben Affleck
                                <h5>Awesome Man</h5>
                            </div>
                        </div>
                        <i class="fa-regular fa-heart"></i>
                    </div>
                    <div class="d-flex justify-content-between suboptions">
                        <h6>2 week ago</h6> 
                        <h6>Like</h6> 
                        <h6>Reply</h6> 
                    </div>
                </div>
            </div>
        </div>


    </div>

</div>