// modal.service.ts
import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  showModalSource = new Subject<boolean>();
  showSearch = new Subject<boolean>();
  showModal = this.showModalSource.asObservable();
  showSearchAdvance = this.showSearch.asObservable();
  ee: EventEmitter<string> = new EventEmitter<string>();
  ee1: EventEmitter<string> = new EventEmitter<string>();
  page: any;

  constructor() { }

  openModal(data, page=null): void {
    console.log("data111111111",data)
    console.log(page);
    if (page) {
      this.page = page;
    }
    console.log(this.page)
    this.showModalSource.next(data);
    return
  }

  emit() {
    console.log("this.page", this.page)
    this.ee.emit(this.page);
    return
  }

  search(val) {
    console.log("this.page", this.page)
    setTimeout(()=> {
      this.ee.emit(val);
      return
    }, 50)
    return
  }

  search1(val) {
    console.log("this.page", this.page)
    console.log("this.page", this.ee1.observers)
    console.log("this.showSearch", this.showSearch)
    setTimeout(()=> {
      this.showSearch.next(val);
      return
    }, 50)
  }
}