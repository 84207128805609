import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid
} from "ng-apexcharts";
type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  showSideBar = false;
  dashboardProjectlist: any;
  dashboardRecentfilelist: any;
  dashboardRecentfolderlist: any;
  dashboardRecentdetails: any; 
  response: any;
  reportDataList:any;
  reportData:any;
  
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  selectForm: any;
  selectedOption: any;
  showDatePicker: boolean;
  reportResponse: any;
  priorityResponse: any;
  priority: any;
  filesCount: any;
  userCount: any;
  projectCount: any;
  priorityCount: any;
  userSharedCount: any;
  GroupSharedCount: any;
  archivedCount: any;
  deletedCount: any;
  startDate: any;
  endDate: string;
  currentDate: Date;

  constructor(private api: ApiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getDashboardDetails();
    this.getReportDetails();
    this.selectForm = this.formBuilder.group({
      selectOption: [''],
      start_date: [''],
      end_date: [''],
      tag :['']
    });

    this.startDate = new Date();
    console.log("this.startDate", this.startDate)
    this.startDate = moment(this.startDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.startDate", this.startDate)
    
    const currentDate = new Date();
    this.endDate = moment(currentDate).add(1, 'days').utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("End Date:", this.endDate);

    this.onSubmit();
  }
  get b() {
    return this.selectForm.controls;
  }
  updateEndDate() {
    if (new Date(this.b.end_date.value) < new Date(this.b.start_date.value)) {
      this.selectForm.get('end_date').setValue(this.b.start_date.value);
    }
  }


  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }
  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }
  getInfo(key:any) {
    console.log(key)
    if(key == '1') {
      this.showSideBar = false;
    }
    else {
      this.showSideBar = true;
    }
    
  }
  getDashboardDetails() {
    this.ngxUiLoaderService.start();
    this.api.getDashboardDetails().subscribe(data=>{
      this.ngxUiLoaderService.stop();
        this.response = data;
        console.log(this.response)
        if (this.response.status == 200) {
          this.dashboardProjectlist = this.response.project_list;
          this.dashboardRecentfilelist = this.response.Recent_files;
          this.dashboardRecentfolderlist = this.response.Folder_list;
          this.dashboardRecentdetails = this.response.details;
        }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getReportDetails() {
    this.ngxUiLoaderService.start();
    this.api.getReport().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      console.log(data);
        this.reportData = data;
        console.log(this.reportData)
        if (this.reportData.status == 200) {
          this.reportDataList = this.reportData.results;
          console.log(this.reportDataList);
          if (this.reportDataList.categories && this.reportDataList.categories.length != 0) {
            this.getreports();        
          }
        }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getreports() {
    this.chartOptions = {
      series: [
        {
          name: "Utilisation en Mo",
          data: this.reportDataList.file_size
        }
      ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false
        },
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors: [
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee",
        "#265aee"
      ],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      xaxis: {
        categories: 
          this.reportDataList.categories,
        labels: {
          style: {
            colors: [
              "black",
              "black",
              "black",
              "black",
              "black",
              "black",
              "black",
              "black",
              "black",
              "black",
              "black",
              "black"
            ],
            fontSize: "12px"
          }
        }
      }
    };
  }
  onSelectionChange(event: any) {
    this.selectedOption = event.target.value;
    if(this.selectedOption == '4'){
      this.getPriority()
    }else{
      this.selectForm.get('tag').setValue('');
    }
    console.log(this.selectedOption);

    this.selectForm.get('selectOption').setValue(this.selectedOption);
    this.selectForm.get('start_date').setValue(this.startDate);
    this.selectForm.get('end_date').setValue(this.endDate);
    
    this.showDatePicker = true;
  }
  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }
  onSubmit() {
    console.log(this.selectForm.value);
    let data = this.selectForm.value;

    if (moment(this.selectForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      data['end_date'] = this.convertOwl(this.selectForm.value.end_date);
    } 
    if (moment(this.selectForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      data['start_date'] = this.convertOwl(this.selectForm.value.start_date);
    } 

    console.log(this.selectForm.value);

    this.ngxUiLoaderService.start();
    this.api.customisedReport(data).subscribe(data=>{
      console.log(data);
      this.reportResponse = data;
      if(this.reportResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.reportResponse);
        this.filesCount = this.reportResponse.file_count;
        this.userCount = this.reportResponse.user_count;
        this.projectCount = this.reportResponse.project_count;
        this.priorityCount = this.reportResponse.tag_count;
        this.userSharedCount = this.reportResponse.shared_user;
        this.GroupSharedCount = this.reportResponse.shared_group;
        this.archivedCount = this.reportResponse.archive;
        this.deletedCount = this.reportResponse.deleted;
        console.log(this.filesCount);
        
        
      }else{
        this.ngxUiLoaderService.stop();
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getPriority(){
    this.ngxUiLoaderService.start();
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

}
