import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { ApiService } from '../../_services/api.services';
import { error, log } from 'console';
import { MatSnackBar } from '@angular/material';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
declare var $: any
@Component({
  selector: 'app-recyclebin',
  templateUrl: './recyclebin.component.html',
  styleUrls: ['./recyclebin.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class RecyclebinComponent implements OnInit {
  @ViewChild('closebutton', {static: false}) closebutton;
  @ViewChild('closebutton2', {static: false}) closebutton2;
  @ViewChild('userMenu', {static: false}) userMenu;
  selection = false;
  gridView = false;
  selectionTrue = false;
  overlayRef: OverlayRef | null;
  sub: Subscription;
  response:any;
  responsedesignation: any;
  resposeData: any;
  deleteResponse: any;
  dataToDelete: string | null = null;
  dataTorestore: any;
  restoreResponse: any;
  deletedFiles: any;
  folderOrFilesID: number[] = [];
  restoreLaterResponse: any;
  FileId: number[];
  bulkRestoreResponse: any;
  deletedProjects: any;
  bulkDeleteresponse: any;
  currentValue: any;
  isSelected: boolean = false;
  routeId: any;

  constructor( public overlay: Overlay,
                private api: ApiService,
                public viewContainerRef: ViewContainerRef,
                public snackBar : MatSnackBar,
                private http: HttpClient,
                private ngxUiLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.selectionTrue = false;
    this.selection = false;
    AOS.init({ disable: 'mobile'});
    // let lineBtn: any =   document.getElementById('lineView');
    // let gridBtn: any =    document.getElementById('gridViewBtn'); 
    // // let table: any = document.getElementById('tableview');
    // let gridview: any = document.getElementById('gridView'); 
    // lineBtn.style.cssText = 'filter: grayscale(100%);';
    // gridBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';

    //   lineBtn.addEventListener('click', () => {
    //       console.log('aAAA')
    //       // table.style.cssText = 'display:block;';
    //       gridview.style.cssText = 'display:none;';
    //       lineBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';
    //       gridBtn.style.cssText = 'filter: grayscale(100%);';
    //       //lineBtn.setAttribute('src','images/3line.png');
    //   });

    //   gridBtn.addEventListener('click', () => {
    //       console.log('aAAA')
    //       // table.style.cssText = 'display:none;';
    //       gridview.style.cssText = 'display:block;';
    //       gridBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';
    //       lineBtn.style.cssText = 'filter: grayscale(100%);';
    //       //lineBtn.setAttribute('src','images/3dot_black.png');

    //   })
    this.getEmployees();
    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)
  }

  @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
      this.selection = false;
    }

    selectAll(){
      // this.selectionTrue = true;
      // console.log(this.response);
      // this.response.forEach(file => (file.completed = true));
      this.isSelected = !this.isSelected;
      console.log(this.resposeData)
      this.folderOrFilesID = [];
      if (Array.isArray(this.resposeData)) {
        this.resposeData.forEach(file => {
          if (this.isSelected == false) {
            return
          }
          console.log(file);
          file.completed = true;
          this.folderOrFilesID.push(file.id);
        });
      }
      if (Array.isArray(this.deletedFiles)) {
        this.deletedFiles.forEach(file => {
          file.completed = true;
          this.folderOrFilesID.push(file.id);
        });
      }

      if (Array.isArray(this.deletedProjects)) {
        this.deletedProjects.forEach(file => {
          file.completed = true;
          this.folderOrFilesID.push(file.id);
        });
      }

      console.log(this.folderOrFilesID);
      
    }

    updateSingleFile(data: any,e): void {
      console.log(e);
      console.log('Single File Selected:',data.id);
      if(e == true){
        if (!this.folderOrFilesID.includes(data.id)) {
          this.folderOrFilesID.push(data.id);
          console.log(this.folderOrFilesID);
        }
      } else {
        if (this.folderOrFilesID.includes(data.id)) {
          const index = this.folderOrFilesID.indexOf(data.id);
          this.folderOrFilesID.splice(index, 1);
        }
      }
      console.log(this.folderOrFilesID)
    }

    bulkRestore(){
      let data ={
        FileId:this.folderOrFilesID
      }
      console.log(data);
      this.closebutton.nativeElement.click();
      this.ngxUiLoaderService.start();
      this.api.bulkRestore(data).subscribe(data=>{
        console.log(data);
        this.bulkRestoreResponse = data;
        if(this.bulkRestoreResponse.status == 200){
          console.log(this.bulkRestoreResponse,'dsdsadsdasdasdsadasdasdasdasdasdasdasdas');
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.bulkRestoreResponse.message, "Dismiss", {
            duration:3000,
          }) 
          setTimeout(() => {
            $('#deleteModal3').modal('hide');
            this.selection = false;
            this.getEmployees();
          }, 100); 

         
        }else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.bulkRestoreResponse.message, "Dismiss", {
            duration:3000,
          })
        }
      },
      error => {
        this.ngxUiLoaderService.stop();
      });

    }


    // getEmployees() {
    //   this.api.getCatdsDetails().subscribe(data=>{
    //       this.response = data.result7.files;
    //       console.log(this.response)
    //   });
    // }

    getEmployees() {
      this.ngxUiLoaderService.start();
      console.log("SAdsdsadsadsadsadsadsadsadsadsadasdsadsa")
      this.api.getCatdsDetails().subscribe(data => {
        this.ngxUiLoaderService.stop();
        console.log(data,'111111');
        this.response = data;
        if (this.response.status == 200) {
          this.resposeData = this.response.folder_results;
          console.log(this.resposeData);
          this.deletedFiles = this.response.file_results;
          console.log(this.deletedFiles);
          this.deletedProjects = this.response.Project_List;
          console.log(this.deletedProjects,'1111');
          this.routeId = this.response.key;
          console.log(this.routeId);
          
          
        }
        else{
          this.snackBar.open(this.response.message, "Dismiss", {
            duration:3000,
          })
        }
      }, error => {
        this.ngxUiLoaderService.stop();
      });
    }
    





  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }

  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }

  selectAction() {
    console.log(this.resposeData)
    console.log(this.deletedFiles)
    console.log(this.deletedProjects)
    this.selection = true;
    this.folderOrFilesID = [];
    if (Array.isArray(this.deletedFiles)) {
      this.deletedFiles.forEach(file => {
        console.log(file);
        
        file.completed = false;
      });
    }
  }

  cancel() {
    this.selection = false;
  }

  // deletePermanently(){
  //   var data = {
  //     files:this.folderOrFilesID}
  //   console.log(data,'1111');
  //   this.userMenu = false
   
  //   this.selection = false  ;
  //   this.closebutton.nativeElement.click();
  //   this.ngxUiLoaderService.start();
  //   this.api.deleteForever(data).subscribe(data=>{
  //     console.log(data);
  //     this.deleteResponse = data;
  //     // console.log(this.deleteResponse,'errere');
  //     if(this.deleteResponse.status == 200){
  //       this.ngxUiLoaderService.stop();
  //       this.snackBar.open(this.deleteResponse.message, "Dismiss", {
  //         duration:3000,
  //       })
  //       this.getEmployees();
  //     }else{
  //       this.ngxUiLoaderService.stop();
  //       this.snackBar.open(this.deleteResponse.message, "Dismiss", {
  //         duration:3000,
  //       })
  //     }
      
  //   },eerror=> {
  //     this.ngxUiLoaderService.stop();
  //   });
    
  // }

  restoreFiles(){
    this.selection = false  ;
    this.closebutton2.nativeElement.click();
    let data = {file_id: this.dataTorestore}
    console.log(data,'111111111111111111');
    this.ngxUiLoaderService.start();
    this.api.restoreDeletedFilesorFolders(data).subscribe(data=>{
      console.log(data,'dssdsdsdsdsdsdsdsds');
      this.restoreResponse = data;
      if(this.restoreResponse.status){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.restoreResponse.message, "Dismiss", {
          duration:3000,
        })
        $('#deleteModal2').modal('hide');
        this.getEmployees();
      }else{
        this.snackBar.open(this.restoreResponse.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
      
    },error=> {
      this.ngxUiLoaderService.stop();
    });
  }

  gridActive(key) {
    if(key == "listActive"){
      this.gridView =  false;
    }else {
      this.gridView =  true;
    }
  }


  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  deleteForever(data){
    // this.deletePermanently(data);
    this.folderOrFilesID.push(data)
    // this.dataToDelete = data;
    console.log(data,'clicked'); 
    $('#deleteModal').modal('show');
    this.closebutton2.nativeElement.click();

  }
  restoreFileorFolder(data){
    console.log('clicked1',data);
    this.dataTorestore = data
    $('#deleteModal2').modal('show');
    this.closebutton2.nativeElement.click();
  }

  restoreLater(){
    var data = {
      files:this.folderOrFilesID,
      entity_type: 2
    }
    console.log(data,"clicked");
    this.closebutton.nativeElement.click();
    this.ngxUiLoaderService.start();
    this.api.bulkDeletePermenently(data).subscribe(data=>{
    //  console.log(data);
    this.restoreLaterResponse = data;
    if(this.restoreLaterResponse.status == 200){
      this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
        duration:3000,
      })
      this.getEmployees();
      this.selection = false;
      this.ngxUiLoaderService.stop();
    }else{
      this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
        duration:3000,
      })
      this.ngxUiLoaderService.stop();
    }
     
    },error=> {
      this.ngxUiLoaderService.stop();
    });
  }

  bulkdeletePermanentandLater(){
    
    var data = {
      files:this.folderOrFilesID,
      entity_type: 1
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.bulkDeletePermenently(data).subscribe(data=>{
      console.log(data);
      this.bulkDeleteresponse = data;
      if(this.bulkDeleteresponse.status == 200){
        console.log(this.bulkDeleteresponse);
        this.ngxUiLoaderService.stop();
        
        this.selection = false;
        this.snackBar.open(this.bulkDeleteresponse.message, "Dismiss", {
          duration:3000,
        })

        setTimeout(() => {
          $('#deleteModal').modal('hide');
          this.getEmployees();
          this.selection = false;
          this.folderOrFilesID = [];
        }, 100); 

        

      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.bulkDeleteresponse.message, "Dismiss", {
          duration:3000,
        })
      }
    },error=> {
      this.ngxUiLoaderService.stop();
    });
  }
  opendeleteModal(){
    $('#deleteModal').modal('show');
  }
  openRestoreModal(){
    $('#deleteModal3').modal('show');
  }
  closeModal(){
    $('#deleteModal3').modal('hide');
  }
  closeModal1(){
    $('#deleteModal2').modal('hide'); 
  }
  
}
