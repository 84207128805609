import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  markAllreadresponse: any;
  notificationList: any;
  notificationData: any;
  readStatus: any;
  notificationStatus: any;
  filterForm: FormGroup;
  formattedDate: string;
  filterNotificationResponse: any;
  todayDate:Date = new Date(23, 59, 59);
  filterNotification: any;


  constructor(private api: ApiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.notification();

    this.filterForm =this.formBuilder.group({
      date: ['',[Validators.required]], 
    })
  }

  transformDate(isoDate: string): string {
    return this.datePipe.transform(isoDate, 'yyyy-MM-dd');
  }





  markAllasRead(notificationId) {
    console.log(notificationId);
    
    if (notificationId) {

      console.log('click',notificationId);
      let data = {notification_id:notificationId}
      console.log(data);

      this.ngxUiLoaderService.start();
      this.api.markAllread(data).subscribe(data => {
        console.log(data);
        this.markAllreadresponse = data

        if (this.markAllreadresponse.status = 200) {
          this.notification();
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.markAllreadresponse.message, "Dismiss", {
            duration: 3000,
          })

          
        } else {
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.markAllreadresponse.message, "Dismiss", {
            duration: 3000,
          })
          
        }

      }, error => {
        this.ngxUiLoaderService.stop();
      });

    }
    else {
      // console.log('poda pohhhh');
      let data = ''
      this.ngxUiLoaderService.start();

      this.api.markAllread(data).subscribe(data => {
        console.log(data);
        this.markAllreadresponse = data

        if (this.markAllreadresponse.status = 200) {
          this.notification()
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.markAllreadresponse.message, "Dismiss", {
            duration: 3000,
          })
          
          
        } else {
          this.snackBar.open(this.markAllreadresponse.message, "Dismiss", {
            duration: 3000,
          })
          this.ngxUiLoaderService.stop();
        }

      }, error => {
        this.ngxUiLoaderService.stop();
      });

    }

  }

  notification(){

    this.ngxUiLoaderService.start();
    this.api.getNotification().subscribe(data => {
      console.log(data);
      this.notificationData = data;
      console.log(this.notificationData);
      if (this.notificationData.status == 200) {
        this.ngxUiLoaderService.stop();
        // this.changeBackground()
        console.log(this.notificationData);
        this.notificationList = this.notificationData.results
        console.log(this.notificationList);

      } else {
        this.snackBar.open(this.notificationData.message, "Dismiss", {
          duration: 3000,
        })
        this.ngxUiLoaderService.stop();
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  // getNotificationStyles(): any {

  //   if (this.notificationList.status == 1) {
  //     return {
  //       'background-color': 'white',
  //       'border': '1px solid #C2C2C2'
  //     };
  //   } else {
  //     return {
  //       'background-color': '#F3F3F3'
  //     };
  //   }
  // }




  updateEndDate(){
    console.log('sdasd' ,this.filterForm.value.date);
    

    const isoDate = this.filterForm.value.date;
    const formattedDate = this.transformDate(isoDate);
    console.log(formattedDate);
    
    
    this.ngxUiLoaderService.start();
    let data ={
      date: formattedDate
    } 
    console.log(data);
    
    this.api.getFilterNotification(data).subscribe(data => {
      console.log(data);
      this.filterNotificationResponse = data;
      if(this.filterNotificationResponse.status == 200){
        console.log(this.filterNotificationResponse);
        // this.snackBar.open(this.filterNotificationResponse.message, "Dismiss", {
        //   duration: 3000,
        // })
        this.filterNotification = this.filterNotificationResponse.result;
        console.log(this.filterNotification);
        
        this.ngxUiLoaderService.stop();
      }else{
        this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.filterNotificationResponse.message, "Dismiss", {
        //   duration: 3000,
        // })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
    
  }

}
