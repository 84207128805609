<div class="footer">
    <div class="content">
      <div class="services">
        <img src="assets/images/footer_icon.png" alt="" class="curser_pointer" routerLink="/">
        <p>{{'we are happy to put our experience at the' | translate}}<br> {{'service of your assignment We promise to stay' | translate}} <br> {{'attentive to you and to evolve with you. Inflow,' | translate}} <br> {{'archiving intelligent..at the service of your documents' | translate}}. </p>
      </div>
      <div class="links">
        <h4>{{'Quick links' | translate}}</h4>
        <!-- <p *ngIf="show_dash"><a routerLink="/dashboard">{{'Dashboard' | translate}}</a></p> -->
        <p><a routerLink="/">{{'Home' | translate}}</a></p>
        <p><a routerLink="/about">{{'About' | translate}}</a></p>
        <!-- <p *ngIf="show_dash"><a (click)="logoutModal()"  class="curser_pointer">{{'Logout' | translate}}</a></p>
        <p><a routerLink="/login" *ngIf="!show_dash">{{'Login' | translate}}</a></p> -->
      </div>
      <!-- <div class="links">
        <h4>Quick links</h4>
        <p><a href="#">About us</a></p>
        <p><a href="#">Apps intro</a></p>
        <p><a href="#">Features</a></p>
        <p><a href="#">Benefits</a></p>
        <p><a href="#">Testimonial</a></p>
      </div> -->
      <div class="details">
        <h4 class="address">{{'Connect with' | translate}}</h4>
        <p class="connectUs_section">
          065 55 31 73 <br>
          Email : archivage@eflow-gabon.com <br>
          Adresse : BP 12151, LBV GABON
        </p>
        <!-- <p>{{'Connect on Social' | translate}}</p>
        <div class="social_media_icons">
          <img src="assets/images/facebook.png" alt="">
          <img src="assets/images/instagram.png" alt="">
          <img src="assets/images/twitternew.png" alt="">
          <img src="assets/images/youtube.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="footer_bottom">
      <hr>
      © 2024 In-flow | All Rights Reserved    
    </div>
</div>
<div class="modal fade" id="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="logoutModalLabel"  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Logout' | translate}}?</h5>
         
          <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closelogoutModal()"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Are you sure you want to log out this app' | translate}} ?</div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button class="btn_primary bg_blue" id="button-id" (click)="closelogoutModal()">{{'Cancel' | translate}}</button>
          <button id="button-id"   class="btn_primary bg_red"   (click)="logoUt()">{{'Logout' | translate}}</button>
        </div>      
      </div>
    </div>
</div>
