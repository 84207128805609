
<aside class="side_menu">   
    <img class="logo mt-4 mainLogo" routerLink="/" src="assets/images/About-nav.png" alt="">
    <button *ngIf="url == 'folders'" class="upload_btn mt-2 mb-3" data-bs-toggle="modal" data-bs-target="#documentModal"><img src="assets/images/office.png" alt="">{{'Create Doc' | translate}}</button>
    <div class="upload_main" [hidden]="currentValue && (!currentValue.permissions.createFolder && !currentValue.permissions.createFile)">
        <button class="upload_btn" *ngIf="url == 'folders'"  (click)="showUpload($event)"><img src="assets/images/add.png" class="new" alt="">{{'New' | translate}}</button>
        <ul class="uploadOptionsDrop" [ngClass]="{'drop': showOptions == true}" >
            <li *ngIf="(currentValue && currentValue.permissions.createFolder)"><a (click)="createFolder()"><img src="assets/images/new_folder.png" alt="">{{'New folder' | translate}}</a></li>
            <li [hidden]="!(currentValue && currentValue.permissions.createFile)">
                <input type="file" name="fileupload" multiple="multiple" id="fileupload" class="hide" />
                <label for="fileupload" class="fileupload"><img src="assets/images/upload_grey_icon.png" alt="">{{'File upload' | translate}}</label>
            </li>
            <li [hidden]="!(currentValue && currentValue.permissions.createFolder)">
                <input type="file" name="folderUpload" multiple="multiple" id="folderUpload" webkitdirectory class="hide" />
                <label for="folderUpload" class="folderUpload" ><img src="assets/images/upload_folder.png" alt="">{{'Folder upload' | translate}}</label>
            </li>
        </ul>
    </div>

    <div class="options" #sideMenu>
        <ul>
            <li routerLink="/dashboard" [ngClass]="{ 'active': url == 'dashboard'}">
                    <div class="align-items-center d-flex">
                        <div class="image_containor" *ngIf="url == 'dashboard'"><img src="assets/images/dashboardActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'dashboard'"><img src="assets/images/dashboard.png" alt=""></div>
                       {{'Dashboard' | translate}}
                    </div>
                    <img *ngIf="url == 'dashboard'" src="assets/images/arrow.png" alt="">
                
            </li>


            <li routerLink="/projects" [ngClass]="{ 'active': url == 'projects' || url == 'folders' || url == 'project-settings'}">
              
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'projects' || url == 'folders' || url == 'project-settings'"><img src="assets/images/projectActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'projects' && url != 'folders' && url != 'project-settings'"><img src="assets/images/project.png" alt=""></div>
                        {{'Projects' | translate}}
                    </div>
                    <img *ngIf="url == 'projects' || url == 'folders' || url == 'project-settings'" src="assets/images/arrow.png" alt="">
            </li>

            <li routerLink="/signature" [ngClass]="{ 'active': url == 'signature'}" >
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'signature'"><img src="assets/images/sign-blue.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'signature'"><img src="assets/images/signature.png" alt=""></div>
                    {{'Signature' | translate}}
                </div>
                <img *ngIf="url == 'signature'" src="assets/images/arrow.png" alt="">
            </li>

            <li routerLink="/catalogue" [ngClass]="{ 'active': url == 'catalogue'}"  *ngIf="(currentValue && currentValue.permissions.manageTeam)">
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'catalogue'"><img src="assets/images/teamActive.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'catalogue'"><img src="assets/images/team.png" alt=""></div>
                    {{'Teams' | translate}}
                </div>
                <img *ngIf="url == 'catalogue'" src="assets/images/arrow.png" alt="">
            </li>


            <li  routerLink="/permissions" [ngClass]="{ 'active': url == 'permissions'}" *ngIf="(currentValue && currentValue.permissions.managePermissions)">
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'permissions'"><img src="assets/images/permissionActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'permissions'"><img src="assets/images/permission.png" alt=""></div>
                        {{'Permissions' | translate}}
                    </div>
                    <img *ngIf="url == 'permissions'" src="assets/images/arrow.png" alt="">
            </li>
            <li routerLink="/metadata" [ngClass]="{ 'active': url == 'metadata-form' || url == 'metadata'}"  *ngIf="(currentValue && currentValue.permissions.manageTemplates)">
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'metadata-form' || url == 'metadata'"><img src="assets/images/metadataActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'metadata-form' && url != 'metadata'"><img src="assets/images/metadata.png" alt=""></div>
                        {{'Metadata' | translate}}
                    </div>
                    <img *ngIf="url == 'metadata-form' || url == 'metadata'" src="assets/images/arrow.png" alt="">
            </li>
            <li routerLink="/reports" [ngClass]="{ 'active': url == 'reports'}" *ngIf="(currentValue && currentValue.permissions.viewStatistics)">
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'reports'"><img src="assets/images/report_blue.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'reports'"><img src="assets/images/report.png" alt=""></div>
                        {{'Reports' | translate}}
                    </div>
                    <img *ngIf="url == 'reports'" src="assets/images/arrow.png" alt="">
            </li>



            <li routerLink="/recyclebin" [ngClass]="{ 'active': url == 'recyclebin'}" *ngIf="(currentValue && currentValue.permissions.ViewRecycleBin)">
                <div class="d-flex align-items-center">
                    <div *ngIf="url != 'recyclebin'" class="image_containor"><img src="assets/images/recycle.png" alt=""></div>
                    <div *ngIf="url == 'recyclebin'" class="image_containor"><img src="assets/images/recycle_blue.png" alt=""></div>
                        {{'Recycle Bin' | translate}}
                </div>
                <img *ngIf="url == 'recyclebin'" src="assets/images/arrow.png" alt="">
            </li>
            <li routerLink="/restorefiles/restoredeletedfiles" [ngClass]="{'active':url == 'restorefiles' || url == 'archieved-files'}" *ngIf="currentValue && (currentValue.permissions.Archive)">
                <div class="d-flex align-items-center">
                    <div *ngIf="url != 'restorefiles' || url == 'archieved-files' " class="image_containor"><img src="assets/images/archive.png" alt=""></div>
                    <div *ngIf="url == 'restorefiles' && url != 'archieved-files' " class="image_containor"><img src="assets/images/archiveblue.png" alt=""></div>
                    {{'Archive' | translate}}
                </div>
                <img *ngIf="url == 'restorefiles' || url == 'archieved-files'" src="assets/images/arrow.png" alt="">
            </li>
        </ul>
    </div>

    <!-- <div>
        <div class="line mt-5"></div>
        <div class="storage_line" data-aos="fade-down" data-aos-delay="1000" data-aos-duration="1500">
            <img src="assets/images/storageline.png" alt="">
            <div class="text_sm">13.6 GB of 50 GB used</div>
        </div>

        <div class="upgrade-graphics" data-aos="fade-down" data-aos-delay="1000" data-aos-duration="1500">
            <div>
                <img src="assets/images/rocket.png" alt="">

                <h5>Want to upgrade storage</h5>
                <button>Upgrade now</button>
            </div>
        </div>
    </div> -->
    <div class="footer">
            <div class="logOutOption mt-5"   (click)="logoutModal()">
                
                <div class="image">
                    <img src="assets/images/logout.png" alt="">
                </div>
                <div class="test">{{'Logout' | translate}}</div>
            </div>
    </div>

    <!-- <input
    id="1-basic"
    type="file"
    #fileInput
    (change)="uploadFiles(fileInput.files)"
    webkitdirectory
    multiple
  />


  <input type="file" (change)="upload($event.target.files)" /> -->
  
</aside>



<div class="modal fade" id="documentModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <div class="modal_header">
          <h5>Add Roles</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
    

      
          <div class="modal-body">
            <div data-bs-dismiss="modal" class="docs-options ">
                <img src="assets/images/doc_logo.png" alt="">
                {{'Create Document' | translate}}
            </div>

            <div data-bs-dismiss="modal" class="docs-options">
                <img src="assets/images/excel_logo.png" alt="">
                {{'Create Excel' | translate}}
            </div>

            <div data-bs-dismiss="modal" class="docs-options">
                <img src="assets/images/ppt_logo.png" alt="">
                {{'Create Presentation' | translate}}
            </div>
          </div>
      
      </div>
    </div>
  </div>


  <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel"   aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
      
            <div class="modal_header">
                <h5>{{'Add New Folder' | translate}}</h5>
                <button type="button" #closebutton class="btn-close" (click)="closseModal()" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div id="folderName_And_Tagsection">
                <form [formGroup]="createNewfolderForm">
                    <div class="modal-body">

                        
                        <div class="input_style mt-4">
                            
                            <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
                            <input class="input" type="email" aria-describedby="emailHelp" placeholder="{{'Folder Name' | translate}}" formControlName="folder_name"  maxlength="32">
                            <div *ngIf = "folderSubmitted && a.folder_name.errors">
                                <div class="error_message" *ngIf="a.folder_name.errors.required">{{'Folder Name is required' | translate}}</div>
                                <!-- <div class="error_message" *ngIf="a.groupName.errors.pattern">{{'Email should valid' | translate}}</div> -->
                            </div>
                        </div>
                        <div class="input_style mt-3">
                            <label for="exampleInputEmail1">{{'Priority' | translate}}</label>

                            <div class="select-createGroup">
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select placeholder="{{'Select Priority' | translate}}" formControlName="tag">
                                        <mat-option value="1">{{ 'High'| translate }}</mat-option>
                                        <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                        <mat-option value="3">{{'Low' | translate}}</mat-option>
                                        <mat-option value="{{item.id}}"  *ngFor="let item of priority">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                
                            </div>
                            <div *ngIf="folderSubmitted && a.tag.errors" class="m-2">
                                <div class="error_message" *ngIf="a.tag.errors.required">{{'Priority' | translate}} {{'is required' | translate}}</div>
                            </div>
    
                        </div>
                        <div class="container_input_flex">
                            <div class="input_style mt-4 d-flex align-items-center">
                                <div class="datePicker">
                                    <div class="dateDiv">
                                        <label for="">{{'Starts from' | translate}}</label>
                                        <mat-form-field class="example-full-width" appearance="none">
                                            <input matInput [matDatepicker]="dp10"  [min]="todayDate" [matDatepicker]="picker" readonly formControlName="start_date" (dateChange)="updateEndDate()">
                                            <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                                            <mat-datepicker #dp10></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="folderSubmitted && a.start_date.errors" class="m-2">
                                            <div class="error_message" *ngIf="a.start_date.errors.required">{{'start date' | translate}} {{'is required' | translate}}</div>             
                                        </div>
                                    </div>
                                    <div>
                                        <img class="minus" src="assets/images/minus.png" alt="">
                                    </div>
                                    <div class="dateDiv">
                                        <div>
                                            <label for="">{{'Ends on' | translate}}</label>
                                            <mat-form-field class="example-full-width" appearance="none">
                                                <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly formControlName="end_date" [min]="a.start_date.value" >
                                                <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                                <mat-datepicker #dp2></mat-datepicker>
                                            </mat-form-field>
                                            <div *ngIf="folderSubmitted && a.end_date.errors" class="m-2">
                                                <div class="error_message" *ngIf="a.end_date.errors.required">{{'end date' | translate}} {{'is required' | translate}} </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                                
                            </div>
                        </div>
                    </div>

                    <button class="btn_primary bg_blue"  (click)="createNewfolder()">{{'Create' | translate}}</button>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="logoutModalLabel"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal_header">
            <h5>{{'Logout' | translate}}?</h5>
           
            <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closelogoutModal()"></button>
          </div>
          <div class="modal-body">
            <div class="text-center">{{'Are you sure you want to log out this app' | translate}} ?</div>
          </div>
          <div id="delete_id" class="btnGrp mt-4">
            <button class="btn_primary bg_blue" id="button-id" (click)="closelogoutModal()">{{'Cancel' | translate}}</button>
            <button id="button-id"   class="btn_primary bg_red"   (click)="logOut()">{{'Logout' | translate}}</button>
          </div>      
        </div>
      </div>
</div>

