import { Component, OnInit, HostListener, ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute,Router,Params } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from '../../../_services/api.services';
import { MatSnackBar } from '@angular/material/snack-bar';
declare const window: any;
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  
  contactForm : FormGroup;
  submitted = false;
  response:any;
  businesssData:any;
  loginvalue:any;
  show_contact:boolean = false;
  responsedata: any;
  private iti: any;
  // @ViewChild('businesscontact', {static: true}) businesscontact: ElementRef;
  // @ViewChild('domainPermission', {static: true}) domainPermission: ElementRef;

  constructor(private router: Router,
              private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService,
              private snackBar: MatSnackBar,
              private authenticationService: AuthenticationService,
              public formBuilder: FormBuilder) { 
                if (this.authenticationService.currentUserValue) {
                  // console.log('atheda vannedaaaaaaaaaa');
                  this.loginvalue = this.authenticationService.currentUserValue;
                  console.log(this.loginvalue);
                  if (this.loginvalue) {
                    this.show_contact = true;
                  }else{
                    this.show_contact = false;
                  }
                }
              }


  ngOnInit() {
      // console.log(this.businesscontact.nativeElement);
      // var element = document.querySelector('.domain-permission');
      // var con = element.computedStyleMap();
      // console.log(con, "sssss");
    AOS.init({ disable: 'mobile'});
    // $('#business-contact-change').click(function(){
    //   $('.business-contact').css("display", "none");
    //   $('.domain-permission').css("display", "block");
    //   // $('.domain-permission').css({
    //   //     'display' : block
    //    })

    // });

    // $('.btn-2').click(function(){
    //     $('.business-contact').css("display", "none");
    //     $('.domain-permission').css("display", "none");
    //     $('.domain-search').css("display", "block");
    //     // $('.domain-permission').css({
    //     //     'display' : block
    //     // })
    // });
    if(this.show_contact){
      this.getBusinessDetails();
    }
    var input = document.querySelector("#phone");
    this.iti = window.intlTelInput(input, {
          // show dial codes too
          separateDialCode: true,
          // If there are some countries you want to show on the top.
          // here we are promoting russia and singapore.
          // preferredCountries: ["ru", "sg"],
          //Default country
          initialCountry:"ga",
          // show only these countres, remove all other
          // onlyCountries: ["ru", "cn","pk", "sg", "my", "bd"],
          // // If there are some countries you want to execlde.
          // // here we are exluding india and israel.
          // excludeCountries: ["in","il"]
        });


        this.contactForm = this.formBuilder.group({
          first_name:['',[Validators.required, Validators.pattern("^[a-zA-Z0-9,À-ÿ]+(\\s{0,1}[a-zA-ZÀ-ÿ])*$")]],
          last_name:['',[Validators.required, Validators.pattern("^[a-zA-Z0-9,À-ÿ]+(\\s{0,1}[a-zA-ZÀ-ÿ])*$")]],
          email:['',[Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
          mobile:['',[Validators.required, Validators.minLength(8), Validators.pattern('^[0-9]+$')]]
        })
  }

  // @HostListener('window:popstate', ['$event'])
  //   onPopState(event) {
  //     var element = document.querySelector('.domain-permission');
  //     var con = element.computedStyleMap().get('display');
  //     console.log(con,'Back button pressed');
  //  }


  get contactFormGet() {
    return this.contactForm.controls;
  }

  onSubmit() {
    console.log(this.contactForm.value)
    console.log(this.iti.getSelectedCountryData().dialCode)
    this.submitted = true;
    if(this.contactForm.invalid) {
      return;
    }
    let data = this.contactForm.value;
    data['mobile']='+' + this.iti.getSelectedCountryData().dialCode + this.contactForm.value.mobile;
    this.ngxUiLoaderService.start();
    this.api.uploadContactData(data).pipe(first())
    .subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.responsedata = data;
      this.snackBar.open(this.responsedata.message, "Alerte", {
        duration: 6000,
      });
      this.contactForm.reset();
      this.submitted = false;
    }, error => {
      this.ngxUiLoaderService.stop();
    });
      // console.log(data);
      // const jsonData = JSON.stringify(data);
      // console.log(jsonData);
      // this.downloadJson(jsonData, 'form-data.json');
  


    //dont delet this below codes. it is the actual code  


    // if(this.contactForm.invalid) {
    //   return;
    // }else {
    //   let data = this.contactForm.value;
    //   data['stage_key'] = 200;
    //   console.log(data)
    //   this.ngxUiLoaderService.start();
    //   this.api.employeeCountForBusiness(data).subscribe(data => {

    //     this.ngxUiLoaderService.stop();
    //     this.response = data;

    //     if(this.response.status == "200") {
    //       this.router.navigate(['/business-info']);
    //     }else {
    //       this.snackBar.open(this.response.message, "Alerte", {
    //         duration: 2000,
    //       });
    //     }

    //   },error => {
    //     this.ngxUiLoaderService.stop();
    //   })
    // }

  }

  downloadJson(jsonData: string, filename: string) {
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  getBusinessDetails() {
    this.api.getBusinessAccountDetails().subscribe(data =>{
      
      if(data.status == "200") {
        console.log('aaaa');
        this.businesssData = data.results;
        console.log(this.businesssData);

        if(this.businesssData.stage > "100") {
          this.contactForm.controls.first_name.setValue(this.businesssData.first_name);
          this.contactForm.controls.last_name.setValue(this.businesssData.last_name);
          this.contactForm.controls.email.setValue(this.businesssData.email);
          this.contactForm.controls.phone.setValue(this.businesssData.mobile);
        }
      }
    })
  }



}
