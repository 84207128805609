<div id="mySidenav" class="sidenav rightSideNav">
    <i (click)="closeNav()" class="fa-solid fa-xmark closeNav"></i>
    <div class="d-flex languageLoginIcon">
        <img class="logo" src="assets/images/About-nav.png" alt="" routerLink="/">
        <div class="languageButton language">
            <div class="button r" id="button-6">
                <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                <div class="knobs"></div>
                <div class="layer"></div>
            </div>
        </div>
    </div>
    <div class="options">
        <ul>
            <!-- <li routerLink="/dashboard" *ngIf="show_dash"  [ngClass]="{ 'active': url == 'dashboard'}">
                <div class="align-items-center d-flex">
                    <div class="image_containor" *ngIf="url == 'dashboard'"><img src="assets/images/dashboardactives.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'dashboard'"><img src="assets/images/dashboardicon.png" alt=""></div>
                    {{'Dashboard' | translate}}
                </div>
                <img *ngIf="url == 'dashboard'" src="assets/images/arrow.png" alt="">
                
            </li> -->
            <!-- <li routerLink="/" [ngClass]="{ 'active': url == ''}">
                <div class="align-items-center d-flex">
                    <div class="image_containor" *ngIf="url == ''"><img src="assets/images/dashboardActive.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != ''"><img src="assets/images/dashboard.png" alt=""></div>
                    {{'Home' | translate}}
                </div>
                <img *ngIf="url == ''" src="assets/images/arrow.png" alt="">
                
            </li>
            <li routerLink="/about" [ngClass]="{ 'active': url == 'about'}">
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'about'"><img src="assets/images/aboutactive.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'about'"><img src="assets/images/abouticon.png" alt=""></div>
                    {{'About' | translate}}
                </div>
                <img *ngIf="url == 'about'" src="assets/images/arrow.png" alt="">
            </li> -->
            <!-- <li routerLink="/login" *ngIf="(!show_dash)" [ngClass]="{ 'active': url == 'login'}">
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'login'"><img src="assets/images/loginactive.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'login'"><img src="assets/images/loginicon.png" alt=""></div>
                    {{'Login' | translate}}
                </div>
                <img *ngIf="url == 'login'" src="assets/images/arrow.png" alt="">
            </li> -->
            <!-- <li *ngIf="show_dash"  [ngClass]="{ 'active': url == 'logout'}"  (click)="logoutModal()">
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'logout'"><img src="assets/images/logoutactive.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'logout'"><img src="assets/images/logouticon.png" alt=""></div>
                    {{'Logout' | translate}}
                </div>
                <img *ngIf="url == 'logout'" src="assets/images/arrow.png" alt="">
            </li>  -->
        </ul>
    </div>
    <div>
        <div class="line mt-3"></div>
    </div>
 
    
</div>



<header>
    <nav>
        <div class="nav-bar">
            <div class="nav_logo">
                <img src="assets/images/About-nav.png" class="curser_pointer" alt="" routerLink="/"> 
            </div>

            <div class="menu bar_menu">
                <i (click)="openNav()" class="fa-solid fa-bars"></i>
            </div>
            
            
            <div class="register_login">
                <ul class="nav-links">
                    <!-- <li><a routerLink="/dashboard" *ngIf="show_dash">{{'Dashboard' | translate}}</a></li> -->
                    <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/">{{'Home' | translate}}</a></li>
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/about">{{'About' | translate}}</a></li> -->
                    <!-- <li><a (click)="logoutModal()" class="curser_pointer" *ngIf="show_dash">{{'Logout' | translate}}</a></li> -->
                </ul>
                <div class="languageButton">
                    <div class="button r" id="button-6">
                        <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                        <div class="knobs"></div>
                        <div class="layer"></div>
                    </div>
                </div>
                <!-- <div class="button_login" *ngIf="(!show_dash) && (login)">
                    <button  class="login-button" routerLink="/login">{{'LOGIN' | translate}}</button>
                </div> -->
            </div>
        </div>
    </nav> 
</header>

<div class="modal fade" id="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="logoutModalLabel"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal_header">
            <h5>{{'Logout' | translate}}?</h5>
           
            <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closelogoutModal()"></button>
          </div>
          <div class="modal-body">
            <div class="text-center">{{'Are you sure you want to log out this app' | translate}} ?</div>
          </div>
          <div id="delete_id" class="btnGrp mt-4">
            <button class="btn_primary bg_blue" id="button-id" (click)="closelogoutModal()">{{'Cancel' | translate}}</button>
            <button id="button-id"   class="btn_primary bg_red"   (click)="logOut()">{{'Logout' | translate}}</button>
          </div>      
        </div>
      </div>
</div>