// google-signin.service.ts

import { Injectable } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleSignInService {
  private loggedIn: boolean;

  constructor(private authService: AuthService) {}

  signInWithGoogle(){
    console.log('JJJJJ')
    return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      console.log(user,"dsdadsada")

      this.loggedIn = true;
      return user;
    }, error => {
        console.log(error);
    });
  }

  signOut(): void {
    this.authService.signOut().then(() => {
      this.loggedIn = false;
    });
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

 alreadySignedUser(): Observable<any> {
    return this.authService.authState.pipe(map(user => {
        console.log(user)
        this.loggedIn = (user != null);
        return user;
    }));
 }
}
