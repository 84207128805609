<footer >
    <div>

        <div class="logo">
            <img src="assets/images/logo-white.png" alt="">
        </div>
        <span>2023 All Rights Reserved</span>
    </div>

    <div class="section2">
        <div class="d-flex flex-column align-items-end">
            <h4 class="heading_1">Contact Us</h4>
            <div class="line"></div>
        </div>
        <div>
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-location-dot mr-12"></i>
                <h5 class="">Wiscosin Ave, Suite 700 <br>Chevy Chase 20815</h5>
            </div>
            <div class="d-flex align-items-center mt-4">
                <i class="fa-regular fa-envelope mr-12"></i>
                <h5 class="">suppor@inflow.com</h5>
            </div>
            <div class="d-flex align-items-center mt-4">
                <i class="fa-solid fa-phone-volume mr-12"></i>
                <h5 class="">+1 800 854-36-80</h5>
            </div>
        </div>
    </div>
    

</footer>
