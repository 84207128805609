<app-sidebar></app-sidebar>
<app-header></app-header>
<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow mb-5">

                <div class="title_container">
                    <div class="parent-flex">
                        <div class="title_left">
                            <div class="d-flex align-items-center">
                                <div class="title_lg">{{'Notification' | translate}}</div>
                            </div>
                            <div class="d-flex"></div>
                        </div>
                        <div class="endChild">
                            <!-- <button class="btn_primary bg_blue"  (click)="markAllasRead(1)">{{'Mark all as read' | translate}}</button> -->
                            <div class="sort_parent">
                                <label for="">{{'Sort by date' | translate}}</label>
                                <form  [formGroup]="filterForm">
                                    <mat-form-field class="example-full-width" appearance="none">
                                        <input matInput [matDatepicker]="dp10"  [min]="todayDate" [matDatepicker]="picker" placeholder="{{'Select date' | translate}}" readonly formControlName="date" (dateChange)="updateEndDate()">
                                        <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                                        <mat-datepicker #dp10></mat-datepicker>
                                    </mat-form-field>
                                </form>
                                <!-- <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select class="custom-select2 text-dark" placeholder="{{'Sort' | translate}}">
                                        <mat-option value="1" >{{'All notification' | translate}}</mat-option>
                                        <mat-option value="2">{{'Today' | translate}}</mat-option>
                                        <mat-option value="3">{{'Last week' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                            </div>
                        </div>
                    </div>
                </div>
<!-- 
                <div class="notification_card unread">
                    <p>Lorem ipsum dolor sit amet consectetur. Fames velit gravida malesuada tortor sapien. Risus et quis non rutrum eget sem laoreet. Tincidunt amet donec purus tincidunt facilisis integer..</p>
                    <div class="grp">
                        <div class="read_btn">{{'Mark as read' | translate}}</div>
                        <div class="date">13:23, 12 October 2023</div>
                    </div>
                    
                    
                    #F3F3F3
                </div> -->
                <div *ngIf="!filterNotification">
                    <div  *ngFor="let notification of notificationList" class="notification_card unread mt-3"  [ngStyle]="notification.status =='1'?{'background-color':'white' ,'border':'1px solid #C2C2C2'} : {'background-color':'#F3F3F3' }">
                        <p>{{notification.message}}</p>
                        <!-- <p>{{notification.status}}</p> -->
                        <div class="grp">
                            <div class="read_btn" *ngIf="notification.status == 0" (click)="markAllasRead(notification.id)">{{'Mark as read' | translate}}</div>
                            <div class="date">{{notification.created_at}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterNotification">
                    <div  *ngFor="let notification of filterNotification" class="notification_card unread mt-3"  [ngStyle]="notification.status =='1'?{'background-color':'white' ,'border':'1px solid #C2C2C2'} : {'background-color':'#F3F3F3' }">
                        <p>{{notification.message}}</p>
                        <!-- <p>{{notification.status}}</p> -->
                        <div class="grp">
                            <div class="read_btn" *ngIf="notification.status == 0" (click)="markAllasRead(notification.id)">{{'Mark as read' | translate}}</div>
                            <div class="date">{{notification.created_at}}</div>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="notificationList && notificationList.length == 0" class="text-center mt-5">
                    <img src="assets/images/nonotification.jpg" class="img-fluid mb-2">
                    <p>{{'No Notification Found' | translate}}</p>
                </div>
                <div *ngIf="filterNotification && filterNotification.length == 0" class="text-center mt-5">
                    <img src="assets/images/nonotification.jpg" class="img-fluid mb-2">
                    <p>{{'No Notification Found' | translate}}</p>
                </div>
                
                <!-- <div class="notification_card unread mt-3">
                    <p>Lorem ipsum dolor sit amet consectetur. Fames velit gravida malesuada tortor sapien. Risus et quis non rutrum eget sem laoreet. Tincidunt amet donec purus tincidunt facilisis integer..</p>
                    <div class="grp">
                        <div class="read_btn">{{'Mark as read' | translate}}</div>
                        <div class="date">13:23, 12 October 2023</div>
                    </div>
                </div> -->
<!-- 
                <div class="notification_card read mt-3">
                    <p>Lorem ipsum dolor sit amet consectetur. Fames velit gravida malesuada tortor sapien. Risus et quis non rutrum eget sem laoreet. Tincidunt amet donec purus tincidunt facilisis integer..</p>
                    <div class="grp">
                        <div class="read_btn">Mark as read</div>
                        <div class="date">13:23, 12 October 2023</div>
                    </div>
                </div> -->


            </div>
        </div>
    </div>
</main>