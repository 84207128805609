import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { ConfirmPasswordValidator } from '../../../_validators/confirm-password.validator';
import { ApiService } from '../../../_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../_services/authentication.service';
import { ActivatedRoute,Router,Params } from '@angular/router';
import { GoogleSignInService } from '../../../_services/google-signin.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm : FormGroup;
  isSubmitted = false;
  response : any;
  showPasswordIcon = "fa-solid fa-eye-slash";
  showPasswordIcon2 = "fa-solid fa-eye-slash";
  showPassword2 = false;
  showPassword = false;
  passwordFieldType2: string = "password";
  passwordFieldType: string = "password";
  responsegoogle: any;
  checked = true;

  constructor(public formBuilder: FormBuilder,
              private ngxUiLoaderService: NgxUiLoaderService,
              private snackBar: MatSnackBar,
              private router: Router,
              private authenticationService: AuthenticationService,
              private api: ApiService,
              private googleSignInService: GoogleSignInService) 
              { }


  ngOnInit() {
    this.alreadySignedUser();
    AOS.init({ disable: 'mobile'});
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
      });


    this.signupForm = this.formBuilder.group({
      full_name : ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9,]+(\s{0,1}[a-zA-Z0-9, ])*$")]],
      email : ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
      password : ['',[Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*?#&](?!.*\\s).{7,}")]],
      confirmPassword : ['',Validators.required],
      i_agree : [true, [Validators.required, this.forbiddenCheckBox]],
    }, { validator: ConfirmPasswordValidator.MatchPassword });
  }

  alreadySignedUser() {
    this.googleSignInService.alreadySignedUser().subscribe(user => {
      console.log(user)
      if (user && user.authToken) {
        this.responsegoogle = user;
      }
    });
  }


  get signupValidate() {
    return this.signupForm.controls;
  }


  checkValue(values:any):void {
    console.log(values);
    if(values == false) {
      console.log('AAA')
      this.signupForm.controls['i_agree'].setValidators([Validators.required]);
    }else {
      this.signupForm.controls['i_agree'].clearValidators();
    }
    this.signupForm.controls['i_agree'].updateValueAndValidity();
  }

  onSubmit() {
      this.isSubmitted = true;
      console.log(this.signupForm);
      if(this.signupForm.invalid){
        return;
      }else {
        this.ngxUiLoaderService.start();
        let signUpArray = this.signupForm.value;
        signUpArray['type'] = "1";

        delete signUpArray.confirmPassword;

        this.api.signUp(signUpArray).subscribe(data => {
          this.ngxUiLoaderService.stop();
          console.log(data)
          this.response = data;
          if(this.response.status == '200') {
            localStorage.setItem('currentUser', JSON.stringify(this.response));
            this.authenticationService.currentUserSubject.next(this.response);
            this.router.navigate(['/business-creation']);
            this.snackBar.open(this.response.message, "Alerte", {
              duration: 3000,
            });
          }else {
            this.snackBar.open(this.response.message, "Alerte", {
              duration: 3000,
            });
          }
        }, error =>{
          this.ngxUiLoaderService.stop();
        });
      }
  }


  
  setShowPassword(id){
    if(id == "a") {
      this.showPassword = (this.showPassword)?false:true;
      if(this.showPassword){
          this.passwordFieldType= "text";
          this.showPasswordIcon = "fa-solid fa-eye";
      }else{
          this.passwordFieldType = "password";
          this.showPasswordIcon = "fa-solid fa-eye-slash";
      }
    }else {
      this.showPassword2 = (this.showPassword2)?false:true;
      if(this.showPassword2){
          this.passwordFieldType2= "text";
          this.showPasswordIcon2 = "fa-solid fa-eye";
      }else{
          this.passwordFieldType2 = "password";
          this.showPasswordIcon2 = "fa-solid fa-eye-slash";
      }
    }
    
  }

  signUpWithGoogle() {
    console.log("asdsadsadasdasdasd")
    this.googleSignInService.signInWithGoogle().then(data => {
      console.log(data, 'adhasjkdshajkdhsajk');
      
      this.response = data 
      let postdata = {
        email:this.response.email,
        authToken:this.response.authToken,
        full_name:this.response.name,
        type : "2"
      }
      this.ngxUiLoaderService.start();
      this.api.signUp(postdata).subscribe(data => {
        this.ngxUiLoaderService.stop();
        console.log(data)
        this.response = data;
        if(this.response.status == '200') {
          localStorage.setItem('currentUser', JSON.stringify(this.response));
          this.authenticationService.currentUserSubject.next(this.response);
          this.router.navigate(['/business-creation']);
          this.snackBar.open(this.response.message, "Alerte", {
            duration: 3000,
          });
        }else {
          this.snackBar.open(this.response.message, "Alerte", {
            duration: 3000,
          });
        }
      }, error =>{
        this.ngxUiLoaderService.stop();
      });
    });
  }

  forbiddenCheckBox(control: FormControl): { [s: string]: boolean } {
    if (control.value === false) {
      return {'checkBoxIsForbidden': true};
    }
  }


}
