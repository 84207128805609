<div class="login">
    <div class="bg_area_main">
        <app-header-landing-about></app-header-landing-about>
    <!-- <nav data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500"> 
        <div class="d-flex align-items-center justify-content-between main">
            <img src="assets/images/About-nav.png" class="logoImage" alt="" (click)="routeLanding()">
            <div class="menu_about_div">
                <ul>
                    <li class="mr-4" routerLink="/">{{'Home' | translate}}</li>
                    <li class="mr-4" routerLink="/about">{{'About' | translate}}</li>
                </ul>
            </div>
            
            <div class="toggle-button-cover toggle_btn toggle_btn_md">
                <div class="button-cover">
                <div class="button r" id="button-6">
                    <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                </div>
                </div>
            </div>
        </div>
    </nav> -->

    <div class="authentications mt-5">
    <div class="container_custom">
        <div class="row">
            <div class="col-md-6">
                <div class="bg_area" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
                    <img src="assets/images/cloudStorage.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6">
                <div class="section_1" id="parent">
                    <div class="d-flex flex-column justify-content-center">
                        <img class="logo img-fluid logoImage" (click)="routeLanding()" src="assets/images/About-nav.png" alt="" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
                        <div class="d-flex justify-content-center mt-3">
                            <div class="content_main">
                                <form [formGroup]="signInForm">
                                    <h4 class="heading_1 mb-15" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1500">{{'Welcome Back' | translate}}</h4>
                                    <div class="input-style" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1500">
                                        <img src="assets/images/mail.png" alt="">
                                        <input type="text" placeholder="{{'Email address' | translate}}" formControlName="email">
                                    </div>

                                    <div *ngIf = "isSubmitted && loginValidate.email.errors">
                                        <div class="error_message" *ngIf="loginValidate.email.errors.required">{{'Email is required' | translate}}</div>
                                        <div class="error_message" *ngIf="loginValidate.email.errors.pattern">{{'Email should valid' | translate}}</div>
                                    </div>

                                    <div class="input-style" data-aos="fade-right" data-aos-anchor="#parent" data-aos-delay="800" data-aos-duration="1500">
                                        <img src="assets/images/password.png" alt="">
                                        <input type="{{ passwordFieldType }}" placeholder="{{'Password' | translate}}" maxlength="25" formControlName="password">
                                        <span (click)="setShowPassword('a')" class="eye_Icon"><i class="{{ showPasswordIcon }} eye_Icon"></i></span>
                                    </div>

                                    <div *ngIf = "isSubmitted && loginValidate.password.errors">
                                        <div class="error_message" *ngIf="loginValidate.password.errors.required">{{'Password is required' | translate}}</div>
                                    </div>

                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="form-check m-0" data-aos="fade-right" data-aos-anchor="#parent" data-aos-delay="1200" data-aos-duration="1500">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                {{'Remember me' | translate}}
                                            </label>
                                        </div>
                                        <h3 routerLink="/forgot-password" class="blue_letter" data-aos-anchor="#parent" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500">{{'Forgot password?' | translate}}</h3>
                                    </div>
                                    <!-- <button routerLink="/dashboard" class="btn-primary mt-5" data-aos="fade-right" data-aos-delay="1400" data-aos-duration="1500">LOGIN</button> -->

                                    <button type="submit" class="btn-primary mt-5" data-aos-anchor="#parent" data-aos="fade-left" data-aos-delay="1600" data-aos-duration="1500" (click)="onSubmit()">{{'LOGIN' | translate}}</button>
                                    <!-- <button class="btn-secondary mt-2 mb-5" data-aos-anchor="#parent" data-aos="fade-left" data-aos-delay="1600" data-aos-duration="1500"><img src="assets/images/google_icon.png" alt="">{{'Sign In with Google' | translate}}</button> -->
                                    <!-- <button *ngIf="responsegoogle" class="btn-secondary google mt-2" data-aos="fade-left" data-aos-delay="1800" data-aos-duration="1500" (click)="signUpWithGoogle()">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <img class="img-fluid user" src="{{ responsegoogle.photoUrl }}" >
                                            </div>
                                            <div class="col-md-8 col-8">
                                                <p>Continue as <b>{{ responsegoogle.firstName }}</b></p>
                                                <h6>{{ responsegoogle.email }}</h6>
                                            </div>
                                            <div class="col-md-2 col-2">
                                                <img class="img-fluid g-1" src="assets/images/google.png" >
                                            </div>
                                        </div>
                                    </button> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
</div>
