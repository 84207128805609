import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('.transition').hover(
      function(){ 
        $(".transition.active").addClass('inactive').removeClass('active');
      },
      function(){ 
        $(".transition.inactive").addClass('active').removeClass('inactive'); 
      }
    );
  }

  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }
  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }

}
