import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateService } from './_services/translate.service';
import { TranslatePipe } from './_pipes/translate.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FavouriteComponent } from './pages/favourite/favourite.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HeaderComponent } from './pages/layout/header/header.component';
import { SidebarComponent } from './pages/layout/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from '../../src/app/_helpers/basic-auth.interceptor';
import { ErrorInterceptor } from '../../src/app/_helpers/error.interceptor';
import { SharedComponent } from './pages/shared/shared.component';
import { RecyclebinComponent } from './pages/recyclebin/recyclebin.component';
import { LoginComponent } from './pages/register/login/login.component';
import { ForgotPasswordComponent } from './pages/register/forgot-password/forgot-password.component';
import { FooterComponent } from './pages/layout/footer/footer.component';
import { SignupComponent } from './pages/register/signup/signup.component';
import { PackagesComponent } from './pages/register/packages/packages.component';
import { BusinessInfoComponent } from './pages/register/business-info/business-info.component';
import { ContactInfoComponent } from './pages/register/contact-info/contact-info.component';
import { BusinessCreationComponent } from './pages/register/business-creation/business-creation.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { SubscriptionPlanComponent } from './pages/subscription-plan/subscription-plan.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { FoldersComponent } from './pages/folders/folders.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { MatButtonModule,MatSnackBarModule,MatDatepickerModule,MatNativeDateModule,MAT_DATE_LOCALE, MatProgressBarModule, MatListModule, MatRadioModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatRippleModule, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
  PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { MatTreeModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { DomainPermissionComponent } from './pages/register/domain-permission/domain-permission.component';
import { DomainSearchComponent } from './pages/register/domain-search/domain-search.component';
import { PlanSummaryComponent } from './pages/register/plan-summary/plan-summary.component';
import { TermsAndConditionsComponent } from './pages/register/terms-and-conditions/terms-and-conditions.component';
import { MetadataFormComponent } from './pages/metadata-form/metadata-form.component';
import { ProjectSettingsComponent } from './pages/project-settings/project-settings.component';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MetadataComponent } from './pages/metadata/metadata.component';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { RestorefilesComponent } from './pages/restorefiles/restorefiles.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ToggleButtonComponent } from '../app/_helpers/toggle-button.component';
import { ArchieveFilesComponent } from './pages/archieve-files/archieve-files.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { DatePipe } from '@angular/common';
import { SearchedfilesComponent } from './pages/searchedfiles/searchedfiles.component';
import { MoveModalComponent } from './pages/move-modal/move-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material';
import { TagInputModule } from 'ngx-chips';
import { NgApexchartsModule } from "ng-apexcharts";
import { FilePreviewComponent } from './pages/file-preview/file-preview.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { RouteReuseStrategy } from '@angular/router';
import { ReuseRoutingStrategy } from './_services/route-reuse.service';
import { MainLayoutComponent } from './pages/layout/main-layout/main-layout.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { HeaderLandingAboutComponent } from './pages/layout/header-landing-about/header-landing-about.component';
import { FooterLayoutComponent } from './pages/layout/footer-layout/footer-layout.component';
import { ErrorComponentComponent } from './pages/error-component/error-component.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ArchievedFilesComponent } from './pages/archieved-files/archieved-files.component';

  const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'yellow',
    // bgsOpacity: 0.5,
    // bgsPosition: POSITION.bottomCenter,
     bgsSize:420,
    // bgsType: SPINNER.rectangleBounce,
    fgsColor: 'white',
    // fgsPosition: POSITION.centerCenter,
     fgsSize: 200,
     fgsType: SPINNER.ballScaleMultiple,
    // logoUrl: 'assets/angular.png',
    pbColor: '#004E9A',
    // pbDirection: PB_DIRECTION.leftToRight,
     pbThickness: 10,
    // text: 'Welcome to ngx-ui-loader',
    // textColor: '#FFFFFF',
    // textPosition: POSITION.centerCenter
  };

  export const CUSTOM_DATE_FORMAT = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  const googleLoginOptions = {
    scope: 'profile email',
    plugin_name: 'E-Flow'
  };

  let config = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider("403592771201-0dvf2pju0c8cpgrfihjnjgh515tod57s.apps.googleusercontent.com", googleLoginOptions)
    }
  ]);
  export function provideConfig() {
    return config;
  }
  export function setupTranslateFactory(
    service: TranslateService): Function {
    return () => service.use(localStorage.getItem('language') || "fr");
  }
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FavouriteComponent,
    TranslatePipe,
    ToggleButtonComponent,
    HeaderComponent,
    SidebarComponent,
    SharedComponent,
    RecyclebinComponent,
    LoginComponent,
    ForgotPasswordComponent,
    FooterComponent,
    SignupComponent,
    PackagesComponent,
    BusinessInfoComponent,
    ContactInfoComponent,
    BusinessCreationComponent,
    ProfileSettingsComponent,
    CatalogueComponent,
    SubscriptionPlanComponent,
    ReportsComponent,
    FoldersComponent,
    ProjectsComponent,
    DomainPermissionComponent,
    DomainSearchComponent,
    PlanSummaryComponent,
    TermsAndConditionsComponent,
    MetadataFormComponent,
    ProjectSettingsComponent,
    MetadataComponent,
    PermissionsComponent,
    RestorefilesComponent,
    NotificationsComponent,
    ArchieveFilesComponent,
    SignatureComponent,
    SearchedfilesComponent,
    MoveModalComponent,
    FilePreviewComponent,
    AboutPageComponent,
    LandingPageComponent,
    MainLayoutComponent,
    HeaderLandingAboutComponent,
    FooterLayoutComponent,
    ErrorComponentComponent,
    ArchievedFilesComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OverlayModule,
    MatTooltipModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, 
    NgApexchartsModule,
    OwlDateTimeModule,
    MatStepperModule,
    OwlNativeDateTimeModule,
    SocialLoginModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderHttpModule.forRoot({ showForeground: false }),
    NgxUiLoaderModule,
    MatButtonModule,MatSnackBarModule, MatProgressBarModule, MatRippleModule, MatRadioModule, MatSelectModule, MatCheckboxModule, MatListModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatIconModule, MatTreeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TagInputModule,
    NgxDocViewerModule,
    SignaturePadModule,
    AutoSizeInputModule,
    DragDropModule
  ],
  entryComponents: [
    MoveModalComponent // Ensure MoveModalComponent is listed in entryComponents
  ],
  providers: [
    DatePipe,
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    { provide: APP_INITIALIZER, useFactory: setupTranslateFactory, deps: [ TranslateService ], multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: RouteReuseStrategy, useClass: ReuseRoutingStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
