
<nav>
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/About-nav.png" alt="">
        <ul>

            <li class="mr-4" routerLink="/">Home</li>
            <!-- <li class="mr-4">Features</li> -->
            <li class="mr-4" routerLink="/about">About</li>
            <!-- <li class="mr-4">En</li>
            <li routerLink="/signup" class="mr-4">Sign Up</li>
            <li routerLink="/" class="mr-4">Login</li> -->
                
        </ul>
    </div>

</nav>

<div class="business-info mt-5 mb-5">
<div class="">
    <div class="main">
        <div data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500">
            <div class="heading-main">Enter Your Business Information</div>
            <div class="subText">Enter your business information  to register your domain.</div>
            <form [formGroup]="businesssInfoForm" (ngSubmit)="onSubmit()">
                <div class="row mt-3">
                    <div class="col-md-5 col-12">
                        <div class="input">
                            <label class="label-test" for="name">Street address</label>
                            <input type="text" value="Street Address" formControlName="street_address">
                        </div>

                        <div class="mt-2" *ngIf = "submitted && businesssInfoGet.street_address.errors">
                            <div class="error_message" *ngIf="businesssInfoGet.street_address.errors.required">Street address is required</div>
                            <div class="error_message" *ngIf="businesssInfoGet.street_address.errors.pattern">Street address shoud valid</div>
                        </div>
                    </div>

                    <div class="offset-md-1 col-md-5 col-12">
                        <div class="input">
                            <label class="label-test" for="name">Street address line 2</label>
                            <input type="text" value="Street Address Line 2" formControlName="street_address_line2">
                        </div>
                        <div class="mt-2" *ngIf = "submitted && businesssInfoGet.street_address_line2.errors">
                            <div class="error_message" *ngIf="businesssInfoGet.street_address_line2.errors.required">Street address line 2 is required</div>
                            <div class="error_message" *ngIf="businesssInfoGet.street_address_line2.errors.pattern">Street address line 2 shoud valid</div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-5 col-12">
                        <div class="input">
                            <label class="label-test" for="name">City</label>
                            <input type="text" value="City" formControlName="city">
                        </div>
                        <div class="mt-2" *ngIf = "submitted && businesssInfoGet.city.errors">
                            <div class="error_message" *ngIf="businesssInfoGet.city.errors.required">City is required</div>
                            <div class="error_message" *ngIf="businesssInfoGet.city.errors.pattern">City shoud valid</div>
                        </div>
                    </div>

                    <div class="offset-md-1 col-md-5 col-12">
                        <div class="input">
                            <label class="label-test" for="name">Pincode</label>
                            <input type="phone" maxlength="7" minlength="4" value="09164" formControlName="pincode">
                        </div>
                        <div class="mt-2" *ngIf = "submitted && businesssInfoGet.pincode.errors">
                            <div class="error_message" *ngIf="businesssInfoGet.pincode.errors.required">Pincode is required</div>
                            <div class="error_message" *ngIf="businesssInfoGet.pincode.errors.pattern || businesssInfoGet.pincode.errors.minlength">Pincode shoud valid</div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-5 col-12">
                        <div class="input">
                            <label class="label-test" for="name">State</label>
                            <input type="text" value="State" formControlName="state">
                        </div>
                        <div class="mt-2" *ngIf = "submitted && businesssInfoGet.state.errors">
                            <div class="error_message" *ngIf="businesssInfoGet.state.errors.required">State is required</div>
                            <div class="error_message" *ngIf="businesssInfoGet.state.errors.pattern">State shoud valid</div>
                        </div>
                    </div>

                    <div class="offset-md-1 col-md-5 col-12">
                        <div class="input">
                            <label class="label-test" for="phone">Business Phone Number</label>
                            <input minlength="8" maxlength="10" name="phone" type="text" id="phone" value="" formControlName="business_phone_no">
                        </div>

                        <div class="mt-2" *ngIf = "submitted && businesssInfoGet.business_phone_no.errors">
                            <div class="error_message" *ngIf="businesssInfoGet.business_phone_no.errors.required">Phone number is required</div>
                            <div class="error_message" *ngIf="businesssInfoGet.business_phone_no.errors.pattern || businesssInfoGet.business_phone_no.errors.minlength">Phone number shoud valid</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-11"><button id="business-info-change" class="btn-primary">NEXT <i class="fa-solid fa-arrow-right"></i></button></div>
                    
                </div>
            </form>
        </div>
        
    </div>
</div>
</div>


