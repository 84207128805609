import { Injectable, ɵConsole } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from '../..//environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '../_services/translate.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    isConnection: any;
    public currrentLang:string = "fr";

    constructor(private authenticationService: AuthenticationService,
                private snackBar: MatSnackBar,
                private translate: TranslateService) { }

                intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
                    console.log(request)
                    console.log(environment.server,"line 40")
                    if (environment.server == true) {
                        const currentUser = this.authenticationService.currentUserValue;
                        const currentLang = this.translate.currentLanguageValue;
                        if(currentLang){
                            this.currrentLang = currentLang.language;
                        }
                        if (currentUser && currentUser.token) {
                            console.log(currentUser.reftoken,"HAAAI")
                            if (request.body) {
                                request = request.clone({
                                    setHeaders: { 
                                        Authorization: `Bearer ${currentUser.token}`,
                                        language: this.currrentLang
                                    },
                                });
                            } 
                            else {
                                request = request.clone({
                                    setHeaders: { 
                                        Authorization: `Bearer ${currentUser.token}`,
                                        language: this.currrentLang
                                    }
                                });
                            }
                            return next.handle(request);
                        } else {
                            request = request.clone({
                                setHeaders: { 
                                    language: this.currrentLang
                                }
                            });
                            return next.handle(request);
                        }
                        
                    }   
                }   
}


