import { Component, OnInit } from '@angular/core';
import '../../_models/tags-input'; 
import { ApiService } from '../../_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ModalService } from 'src/app/_services/modal.service';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
declare var $:any;
declare const window: any;
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  teamsMembers = false;
  groupSection = false;
  userSection = true;
  showSideBar = false;
  private tagInput: any; 
  public rejectReasonForm:any;
  response:any;
  SignatureRequestData:any;
  SignatureRequestDataList:any;
  SignatureIncomingRequest:any;
  SignatureIncomingRequestList:any;
  rejectSignaturedetails:any;
  cancelSignatureRequestdetails:any;
  signature_id:any;
  containers :any[] // Example array, replace with your data
  showInnerDiv: boolean[];
  usersubmitted: any;
  rejectuuid: any;
  signature = this._sanitizer.bypassSecurityTrustResourceUrl("/assets/images/signature.pdf");
  button1Clicked: boolean = false;
  isSidebarOpen: boolean;

  constructor(private api: ApiService,
              private ngxUiLoaderService: NgxUiLoaderService,
              private modalService: ModalService,
              public snackBar : MatSnackBar,
              public formBuilder: FormBuilder,
              protected _sanitizer: DomSanitizer ) { }

  ngOnInit() {
    // this.getEmployees();
      this.tagInput = new TagsInput({
        selector: 'tag-input1',
        duplicate: false,
        max: 10,
        icon:"assets/images/blue_cross.png"
      });
      this.tagInput.addData(["Financial", "Employees", "Confidential"]);

      var input = document.querySelector("#phone");
      window.intlTelInput(input, {
      // show dial codes too
      separateDialCode: true,
      // If there are some countries you want to show on the top.
      // here we are promoting russia and singapore.
      // preferredCountries: ["ru", "sg"],
      //Default country
      initialCountry:"ga",
      });
      this.getSignatureRequestSendList();
      this.getSignatureIncomingRequest();
      this.rejectReasonForm = this.formBuilder.group({
        reason:['', [ Validators.required]]
      });
  
  }

  get f() {
    return this.rejectReasonForm.controls;
  }
  // getEmployees() {
  //   this.api.getCatdsDetails().subscribe(data=>{
  //     console.log(data);
      
  //       this.response = data.result5.files;
  //       this.containers = data.result5.files;
  //       console.log(this.containers,"ppppp");
  //       this.showInnerDiv = new Array(this.containers.length).fill(false);
  //   });
  // } 
  

  toggleInnerDiv(index: number) {
    for (let i = 0; i < this.showInnerDiv.length; i++) {
      if (i !== index) {
        this.showInnerDiv[i] = false;
      }
    }

    // Toggle the state of the clicked container
    this.showInnerDiv[index] = !this.showInnerDiv[index];
  }

  getInfo(key:any) {
    console.log(key)
    if(key == '1') {
      this.showSideBar = false;
    }
    else {
      this.showSideBar = true;
    }
    
  }

  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }

  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }


  showTeams() {
    if (this.teamsMembers == true) {
      this.teamsMembers = false;
    }else {
      this.teamsMembers = true;
    }
  }


  clickTab(key) {
    if(key == 'user'){
      this.userSection = true;
      this.groupSection = false;
    }else {
      this.userSection = false;
      this.groupSection = true;
    }
  }

  rejectModal(uuid) {
    this.rejectuuid = uuid;
    $('#staticBackdrop').modal('show');
  }
  getSignatureRequestSendList() {
    this.ngxUiLoaderService.start();
    this.api.getSignatureRequestSendList().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      console.log(data);
        this.SignatureRequestData = data;
        console.log(this.SignatureRequestData)
        if (this.SignatureRequestData.status == 200) {
          this.SignatureRequestDataList = this.SignatureRequestData.results;
          console.log(this.SignatureRequestDataList);       
        }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }
  getSignatureIncomingRequest() {
    this.ngxUiLoaderService.start();
    this.api.getSignatureIncomingRequest().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      console.log(data);
        this.SignatureIncomingRequest = data;
        console.log(this.SignatureIncomingRequest)
        if (this.SignatureIncomingRequest.status == 200) {
          this.SignatureIncomingRequestList = this.SignatureIncomingRequest.results;
          console.log(this.SignatureIncomingRequestList);       
        }
      }, error => {
        this.ngxUiLoaderService.stop();
      });
    }
  rejectRequest() {
    this.ngxUiLoaderService.start();
    let data = {
    signature_id: this.rejectuuid,
    reason:this.rejectReasonForm.value.reason
    }
    console.log(data);
    this.api.rejectSignature(data).subscribe(data=>{
    console.log(data);
    this.rejectSignaturedetails = data;
    if(this.rejectSignaturedetails.status == 200){
      data = null;
      $('#deleteModal4').modal('hide')
      this.closseModal();
      this.ngxUiLoaderService.stop();
      this.snackBar.open(this.rejectSignaturedetails.message, "Dismiss", {
        duration:3000,
      })
      this.getSignatureIncomingRequest();
      this.ngxUiLoaderService.stop();
    }else{
      this.ngxUiLoaderService.stop();
      this.snackBar.open(this.rejectSignaturedetails.message, "Dismiss", {
        duration:3000,
      })
    }
  }, error => {
  this.ngxUiLoaderService.stop();
    
  });
    
  }
  cancelSignatureRequest() { 
    this.ngxUiLoaderService.start();
    let data = {
    signature_id: this.signature_id
    }
    console.log(data);
    this.api.cancelSignatureRequest(data).subscribe(data=>{
    console.log(data);
    this.cancelSignatureRequestdetails = data;
    if(this.cancelSignatureRequestdetails.status == 200){
      console.log(this.cancelSignatureRequestdetails);
        data = null;
        $('#deleteModal').modal('hide')
        $(".modal-backdrop").remove();
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.cancelSignatureRequestdetails.message, "Dismiss", {
          duration:3000,
        })
        this.getSignatureRequestSendList();
        this.ngxUiLoaderService.stop();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.cancelSignatureRequestdetails.message, "Dismiss", {
          duration:3000,
        })
      }
     }, error => {
      this.ngxUiLoaderService.stop();
        
      });    
  }
  cancelSignaturedata(signature_id){
    this.signature_id = signature_id;
    $('#deleteModal').modal('show');
  }

  rejectRequestdata(){
    this.usersubmitted = true;
    if (this.rejectReasonForm.invalid) {
      return;
    }
    $('#staticBackdrop').modal('hide');
    setTimeout(() => {
      $('#deleteModal4').modal('show');
    }, 500)
  }
  closseModal(){
    this.rejectReasonForm.reset();
    this.usersubmitted = false;
  }
  addSignature(){
    this.button1Clicked = true;
    this.signature = this._sanitizer.bypassSecurityTrustResourceUrl("/assets/images/withsign.pdf");
  }

  addSignaturerequest() {
    this.signature = this._sanitizer.bypassSecurityTrustResourceUrl("/assets/images/signature.pdf");
    $('#addUser-signature').modal('show');
  }

  saveSignature() {
    $('#addUser-signature').modal('hide');
  }

  onSidebarOpened(isOpen: boolean) {
    this.isSidebarOpen = isOpen;
    console.log('ye kya huaaaa');
    
  }
}
