<app-sidebar></app-sidebar>
<app-header></app-header>
<main >
  <div class="container-custom">
    <div class="columns">
      <div class="main">

        <div class="heading_1  align-items-center justify-content-between" data-aos="fade-right" data-aos-delay="400"
          data-aos-duration="1500">


          <div class="title_container">
            <div class="parent-flex profile_head">
              
            <!-- <img class="title_img" src="assets/images/blue_bin.png" alt=""> -->
            <div class="title_lg ms-2">{{'Profile Details' | translate}}</div>
            <button (click)="toggleEditMode('data')" id="edit-profile-id" class="btn_primary bg_blue mt-2 mb-4" >{{ editMode ?  ('Cancel' | translate) : ('Edit Profile' | translate)}}</button>
            <!-- <div class="settingsBtn"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->            
            </div>
        </div>
        </div>
        
        <!-- GRID VIEW  -->
        <div class="profile_detail" id="profileDetails" [ngClass]="{ 'showData': profile == true}">
            <form action="" [formGroup]="profileForm" (ngSubmit)="onSaveChanges()">
            <div class="profile-img">
                <div class="position-relative" ><img src="{{prpicUrl}}" alt="" id="proPic" class="img_fluid" > 
                    <div for="uploadProfilepic" (change)="upload($event, 'file')">
                        <label  class="file-upload-card labeltxt" id="uploadProfilepic">
                            <input class="uploadOrdrop" id="file" type="file"   data-target="userPhoto" [disabled]="isInputDisabled"  aria-describedby="emailHelp">
                            <img *ngIf="isHiddenButtonVisible" class="up_ic img_fluid" src="assets/images/img_up.png" alt="" >
                            <!-- <a id="file" href="{{userPhoto}}" target="_blank" class="viewFile text-center"></a> -->
                            <!-- <div *ngIf="usersubmitted && b.file.errors" class="invalid-feedback text-center m-0">
                                <div *ngIf="g.file.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                                <div *ngIf="g.file.errors.required">{{'Upload Employee Photo' | translate}} {{'cannot be blank'|translate}}</div>
                            </div> -->
                        </label>
                    </div>
                </div>
                
                <div class="profileUserdetails">
                    <h4>{{username}}</h4>
                    <h5 class="mt-2">{{useremail}}</h5>
                </div>
            </div>

            <div class="profile-sec">
                <div class="heading_1 mt-5">
                    <h4>{{'Edit profile' | translate}}</h4>
                </div>
                <div class="row mt-4">
                    <div class="col-md-4">
                        <label for="exampleInputEmail1" >{{'First Name' | translate}}</label>
                        <input type="email" class="first_name" aria-describedby="emailHelp" placeholder="{{'First Name' | translate}}" [disabled]="isInputDisabled" maxlength="32"  formControlName="first_name">
                        <div *ngIf="usersubmitted && b.first_name.errors">
                            <div class="error_message text-danger" *ngIf="b.first_name.errors.required">{{'First Name' | translate}} {{'is required' | translate}}</div>
                            <div class="error_message text-danger" *ngIf="b.first_name.errors.pattern">{{'First Name' | translate}} {{'should valid' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="exampleInputEmail1">{{'Last Name' | translate}}</label>
                        <input type="email" class="last_name" aria-describedby="emailHelp" placeholder="{{'Last Name' | translate}}" [disabled]="isInputDisabled"  maxlength="32" formControlName="last_name">
                        <div *ngIf="usersubmitted && b.last_name.errors">
                            <div class="error_message text-danger" *ngIf="b.last_name.errors.required">{{'Last Name' | translate}} {{'is required' | translate}}</div>
                            <div class="error_message text-danger" *ngIf="b.last_name.errors.pattern">{{'Last Name' | translate}} {{'should valid' | translate}}</div>
                        </div>
                    </div>
                    
                </div>
                <div class="row mt-3">
                    <div class="col-md-4">
                        <label for="exampleInputEmail1">{{'Email Address' | translate}}</label>
                        <input type="email" class="" aria-describedby="emailHelp" placeholder="{{'Email Address' | translate}}" [disabled]="isInputDisabled"  readonly formControlName="email">
                        <div *ngIf="usersubmitted && b.email.errors">
                            <div class="error_message text-danger" *ngIf="b.email.errors.required">{{'Email Address' | translate}} {{'is required' | translate}}</div>
                            <div class="error_message text-danger" *ngIf="b.email.errors.pattern">{{'Email Address' | translate}} {{'should valid' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="exampleInputEmail1">{{'Gender' | translate}}</label>
                        <select class="form-select" aria-label="Default select example" formControlName="gender">
                            <option disabled selected value="">{{'Select Gender' | translate}}</option>
                            <option value="0">{{'Male' | translate}}</option>
                            <option value="1">{{'Female' | translate}}</option>
                            <option value="2">{{'Other' | translate}}</option>
                        </select>
                        <div *ngIf="usersubmitted && b.gender.errors">
                            <div class="error_message text-danger" *ngIf="b.gender.errors.required">{{'Gender' | translate}} {{'is required' | translate}}</div>
                            <div class="error_message text-danger" *ngIf="b.gender.errors.pattern">{{'Gender' | translate}} {{'should valid' | translate}}</div>
                        </div>
                    </div>
                    
                </div>
                <div class="row mt-3">

                    <div class="col-md-4">
                        <label for="">{{'Date of birth' | translate}}</label>
                        <div class="input-group mb-3" [owlDateTimeTrigger]="dt30">
                        <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input [owlDateTime]="dt30" class="form-control  controlForm"  formControlName="dateof_birth" [min]="min" readonly placeholder="{{'Select Date of Birth' | translate}}" [max]="maxDate">
                        </div>
                        <owl-date-time [pickerType]="'calendar'" #dt30></owl-date-time>
                        <!-- <label for="exampleInputEmail1">{{ 'Date of Birth' | translate }}</label>
                        <mat-form-field class="profile_detail input" appearance="none">
                            <input id="id-birth" matInput [matDatepicker]="dp1" formControlName="dateof_birth" readonly placeholder="{{'Select Date of Birth' | translate}}" (dateInput)="onDateInput()" [max]="maxDate" >
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker #dp1></mat-datepicker>
                        </mat-form-field> -->
                        <div *ngIf="usersubmitted && profileForm.get('dateof_birth').errors">
                            <div class="error_message text-danger" *ngIf="profileForm.get('dateof_birth').errors.required">{{ 'Date of Birth' | translate }} {{ 'is required' | translate }}</div>
                            <div class="error_message text-danger" *ngIf="profileForm.get('dateof_birth').errors.pattern">{{ 'Date of Birth' | translate }} {{ 'should be valid' | translate }}</div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="exampleInputEmail1">{{'Password' | translate}}</label>
                        <div class="position-relative">
                            <input type="password" class="" id="" aria-describedby="emailHelp" placeholder="{{'Password' | translate}}" [disabled]="isInputDisabled" readonly  value="*******">
                            <span  class="reset_btn" (click)="openresetPassword()">{{'Reset' | translate}}</span>
                        </div>
                        <!-- <div *ngIf="usersubmitted && profileForm.get('password').errors">
                            <div class="error_message text-danger" *ngIf="profileForm.get('password').errors.required">{{ 'Password' | translate }} {{ 'is required' | translate }}</div> -->
                            <!-- <div class="error_message text-danger" *ngIf="profileForm.get('password').errors.pattern">{{ 'Password' | translate }} {{ 'should be valid' | translate }}</div> -->
                        <!-- </div> -->
                            <!-- <div class="error_message text-danger" *ngIf="profileForm.get('password').errors.required">{{ 'Password' | translate }} {{ 'is required' | translate }}</div>
                            <div class="error_message text-danger" *ngIf="profileForm.get('password').errors.pattern">{{ 'Password' | translate }} {{ 'should be valid' | translate }}</div>
                            -->
                    </div>

                    
                </div>
                <div class="row mt-3">
                    <div class="col-md-4">
                        <label for="exampleInputEmail1">{{'Mobile Number' | translate}}</label>
                        <input type="email"  aria-describedby="emailHelp" placeholder="{{'Mobile Number' | translate}}" [attr.disabled]="isInputDisabled"  formControlName="mobile" maxlength="12" minlength="0" readonly>
                        <div *ngIf="usersubmitted && b.mobile.errors">
                            <div class="error_message text-danger" *ngIf="b.mobile.errors.required">{{'Mobile Number' | translate}} {{'is required' | translate}}</div>
                            <div class="error_message text-danger" *ngIf="b.mobile.errors.pattern">{{'Mobile Number' | translate}} {{'should valid' | translate}}</div>
                        </div>
                    </div>
                </div>
               
               

                <div class="row mt-4"  *ngIf="!submittedSuccessfully">
                    <div class="col-md-8">
                        <a ><button id="id-button" class="btn_primary bg_blue mt-2 mb-4" *ngIf="isHiddenButtonVisible"  type="submit">{{'Save Changes' | translate}}</button></a>
                    </div>

                </div>

                <div class="signature_details ms-2">
                   <div class="d-flex justify-content-space-between">
                        <div class="d-flex align-items-center">
                            <h1>{{'Signature Details' | translate}}</h1>
                            <img src="assets/images/warning.png" alt="no img" *ngIf="!signatureDrawImage1">
                        </div>
                        <div>
                            <button id="editSign" class="btn_primary bg_blue" type="button" *ngIf="signatureDrawImage1" (click)="editSignature()">{{ 'Edit Signature' | translate }}</button>
                        </div>
                   </div>
                </div>

                <div class="upload_signature mt-4" id="upload_sign_id">
                    <div class="upload_box">
                        <img [src]="signatureDrawImage1 ? signatureDrawImage1 : 'assets/images/unionplace.png'"alt="">
                        <div *ngIf="!signatureDrawImage1; else deletesSignature" (click)="getSign(1)">
                            <p class="mt-3">{{ 'Please Upload or Create your Signature' | translate }}</p>
                        </div>
                        <ng-template #deletesSignature>
                            <p class="upload_signature_delete text-danger" (click)="deleteModal()" >{{ 'Delete Signature' | translate }}</p>
                        </ng-template>                          
                    </div>
                </div>
            </div>
            </form>
        </div>
        <!-- password reset modal -->
        <div class="modal fade" id="passwordCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
              
                    <div class="modal_header">
                        <img class="logo mt-4" routerLink="/dashboard" src="assets/images/About-nav.png" alt="">
                        <button type="button" #closebutton class="btn-close" (click)="closseModal()" aria-label="Close"></button>
                      </div>
                      <div class="modal-body bodyModal">
                        <div id="folderName_And_Tagsection">
                        <form [formGroup]="resetPasswordForm">
                            <div class="modal-body bodyModal">
        
                                
                                <div class="input_style mt-4">
                                    
                                    <label for="exampleInputEmail1">{{'Old Password' | translate}}</label>
                                    <input class="input" type="text" aria-describedby="emailHelp" placeholder="{{'Old Password' | translate}}" formControlName="old_password" >
                                    
                                </div>
                                <div id="passwordRequired" *ngIf = "isSubmitted && resetPasswordValidate.old_password.errors">
                                    <div id="passwordRequired" class="error_message" *ngIf="resetPasswordValidate.old_password.errors.required">{{'Password is required' | translate}}</div>
                                </div>


                                <div class="input_style mt-3 passwordEyeIcon" id="new" data-aos="fade-left" data-aos-anchor="#parent" data-aos-delay="1000" data-aos-duration="1500">
                                    
                                    <label for="exampleInputEmail1">{{'New Password' | translate}}</label>
                                    <input type="{{ passwordFieldType }}" class="input" type="password" aria-describedby="emailHelp" placeholder="{{'New Password' | translate}}" maxlength="25" formControlName="password" >
                                    <span (click)="setShowPassword('a')" class="eye_Icon"><i class="{{ showPasswordIcon }} eye_Icon"></i></span>
                                    
                                </div>
                                <div id="passwordRequired1" *ngIf = "isSubmitted && resetPasswordValidate.password.errors">
                                    <div class="error_message" *ngIf="resetPasswordValidate.password.errors.required">{{'New Password is required' | translate}}</div>
                                    <div class="error_message" *ngIf="resetPasswordValidate.password.errors.pattern">{{'Password is not strong enough' | translate}}</div>
                                </div>

                                <div class="input_style passwordEyeIcon"  id="new" data-aos="fade-left" data-aos-anchor="#parent" data-aos-delay="1200" data-aos-duration="1500">
                                    
                                    <label for="exampleInputEmail1">{{'Confirm New Password' | translate}}</label>
                                    <input type="{{ passwordFieldType2 }}"  class="input" type="password" aria-describedby="emailHelp" placeholder="{{'Confirm New Password' | translate}}" formControlName="confirm_password" >
                                    <span (click)="setShowPassword('b')" class="eye_Icon"><i class="{{ showPasswordIcon2 }}"></i></span>
                                    
                                </div>
                                <div id="passwordRequired2"  *ngIf = "isSubmitted && resetPasswordValidate.confirm_password.errors">
                                    <div class="error_message" *ngIf="resetPasswordValidate.confirm_password.errors.required">{{'Confirm New Password is required' | translate}}</div>
                                    <div class="error_message" *ngIf="resetPasswordValidate.confirm_password.errors.ConfirmPassword">{{'Passwords do not match' | translate}}</div>
                                </div>
                            </div>
        
                            <div  id="passwordChangeId"><button class="btn_primary bg_blue" type="submit" (click)="onSubmit()">{{'Save Changes' | translate}}</button></div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- GRID VIEW CLOSE -->
        <!-- MODAL -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" id="custom-padding">
                    <div class="modal-header" id="modal_header_id">
                      <h1 class="modal-title fs-5 w-100 text-center" id="staticBackdropLabel">{{'Add Signature' | translate}}</h1>
                      <button type="button" id="button_close" class="btn-close me-5" (click)="closeModal()" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex align-items-center justify-content-evenly mb-4" id="modal-body-id">
                        <div class="catalogueFunction1 mb-4">
                            <div class="toggle_screen">
                                <div class="one tabOption" (click)="clickTab('user')">
                                    <h5 [ngClass]="{'color_blue': userSection == true}" id="chooses">{{'Draw' | translate}}</h5>
                                    <div [ngClass]="{'lineExtent': userSection == true, 'line': userSection == false}" id="chooses_line" ></div>
                                </div>
                                <div class="two tabOption" (click)="clickTab('group')">
                                    <h5 [ngClass]="{'color_blue': groupSection == true}" id="draws">{{'Choose' | translate}}</h5>
                                    <div [ngClass]="{'lineExtent': groupSection == true, 'line': groupSection == false}" id="draws_line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- file upload portion -->
                    <div *ngIf="groupSection" class="user-profile" id="tab-2">
                        <form  [formGroup]="UsersignatureForm" >
                        <div class="file-section">
                            <label class="label-img w-100" for="image">
                            <div class="upload_signature" id="modal-sign-id">
                                <div class="upload_box">
                                    <input type="file" name="image" id="image" style="display:none;" (change)="upload($event, 'image')"   data-target="signature" accept="image/png, image/jpeg"/>
                                    <img [src]="signatureDrawImage ? signatureDrawImage : 'assets/images/uploads.png'" alt="">
                                    <p class="mt-2">{{'Please Upload your Signature' | translate }}</p>
                                </div>  

                                <div *ngIf="signaturesubmitted  && z.user_photo.errors">
                                    <div class="error_message text-danger text-center" *ngIf="z.user_photo.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                                    <div class="error_message text-danger text-center" *ngIf="z.user_photo.errors.required">{{'Signature cannot be blank'|translate}}</div>
                                </div>

                            </div>
                                <div class="save">
                                <button type="button" class="btn mt-3" (click)="onSaveChanges(1)">{{'Save Changes' | translate}}</button>
                            </div></label>
                        </div>
                        </form>
                    </div>
                    <!-- draw signature portion -->
                    <div *ngIf="userSection" class="user-profile" id="tab-1">
                        <div class="file-section" >
                            <div class="upload_signature" id="signature_id">
                                <!-- <ng-signature-pad value="f.declarent_signature.value" doneButtonText="{{ 'Save' | translate }}" height="200px" width="700px" clearButtonText="{{ 'Reset' | translate }}" format="base64" (done)="saveDeclarentSignature($event)"></ng-signature-pad> -->
                                <div class="upload_box">
                                    <p>{{'Please Draw your Signature' | translate}}</p>
                                    <!-- <ng-signature-pad class="Signature-pad" doneButtonText="{{ 'Save' | translate }}" height="200" width="700" clearButtonText="{{ 'Reset' | translate }}" format="base64" (done)="saveDeclarentSignature($event)"></ng-signature-pad> -->
                                    <canvas class="signature-pad-canvas" #canvas width="900" height="400" style="touch-action: none;"></canvas>
                                    <div class="text-center d-flex justify-content-end">
                                        <button (click)="clear()" class="btn-info clearbtn">{{'Reset' | translate}}</button>
                                        <button (click)="save()" [disabled]="isCanvasBlank()" class="btn-success donebtn">{{'Save sign' | translate}}</button>
                                    </div>
                                </div>
                                <img  src="{{ f.signature.value }}" *ngIf="f.signature.value && imagenotshow" (error)="getImg()">
                            </div>
                            <div *ngIf = "signaturesubmitted && f.signature.errors" class="text-center">
                                <div class="error_message" *ngIf=" f.signature.errors.required">{{'Signature is required' | translate}}</div>
                            </div>
                            <div class="save">
                                <button type="button" id="save_button" class="btn mt-3" (click)="onSaveChanges(2)">{{'Save Changes' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   


      </div>

    </div>


  </div>
  <div  class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Delete' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to delete' | translate}}</div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id"  class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red" (click)="deleteSignature()">{{'Delete' | translate}}</button>
        </div>      
      </div>
    </div>
  </div>

</main>