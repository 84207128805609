import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/_services/api.services';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DatePipe } from '@angular/common';
import { ConfirmPasswordValidator2 } from 'src/app/_validators/confirm-password.validator';
declare var $ : any;
import SignaturePad from 'signature_pad';
import { ModalService } from 'src/app/_services/modal.service';
import { log } from 'console';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {
  state$: Observable<object>;
  groupSection = false;
  userSection = true;
  profile = true;
  template = false;
  usersubmitted = false;
  notification = false;
  security = false;
  templateEdit = false;
  btnDisabled = false;
  isSubmitted = false;
  isHiddenButtonVisible: boolean = false;
  isHiddenButtonDisabled: boolean = true;
  submittedSuccessfully: boolean = false;
  resetSuccessfully: boolean = false;
  passwordSubmitted: boolean;
  isInputDisabled = true;
  profileForm: FormGroup;
  resetPasswordForm : FormGroup;
  signatureForm : FormGroup;
  currentLang: any;
  responseupload: any;
  maxDate: Date;
  responseData: any;
  resetResponseData  :any;
  resetResponseShow :any ;
  username : string;
  usernameuuid:any;
  useremail: any;
  imageUrl: any;
  passwordFieldType2: string = "password";
  passwordFieldType: string = "password";
  showPasswordIcon = "fa-solid fa-eye-slash";
  showPasswordIcon2 = "fa-solid fa-eye-slash";
  showPassword = false;
  showPassword2 = false;
  prpicUrl: any;
  signatureDrawImage:any;
  propicFile: any;
  FileSignature:any;
  responseProfile: any;
  deleteSignaturedetails:any;
  editMode: boolean = false;
  @ViewChild('canvas', { static: false }) signaturePadElement;
  signaturePad: any;
  minDate: Date;
  signaturesubmitted = false;
  signatureDrawImage1: any;
  min = new Date(1924, 4, 10, 10, 50);
  imagenotshow = true;
  UsersignatureForm: FormGroup;
 

  constructor(public activatedRoute: ActivatedRoute,
              public router: Router,
              public formBuilder: FormBuilder,
              private api: ApiService,
              public snackBar : MatSnackBar,
              private ngxUiLoaderService: NgxUiLoaderService,
              private datePipe: DatePipe,
              private elementRef: ElementRef,
              private modalService: ModalService,
              private dateAdapter: DateAdapter<Date>){
                if(this.router.getCurrentNavigation().extras.state) {
                console.log("asdsadsadsa")
                setTimeout(()=> {
                  this.getSign(1);
                }, 500)
                }
              }

  ngOnInit() {
    // $('#staticBackdrop').modal('show');
    this.profileForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.pattern("^[a-zA-ZÀ-ÿ,\\s]+$")]],
      last_name: ['', [Validators.required, Validators.pattern("^[a-zA-ZÀ-ÿ,\\s]+$")]],
      email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
      gender: ['', Validators.required],
      dateof_birth: ['', Validators.required],
      // password: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      // file:['', Validators.required]
    });
    this.signatureForm = this.formBuilder.group({
      signature: ['', [Validators.required]]
    });

    // this.resetPasswordForm = this.formBuilder.group({
    //   old_password : ['', Validators.required],
    //   password : ['',[Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*?#&](?!.*\\s).{7,}")]],
    //   confirm_password : ['',Validators.required],
    // }, { validator: ConfirmPasswordValidator.MatchPassword });
    this.resetPasswordForm = this.formBuilder.group({
      old_password: ['', Validators.required],
      password: ['',[Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*?#&](?!.*\\s).{7,}")]],
      confirm_password: ['',Validators.required],
       }, { validator: ConfirmPasswordValidator2.MatchPassword2 
    });
    const today = new Date();
    // this.maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    console.log(today);
    this.maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate(), 23, 59, 59);
    console.log(this.maxDate);

    this.minDate =  new Date(today.getFullYear() - 150, today.getMonth(), today.getDate());
    this.dataFromLocalstorage();
    
    this.currentLang  = localStorage.getItem("language");
    console.log(this.currentLang);

    this.dateAdapter.setLocale(this.currentLang); 
    this.UsersignatureForm = this.formBuilder.group({
      user_photo: ['', [Validators.required]],
    })
  }
  get z() {
    return this.UsersignatureForm .controls;
  }

  onDateInput(): void {
    const dobControl = this.profileForm.get('dateof_birth');
    if (dobControl.value instanceof Date) {
        const formattedDate = this.datePipe.transform(dobControl.value, 'yyyy-MM-dd'); // Date format may depend on your backend requirements
        dobControl.setValue(formattedDate); // Use setValue to set the form control's value
    }
  }
  get b() {
    return this.profileForm.controls;
  }
  get f() {
    return this.signatureForm.controls;
  }

  openNav() {
    console.log('aaaa')
    $('#mySidenav').css("width", "305px");	  
  }

  closeNav() {
    $('#mySidenav').css("width", "0");
  }

  showSections(key:any) {
    switch(key) {
      case 1 : {
        this.profile = true;
        this.template = false;
        this.notification = false;
        this.security = false;
        this.templateEdit = false;
        this.closeNav();
        break;
      }
      case 2 : {
        this.template = true;
        this.profile = false;
        this.notification = false;
        this.security = false;
        this.templateEdit = false;
        this.closeNav();
        break;
      }
      case 3 : {
        this.notification = true;
        this.template = false;
        this.profile = false;
        this.security = false;
        this.templateEdit = false;
        this.closeNav();
        break;
      }
      case 4 : {
        this.security = true;
        this.notification = false;
        this.template = false;
        this.profile = false;
        this.templateEdit = false;
        this.closeNav();
        break;
      }

      case 5 : {
        this.templateEdit = true;
        this.security = false;
        this.notification = false;
        this.template = false;
        this.profile = false;
        this.closeNav();
        break;
      }
    }
  }
  clickTab(key) {
    if(key == 'user'){
      console.log("dfdsfdsfdsfdsfdsfdsfdsfdsfdsfdsfdsfdsfsfdsfdsfd")
      this.userSection = true;
      this.groupSection = false;
      this.getSign();
    }else {
      this.userSection = false;
      this.groupSection = true;
    }
  }

  upload(event: any, id): void {
    console.log("2250", event)
    console.log(id);
    
    console.log("2251", event.target.files[0])

    if (event.target.files.length > 0) {

      const file = event.target.files[0];
      console.log('uploaded image: ', file)
      const filename = file.name;
      console.log(filename);
      
      var ext = filename.split('.').pop();
      if (file.size > 5242880) {

        if (this.currentLang == 'fr') {
          alert('La taille du fichier doit être inférieure à 5MB')
        } else {
          alert('File Size should be less than 5MB')
        }
        return;
      }
      if (id = 'file') {
        console.log("340  file")
        if (ext != 'jpg' && ext != 'JPG' && ext != 'png' && ext != 'PNG' && ext != 'JPEG' && ext != 'jpeg') {
          if (this.currentLang == 'fr') {
            alert('Type de fichier non autorisé. Les types de fichier autorisés sont  .jpg .png .jpeg ')
          } else {
            alert('File type not allowed. Allowed file types are .jpg .png .jpeg ')
          }
          return;
        }
      } else {
        if (ext != 'jpg' && ext != 'JPG' && ext != 'png' && ext != 'PNG' && ext != 'JPEG' && ext != 'jpeg' ) {
          if (this.currentLang == 'fr') {
            alert('Type de fichier non autorisé. Les types de fichier autorisés sont  .jpg .png .jpeg ')
          } else {
            alert('File type not allowed. Allowed file types are .jpg .png .jpeg')
          }
          return;
        }
      }

      this.ngxUiLoaderService.start();
      const target = event.target.dataset.target;
      console.log(target,'9999999999999999')
      const formData = new FormData();
      formData.append('file', file);
      console.log(target);
      
      formData.append('target', target);
      console.log(formData);
      
      console.log("file is :", file)
      console.log("target is :", target)
      // console.log(formData,'77777777777');
      
      
      this.api.upload(formData).pipe(first())
        .subscribe(data => {
          console.log(data,'0000000000000000000000');
          
            this.ngxUiLoaderService.stop();
            this.responseupload = data;
            console.log(this.responseupload,'12112');
            
            if (this.responseupload.status == 200) {
              this.imageUrl = this.responseupload.results.imageUrl
              this.propicFile=  this.responseupload.results.file
              if(target=='signature'){
                this.signatureDrawImage = this.imageUrl
                this.FileSignature = this.propicFile
                console.log(this.signatureDrawImage);
                this.imagenotshow = true;
                this.signatureForm.get('signature').setValue(this.propicFile)
                this.UsersignatureForm.get('user_photo').setValue(this.propicFile)
                
              } else{
                this.prpicUrl = this.imageUrl
              }

              console.log(this.imageUrl);
             
            }else{
              this.snackBar.open(this.responseupload.message, "Dismiss", {
                duration:3000,
              })
            }

          },
          error => {

          });



    }
  }

  dataFromLocalstorage(){
   let data = localStorage.getItem("currentUserDetails");
    let userDataObject = JSON.parse(data);
    let firstName = userDataObject.user_details.first_name;
    this.username = userDataObject.user_details.first_name + ' ' + userDataObject.user_details.last_name;
    this.useremail = userDataObject.user_details.email;
    this.prpicUrl = userDataObject.user_details.profile_pic;
    this.signatureDrawImage1 = userDataObject.user_details.signature_url;
    this.FileSignature = userDataObject.user_details.signature;
    this.usernameuuid = userDataObject.user_details.uuid;
    console.log(userDataObject.user_details.gender);
    
    
    console.log(firstName);
    console.log(userDataObject);
    
    this.profileForm.disable()
    this.profileForm.get('first_name').setValue(userDataObject.user_details.first_name)
    this.profileForm.get('last_name').setValue(userDataObject.user_details.last_name)
    this.profileForm.get('email').setValue(userDataObject.user_details.email)
    this.profileForm.get('gender').setValue(userDataObject.user_details.gender)
    this.profileForm.get('mobile').setValue(userDataObject.user_details.mobile)
    this.profileForm.get('dateof_birth').setValue(userDataObject.user_details.dob)
    // console.log(userDataObject.user_details.gender);
  }
  closeModal(){
    $('#staticBackdrop').modal('hide');
    this.signatureForm.reset();
    this.signaturesubmitted = false;
    this.signatureDrawImage = null;
  }
  closseModal(){
    $('#passwordCreateModal').modal('hide')
    this.passwordSubmitted = false;
    this.resetFormAndModal();
  }
  // toggleEditMode1() {
  //   this.editMode = !this.editMode;
  // }

  onSaveChanges(val=null) {
    var data;
    if (!val) {
      this.usersubmitted = true;
      console.log(this.profileForm, 'ssdadsd');
      if (this.profileForm.invalid) {
        return;
      }
      data = {
        profile_pic: this.propicFile,
        ... this.profileForm.value,
        entity_type: 1,
      }
      data['dateof_birth'] = this.datePipe.transform(this.b.dateof_birth.value, 'dd-MM-yyyy');
    } else {
      if(val == '2'){
        console.log(val);
        
        this.signaturesubmitted = true;
      console.log(this.signatureForm);
      
      if (this.signatureForm.invalid) {

        return;
      }
      console.log(this.f.signature.value)
      data = {
        signature: this.f.signature.value,
        entity_type: 2,
      }
      }else{
        console.log(val);
        this.signaturesubmitted = true;
        console.log(this.UsersignatureForm);
        
        if (this.UsersignatureForm.invalid) {
  
          return;
        }
        console.log(this.f.signature.value)
        data = {
          signature: this.f.signature.value,
          entity_type: 2,
        }
      }
      
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.updateProfile(data).subscribe(data => {
      this.responseData = data;
      if(this.responseData.status == 200){
        console.log(this.responseData,'77777777');
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseData.message, "Dismiss", {
          duration:3000,
        })
        
        this.submittedSuccessfully = false;
        this.signaturesubmitted = false;
        this.editMode = false;
        this.usersubmitted = false;
        console.log('4');
        
        $('#staticBackdrop').modal('hide');
        this.toggleEditMode();
        this.getAccounts();
        
        
      } else {
        console.log(this.responseData,'7777778888');
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseData.message, "Dismiss", {
          duration:3000,
        })
      }
    },
    error => {
      this.ngxUiLoaderService.stop();
    });
  }
  
setShowPassword(id){
  if(id == "a") {
    this.showPassword = (this.showPassword)?false:true;
    if(this.showPassword){
      this.passwordFieldType= "text";
      this.showPasswordIcon = "fa-solid fa-eye";
    }else{
      this.passwordFieldType = "password";
      this.showPasswordIcon = "fa-solid fa-eye-slash";
    }
  } else {
    this.showPassword2 = (this.showPassword2)?false:true;
    if(this.showPassword2){
      this.passwordFieldType2= "text";
      this.showPasswordIcon2 = "fa-solid fa-eye";
    } else { 
      this.passwordFieldType2 = "password";
      this.showPasswordIcon2 = "fa-solid fa-eye-slash";
    }
  }
  
}
get resetPasswordValidate() {
  return this.resetPasswordForm.controls;
}

resetFormAndModal() {
  this.resetPasswordForm.reset();
  this.isSubmitted = false;
}


onSubmit() {
  this.isSubmitted = true;
  console.log(this.resetPasswordForm);
  if(this.resetPasswordForm.invalid){
    return;
  }else {
    let data = this.resetPasswordForm.value;
    this.ngxUiLoaderService.start();
    console.log(data); 
    this.api.resetChangePassword(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.resetResponseData = data;
      console.log(this.resetResponseData);
      if(this.resetResponseData.status == 200){
        this.resetResponseShow = this.resetResponseData.results
        console.log(this.resetResponseShow);
          $('#passwordCreateModal').modal('hide')
          $(".modal-backdrop").remove();
        this.resetFormAndModal();
        this.passwordSubmitted = false
        this.snackBar.open(this.resetResponseData.message, "Dismiss", {
          duration:3000,
        })
      }else{
        this.ngxUiLoaderService.stop();
       this.snackBar.open(this.resetResponseData.message, "Dismiss", {
         duration:3000,
       })
       
      }
   },
   error => {
    this.ngxUiLoaderService.stop();
   });
}
}

getAccounts() {
  this.ngxUiLoaderService.start();
  this.api.getAccounts().subscribe(data => {
    this.ngxUiLoaderService.stop();
    this.responseProfile = data;
    console.log(this.responseProfile)
    if(this.responseProfile.status == "200") {
      this.ngxUiLoaderService.stop();
      localStorage.setItem('currentUserDetails', JSON.stringify(this.responseProfile.results))
      let data = localStorage.getItem("currentUserDetails");
      console.log(data);
      let userDataObject = JSON.parse(data);
      this.username = userDataObject.user_details.first_name + ' ' + userDataObject.user_details.last_name;
      // this.router.navigate(['/dashboard']);
      this.dataFromLocalstorage();
    }else {
      this.ngxUiLoaderService.stop();
      this.snackBar.open(this.responseProfile.message, "Alerte", {
        duration: 2000,
      });
    }

  },error => {
    this.ngxUiLoaderService.stop();
  });
}
toggleEditMode(vall = null) {
  console.log(vall);
  
  if (vall == 'data') {
    this.editMode = !this.editMode;
    console.log("SAdsadas")
    this.isInputDisabled = false;
    this.profileForm.enable()
    this.isHiddenButtonVisible = true;
  } else {
    this.isInputDisabled = true;
    this.profileForm.disable()
    this.isHiddenButtonVisible = false;
  }
}

isCanvasBlank(): boolean {
  if (this.signaturePad) {
    return this.signaturePad.isEmpty() ? true : false;
  }
}

getSign(val=null) {
  if (val) {
    console.log(val);
    
    $('#staticBackdrop').modal('show');
  }
  setTimeout(() => {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    console.log(window.innerWidth)
    console.log(window.innerHeight)

    canvas.width = window.innerWidth - 1400;
    if (window.innerHeight > 800) {
      canvas.height = window.innerHeight - 600;
    }
    console.log(this.signaturePadElement)
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signaturePad.clear();
    this.signaturePad.penColor = 'rgb(0,0,0)';
    this.signatureForm.get('signature').setValue(null);
  }, 10);
}

save() {
  this.imagenotshow = true;
  const img = this.signaturePad.toDataURL();
  this.signatureForm.get('signature').setValue(img);
}

clear() {
  this.signaturePad.clear();
  this.signatureForm.get('signature').setValue(null);
}
deleteModal(){
  $('#deleteModal3').modal('show');
}
deleteSignature() {
  var data =  {
    user_id:this.usernameuuid 
    }
   console.log(data);
   this.ngxUiLoaderService.start();
   this.api.deleteSignature(data).subscribe(data=>{
    console.log(data);
    this.deleteSignaturedetails = data;
    if(this.deleteSignaturedetails.status == 200){

      console.log('1');
      
      this.ngxUiLoaderService.stop();
      console.log(this.deleteSignaturedetails);
      this.snackBar.open(this.deleteSignaturedetails.message, "Dismiss", {
        duration:3000,
      })
      
      this.userSection = true;
      this.groupSection = false;
      console.log('2');
      
      this.getAccounts();
      console.log('3');
      $('#deleteModal3').modal('hide')
      $(".modal-backdrop").remove();
      this.modalService.emit();
    }else{
      this.ngxUiLoaderService.stop();
      this.snackBar.open(this.deleteSignaturedetails.message, "Dismiss", {
        duration:3000,
      })
    }
   }, error => {
    this.ngxUiLoaderService.stop();
      
    });
  }

  editSignature() {
    this.getSign(1);
    setTimeout(() => {
      this.imagenotshow = true;
      this.signatureForm.get('signature').setValue(this.FileSignature);
      this.signatureDrawImage = this.signatureDrawImage1;
    }, 100)
  }

  getImg() {
    this.signatureForm.get('signature').setValue('')
    this.signatureDrawImage = null;
    this.imagenotshow = false;
    console.log("dsfdsfdsfds",this.signatureForm);
    console.log(this.signatureDrawImage);
    
  }

  openresetPassword() {
    $('#passwordCreateModal').modal('show');
  }

}
