import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute,Router,Params } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { Unauthorised } from '../../../_helpers/unauthorised';

@Component({
  selector: 'app-plan-summary',
  templateUrl: './plan-summary.component.html',
  styleUrls: ['./plan-summary.component.scss']
})
export class PlanSummaryComponent implements OnInit {
  submitted = false;
  response:any;
  planSelectForm : FormGroup;
  businesssData:any;
  constructor(private api : ApiService,
              private snackBar: MatSnackBar,
              private router: Router,
              private unauthorised: Unauthorised,
              public formBuilder: FormBuilder,
              private ngxUiLoaderService: NgxUiLoaderService) { }



  ngOnInit() {
    this.unauthorised.businessComplete();
    this.planSelectForm = this.formBuilder.group({
      annual_plan : ['', Validators.required],
    });
    this.planSelectForm.controls.annual_plan.setValue("1")
  }
  
  get planSummaryControl() {
    return this.planSelectForm.controls;
  }

  
  showBusinessDashboard() {
    this.submitted = true;
    if (this.planSelectForm.invalid) {
      return;
    }else {

      let data = this.planSelectForm.value;
      data['stage_key'] = 400;

      this.ngxUiLoaderService.start();
      this.api.employeeCountForBusiness(data).subscribe(data => {

        
        this.response = data;

        if(this.response.status == "200") {
          this.getAccounts();
          this.router.navigate(['/dashboard']);
        }else {
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.response.message, "Alerte", {
            duration: 2000,
          });
        }

      },error => {
        this.ngxUiLoaderService.stop();
      });
    }
  }

  getAccounts() {
    this.api.getAccounts().subscribe(data => {
      this.response = data;
      console.log(this.response)
      if(this.response.status == "200") {
        this.ngxUiLoaderService.stop();
        this.router.navigate(['/dashboard']);
      }else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.response.message, "Alerte", {
          duration: 2000,
        });
      }

    },error => {
      this.ngxUiLoaderService.stop();
    });
  }
}
