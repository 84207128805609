<!-- <app-move-modal></app-move-modal> -->
<div id="mySidenav" class="sidenav">
    <i (click)="closeNav()" class="fa-solid fa-xmark closeNav"></i>
    <img class="logo" src="assets/images/About-nav.png" alt="" routerLink="/">

    <button *ngIf="url == 'folders'" class="upload_btn mt-2" data-bs-toggle="modal" data-bs-target="#documentModal"><img src="assets/images/office.png" alt="">{{'Creatre Doc' | translate}}</button>
    <div class="upload_main mt-3 mb-5" [hidden]="currentValue && (!currentValue.permissions.createFolder && !currentValue.permissions.createFile)">
        <button class="upload_btn" *ngIf="url == 'folders'"  (click)="showUpload($event)"><img src="assets/images/add.png" alt="">{{'New' | translate}}</button>
        <ul class="uploadOptionsDrop" [ngClass]="{'drop': showOptions == true}" >
            <li *ngIf="(currentValue && currentValue.permissions.createFolder)"><a data-toggle="modal" (click)="createFolder('asd')"><img src="assets/images/new_folder.png" alt="">{{'New folder' | translate}}</a></li>
            <li [hidden]="!(currentValue && currentValue.permissions.createFile)">
                <input type="file" name="fileupload" multiple="multiple" id="fileupload" class="hide" />
                <label for="fileupload" class="fileupload"><img src="assets/images/upload_grey_icon.png" alt="">{{'File upload' | translate}}</label>
            </li>
            <li [hidden]="!(currentValue && currentValue.permissions.createFolder)">
                <input type="file" name="folderUpload" multiple="multiple" id="folderUpload" webkitdirectory class="hide" />
                <label for="folderUpload" class="folderUpload"><img src="assets/images/upload_folder.png" alt="">{{'Folder upload' | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="options">
        <ul>
            <li routerLink="/dashboard" [ngClass]="{ 'active': url == 'dashboard'}">
                    <div class="align-items-center d-flex">
                        <div class="image_containor" *ngIf="url == 'dashboard'"><img src="assets/images/dashboardActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'dashboard'"><img src="assets/images/dashboard.png" alt=""></div>
                       {{'Dashboard' | translate}}
                    </div>
                    <img *ngIf="url == 'dashboard'" src="assets/images/arrow.png" alt="">
                
            </li>


            <li routerLink="/projects" [ngClass]="{ 'active': url == 'projects'}">
              
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'projects'"><img src="assets/images/projectActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'projects'"><img src="assets/images/project.png" alt=""></div>
                        {{'Projects' | translate}}
                    </div>
                    <img *ngIf="url == 'projects'" src="assets/images/arrow.png" alt="">
            </li>
            <li routerLink="/signature" [ngClass]="{ 'active': url == 'signature'}" >
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'signature'"><img src="assets/images/sign-blue.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'signature'"><img src="assets/images/signature.png" alt=""></div>
                    {{'Signature' | translate}}
                </div>
                <img *ngIf="url == 'signature'" src="assets/images/arrow.png" alt="">
            </li>

            <li routerLink="/catalogue" [ngClass]="{ 'active': url == 'catalogue'}"  *ngIf="(currentValue && currentValue.permissions.manageTeam)">
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'catalogue'"><img src="assets/images/teamActive.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'catalogue'"><img src="assets/images/team.png" alt=""></div>
                    {{'Teams' | translate}}
                </div>
                <img *ngIf="url == 'catalogue'" src="assets/images/arrow.png" alt="">
            </li>


            <li  routerLink="/permissions" [ngClass]="{ 'active': url == 'permissions'}" *ngIf="(currentValue && currentValue.permissions.managePermissions)">
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'permissions'"><img src="assets/images/permissionActive.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'permissions'"><img src="assets/images/permission.png" alt=""></div>
                        {{'Permissions' | translate}}
                    </div>
                    <img *ngIf="url == 'permissions'" src="assets/images/arrow.png" alt="">
            </li>
            <li routerLink="/metadata" [ngClass]="{ 'active': url == 'metadata-form' || url == 'metadata'}" *ngIf="(currentValue && currentValue.permissions.manageTemplates)">
                <div class="d-flex align-items-center">
                    <div class="image_containor" *ngIf="url == 'metadata-form' || url == 'metadata'"><img src="assets/images/card_blue.png" alt=""></div>
                    <div class="image_containor" *ngIf="url != 'metadata-form' && url != 'metadata'"><img src="assets/images/metadata.png" alt=""></div>
                    {{'Metadata' | translate}}
                </div>
                <img *ngIf="url == 'metadata-form' || url == 'metadata'" src="assets/images/arrow.png" alt="">
            </li>
            <li routerLink="/reports" [ngClass]="{ 'active': url == 'reports'}" *ngIf="(currentValue && currentValue.permissions.viewStatistics)">
                    <div class="d-flex align-items-center">
                        <div class="image_containor" *ngIf="url == 'reports'"><img src="assets/images/report_blue.png" alt=""></div>
                        <div class="image_containor" *ngIf="url != 'reports'"><img src="assets/images/report.svg" alt=""></div>
                        {{'Reports' | translate}}
                    </div>
                    <img *ngIf="url == 'reports'" src="assets/images/arrow.png" alt="">
            </li>



            <li routerLink="/recyclebin" [ngClass]="{ 'active': url == 'recyclebin'}" *ngIf="(currentValue && currentValue.permissions.ViewRecycleBin)">
                <div class="d-flex align-items-center">
                    <div *ngIf="url != 'recyclebin'" class="image_containor"><img src="assets/images/recycle.png" alt=""></div>
                    <div *ngIf="url == 'recyclebin'" class="image_containor"><img src="assets/images/recycle_blue.png" alt=""></div>
                    {{'Recycle Bin' | translate}}
                </div>
                <img *ngIf="url == 'recyclebin'" src="assets/images/arrow.png" alt="">
            </li>

            <!-- <li routerLink="/restorefiles/restoredeletedfiles" [ngClass]="{ 'active':url == 'restorefiles'}" *ngIf="currentValue && (currentValue.permissions.Archive)">
                <div class="d-flex align-items-center">
                    <div *ngIf="url != 'restorefiles' " class="image_containor"><img src="assets/images/archive.png" alt=""></div>
                    <div *ngIf="url == 'restorefiles'" class="image_containor"><img src="assets/images/archiveblue.png" alt=""></div>
                    {{'Archive' | translate}}
                </div>
                <img *ngIf="url == 'restorefiles'" src="assets/images/arrow.png" alt="">
            </li> -->
            <li routerLink="/restorefiles/restoredeletedfiles" [ngClass]="{'active':url == 'restorefiles' || url == 'archieved-files'}" *ngIf="currentValue && (currentValue.permissions.Archive)">
                <div class="d-flex align-items-center">
                    <div *ngIf="url != 'restorefiles' || url == 'archieved-files' " class="image_containor"><img src="assets/images/archive.png" alt=""></div>
                    <div *ngIf="url == 'restorefiles' && url != 'archieved-files' " class="image_containor"><img src="assets/images/archiveblue.png" alt=""></div>
                    {{'Archive' | translate}}
                </div>
                <img *ngIf="url == 'restorefiles' || url == 'archieved-files'" src="assets/images/arrow.png" alt="">
            </li>

            <li *ngIf="url == 'searched-files'"  routerLink="/notifications" >
                <div class="d-flex align-items-center">
                    <div class="image_containor notification_icons"><img src="assets/images/Notification.png" alt=""></div>
                    {{'Notification' | translate}}
                </div>
            </li>
        </ul>
    </div>
    <div>
        <div class="line mt-3"></div>
            <div class="logOutOption mt-4" (click)="logoutModal()">
                
                <div class="image">
                    <img src="assets/images/logout.png" alt="">
                </div>
                <div class="test">{{'Logout' | translate}}</div>
            </div>
    </div>
 
    
</div>
<header>
    <div class="header-container border_btm position-relative">
        <div class="row hideSearchOpen">
            <div class="col-md-11 col-11 col-sm-11">
                <div class="position-relative h-100" >
                <div class="mobile_nav_icon" *ngIf="isSectionVisible()">
                    <i (click)="openNav()" class="fa-solid fa-bars"></i>
                    <div class="position-relative search">
                        <div class="mobileHeaderMenu" >
                            <div class="mobile_search" (click)="showSearch()" id="mobileSearchIcon">
                                <div class="search_icon" (click)="navigateSerchedfile('search')"><i class="fa-solid fa-magnifying-glass" ></i></div>
                            </div>
                            <div class="toggle-button-cover toggle_btn">
                                <div class="button-cover">
                                <div class="button r" id="button-6">
                                    <input type="checkbox" class="checkbox" (change)="getChangelang($event)"  />
                                    <div class="knobs"></div>
                                    <div class="layer"></div>
                                </div>
                                </div>
                            </div>
                            <div  class="filter_mobile" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="navigateSerchedfile('filter')">
                                <i class="fa-solid fa-sliders"></i>
                            </div>
                            <div routerLink="/notifications">
                                <img route src="assets/images/Notification.png" alt="" class="notification">
                            </div>
                        </div>
                            <div class="form-inline" *ngIf="isSectionVisible()">
                                <form action="" id="new_group" (ngSubmit)="submit_handler('inputSearch')">
                                    <i class="fa-solid fa-magnifying-glass search_icon"></i>
                                    <input id="inputSearch" class="" type="input" placeholder="{{'Search for files' | translate}}" aria-label="Search"  (click)="navigateSerchedfile('search')">
                                </form>
                                <img route src="assets/images/cross.png" alt="" class="" id="close-search">
                                <button  class="filterButton" id="filter_Button" #qryButton3 (click)="navigateSerchedfile('filter')">{{'Filter' | translate}}</button>
                                <button class="btn btn-search my-2 my-sm-0" id="search_button" (click)="submit_handler('inputSearch')" >{{'Search' | translate}}</button>
                                <!-- <button  class="filterButton" id="filter_Button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="toggle(trigger)">Filter</button> -->
                                <!-- <button class="btn btn-primary" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="toggle(trigger)">Open overlay</button> -->

                            </div>

                            <!-- <button #qryButton3 (click)="displayMenu3(qryButton3)">
                                Template
                              </button> -->
                          
                  
                    </div>
                    <!-- <div class="   ">
                        
                        <div class="mobile_search" (click)="showSearch()" id="mobileSearchIcon">
                            <div class="search_icon"><i class="fa-solid fa-magnifying-glass"></i></div>
                        </div>
                        <div class="filter_mobile" (click)="filter('1')">
                            <i class="fa-solid fa-sliders"></i>
                        </div>
                        <div>
                            <img route src="assets/images/Notification.png" alt="" class="notification">
                        </div>
                    </div> -->
    
                </div>
                
               
                </div>
            </div>
            <!-- <div class="col-md-2">
                <div class="search font-weight-normal">All Files<i class="fa-solid fa-angle-down ml-5"></i></div>
            </div> -->
            <div class="col-md-1 col-1 col-sm-1 dv_3">
                <div class="position-relative">
                    <ul class="flex_custom m-0" >
                        <li>
                            <form [formGroup]="languageForm">
                                <!-- <div class="toggle_btn toggle_btn_md">
                                    <input type="checkbox" id="toggle-button-checkbox"
                                    (change)="changed($event.target.checked)">
                                <label class="toggle-button-switch"  
                                    for="toggle-button-checkbox"></label>
                                <div class="toggle-button-text">
                                    <div class="toggle-button-text-on">En</div>
                                    <div class="toggle-button-text-off">Fr</div>
                                </div>
                                </div> -->
                                <div class="toggle-button-cover toggle_btn toggle_btn_md">
                                    <div class="button-cover">
                                    <div class="button r" id="button-6">
                                        <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                                        <div class="knobs"></div>
                                        <div class="layer"></div>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </li>
                        <li class="notification_icon cursor_pointer" routerLink="/notifications">
                            <div class="mark"></div>
                            <img src="assets/images/Notification.png" alt="">
                        </li>
                        <li class="ml-4">
                                <div (click)="showProfile('1')"><img src="{{prpicUrl}}" alt="" role='button' class="proPic"></div>
                        </li>
                    </ul>
                </div>
            </div>
             
        </div>
        <div class="mobile_search_field">
            <form class="" (ngSubmit)="submit_handler('inputSearch2')">
                <div class="form-inline" (click)="navigateSerchedfile('s')">
                    <i class="fa-solid fa-arrow-left search_icon"  ></i>
                    <input class="" id="inputSearch2"   type="input" placeholder="{{'Search for files' | translate}}" aria-label="Search">
                    <img route src="assets/images/cross.png" alt="" class="" id="close-search2">
                    <button class="btn btn-search my-2 my-sm-0" id="search_button2" type="submit">{{'Search' | translate}}</button>
                </div>
                <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> -->
            </form>
        </div>
        <!-- <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerOrigin" [cdkConnectedOverlayHasBackdrop]="true"
             (backdropClick)="isOpen=false" [cdkConnectedOverlayOpen]="isOpen">
             <div class="filter">
                <h5>Filter by types</h5>
                <div class="selectGrp mt-4">
                    <div class="options">
                        <label for="">Project</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Employee Offer Letter</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div class="options">
                        <label for="">Templates</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Offer Letter</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div class="options">
                        <label for="">Tags</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Confidential</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>
                <div class="mt-3 options" >
                    <label for="">Creation date</label>
                    <input type="date" class="form-control widthCustom">
                </div>

                <div class="mt-5">
                    <h5>Select by Timeline</h5>

                    <div class="slider mt-2">
                        <span class="slider-label">1</span>
                        <input type="range" class="range" min="0" max="10">
                        <output class="bubble"></output>
                        <span id="slideroutput" class="slider-value">10</span>
                    </div>

                </div>

                <div class="btnGrp">
                    <button  class="btn1">Reset all filters</button>
                    <div class="one">
                        <div (click)="isOpen=false"  class="btnCancel">Cancel</div>
                        <button (click)="isOpen=false"  class="btn-primary">Apply Filters</button>
                    </div>
                </div>
         
            </div>
        </ng-template> -->
        <!-- <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerOrigin2" [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="isOpen2=false" [cdkConnectedOverlayOpen]="isOpen2">
        <div class="filter">
           <h5>Filter by types</h5>
           <div class="selectGrp mt-4">
               <div class="options">
                   <label for="">Project</label>
                   <select class="form-select" aria-label="Default select example">
                       <option selected>Employee Offer Letter</option>
                       <option value="1">One</option>
                       <option value="2">Two</option>
                       <option value="3">Three</option>
                   </select>
               </div>
               <div class="options">
                   <label for="">Metadata</label>
                   <select class="form-select" aria-label="Default select example">
                       <option selected>Offer Letter</option>
                       <option value="1">One</option>
                       <option value="2">Two</option>
                       <option value="3">Three</option>
                   </select>
               </div>
               <div class="options">
                   <label for="">Tags</label>
                   <select class="form-select" aria-label="Default select example">
                       <option selected>Confidential</option>
                       <option value="1">One</option>
                       <option value="2">Two</option>
                       <option value="3">Three</option>
                   </select>
               </div>
           </div>
           <div class="mt-3 options" >
               <label for="">Joining Date</label>
               <input type="date" class="form-control widthCustom">
           </div>

           <div class="mt-5">
               <h5>Select by Timeline</h5>

               <div class="slider mt-2">
                   <span class="slider-label">1</span>
                   <input type="range" class="range" min="0" max="10">
                   <output class="bubble"></output>
                   <span id="slideroutput" class="slider-value">10</span>
               </div>

           </div>

           <div class="btnGrp">
               <button  class="btn1">Reset all filters</button>
               <div class="one">
                   <div (click)="isOpen=false"  class="btnCancel">Cancel</div>
                   <button (click)="isOpen=false"  class="btn-primary">Apply Filters</button>
               </div>
           </div>
    
       </div>
   </ng-template> -->
    
        <div class="profile_settings">
            <div class="profile">
                <img src="assets/images/dpPic.png" alt="">
                <div class="name_section">
                    <h4>Jesse Walter</h4>
                    <h5>jessewalter00@gmail.com</h5>
                </div>
                <div class="line"></div>
            </div>
            <div class="line"></div>
            <div class="options">
                <ul>
                    <li>
                        <img src="assets/images/userPlus.png" alt="">
                        {{'Add account' | translate}}
                    </li>
                    <li routerLink="/profile-settings">
                        <img src="assets/images/wheel.png  " alt="">
                        {{'Settings' | translate}}
                    </li>
                    <li>
                        <img src="assets/images/info.png" alt="">
                        {{'Help Center' | translate}}
                    </li>
                </ul>
            </div>
        </div>
    
    
    
    </div>
    </header>

    <div class="modal fade" id="searchNullModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
              <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

              <div class="modal-body">
                <div  id="fileFound">
                <i class="fa-solid fa-circle-exclamation"></i>
                <div class="data">{{'No File Found' | translate}}</div>
                </div>
              </div>
          
          </div>
        </div>
    </div>


      <!-- <ng-template  #overlayMenuList >
             <div class="filter">
                <h5>{{'Filter by types' | translate}}</h5>
                <div class="selectGrp mt-4">
                    <div class="options">
                        <label for="">{{'Project' | translate}}</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedProject">
                            <option disabled hidden selected  value="null">{{'Employee Offer Letter' | translate}}</option>
                            <option  *ngFor="let project of projectList" [value]="project.id">{{project.name | translate}}</option>
                        </select>
                    </div>
                    <div class="options">
                        <label for="">{{'Templates' | translate}}</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedTemplate">
                            <option disabled hidden selected  value="null">{{'Select Template' | translate}}</option>
                            <option *ngFor="let template of templateList" [value]="template.id">{{template.template_name | translate}}</option>
  
                        </select>
                    </div>
                    <div class="options">
                        <label for="">{{'Tags' | translate}}</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedTag">
                            <option disabled hidden selected  value="null">{{'Confidential' | translate}}</option>
                            <option *ngFor="let tag of tagList" [value]="tag.id">{{tag.tag | translate}}</option>
 
                        </select>
                    </div>
                </div>
                <div class="mt-3 options" >
                    <mat-form-field class="example-date-select" appearance="none">
                        <input matInput [matDatepicker]="dp1" [matDatepicker]="picker" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="mt-5">
                    <h5>{{'Select by Timeline' | translate}}</h5>

                    <div class="slider mt-2">
                        <span class="slider-label">1</span>
                        <input type="range" class="range" min="0" max="10">
                        <output class="bubble"></output>
                        <span id="slideroutput" class="slider-value">10</span>
                    </div>

                </div>

                <div class="btnGrp">
                    <button (click)="close()"  class="btn1">{{'Reset all filters' | translate}}</button>
                    <div class="one">
                        <div (click)="close()"  class="btnCancel">{{'Cancel' | translate}}</div>
                        <button  (click)="applyFilters()"  class="btn-primary">{{'Apply Filters' | translate}}</button>
                    </div>
                </div>
         
            </div>
        </ng-template> -->
        <div class="modal fade" id="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="logoutModalLabel"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                  <div class="modal_header">
                    <h5>{{'Logout' | translate}}?</h5>
                   
                    <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closelogoutModal()"></button>
                  </div>
                  <div class="modal-body">
                    <div class="text-center">{{'Are you sure you want to log out this app' | translate}} ?</div>
                  </div>
                  <div id="delete_id" class="btnGrp mt-4">
                    <button class="btn_primary bg_blue" id="button-id" (click)="closelogoutModal()">{{'Cancel' | translate}}</button>
                    <button id="button-id"   class="btn_primary bg_red"   (click)="logOut()">{{'Logout' | translate}}</button>
                  </div>      
                </div>
              </div>
        </div>
        <div class="modal fade" id="folderCreateModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel"   aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
              
                    <div class="modal_header">
                        <h5>{{'Add New Folder' | translate}}</h5>
                        <button type="button" #closebutton class="btn-close" (click)="closseModal()" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div id="folderName_And_Tagsection">
                        <form [formGroup]="createNewfolderForm">
                            <div class="modal-body">
        
                                
                                <div class="input_style mt-4">
                                    
                                    <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
                                    <input class="input" type="email" aria-describedby="emailHelp" placeholder="{{'Folder Name' | translate}}" formControlName="folder_name" >
                                    <div *ngIf = "folderSubmitted && a.folder_name.errors">
                                        <div class="error_message" *ngIf="a.folder_name.errors.required">{{'Folder Name is required' | translate}}</div>
                                        <!-- <div class="error_message" *ngIf="a.groupName.errors.pattern">{{'Email should valid' | translate}}</div> -->
                                    </div>
                                </div>
                                <div class="input_style mt-3">
                                    <label for="exampleInputEmail1">{{'Priority' | translate}}</label>
        
                                    <div class="select-createGroup">
                                        <mat-form-field class="custom-form-field1" appearance="none">
                                            <mat-select placeholder="{{'Select Priority' | translate}}"   formControlName="tag">
                                                <mat-option value="1"  >{{ 'High'| translate }}</mat-option>
                                                <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                                <mat-option value="3">{{'Low' | translate}}</mat-option>
                                                <mat-option value="{{item.id}}"  *ngFor="let item of priority">{{item.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        
                                    </div>
                                    <div *ngIf="folderSubmitted && a.tag.errors" class="m-2">
                                        <div class="error_message" *ngIf="a.tag.errors.required">{{'Priority' | translate}} {{'is required' | translate}}</div>
                                        <!-- <div class="error_message" *ngIf="b.project_type.errors.pattern">{{'Project type' | translate}} {{'shoud valid' | translate}}</div> -->
                                    </div>
            
                                    <!-- <div class="tagArea">
                                        <input type="text" id="tag-input2" #tagInput>
                                    </div> -->
                                    <!-- <div class="error_message" *ngIf="folderSubmitted && tagInput2.arr.length === 0">
                                        {{ 'Tags is required' | translate }}
                                    </div> -->
                                </div>
                                <div class="container_input_flex">
                                    <div class="input_style mt-4 d-flex align-items-center">
                                        <div class="datePicker">
                                            <div class="dateDiv">
                                                <label for="">{{'Starts from' | translate}}</label>
                                                <mat-form-field class="example-full-width" appearance="none">
                                                    <input matInput [matDatepicker]="dp10"  [min]="todayDate" [matDatepicker]="picker" readonly formControlName="start_date" (dateChange)="updateEndDate()">
                                                    <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                                                    <mat-datepicker #dp10></mat-datepicker>
                                                </mat-form-field>
                                                <div *ngIf="folderSubmitted && a.start_date.errors" class="m-2">
                                                    <div class="error_message" *ngIf="a.start_date.errors.required">{{'start date' | translate}} {{'is required' | translate}}</div>                                 
                                                    <!-- <div class="error_message" *ngIf="b.project_start_date.errors.matDatepickerMin">{{'Project start date' | translate}} {{'should valid' | translate}}</div>                                  -->
                                                </div>
                                            </div>
                                            <div class="minus-div">
                                                <img class="minus" src="assets/images/minus.png" alt="">
                                            </div>
                                            <div class="dateDiv">
                                                <div>
                                                    <label for="">{{'Ends on' | translate}}</label>
                                                    <mat-form-field class="example-full-width" appearance="none">
                                                        <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly formControlName="end_date" [min]="a.start_date.value" >
                                                        <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                                        <mat-datepicker #dp2></mat-datepicker>
                                                    </mat-form-field>
                                                    <div *ngIf="folderSubmitted && a.end_date.errors" class="m-2">
                                                        <div class="error_message" *ngIf="a.end_date.errors.required">{{'end date' | translate}} {{'is required' | translate}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
        
                                        
                                    </div>
                                </div>
                            </div>
        
                            <button class="btn_primary bg_blue"  (click)="createNewfolder()">{{'Create' | translate}}</button>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>