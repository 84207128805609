import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class ReuseRoutingStrategy implements RouteReuseStrategy {
  storedHandles: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    console.log("lorr",route)
    return route.data.reuseRoute || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    console.log("2343erttt",route)
    if (route.data.reuseRoute) {
      this.storedHandles[route.data.reuseKey] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    console.log("qwerty",route)
    const handle = this.storedHandles[route.data.reuseKey];
    return !!route.routeConfig && !!handle;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    console.log("asses",route)
    console.log("asses",route.data)
    if (!route.data.state) {
      console.log("success midilaj got it",this.storedHandles)
      this.storedHandles = {}; 
    }
    const id = route.data.reuseKey;
    console.log("this.storedHandles",this.storedHandles)
    if (!route.routeConfig || !this.storedHandles[id]) {
      return null;
    }
    console.log("this.storedHandles111111111111",this.storedHandles)
    return this.storedHandles[id];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
