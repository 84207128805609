import { Component, OnInit,ViewChild, ElementRef ,EventEmitter} from '@angular/core';
import '../../_models/tags-input'; 
import { ApiService } from '../../_services/api.services';
declare const window: any;
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter, MatSnackBar } from '@angular/material';
declare var $: any;
import { NgxUiLoaderService } from "ngx-ui-loader";
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { log } from 'console';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

  // @ViewChild('createProjectModal') createProjectModal: any;
  public addUserForm: FormGroup;
  public addGroupForm: FormGroup;
  teamsMembers = false;
  groupSection = false;
  userSection = true;
  showSideBar = false;
  useruuid: any;
  groupuuid: any;
  private tagInput: any; 
  response:any;
  showInnerDiv: boolean[];
  usersubmitted = false;
  responselist: any;
  userlist: any;
  grouplist: any[];
  colors: any;
  responsegroup: any;
  responsedesignation: any;
  designationlist: any;
  responsedelete: any;
  groupMembersimage: any;
  isSubmitted = false;
  currentLang: string;
  responseupload: any;
  user_Photo: any;
  createGroupForm: FormGroup;
  grouMembers:any[]=[];
  groupSubmitted: boolean;
  responseData: any;
  groupId: any;
  deleteResponse: any;
  editGroupData: any;
  editGroupList: any;
  editGroupMembers: any;
  tags: any;
  editData: any;
  groupid: any;
  updateGroupresponse: any;
  responseActivelist: any;
  activeUserlist: any;
  maxDate: Date;
  minDate: Date;
  userphtoFile: any;
  backgroundColor: any;
  backgroundColorClass: string;
  randomColor: any;
  editUserr: any;
  buttonText: string = "Invite";
  isEditMode: boolean = false;
  isEditGroupMode : boolean = false;
  openAddusermodal:any;
  readonlyMode: boolean = false;
  grouMembersuuid : any[]=[];
  removeUsers : any[]=[];
  array: any;
  // today1 = new Date();
  // startDate = new Date(this.today1.setDate(this.today1.getDate() + 2));
  currentValue: any;
  min = new Date(1924, 4, 10, 10, 50);
  buttonText1 : string =  "Create group";
  tagSelect: any;
  priorityResponse: any;
  priority: any;
 

  constructor(private api: ApiService,
              public formBuilder: FormBuilder,
              public snackBar : MatSnackBar,
              private ngxUiLoaderService: NgxUiLoaderService,
              private datePipe: DatePipe,
              private dateAdapter: DateAdapter<Date>) { }

  ngOnInit() {
    // $('#createProjectModal').modal('show');

    this.addUserForm = this.formBuilder.group({
      first_name : ['',[Validators.required,Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ, ]+$")]],
      last_name : ['',[Validators.required, Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ, ]+$")]],
      designation : ['',[Validators.required]],
      date_of_birth : ['',[Validators.required]],
      email : ['',[Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
      mobile : ['',[Validators.required, Validators.minLength(8), Validators.maxLength(12), Validators.pattern('^[0-9]+$')]],
      user_photo: ['', [Validators.required]],
      imageFile: ['']
      // user_id : ['']
    });

    this.addGroupForm = this.formBuilder.group({
      group_name : ['',[Validators.required]],
      tag : ['',[Validators.required]]
    });

    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)


    this.getUsers(1);
    this.getGroups(1);
    this.getDesignations();
      this.tagInput = new TagsInput({
        selector: 'tag-input1',
        duplicate: false,
        max: 10,
        icon:"assets/images/blue_cross.png",
      });
      this.tagInput.addData([]);
      // console.log(this.tagInput,'sdsadsdsdsdsdsdsdsd');
      

      var input = document.querySelector("#phone");
      window.intlTelInput(input, {
      // show dial codes too
      separateDialCode: true,
      // If there are some countries you want to show on the top.
      // here we are promoting russia and singapore.
      // preferredCountries: ["ru", "sg"],
      //Default country
      initialCountry:"ga",
      // show only these countres, remove all other
      // onlyCountries: ["ru", "cn","pk", "sg", "my", "bd"],
      // // If there are some countries you want to execlde.
      // // here we are exluding india and israel.
      // excludeCountries: ["in","il"]
      });
      const today = new Date();
      this.maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate(),23, 59, 59);
      this.minDate =  new Date(today.getFullYear() - 150, today.getMonth(), today.getDate());
      this.currentLang  = localStorage.getItem("language");
      console.log(this.currentLang);
      this.dateAdapter.setLocale(this.currentLang); 
      this.getPriority();
  }
  get a() {
    return this.addGroupForm.controls;
  }

  get b() {
    return this.addUserForm.controls;
  }
  get today():Date{
    return new Date();
  }
  

  getDesignations() {
    this.api.getDesignations().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsedesignation = data;
      if (this.responsedesignation.status == 200) {
        this.designationlist = this.responsedesignation.results;
        console.log(this.designationlist,'1111');
        
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 


  getUsers(val) {
    console.log(val);
    
    if (val == 1) {
      this.ngxUiLoaderService.start();
    }
    this.api.getUsers().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responselist = data;
      if (this.responselist.status == 200) {
        this.userlist = this.responselist.results;
      }else{
        this.snackBar.open(this.responselist.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 

  groupEditfunctions(item){
    this.grouMembersuuid = [];
    this.grouMembers = [];
    this.editGroup(item);
    this.isEditGroupMode = !this.isEditGroupMode;
    this.buttonText1 = "Update";
    $('#createProjectModal').modal('show');
    this.getActiveUsers();
  }




  getActiveUsers() {
    var data = {
      entityType :'1',
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    console.log('gggg');
    this.api.getActiveUser(data).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      console.log(data);
      
      this.responseActivelist = data;
      if (this.responseActivelist.status == 200) {
        this.activeUserlist = this.responseActivelist.results;
        console.log(this.activeUserlist);
        $('#createProjectModal').modal('show');
      }else{
        this.snackBar.open(this.responseActivelist.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      console.log('hi');
      
      this.ngxUiLoaderService.stop();
    });
  } 

  getGroups(val) {
    if (val == 1) {
      this.ngxUiLoaderService.start();
    }
    this.api.getGroups().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsegroup = data;
      if (this.responsegroup.status == 200) {
        this.grouplist = this.responsegroup.results;
        console.log( this.grouplist);
        
        this.colors = this.grouplist.map(() => this.generateRandomColor());
        console.log(this.grouplist,"ppppp");
        // console.log(this.grouplist.,"ppppp");
        this.ngxUiLoaderService.stop();
        // this.groupMembersimage = this.responsegroup.results.profile_pic; 
        this.showInnerDiv = new Array(this.grouplist.length).fill(false);
        // console.log(this.groupMembersimage)
        // this.snackBar.open(this.responsegroup.message, "Dismiss", {
        //   duration:3000,
        // })
      }else{
        this.snackBar.open(this.responsegroup.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 
  
 

  toggleInnerDiv(index: number) {
    console.log(index)
    for (let i = 0; i < this.showInnerDiv.length; i++) {
      if (i !== index) {
        this.showInnerDiv[i] = false;
      }
    }

    // Toggle the state of the clicked container
    this.showInnerDiv[index] = !this.showInnerDiv[index];
  }

  getInfo(key:any) {
    console.log(key)
    if(key == '1') {
      this.showSideBar = false;
    }
    else {
      this.showSideBar = true;
    }
    
  }

  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }

  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }


  showTeams() {
    if (this.teamsMembers == true) {
      this.teamsMembers = false;
    }else {
      this.teamsMembers = true;
    }
  }


  clickTab(key) {
    if(key == 'user'){
      this.userSection = true;
      this.groupSection = false;
    }else {
      this.userSection = false;
      this.groupSection = true;
    }
  }

  closseModal(val=null){
    this.usersubmitted = false;
    if (!val) {
      this.addUserForm.reset();
    }
    this.editUserr = null;
    this.user_Photo = null;

    this.isEditMode = false;
    this.isEditGroupMode = false
    this.readonlyMode = false;

  }
  

  resetFormAndModal() {
    this.addUserForm.reset();
    this.addGroupForm.reset();
    this.usersubmitted = false;
  }

  onSubmitnewUser() {
    this.usersubmitted = true;
    console.log( this.datePipe.transform(this.addUserForm.value.date_of_birth, 'dd-MM-yyyy'))
    if(this.addUserForm.status == "INVALID") {
      console.log('sdasdsd',this.addUserForm);
      return
    }
    console.log(this.addUserForm,'hhhhhhh')
    console.log(this.addUserForm.value );
    var data = this.addUserForm.value;

    delete data['imageFile'];
    console.log(data);
    if(this.editUserr){
      let user_id = this.editUserr.uuid;
      data.user_id = user_id;
      data['date_of_birth'] = this.datePipe.transform(this.addUserForm.value.date_of_birth, 'dd-MM-yyyy')
      console.log(data);
    }else{
      data['date_of_birth'] = this.convertOwl(this.addUserForm.value.date_of_birth);
    }
      this.ngxUiLoaderService.start();
      this.api.addNewUser(data).subscribe(data=>{
        this.ngxUiLoaderService.stop();
        console.log(data,'12211');
        this.response = data;
        console.log(this.response)
        
        if(this.response.status == 200) {
          this.snackBar.open(this.response.message, "Dismiss", {
            duration:3000,
          })
          
          $('#addUser').modal('hide');
          $(".modal-backdrop").remove();    
          this.usersubmitted = false;
          this.getUsers(2);
          setTimeout(() => {
            if (!this.editUserr) {
              console.log(this.editUserr);
              
              $('#inviteSuccessModal').modal('show');
              this.getUsers(2);
            } else {
              this.addUserForm.reset();
            }
            this.closseModal(1)
          }, 1000)

        } else {
          this.snackBar.open(this.response.message, "Dismiss", {
            duration:3000,
          })
        }
      }, error => {
        this.ngxUiLoaderService.stop();
      });
    
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      console.log(date)
      if(date.length == 1)
        return str;
      else
        return [date[2], mnths[date[1]],date[3]].join("-");
    }

    addNewUser() {
      this.usersubmitted = false;
      this.addUserForm.reset();
    }
    
    deleteUser() {
      var data = {
        user_id: this.useruuid
      }
      this.ngxUiLoaderService.start();
      this.api.deleteUser(data).subscribe(data=>{
        this.ngxUiLoaderService.stop();
        this.responsedelete = data;
        if (this.responsedelete.status == 200) {
          this.useruuid = null;
          this.getUsers(1);
          $('#deleteModal3').modal('hide'); 
          $(".modal-backdrop").remove();    
        }
        this.snackBar.open(this.responsedelete.message, "Dismiss", {
          duration:3000,
        })
      }, error => {
        this.ngxUiLoaderService.stop();
      });
    }

    editUser(item) {
      this.readonlyMode = !this.readonlyMode;
      this.isEditMode = !this.isEditMode;
      this.buttonText = this.isEditMode ? "Update" : "Invite";
      console.log(item)

      this.editUserr = item;
      console.log(this.editUserr)
      this.addUserForm.get('first_name').setValue(item.first_name);
      this.addUserForm.get('last_name').setValue(item.last_name);
      this.addUserForm.get('designation').setValue(item.designation);
      this.addUserForm.get('designation').setValue(item.designation_id);
      this.addUserForm.get('date_of_birth').setValue(item.dob);
    
      this.addUserForm.get('email').setValue(item.email);
      this.addUserForm.get('mobile').setValue(item.mobile);
      this.user_Photo = item.profile_pic
      
      this.addUserForm.get('user_photo').setValue(this.editUserr.profile);
      $('#addUser').modal('show');
    }

    closemodal(){
      this.addUserForm.reset();
      $('#inviteSuccessModal').modal('hide')
      $(".modal-backdrop").remove();
    }

    openAdduserModal(){
      this.buttonText = 'Invite';
      $('#addUser').modal('show');
    }

    close(){
      console.log('clicked');
      $('#createProjectModal').modal('hide');

    }
  
    upload(event: any, id): void {
      console.log("2250", event)
      console.log("2251", event.target.files[0])
  
      if (event.target.files.length > 0) {
  
        const file = event.target.files[0];
        console.log('uploaded image: ', file)
        const filename = file.name;
        console.log(filename);
        
        var ext = filename.split('.').pop();
        this.currentLang  = localStorage.getItem("language");
        if (file.size > 5242880) {
  
          if (this.currentLang == 'fr') {
            alert('La taille du fichier doit être inférieure à 5MB')
          } else {
            alert('File Size should be less than 5MB')
          }
          return;
        }
        if (id == 'user_photo') {
          console.log("340  user_Photo")
          if (ext != 'jpg' && ext != 'JPG' && ext != 'png' && ext != 'PNG' && ext != 'JPEG' ) {
            if (this.currentLang == 'fr') {
              alert('Type de fichier non autorisé. Les types de fichier autorisés sont  .jpg .png .jpeg ')
            } else {
              alert('File type not allowed. Allowed file types are .jpg .png .jpeg ')
            }
            return;
          }
        } else {
          if (ext != 'jpg' && ext != 'JPG' && ext != 'png' && ext != 'PNG' && ext != 'JPEG' && ext != 'pdf' && ext != 'PDF' && ext != 'doc' && ext != "Doc" && ext != 'bmp' && ext != 'BMP' && ext != 'webp' && ext != 'heic' && ext != 'heif') {
            if (this.currentLang == 'fr') {
              alert('Type de fichier non autorisé. Les types de fichier autorisés sont  .jpg .png .jpeg ')
            } else {
              alert('File type not allowed. Allowed file types are .jpg .png .jpeg ')
            }
            return;
          }
        }
  
        this.ngxUiLoaderService.start();
        const target = event.target.dataset.target;
        console.log(file,'9999999999999999')
        const formData = new FormData();
        formData.append('file', file);
        console.log(formData);
        
        formData.append('target', target);
        console.log(formData);
        
        console.log("file is :", file)
        console.log("target is :", target)
        console.log(formData,'77777777777');
        
        
        this.api.upload(formData).pipe(first())
          .subscribe(data => {
            console.log(data,'0000000000000000000000');
            
              this.ngxUiLoaderService.stop();
              this.responseupload = data;
              console.log(this.responseupload,'12112');
              
              if (this.responseupload.status == 200) {
  
                const fileNameDisplay = document.getElementById(id);
                console.log(fileNameDisplay);
                
                if (this.currentLang == 'fr') {
                  fileNameDisplay.textContent = `Cliquez pour voir le fichier`;
                } else {
                 
                  fileNameDisplay.textContent = `Click to view File`;
                  
                }
                
                console.log('kitty');
                if (target == 'user_photo') {
                  console.log('kitty');
                  
            
                  this.user_Photo = this.responseupload.results.imageUrl;
                  this.addUserForm.get('user_photo').setValue(this.responseupload.results.file);
                  console.log(this.userphtoFile);
                  
                  // console.log(this.user_Photo);
                  // this.addUserForm.get('user_photo').setValue(this.user_Photo);
                  console.log(this.addUserForm);
                  
        
                }
                
              } else {
                this.snackBar.open(this.responseupload.message, "OK", {
                  duration: 5000,
                });
              }
            },
            error => {
  
           });
  
      }
    }

  
    removeUser(index: number, uuid, member) {
      console.log(member, 'yyyyyyyyy');
      this.grouMembersuuid = this.grouMembersuuid.filter(item => item !== uuid);
      console.log(this.grouMembersuuid);
      
      this.grouMembers.splice(index, 1);
      
      if (!member.notinuser) {
        this.removeUsers.push(member.uuid);
        console.log(this.removeUsers, 'removed user');
      }
    }
    
    adduser(user) {
      console.log('clicked', user);
      const isUserPresent = this.grouMembers.some(member => member.uuid === user.uuid);
      console.log(isUserPresent);
      
      if (!isUserPresent) {
        user['notinuser'] = 0; 
        this.grouMembers.push(user);
        this.grouMembersuuid.push(user.uuid);
        console.log(this.grouMembersuuid, 'xxxxxxxxxxxxx');
        console.log(this.grouMembers, '22222222222');
  
        this.removeUsers = this.removeUsers.filter(uuid => uuid !== user.uuid);
        console.log(this.removeUsers, 'updated removed users');
      }
    }


    generateRandomColor(): string {
      return '#' + Math.floor(Math.random() * 16777215).toString(16); 
    }

    getValueFromInput() {
      const inputValue = this.tagInput.nativeElement.value;
      console.log('Input value:', inputValue);
    }

    getShortName(group_name) {
      return group_name.charAt(0); 
      // return group_name.split(' ').map(n => n[0]).join('');
    }

    createProjectModal() {
      this.editData = null;
      this.groupSubmitted = false;
      this.addGroupForm.reset();
      // this.tagInput.removeAllTags();
      this.grouMembers = [];
      this.grouMembersuuid = [];
      console.log('GFFFFFFF');
      this.buttonText1 = 'Create group';
      this.addGroupForm.get('tag').setValue('1');
      this.getActiveUsers()
    }

    ngSubmitcreateGroup(){
      this.groupSubmitted = true;
      console.log(this.addGroupForm.value,);
      console.log(this.grouMembersuuid);
      console.log(this.removeUsers);

      if((this.addGroupForm.invalid) ||  (this.grouMembers.length == 0)){
        console.log('asdsaddssdasasdsadadaddsadsdsad')
        return;
      }
      this.ngxUiLoaderService.start();
      let data = {
        ...this.addGroupForm.value,
        user_id: this.grouMembersuuid,
        removed_user:this.removeUsers
      };

      console.log(data);
      

      if(this.editData){
        this.updateGroup(data)
      } else {
        this.addGroup(data);
      }
    
    }

    updateGroup(data) {
      console.log(this.editData);
      this.groupid =  this.editData.uuid 
      this.ngxUiLoaderService.stop();
        this.api.updategroup(data,this.groupid).subscribe(data=>{
        console.log(data);
        this.updateGroupresponse = data;
        console.log(this.updateGroupresponse,'aasssssasaasaass');
        if(this.updateGroupresponse.status){
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.updateGroupresponse.message, "Dismiss", {
            duration:3000,
          })
          this.addGroupForm.reset();
          this.getGroups(1);
          $('#createProjectModal').modal('hide');  
          this.editData = null;
          this.removeUsers =[];
          console.log(this.removeUsers);
          
        }else{
          this.ngxUiLoaderService.stop();
          // this.editData = null;
          this.snackBar.open(this.updateGroupresponse.message, "Dismiss", {
            duration:3000,
          })
        }
      }, error=> {
        this.ngxUiLoaderService.stop();
      })
    }

    addGroup(data) {
      this.api.createGroup(data).subscribe(data=>{
        this.responseData = data
        console.log(this.responseData);
        if(this.responseData.status == 200){
          this.ngxUiLoaderService.stop();
          this.getGroups(1);
          this.snackBar.open(this.responseData.message, "Dismiss", {
            duration:3000,
          })
          // this.createProjectModal.close('dismiss');
          $('#createProjectModal').modal('hide');
          this.grouMembersuuid =[];
        }
        else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.responseData.message, "Dismiss", {
            duration:3000,
          })
        }

      }, error=> {
        // this.ngxUiLoaderService.stop();
        this.ngxUiLoaderService.stop();
      })
    }

    closeGroupmodal(){
      
      this.groupSubmitted = false;
      const tagsWrapper = document.querySelector('.tags-input-wrapper');
        if (tagsWrapper) {
          console.log(tagsWrapper);
          const spanTags = tagsWrapper.querySelectorAll('span');
          spanTags.forEach(span => {
            console.log(span);
            
            span.parentNode.removeChild(span);
          });
          const inputElement = tagsWrapper.querySelector('input');
          if (inputElement) {
            inputElement.value = '';
          }
        }
        this.addGroupForm.reset();
        this.grouMembers = [];
    }

    deleteGroup(){
      var data = {
        groupid: this.groupuuid
      }
      this.ngxUiLoaderService.start();
      this.api.deleteGroups(this.groupuuid).subscribe(data=>{
        this.ngxUiLoaderService.stop();
        this.deleteResponse = data;
        console.log(this.deleteResponse);
        if(this.deleteResponse.status==200){
          this.groupuuid = null;
          this.getGroups(1);
          $('#deleteModal4').modal('hide'); 
          $(".modal-backdrop").remove(); 
        }
          this.snackBar.open(this.deleteResponse.message, "Dismiss", {
            duration:3000,
            
          })
      }, error=> {
        // this.ngxUiLoaderService.stop();
        this.ngxUiLoaderService.stop();
      })
      
    }

    // deleteGroup(){
    //   var data = {
    //     user_id: this.groupuuid
    //   }
    //   this.ngxUiLoaderService.start();
    //   this.api.deleteGroups(data).subscribe(data=>{
    //     this.deleteResponse = data;
    //     console.log(this.deleteResponse,'sdasdsadasd');
    //     if(this.deleteResponse.status){
    //       this.ngxUiLoaderService.stop();
    //       this.snackBar.open(this.deleteResponse.message, "Dismiss", {
    //         duration:3000,
            
    //       })
    //       this.getGroups(1);
    //     }else{
    //       this.ngxUiLoaderService.stop();
    //       this.snackBar.open(this.deleteResponse.message, "Dismiss", {
    //         duration:3000,
    //       })
    //     }
        
    //   }, error=> {
    //     // this.ngxUiLoaderService.stop();
    //     this.ngxUiLoaderService.stop();
    //   })
      
    // }

    editGroup(item){
      console.log('clicked',item);
      if(item){
        this.editData = item;
        console.log(this.editData,'qqqqqqq');
        this.addGroupForm.get('group_name').setValue(this.editData.group_name);
        this.addGroupForm.get('tag').setValue(this.editData.groupTemplate);
        this.grouMembers= this.editData.groupMembers;
        console.log(this.grouMembers);
        
        for (let key in this.grouMembers) {
          this.grouMembersuuid.push(this.grouMembers[key].uuid)
          console.log(this.grouMembers);
          
        }
        console.log(this.grouMembersuuid);
        // this.tags =this.editData.groupTemplate
        // if (this.tags &&this.tags.length) { 
        // this.tags.forEach(tag => {
        // this.tagInput.addData([tag]);
        // });
        // }
        let data = {
          ...this.addGroupForm.value,
          user_id: this.grouMembers,
          
        }; 
        let groupId = this.editData.id;
        console.log(data,groupId);
      }
    }
    
    deleteUserList(uuid){
      this.useruuid = uuid;
      console.log(this.useruuid);
      $('#deleteModal3').modal('show');
    }
    deleteGroupList(uuid){
      this.groupuuid = uuid;
      console.log(this.groupuuid);
      $('#deleteModal4').modal('show');
    }

    change(event){
      this.tagSelect = event.value;
      console.log(this.tagSelect);
      
    }

    getPriority(){
      this.ngxUiLoaderService.start();
      this.api.getPrioritydata().subscribe(data=>{
        console.log(data);
        this.priorityResponse = data;
        if(this.priorityResponse.status == 200){
          this.ngxUiLoaderService.stop();
          console.log(this.priorityResponse);
          this.priority = this.priorityResponse.result;
          
        }else{
          this.ngxUiLoaderService.stop();
        }
        
      }, error => {
        this.ngxUiLoaderService.stop();
      })
    }
}
