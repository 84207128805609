import { Component, OnInit } from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-domain-search',
  templateUrl: './domain-search.component.html',
  styleUrls: ['./domain-search.component.scss']
})
export class DomainSearchComponent implements OnInit {
  signInForm : FormGroup
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.signInForm = this.formBuilder.group({
      domain_name  : ['', [Validators.required]]
    })
   
  }

  get signupValidate() {
    return this.signInForm.controls;
  }



}
