import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from '../../../_services/api.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
declare const window: any;
import * as AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.scss']
})
export class BusinessInfoComponent implements OnInit {

  businesssInfoForm : FormGroup;
  submitted = false;
  response:any;
  businesssData:any;
  constructor(private router: Router,
              private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService,
              private snackBar: MatSnackBar,
              public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.businesssInfoForm = this.formBuilder.group({
      street_address:['',[Validators.required, Validators.pattern("^[a-zA-Z0-9,]+(\s{0,1}[a-zA-Z0-9, ])*$")]],
      street_address_line2:['',[Validators.required, Validators.pattern("^[a-zA-Z0-9,]+(\s{0,1}[a-zA-Z0-9, ])*$")]],
      city:['',[Validators.required, Validators.pattern("^[a-zA-Z0-9,]+(\s{0,1}[a-zA-Z])*$")]],
      state:['',[Validators.required, Validators.pattern("^[a-zA-Z0-9,]+(\s{0,1}[a-zA-Z])*$")]],
      pincode:['',[Validators.required, Validators.minLength(4), Validators.pattern('^[0-9]+$')]],
      business_phone_no:['',[Validators.required, Validators.minLength(8), Validators.pattern('^[0-9]+$')]]
    })
    this.getBusinessDetails();



    AOS.init({ disable: 'mobile'});
    var input = document.querySelector("#phone");
    window.intlTelInput(input, {
      // show dial codes too
      separateDialCode: true,
      // If there are some countries you want to show on the top.
      // here we are promoting russia and singapore.
      // preferredCountries: ["ru", "sg"],
      //Default country
      initialCountry:"in",
      // show only these countres, remove all other
      // onlyCountries: ["ru", "cn","pk", "sg", "my", "bd"],
      // // If there are some countries you want to execlde.
      // // here we are exluding india and israel.
      // excludeCountries: ["in","il"]
    });

    // $('#business-info-change').click(function(){
    //   $('.business-info').css("display", "none");
    //   $('#planeDetails').css("display", "block");
    //   // $('.planeDetails').css({
    //   //     'display' : block
    //   // })

    // })




  }


  get businesssInfoGet() {
    return this.businesssInfoForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.businesssInfoForm.controls)

    if(this.businesssInfoForm.invalid) {
      return;
    }
    else {
      let data = this.businesssInfoForm.value;
      data['stage_key'] = 300;
      console.log(data)
      this.ngxUiLoaderService.start();
      this.api.employeeCountForBusiness(data).subscribe(data => {

        this.ngxUiLoaderService.stop();
        this.response = data;

        if(this.response.status == "200") {
          this.router.navigate(['/plan-summary']);
        }else {
          this.snackBar.open(this.response.message, "Alerte", {
            duration: 2000,
          });
        }

      },error => {
        this.ngxUiLoaderService.stop();
      })
    }

  }


  getBusinessDetails() {
    this.api.getBusinessAccountDetails().subscribe(data =>{
      
      if(data.status == "200") {
        console.log('aaaa');
        this.businesssData = data.results;
        console.log(this.businesssData);

        if(this.businesssData.stage > "200") {
          this.businesssInfoForm.controls.street_address.setValue(this.businesssData.street_address);
          this.businesssInfoForm.controls.street_address_line2.setValue(this.businesssData.street_address_line2);
          this.businesssInfoForm.controls.city.setValue(this.businesssData.city);
          this.businesssInfoForm.controls.state.setValue(this.businesssData.state);
          this.businesssInfoForm.controls.pincode.setValue(this.businesssData.pincode);
          this.businesssInfoForm.controls.business_phone_no.setValue(this.businesssData.business_phone_no);
        }
      }
    })
  }


  showBusinessDashboard() {
    localStorage.setItem('login',"business");
    this.router.navigate(['/dashboard']);
  }
}
