<div class="forgotPassword"> 
<div class="bg_area_main">
    <app-header-landing-about></app-header-landing-about>
    <!-- <nav data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
        
        <div class="d-flex align-items-center justify-content-between main">
            <img src="assets/images/About-nav.png" alt="" (click)="routeLanding()" class="cursor_pointer" >
            <div class="menu_about_div">
                <ul>
                    <li class="mr-4" routerLink="/">{{'Home' | translate}}</li>
                    <li class="mr-4" routerLink="/about">{{'About' | translate}}</li>
                </ul>
            </div>
            <div class="toggle-button-cover toggle_btn toggle_btn_md">
                <div class="button-cover">
                <div class="button r" id="button-6">
                    <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                </div>
                </div>
            </div>

        </div>
    </nav> -->

    <div class="authentications mt-5">
    <div class="container_custom mb-5">
        <div class="row">
            <div class="col-md-6">
                <div class="bg_area" id="parent" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
                    <div (click)="getBack()" class="arrow-left"><i class="fa-solid fa-arrow-left"></i></div>
                    <img class="img-fluid" src="assets/images/cloudStorage.png" alt="">
                </div>
            </div>
            <div class="col-md-6 position-relative">
                <div (click)="getBack()" class="arrow-left2"><i class="fa-solid fa-arrow-left"></i></div>
                <div class="section_1" [hidden]="!emailform || otpform || passwordform">
                    <div class="d-flex flex-column justify-content-center">
                        <img class="logo img-fluid cursor_pointer" src="assets/images/About-nav.png" (click)="routeLanding()" alt="" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
                        <div class="d-flex justify-content-center mt-5">
                            <div class="content_main">
                                <h4 class="heading_1 mb-2" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1500">{{'Forgot password?' | translate}}</h4>
                                <form [formGroup]="emailForm" class="mt-4">
                                <h6 class="text-sm mb-15" data-aos="fade-right" data-aos-anchor="#parent" data-aos-delay="400" data-aos-duration="1500">{{'Enter your Email id to reset password.' | translate}}</h6>
                                    <div class="input-style" data-aos="fade-right" data-aos-anchor="#parent" data-aos-delay="800" data-aos-duration="1500">
                                      <img src="assets/images/mail.png" alt="">
                                      <input type="text" placeholder="{{ 'Email address' | translate }}" formControlName="email">
                                    </div>
                                    <div *ngIf="emailSubmitted && emailError.email.errors">
                                        <div class="error_message text-danger" *ngIf="emailError.email.errors.required">{{'Email is required' | translate}}</div>
                                        <div class="error_message text-danger" *ngIf="emailError.email.errors.pattern">{{'Email should valid' | translate}}</div>
                                    </div>
                                    <button type="submit" data-aos-anchor="#parent" id="emailSubmit" class="btn-primary mt-5" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500" (click)="onSubmitEmailform() ">
                                      {{ 'CONFIRM' | translate }}
                                    </button>
                                </form>

                                <!-- <button id="emailSubmit" class="btn-primary mt-5" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500" (click)="submit('email')">{{'CONFIRM' | translate}}</button> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section_1" [hidden]="emailform || !otpform || passwordform">
                    <div class="d-flex flex-column justify-content-center">
                        <img class="logo cursor_pointer" src="assets/images/About-nav.png" alt="" (click)="routeLanding()" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
                        <div class="d-flex justify-content-center mt-5">
                            <div class="content_main profile-sec1">
                                <h4 class="heading_1">{{'Enter OTP' | translate}}</h4>
                                <h6 class="text-sm mb-15">{{'Please enter the 4-digit OTP we have sent to your' | translate}} <br>{{'email address' | translate}}</h6>
                                <form [formGroup]="otpForm" class="mt-4" >
                                <div>
                                    <div class="d-flex otp_input justify-content-center mt-3">
                                        <input type="text" data-aos="fade-right" data-aos-delay="800" data-aos-duration="1500" maxlength="1" minlength="1" formControlName="otp1" (input)="moveToNext($event, 1)">
                                        <input type="text" data-aos="fade-right" data-aos-delay="900" data-aos-duration="1500" maxlength="1" minlength="1" formControlName="otp2" (input)="moveToNext($event, 2)">
                                        <input type="text" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500" maxlength="1" minlength="1" formControlName="otp3" (input)="moveToNext($event, 3)">
                                        <input type="text" data-aos="fade-right" data-aos-delay="1100" data-aos-duration="1500" maxlength="1" minlength="1" formControlName="otp4" (input)="moveToNext($event, 4)">
                                    </div>
                                    <div *ngIf="otpSubmitted">
                                        <div *ngIf="h.otp1.errors?.required || h.otp2.errors?.required || h.otp3.errors?.required || h.otp4.errors?.required" class="error-message">{{'OTP is required' | translate}}</div>
                                        <div *ngIf="(h.otp1.value || h.otp2.value || h.otp3.value || h.otp4.value) && (h.otp1.errors?.pattern || h.otp2.errors?.pattern || h.otp3.errors?.pattern || h.otp4.errors?.pattern)" class="error-message">{{'OTP is not valid' | translate}}</div>
                                    </div>
                                    <h6 class="text-sm mb-15 text-center mt-2" data-aos="fade-right" data-aos-delay="1300" data-aos-duration="1500">{{'Don’t get a code?' | translate}} <span class="blue_letter">{{'Click to Resend.' | translate}}</span></h6>
                                </div>                                    
                                <button id="otpSubmit" class="btn-primary mt-5" data-aos="fade-right" data-aos-delay="1500" data-aos-duration="1500" (click)="onSubmitOtpform()">{{'VERIFY' | translate}}</button>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="section_1 h600" id="forgot_3" > -->
                <div class="section_1" [hidden]="emailform || otpform || !passwordform">
                    <div class="d-flex flex-column justify-content-center">
                        <img class="logo cursor_pointer" src="assets/images/About-nav.png" alt="" (click)="routeLanding()">
                        <form [formGroup]="newpasswordForm">
                            <div class="d-flex justify-content-center mt-5">
                                <div class="content_main profile-sec1">
                                    <h4 class="heading_1">{{ 'Reset Password' | translate }}</h4>
                                    <h6 class="text-sm mb-4 mt-2">{{ 'Please enter your new password' | translate }}</h6>
                                    <div class="input-style">
                                        <img src="assets/images/password.png" alt="">
                                        <input type="{{ passwordFieldType }}" placeholder="{{ 'New Password' | translate }}" formControlName="password">
                                        <span (click)="setShowPassword('a')" class="eye_Icon"><i class="{{ showPasswordIcon }} eye_Icon"></i></span>
                                    </div>
                                    <div *ngIf="newpassSubmitted && newpasswordForm.get('password').errors">
                                        <div class="error_message text-danger" *ngIf="newpasswordForm.get('password').errors.required">{{ 'New Password' | translate }} {{ 'is required' | translate }}</div>
                                        <div class="error_message" *ngIf="newpasswordForm.get('password').errors.pattern">{{'For more security, your password must include upper and lower case letters, at least one number, at least one special character ( !@#$?) and must have a minimum length of eight characters.' | translate}}</div>
                                    </div>
                                    <div class="input-style">
                                        <img src="assets/images/password.png" alt="">
                                        <input type="{{ passwordFieldType2 }}" placeholder="{{ 'Confirm New Password' | translate }}" formControlName="repeatnewpass">
                                        <span (click)="setShowPassword('b')" class="eye_Icon"><i class="{{ showPasswordIcon2 }}"></i></span>
                                    </div>
                                    <div *ngIf="newpassSubmitted && newpasswordForm.get('repeatnewpass').errors" >
                                        <div class="error_message text-danger" *ngIf="newpasswordForm.get('repeatnewpass').errors.required">{{ 'Confirm New Password' | translate }} {{ 'is required' | translate }}</div>
                                        <!-- <div class="error_message" *ngIf="newpasswordForm.get('password').errors.pattern">{{'For more security, your password must include upper and lower case letters, at least one number, at least one special character ( !@#$?) and must have a minimum length of eight characters.' | translate}}</div> -->
                                    </div>
                                    <div *ngIf="newpassSubmitted && newpasswordForm.get('repeatnewpass').errors">
                                        <!-- <div class="error_message text-danger" *ngIf="newpasswordForm.get('repeatnewpass').errors.required">{{ 'Confirm New Password' | translate }} {{ 'is required' | translate }}</div> -->
                                        <div class="error_message text-danger" *ngIf="newpasswordForm.get('repeatnewpass').errors.passwordMismatch">{{ 'Passwords do not match' | translate }}</div>
                                    </div>

                                    <button id="newPassSubmit" class="btn-primary mt-4" (click)="onSubmitNewPasswordform()">{{ 'SAVE' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
    </div>
</div>
</div>
