
<nav>
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/About-nav.png" alt="">
        <ul>

            <li class="mr-4" routerLink="/">Home</li>
            <!-- <li class="mr-4">Features</li> -->
            <li class="mr-4" routerLink="/about">About</li>
            <!-- <li class="mr-4">En</li> -->
            <!-- <li routerLink="/signup" class="mr-4">Sign Up</li>
            <li routerLink="/login" class="mr-4">Login</li> -->
                
        </ul>
    </div>

</nav>


<div id="planeDetails" class="mb-5 mt-4">
    <div class="container_1">
        <div class="main">
            <div class="profile-sec">
                <form [formGroup]="planSelectForm" (ngSubmit)="showBusinessDashboard()">
                    <div class="heading-main">Review your Business plan.Try free for 7 days.</div>
                    <div class="subText">Enter your business information  to register your domain.</div>
        
                    <div class="plan_details">
        
                        <div class="price">

                            <div>
                                <mat-radio-group aria-label="Select an option" formControlName="annual_plan">
                                    <mat-radio-button class="mt-3" value="1"></mat-radio-button>
                                
                                </mat-radio-group>
                                Annual Plan
                            </div>
        
                            <div class="period">
                                1100
                                <small>per user/month</small>
                            </div>
                        </div>
                        <div *ngIf = "submitted && planSummaryControl.annual_plan.errors">
                            <div class="error_message" *ngIf="planSummaryControl.annual_plan.errors.required">Plan choose required</div>
                        </div>
        
                        <div class="plan-benifits">
                            <ul>
                                <li><img src="assets/images/Done.png" alt="">Custom  email with your domain</li>
                                <li><img src="assets/images/Done.png" alt="">Offline services</li>
                                <li><img src="assets/images/Done.png" alt="">Advanced Security</li>
                                <li><img src="assets/images/Done.png" alt="">Admin Features</li>
                                <li><img src="assets/images/Done.png" alt="">24/7 support</li>
                                <li><img src="assets/images/Done.png" alt="">No charge for 7-day free trial;<br>monthly charges afterwards</li>
                            </ul>
                        </div>
        
                    </div>
                
    
                    <button type="submit" id="nextPageClick" class="btn-primary">NEXT <i class="fa-solid fa-arrow-right"></i></button>
                </form>
            </div>
        </div>
    
    </div>
    </div>
