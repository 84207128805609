import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../_services/translate.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router' 
import { ApiService } from '../../../_services/api.services';
declare var $: any;
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss']
})
export class FooterLayoutComponent implements OnInit {
  loginvalue: any;
  show_dash: boolean;
  response: any;

  constructor(private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngxUiLoaderService: NgxUiLoaderService) {
    if (this.authenticationService.currentUserValue) {
      // console.log('atheda vannedaaaaaaaaaa');
      this.loginvalue = this.authenticationService.currentUserValue;
      console.log(this.loginvalue);
      if (this.loginvalue) {
        this.show_dash = true;
      }else{
        this.show_dash = false;
      }
    }
   }

  ngOnInit() {
  }

  logoutModal(){
    $('#logoutModal').modal('show');
  }
  closelogoutModal(){
    $('#logoutModal').modal('hide');
  }

  logoUt() {
    console.log('adssa');
    
    this.ngxUiLoaderService.start();
    var data = {

    }
    this.api.logOut(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.response = data;
      if(this.response.status == "200"){
        $('#logoutModal').modal('hide')
        console.log('666666666666666666');
        
        this.show_dash = false;
        this.authenticationService.logout();
        console.log('dsasd');
        
        
      } else {
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error=>{
      this.ngxUiLoaderService.stop();
    })
  }
}
