import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import { Router, ActivatedRoute } from '@angular/router' 
import { MatSnackBar } from '@angular/material';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { fromEvent, Subscription } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { take, filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any

@Component({
  selector: 'app-restorefiles',
  templateUrl: './restorefiles.component.html',
  styleUrls: ['./restorefiles.component.scss']
})
export class RestorefilesComponent implements OnInit {

  response:any;
  id:any;
  overlayRef: OverlayRef | null;
  sub: Subscription;
  restoreLaterResponse: any;
  destructionReport: any;
  destructionReportFile: any;
  restoreFolders: any;
  restoreProject: any;
  deletedDataFolder: any;
  deleteDataProject: any;
  restoreFiles: any;
  limit=3;
  restorer: any;
  @ViewChild('userMenu', {static: false}) userMenu;
  dataTorestore: any;
  @ViewChild('closebutton2', {static: false}) closebutton2;
  restoreResponse: any;
  currentValue: any;
  bulkDeleteresponse: any;
  archievedResponse: any;
  archievedFolers: any;
  passwordOpenmodalForm: any;
  responsePassword: any;
  folderSubmitted: boolean;
  showActionbox: boolean;
  currentUserid: any;
  archievedUuid: any;
  showPasswordIcon = "fa-solid fa-eye-slash";
  showPassword = false;
  passwordFieldType: string = "password";
  discription: any;
  archievedDate: any;
  folderName: any;
  isButtonHiddenCancel : boolean = true;
  isButtonHiddenSubmitt : boolean = true;
  isButtonHidden: boolean = false;
  destruction: boolean;
  archived: boolean;
  noData: any;

  constructor(private api: ApiService,
              private route: ActivatedRoute,
              private ngxUiLoaderService: NgxUiLoaderService,
              public snackBar : MatSnackBar,
              public overlay: Overlay,
              public viewContainerRef: ViewContainerRef,
              private _location: Location,
              public formBuilder: FormBuilder,
              private router: Router,) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if(this.id = 'restoredeletedfiles'){
        console.log('poyoo');
        this.getArchievedFolders();
      }
    });
    
    
    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue);

    this.passwordOpenmodalForm = this.formBuilder.group({
      archived_id : ['',[Validators.required]],
      password:['',[Validators.required]],
      // comments:['',[Validators.required]]
    });
  }
  get a() {
    return this.passwordOpenmodalForm.controls;
  }

  // getRestoreLaterdata(){
  //   console.log('kerivaada makkale');
  //   this.api.getrestoreLater().subscribe(data=>{
  //     console.log(data);
  //     this.restoreLaterResponse = data;
  //     console.log(this.restoreLaterResponse);
  //     if(this.restoreLaterResponse.status == 200){
  //       this.restorer = this.restoreLaterResponse.results;
  //       console.log(this.restorer);
        
  //       this.restoreFolders = this.restoreLaterResponse.results.folder;
  //       this.restoreFiles = this.restoreLaterResponse.results.files;
  //       console.log(this.restoreFolders);
  //       this.restoreProject = this.restoreLaterResponse.results.project;
  //       console.log(this.restoreProject);
        
        
  //     }else{
  //       this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
  //         duration:3000,
  //       })
  //     }
      
      
  //   },
  //   error => {
  //     this.ngxUiLoaderService.stop();
  //   })
    
  // }

  getArchievedFolders(){
    console.log('hyoooo');
    this.ngxUiLoaderService.start();
    this.api.getArchievedFiles().subscribe(data=>{
      console.log(data);
      this.archievedResponse = data;
      if(this.archievedResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.archievedResponse);
        this.noData = this.archievedResponse.results;
        console.log(this.noData);
        
        this.archievedFolers = this.archievedResponse.results.files;
        console.log(this.archievedFolers);
        this.archived = true;
      }
      else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.archievedResponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    },
    error => {
      this.ngxUiLoaderService.stop();
    })
  }

  setShowPassword(id){
    if(id == "a") {
      this.showPassword = (this.showPassword)?false:true;
      if(this.showPassword){
        this.passwordFieldType= "text";
        this.showPasswordIcon = "fa-solid fa-eye";
      }else{
        this.passwordFieldType = "password";
        this.showPasswordIcon = "fa-solid fa-eye-slash";
      }
    }
  }

  getDestruction(){
    this.ngxUiLoaderService.start();
    this.api.getDestructionReports().subscribe(data=>{
      console.log(data,'qwerty');
      this.destructionReport = data;
      console.log(this.destructionReport);
      
      if(this.destructionReport.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.destructionReport);
        this.destructionReportFile = this.destructionReport.results;
        this.deletedDataFolder = this.destructionReport.results.folder;
        this.deleteDataProject = this.destructionReport.results.project;
        console.log(this.destructionReportFile);
        this.destruction = true;
      }else{
        console.log(this.destructionReportFile);
        this.ngxUiLoaderService.stop();
      }
      
    },
    error => {
      this.ngxUiLoaderService.stop();
    })
  }

  open(event: MouseEvent, user) {
    if((this.currentValue.permissions.restoreFile) || (this.currentValue.permissions.permenantDelete)){
      console.log('keriva');
      console.log(event);
    
    console.log(user)
    this.currentUserid = user.uuid;
    console.log(this.currentUserid);
    
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y  })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);
      console.log(positionStrategy);
      

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
    console.log(this.overlayRef);
    
    console.log(this.userMenu);
    console.log(this.viewContainerRef);
    
    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));
    console.log(this.overlayRef);
    

    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close());
    console.log(this.sub);
    }
    
    
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
  restoreFileorFolder(data){
    this.close();
    console.log('clicked1',data);
    this.dataTorestore = data;
    $('#deleteModal2').modal('show');
    this.closebutton2.nativeElement.click();
  }

    restoreFiles1(){
      this.closebutton2.nativeElement.click();
      let data = {file_id: this.dataTorestore}
      console.log(data,'111111111111111111');
      this.ngxUiLoaderService.start();
      this.api.restoreDeletedFilesorFolders(data).subscribe(data=>{
        console.log(data,'dssdsdsdsdsdsdsdsds');
        this.restoreResponse = data;
        if(this.restoreResponse.status){
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.restoreResponse.message, "Dismiss", {
            duration:3000,
          })
          // this.getRestoreLaterdata();
        }else{
          this.snackBar.open(this.restoreResponse.message, "Dismiss", {
            duration:3000,
          })
          this.ngxUiLoaderService.stop();
        }
        
      },error=> {
        this.ngxUiLoaderService.stop();
      });
    }

    deleteForever(data){
      this.close();
      this.dataTorestore = [data];
      console.log(data,'clicked'); 
      $('#deleteModal').modal('show');
      this.closebutton2.nativeElement.click();
  
    }

    restoreLater(){
      var data = {
        files: this.dataTorestore,
        entity_type: 2
      }
      console.log(data,"clicked");
      $('#deleteModal').modal('hide');
      this.ngxUiLoaderService.start();
      this.api.bulkDeletePermenently(data).subscribe(data=>{
      //  console.log(data);
      this.restoreLaterResponse = data;
      if(this.restoreLaterResponse.status == 200){
        this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
          duration:3000,
        })
        // this.getRestoreLaterdata();
        this.getDestruction();
        this.ngxUiLoaderService.stop();
      }else{
        this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
       
      },error=> {
        this.ngxUiLoaderService.stop();
      });
    }

    bulkdeletePermanentandLater(){
      var data = {
        files:this.dataTorestore,
        entity_type: 1
      }
      console.log(data);
      this.ngxUiLoaderService.start();
      this.api.bulkDeletePermenently(data).subscribe(data=>{
        console.log(data);
        this.bulkDeleteresponse = data;
        if(this.bulkDeleteresponse.status == 200){
          console.log(this.bulkDeleteresponse);
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.bulkDeleteresponse.message, "Dismiss", {
            duration:3000,
          })
          // this.getRestoreLaterdata();
          this.getDestruction();
          setTimeout(() => {
            $('#deleteModal').modal('hide');
          }, 1000); 
        }else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.bulkDeleteresponse.message, "Dismiss", {
            duration:3000,
          })
        }
      },error=> {
        this.ngxUiLoaderService.stop();
      });
    }

    downloadPath(e){
      window.open(e, '_blank');
      console.log('hhhhhhhhhhhhhhhhhhhhhhhhhh');
    }
    goBackbutton() {
      this.destruction = false;
      this._location.back();
    }

    openPasswordModal(files){
      console.log(files);
      this.archievedUuid = files.uuid;
      this.discription = files.comment;
      this.archievedDate = files.date;
      this.folderName = files.name;
      // this.passwordOpenmodalForm.get('comments').setValue(files.comment);
      this.passwordOpenmodalForm.get('archived_id').setValue(files.uuid);
      $('#ModalforArchieve_password').modal('show');

    }

    openAchievedList(){
      this.showActionbox = false;
      this.folderSubmitted = true;
      console.log(this.passwordOpenmodalForm.value);
      if(this.passwordOpenmodalForm.invalid){
        return
      }
      this.ngxUiLoaderService.start();
      let data = this.passwordOpenmodalForm.value;
      this.api.passwordModal(data).subscribe(data=>{
        console.log(data);
        this.responsePassword = data;
        if (this.responsePassword.status == 200) {
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.responsePassword.message, "Dismiss", {
            duration:3000,
          })
          // this.showActionbox = true;
          $('#ModalforArchieve_password').modal('hide');
            
          this.router.navigate(['/archieved-files', this.archievedUuid])
          this.folderSubmitted = false;
        }else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.responsePassword.message, "Dismiss", {
            duration:3000,
          })
          
        }
      },error=> {
        this.ngxUiLoaderService.stop();
      });
      
    }

  closemodal(){
    $('#ModalforArchieve_password').modal('hide');
    this.folderSubmitted = false;
    this.passwordOpenmodalForm.reset()
    this.isButtonHiddenCancel  = true;
    this.isButtonHiddenSubmitt  = true;
    this.isButtonHidden = false;
  }
  hideAndshow(){
    console.log('clicked');
    this.isButtonHiddenCancel  = false;
    this.isButtonHiddenSubmitt  = false;
    this.isButtonHidden = true;
  }
  cancelhidden(){
    $('#ModalforArchieve_password').modal('hide');
    this.folderSubmitted = false;
    this.passwordOpenmodalForm.reset()
  }
  destructionOpen(){
    this.id = null;
    this.router.navigate(['/restorefiles', 'deletepermanentfiles'])
    this.getDestruction();
  }
    
}
