
<nav>
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/logolg2.png" alt="">
        <ul>

                <li class="mr-4">Home</li>
                <li class="mr-4">Features</li>
                <li class="mr-4">About</li>
                <li class="mr-4">En</li>
                <li routerLink="/signup" class="mr-4">Sign Up</li>
                <li routerLink="/" class="mr-4">Login</li>
                
        </ul>
    </div>

</nav>

<div class="subscription_section mt-4 mb-5">
    <div class="">
        <div class="main">
            <div class="table-custom">
                <div class="table_head mb-5">
                    <div class="table_col col_1">
                        <img src="assets/images/Shape.png" alt="">
                        <div class="title_lg">30-day money-back<br>guarantee</div>
                    </div>
                    <div class="table_col col_1 transition active">
                        <div class="text-center">
                            <div class="title_lg">Trial Version</div>
                            <div class="price">
                                <h3>$0</h3>
                                <h6 >per month</h6>
                            </div>
                            <div class="button title_sm">7 days free trial</div>
                        </div>
                    </div>
                    <div class="table_col col_1 transition">
                        <div class="text-center">
                            <div class="title_lg">Induvidual</div>
                            <div class="price">
                                <h3>$19</h3>
                                <h6 >per month</h6>
                            </div>
                            <div (click)="choosePlan(1)" class="button title_sm">Choose plan</div>
                        </div>
                    </div>
                    <div class="table_col col_1 transition">
                        <div class="text-center">
                            <div class="title_lg">Business</div>
                            <div class="price">
                                <h3>$99</h3>
                                <h6 >per month</h6>
                            </div>
                            <div (click)="choosePlan(2)" class="button title_sm">Choose plan</div>
                        </div>
                    </div>
                </div>
                
                <div class="table_row">
                    <div class="table_col col_1">
                        <div class="title_sm">Feature title</div>
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                    <div class="table_col col_1">
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                </div>
                <div class="table_row">
                    <div class="table_col col_1">
                        <div class="title_sm">Feature title</div>
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                    <div class="table_col col_1">
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                </div>
                <div class="table_row">
                    <div class="table_col col_1">
                        <div class="title_sm">Feature title</div>
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                    <div class="table_col col_1">
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                </div>
                <div class="table_row">
                    <div class="table_col col_1">
                        <div class="title_sm">Feature title</div>
                    </div>
                    <div class="table_col col_1">
                        <img src="assets/images/Shape-2.png" alt="">
                    </div>
                    <div class="table_col col_1">
                    </div>
                    <div class="table_col col_1">
                    <img src="assets/images/Shape-2.png" alt="">
                    </div>
                </div>
            </div>





        </div>
    </div>
</div>
<app-footer></app-footer>