import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private dataSource = new Subject<boolean>();
  currentData = this.dataSource.asObservable();

  constructor() { }

  changeData(data: any) {
    console.log(data);
    
    this.dataSource.next(data);
  }
}