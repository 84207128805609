import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { ApiService } from '../../../_services/api.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute,Router,Params } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { error } from 'console';

@Component({
  selector: 'app-business-creation',
  templateUrl: './business-creation.component.html',
  styleUrls: ['./business-creation.component.scss']
})
export class BusinessCreationComponent implements OnInit {

  emplyeeForm : FormGroup;
  submitted=false;
  response:any;
  businesssData:any;
  constructor(public formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private router: Router,
              private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService) { }

  ngOnInit() {
    this.emplyeeForm = this.formBuilder.group({
      business_name : ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9,]+(\s{0,1}[a-zA-Z0-9,. ])*$")]],
      region : ['', Validators.required],
      no_of_employees : ['', Validators.required],
    })
    AOS.init({ disable: 'mobile'});
    this.emplyeeForm.controls.no_of_employees.setValue("1");
    this.getBusinessDetails();
  }


  onSubmit() {
    this.submitted = true;
    if (this.emplyeeForm.invalid) {
      return;
    }else {

      let data = this.emplyeeForm.value;
      data['stage_key'] = 100;

      this.ngxUiLoaderService.start();
      this.api.employeeCountForBusiness(data).subscribe(data => {

        this.ngxUiLoaderService.stop();
        this.response = data;

        if(this.response.status == "200") {
          this.router.navigate(['/contact-info']);
        }else {
          this.snackBar.open(this.response.message, "Alerte", {
            duration: 2000,
          });
        }

      },error => {
        this.ngxUiLoaderService.stop();
      })
    }
  }

  get emplyeeFormControl() {
    return this.emplyeeForm.controls;
  }


  getBusinessDetails() {
    this.api.getBusinessAccountDetails().subscribe(data =>{
      
      if(data.status == "200") {
        console.log('aaaa');
        this.businesssData = data.results;
        console.log(this.businesssData);

        if(this.businesssData.stage > "0") {
          this.emplyeeForm.controls.business_name.setValue(this.businesssData.business_name);
          this.emplyeeForm.controls.region.setValue(this.businesssData.region);
          this.emplyeeForm.controls.no_of_employees.setValue(this.businesssData.no_of_employees);
        }
      }
    })
  }
}
