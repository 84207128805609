import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef, NgZone  } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router, ActivatedRoute, Event, NavigationStart } from '@angular/router'
import { ApiService } from 'src/app/_services/api.services';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jQuery from 'jquery';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { error } from 'console';
import '../../../_models/tags-input'; 
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
declare const window: any;
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    "(window:click)": "dismissOpenItems()"
  }

})
export class SidebarComponent implements OnInit {

  public createNewfolderForm: FormGroup;
  url:any;
  login:any;
  response:any;
  showOptions = false;
  public tagInput2: any;
  folderSubmitted: boolean;
  @ViewChild('sideMenu', {static: false}) sideMenu: TemplateRef<any>;
  routeSub: any;
  projectId: any;
  createNewFolderResponse: any;
  responseuploadFile: any;
  currentValue: any;
  responseProfile: any;
  priorityResponse: any;
  priority: any;
  todayDate:Date = new Date();
  startDate: any;
  endDate: any;

  constructor(private ngZone:NgZone,
              private router: Router,
              private api : ApiService,
              private snackBar: MatSnackBar,
              private authenticationService: AuthenticationService,
              private ngxUiLoaderService: NgxUiLoaderService,
              public formBuilder: FormBuilder,
              private route: ActivatedRoute,
              
              ) 
              {
                this.login = localStorage.getItem('login');
                // this.login = 'business';
               }


  ngOnInit() {
    AOS.init({ disable: 'mobile'});
    this.getAccounts();
//     this.router.events.subscribe((event: Event) => {
//       if (event instanceof NavigationStart) {
//           const data  = event.url;
//           let newStr = data.slice(1);
//           this.ngZone.run(() => {
//             this.url = newStr;
//             console.log(this.url)
//           });
//       }
//  });

    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)
    
    this.createNewfolderForm = this.formBuilder.group({
      folder_name : ['',[Validators.required]],
      tag:['',[Validators.required]],
      start_date:['',[Validators.required]],
      end_date:['',[Validators.required]]
    });

    this.ngZone.run(()=> {
      this.url = window.location.pathname.split('/')[1];
      console.log(this.url)
    });

    this.tagInput2 = new TagsInput({
      selector: 'tag-input2',
      duplicate: false,
      max: 10,
      icon:"assets/images/blue_cross.png"
    });
    this.tagInput2.addData([]);
    

    

    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) 
      this.projectId = params['id'];
      console.log(this.projectId) 
    });

    this.startDate = new Date();
    console.log("this.startDate", this.startDate)
    //this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.startDate = moment(this.startDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.startDate", this.startDate)

   
    let currentDate = new Date();

    this.endDate = new Date(currentDate);
    this.endDate.setFullYear(currentDate.getFullYear() + 1);
     console.log(this.endDate)

    // this.endDate =  new Date(currentDate.getTime() + (24 * 60 * 60 * 1000));
    // this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.endDate = moment(this.endDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.endDate", this.endDate)



  }
  get a() {
    return this.createNewfolderForm.controls;
  }

  updateEndDate() {
    if (new Date(this.a.end_date.value) < new Date(this.a.start_date.value)) {
      this.createNewfolderForm.get('end_date').setValue(this.a.start_date.value);
    }
  }

  ngOnDestroy() {
    if (this.tagInput2) {
      this.tagInput2.destroy();
    }
  }

  toggleClass(a:any,b:any){
    console.log(a,b)
  }

  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }

  // showUpload() {
  //     $('.uploadOptionsDrop').addClass('drop')
  // }
  // fileUpload() {
  //   console.log('HI');
  //   $('#fileupload').trigger('click');
  // }

  logOut() {
    this.ngxUiLoaderService.start();
    var data = {

    }
    this.api.logOut(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.response = data;
      if(this.response.status == "200"){
        $('#logoutModal').modal('hide')
        this.authenticationService.logout();
      } else {
        this.snackBar.open(this.response.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error=>{
      this.ngxUiLoaderService.stop();
    })

    
  }

  uploadFiles(files: any) {
    const arrFiles = Array.from(files);
    // const folderHolder = {};
    // arrFiles.forEach((file) => {
    //   const path: string = file['webkitRelativePath'];
    //   const pathPieces = path.split('/');
    //   pathPieces.pop();
    //   console.log(path, pathPieces.join('/'));
    //   if (!folderHolder[pathPieces.join('/')]) {
    //     // create folder and add to folderHolder
    //     this.uploadFileService
    //       .createFolder(pathPieces.join('/'))
    //       .subscribe(
    //         (folderId) => (folderHolder[pathPieces.join('/')] = folderId)
    //       );
    //   }
    // });

    // //
    // setTimeout(() => {
    //   console.log(folderHolder);
    // }, 100);

    console.log(arrFiles, '444');
  }


  // upload(files: File[]){
  // this.uploadAndProgress(files);
  // }


  uploadAndProgress(files: File[]){
    console.log(files)
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file',f))
    
    // this.http.post('https://file.io', formData, {reportProgress: true, observe: 'events'})
    //   .subscribe(event => {
    //     if (event.type === HttpEventType.UploadProgress) {
    //       this.percentDone = Math.round(100 * event.loaded / event.total);
    //     } else if (event instanceof HttpResponse) {
    //       this.uploadSuccess = true;
    //     }
    // });
  }


  showUpload(event: MouseEvent):void {
    console.log(event);
    
    event.stopPropagation(); 
    console.log(this.showOptions,"line 135")
    if (this.showOptions == true) {
      this.showOptions = false;
    }else {
      this.showOptions = true;







      
      console.log('dsfdsfdsfsdf');
      
    }
   // Drop down propagation
  }


  dismissOpenItems(){
    if (this.showOptions == true) {
      this.showOptions = false;
    }
  }


  closseModal(){
    $('#folderCreateModal').modal('hide')
    
    const tagsWrapper = document.querySelector('.tags-input-wrapper');
      if (tagsWrapper) {
        const spanTags = tagsWrapper.querySelectorAll('span');
        const inputElement = tagsWrapper.querySelector('input');
        if (inputElement) {
          inputElement.value = '';
        }
        spanTags.forEach(span => {
            span.parentNode.removeChild(span);
        });
      }
    this.folderSubmitted = false
    this.createNewfolderForm.reset();
  }


  resetFormAndModal() {
    this.createNewfolderForm.reset();
  
    this.folderSubmitted = false;
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  createNewfolder() {
    console.log('clicked',this.createNewfolderForm.value);
    let value = this.createNewfolderForm.value
    console.log(this.createNewfolderForm.value.start_date)
    console.log(this.createNewfolderForm.value.end_date)
    console.log(moment(this.createNewfolderForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.createNewfolderForm.value.end_date))
    if (moment(this.createNewfolderForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.createNewfolderForm.value.end_date);
    } 
    if (moment(this.createNewfolderForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.createNewfolderForm.value.start_date);
    } 

    console.log(value);
    
    this.folderSubmitted = true;
  
    if (this.createNewfolderForm.invalid) {
      return;
    }
  
    let data = {
      ...this.createNewfolderForm.value,
      project_id: this.projectId
    };
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.createNewFolder(data).subscribe(data => {
      console.log(data);
      this.createNewFolderResponse = data;
      if (this.createNewFolderResponse.status == 200) {
        this.api.setData("Data from Component A");
        this.createNewfolderForm.reset();
        this.ngxUiLoaderService.stop();
        this.folderSubmitted = false;
        setTimeout(() => {
          $('#folderCreateModal').modal('hide');
        }, 50);
        this.snackBar.open(this.createNewFolderResponse.message, "Dismiss", {
          duration: 3000,
        });
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.createNewFolderResponse.message, "Dismiss", {
          duration: 3000,
        });
      }
    });
  } 

  uploadFolder(e){
    console.log(e);
    
  }

  // upload(event: any): void {
  //   console.log('entered');

  //   console.log("2250", event);
  //   console.log("2251", event.target.files);

  //   if (event.target.files.length > 0) {
  //     this.ngxUiLoaderService.start();

  //     const files: FileList = event.target.files;
  //     console.log(files);

  //     const formData = new FormData();
  //     const target = event.target.dataset.target;
  //     console.log(target);
      
  //     formData.append('target', target);
  //     formData.append('project_id', this.projectId);

  //     for (let i = 0; i < files.length; i++) {
  //       const file: File = files[i];
  //       console.log('uploaded ', file);
  //       // const filename = file.name;
  //       // console.log(filename);
  //       formData.append('file', file);
  //       console.log(formData);

  //     }

  //     console.log(formData);

  //     this.api.sidebarfileUpload(formData).subscribe(data => {
  //       console.log(data, '0000000000000000000000');

  //       this.responseuploadFile = data;
  //       console.log(this.responseuploadFile, '12112');
  //       if (this.responseuploadFile.status == 200) {
  //         this.ngxUiLoaderService.stop();
  //         this.snackBar.open(this.responseuploadFile.message, "Dismiss", {
  //           duration: 3000,
  //         });
  //       } else {
  //         this.ngxUiLoaderService.stop();
  //         this.snackBar.open(this.responseuploadFile.message, "Dismiss", {
  //           duration: 3000,
  //         });
  //       }
  //     }, error => {
  //       this.ngxUiLoaderService.stop();
  //     });
  //   }
  // }

  logoutModal(){
    $('#logoutModal').modal('show');
  }
  closelogoutModal(){
    $('#logoutModal').modal('hide');
  }

  getAccounts() {
    this.api.getAccounts().subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.responseProfile = data;
      if(this.responseProfile.status == "200") {
        localStorage.setItem('currentUserDetails', JSON.stringify(this.responseProfile.results));
        this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
        console.log(this.currentValue);
        
      }
  
    },error => {
      this.ngxUiLoaderService.stop();
    });
  }

  createFolder() {
    this.createNewfolderForm.get('tag').setValue('1');
    this.createNewfolderForm.get('start_date').setValue(this.startDate);
    this.createNewfolderForm.get('end_date').setValue(this.endDate);
    $('#folderCreateModal').modal('show');
    this.getPriority();
    console.log(this.createNewfolderForm)
  }
  getPriority(){
    this.ngxUiLoaderService.start();
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

}
