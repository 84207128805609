import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'inFlow_angular';
  constructor(private cdref: ChangeDetectorRef ) { }

  ngOnInit() {
    AOS.init({ disable: 'mobile'});
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }



// Commmon Functions
  

 



// Commmon Functions End
  
}
