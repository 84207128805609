<app-sidebar></app-sidebar>
<app-header></app-header>
<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">
                
                <!-- <div class="heading_1 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <img class="mr-12" src="assets/images/fav.svg" alt="">
                        <h4>Favorites</h4>
                    </div>
                    <div class="d-flex align-items-center">

                        <img class="mr-12" id="lineView" src="assets/images/3line.png"  alt="">
                        <img id="gridViewBtn" src="assets/images/grid.svg" alt="">

                    </div>
                </div> -->

                <div class="catalogueFunction mb-4">
                   
                    <div class="toggle_screen">
                        <div class="one tabOption" (click)="clickTab('user')">
                            <h5 [ngClass]="{'color_blue': userSection == true}">{{'Users' | translate}}</h5>
                            <div [ngClass]="{'lineExtent': userSection == true, 'line': userSection == false}" ></div>
                        </div>
                        <div class="two tabOption" (click)="clickTab('group')">
                            <h5 [ngClass]="{'color_blue': groupSection == true}">{{'Groups' | translate}}</h5>
                            <div [ngClass]="{'lineExtent': groupSection == true, 'line': groupSection == false}" ></div>
                        </div>
                    </div>

                    <div class="add_functions">
                        <button *ngIf="userSection && (currentValue && (currentValue.permissions.addUsers))" class="btn_primary bg_blue" [ngClass]="{'removeItem': showSideBar == true}" (click)="openAdduserModal()">{{'Add User' | translate}}</button>
                        <button *ngIf="groupSection && (currentValue && (currentValue.permissions.createGroups))" class="btn_primary bg_blue" [ngClass]="{'removeItem': showSideBar == true}" (click)=" createProjectModal()">{{'Create Group' | translate}}</button>
                    </div>
                </div>



                <div *ngIf="groupSection" class="catalogueGroup" id="tab-2">
                    <div class="wrapper">
                        <div class="Rtable Rtable--5cols Rtable--collapse" id="">
                               <div class="Rtable-row Rtable-row--head">
                                <div class="Rtable-cell name-cell column-heading">{{'Name' | translate}} <i class="fa-solid fa-arrow-up"></i></div>
                                <div class="Rtable-cell email-cell column-heading">{{'Members' | translate}}</div>
                                <div class="Rtable-cell phone-cell column-heading removeItem2" [ngClass]="{'removeItem': showSideBar == true}">{{'Last Modified' | translate}}</div>
                                <div class="Rtable-cell role-cell column-heading" [ngClass]="{'removeItem': showSideBar == true}">{{'Shared with' | translate}}</div>
                                <div class="Rtable-cell action-cell column-heading">{{'Action' | translate}}</div>
                            </div>

                            <div class="cursor_pointer contents w-100">
                                <div *ngFor="let item of grouplist  index as i">
                                    <div class="Rtable-row is-striped" [ngClass]="{'activeColor': teamsMembers == true}">
                                        <div class="Rtable-cell name-cell">
                                            <div class="Rtable-cell--content name-content fw-400">
                                                <!-- <img class="mr-12" src="" alt="{{getShortName(item.group_name)}}"> -->
                                                <div class="s-name mr-12" [style.background-color]="colors[i]">
                                                    {{getShortName(item.group_name)}}
                                                </div>
                                                <div class="groupName">
                                                    {{ item .group_name }} 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="Rtable-cell email-cell">
                                            <div class="Rtable-cell--heading">Members</div>
                                            <div class="Rtable-cell--content replay-link-content"> {{item.members}}</div>
                                        </div>
                                        <div class="Rtable-cell phone-cell removeItem2" [ngClass]="{'removeItem': showSideBar == true}">
                                            <div class="Rtable-cell--heading">Last Modified</div>
                                            <div class="Rtable-cell--content date-content"><span class=""> {{item.last_modified}}</span></div>
                                        </div>
                                        <div class="Rtable-cell role-cell" [ngClass]="{'removeItem': showSideBar == true}">
                                            <div class="Rtable-cell--heading">{{'Shared with' | translate}}</div>
                                            <div class="Rtable-cell--content date-content  groupmemberPic">
                                                <ul class="p-0">
                                                    <li *ngFor="let members of item.Members" class="d-inline"><img src="{{members.profile_pic}}" alt=""></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="Rtable-cell action-cell">
                                            <div class="Rtable-cell--heading">{{'Action' | translate}}</div>
                                            <div class="Rtable-cell--content access-link-content widthSide">
                                                <div class="d-flex align-items-center">
                                                    <img *ngIf="(currentValue && (currentValue.permissions.deleteGroups))" (click)="deleteGroupList(item.uuid)" class="mr-12 delete-icon" src="assets/images/delete.png" alt="">
                                                    <img class="mr-12 share-icon" src="assets/images/pencil.png" alt="" (click)="groupEditfunctions(item)" *ngIf="groupSection && (currentValue && (currentValue.permissions.createGroups))">
                                                   <img class="mr-12" [ngClass]="{'rotateClass': showInnerDiv[i] == true, 'removeRotateClass': showInnerDiv[i] == false}" src="assets/images/arrow_grey.png" (click)="toggleInnerDiv(i)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="teamMembers" *ngIf="showInnerDiv[i]">
                                        <div class="Rtable Rtable--5cols Rtable--collapse" id=""  class="subSection" [ngClass]="{'activeColor': teamsMembers == true}" *ngFor="let detail of item.groupMembers">
                                            <div class="cursor_pointer contents w-100" >
                                                <div class="Rtable-row is-striped" >
                                                    <div class="Rtable-cell name-cell userPropic">
                                                        <div class="Rtable-cell--heading">Name</div>
                                                        <div class="Rtable-cell--content name-content"><img src="{{detail.profile_pic}}" alt=""><span>{{detail.name}}</span></div>
                                                    </div>
                                                    <div class="Rtable-cell email-cell"  [ngClass]="{'removeItem': showSideBar == true}">
                                                        <div class="Rtable-cell--heading">{{'Mail ID' | translate}}</div>
                                                        <div class="Rtable-cell--content replay-link-content userEmail">{{detail.email}}</div>
                                                    </div>
                                                    <div class="Rtable-cell phone-cell"  [ngClass]="{'removeItem': showSideBar == true}">
                                                        <div class="Rtable-cell--heading">Mobile number</div>
                                                        <div class="Rtable-cell--content date-content"><span class="">{{detail.mobile}}</span></div>
                                                    </div>
                                                    <div class="Rtable-cell role-cell">
                                                        <div class="Rtable-cell--heading">{{'Roles' | translate}}</div>
                                                        <div class="Rtable-cell--content date-content"><span class="">{{detail.designation}}</span></div>
                                                    </div>
                                                    <div class="Rtable-cell action-cell">
                                                        <div class="Rtable-cell--heading rac">{{'Action' | translate}}</div>
                                                        <!-- <div class="Rtable-cell--content access-link-content">
                                                            <div class="d-flex align-items-center">
                                                                <img class="mr-12 delete-icon" src="assets/images/delete.png" alt="">
                                                                <img class="mr-12 share-icon" src="assets/images/pencil.png" alt="">
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div> 
                            </div>
                        </div>
                        <div *ngIf="grouplist && grouplist.length == 0" class="text-center userNotFound">
                            <img src="assets/images/nouser.jpg" class="img-fluid mb-2">
                            <p>{{'No Groups Found' | translate}}</p>
                        </div>
                    </div>
                </div>


                <div *ngIf="userSection" class="catalogueUser" id="tab-1">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--5cols Rtable--collapse"  id="table-main">
                            <div class="Rtable-row Rtable-row--head">
                                <div class="Rtable-cell name-cell column-heading">{{'Name' | translate}} <i class="fa-solid fa-arrow-up"></i></div>
                                <div class="Rtable-cell email-cell column-heading removeItem2" [ngClass]="{'removeItem': showSideBar == true}">{{'Mail ID' | translate}}</div>
                                <div class="Rtable-cell phone-cell column-heading removeItem3" [ngClass]="{'removeItem': showSideBar == true}">{{'Mobile number' | translate}}</div>
                                <div class="Rtable-cell role-cell column-heading">{{'Roles' | translate}}</div>
                                <div class="Rtable-cell action-cell column-heading">{{'Action' | translate}}</div>
                            </div>
                
                            <div class="Rtable-row is-striped" *ngFor="let item of userlist">
                                <div class="Rtable-cell name-cell">
                                    <div class="Rtable-cell--heading">Name</div>
                                    <div class="Rtable-cell--content name-content nameUser"><img class="userImageListing"  src="{{item.profile_pic}}" alt=""><span class="profileName">
                                        {{item.name}}</span></div>
                                </div>
                                <div class="Rtable-cell email-cell removeItem2" [ngClass]="{'removeItem': showSideBar == true}">
                                    <div class="Rtable-cell--heading" [ngClass]="{'removeItem': showSideBar == true}">Mail ID</div>
                                    <div class="Rtable-cell--content replay-link-content" >{{item.email}}</div>
                                </div>
                                <div class="Rtable-cell phone-cell removeItem3" [ngClass]="{'removeItem': showSideBar == true}" >
                                    <div class="Rtable-cell--heading">Mobile number</div>
                                    <div class="Rtable-cell--content date-content removeItem3"><span class="">{{item.mobile}}</span></div>
                                </div>
                                <div class="Rtable-cell role-cell">
                                    <div class="Rtable-cell--heading">Roles</div>
                                    <div class="Rtable-cell--content date-content"  id="roleId"><span class="">{{item.designation}}</span></div>
                                </div>
                                <div class="Rtable-cell action-cell">
                                    <div class="Rtable-cell--heading">{{'Action' | translate}}</div>
                                    <div class="Rtable-cell--content access-link-content">
                                        <div class="d-flex align-items-center widthSide">
                                            <img *ngIf="currentValue && (currentValue.permissions.deleteUsers)" class="mr-12 delete-icon" src="assets/images/delete.png" alt="" (click)="deleteUserList(item.uuid)">
                                            <img class="mr-12 share-icon" src="assets/images/pencil.png" alt="" (click)="editUser(item)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="userlist && userlist.length == 0" class="text-center userNotFound">
                        <img src="assets/images/nouser.jpg" class="img-fluid mb-2">
                        <p>{{'No Users Found' | translate}}</p>
                    </div>
                </div>
            </div>
            <app-file-preview></app-file-preview>
            <!-- <aside class="sidebar-second position-relative" [ngClass]="{'width-side-second': showSideBar == true}" id="storageDetils">
                <div class="heading_1">
                    <h4 class="text-center">{{'Cloud Storage' | translate}}</h4>
                </div>
                <img class="close_storage" *ngIf="showSideBar" (click)="getInfo('1')" src="assets/images/arrow_right.png" alt="">
                <img *ngIf="!showSideBar" class="close_storage2" (click)="getInfo('2')" src="assets/images/angle_left.png" alt="">
                <div class="d-flex align-items-center flex-column storage">
                    <img class="img-fluid" src="assets/images/storage.png" alt="">
                    <div class="text_sm mt-3">
                        13.6 GB of 50 GB {{'used' | translate}}
                    </div>
                </div>

                <div class="storage p-0 mt-4">
                    <div class="card1">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/doc.png" alt="">
                                <div>
                                    <div class="subText">{{'Document' | translate}}</div>
                                    <div class="smText">112 {{'Files' | translate}}</div>
                                </div>
                            </div>
                            <div class="text_sm">2.2 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/image.png" alt="">
                                <div>
                                    <div class="subText">{{'Photos' | translate}}</div>
                                    <div class="smText">112 {{'Files' | translate}}</div>
                                </div>
                            </div>
                            <div class="text_sm">1.3 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/video.png" alt="">
                                <div>
                                    <div class="subText">{{'Videos' | translate}}</div>
                                    <div class="smText">112 {{'Files' | translate}}</div>
                                </div>
                            </div>
                            <div class="text_sm">4 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/music.png" alt="">
                                <div>
                                    <div class="subText">{{'Audios' | translate}}</div>
                                    <div class="smText">112 {{'Files' | translate}}</div>
                                </div>
                            </div>
                            <div class="text_sm">3.3 GB</div>
                        </div>
                    </div>
                    <div class="card1">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/download.png" alt="">
                                <div>
                                    <div class="subText">{{'Downloads' | translate}}</div>
                                    <div class="smText">112 {{'Files' | translate}}</div>
                                </div>
                            </div>
                            <div class="text_sm">2.8 GB</div>
                        </div>
                    </div>
                </div>
            </aside> -->
        </div>
       
        

        <div class="modal fade" id="addUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>{{'Add New User' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closseModal()"></button>
                </div>
                <div class="modal-body">
                    <form  [formGroup]="addUserForm" >
                    <div class="input-container">
                        <div class="input">
                            <label class="label-test" for="name">{{'First Name' | translate}}</label>
                            <input type="text" value="" formControlName="first_name" maxlength="16" name="user_name" placeholder="{{'First Name' | translate}}">
                            <div *ngIf="usersubmitted && b.first_name.errors">
                                <div class="error_message text-danger" *ngIf="b.first_name.errors.required">{{'First Name' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message text-danger" *ngIf="b.first_name.errors.pattern">{{'First Name' | translate}} {{'should valid' | translate}}</div>
                            </div>
                            
                        </div>
                        <div class="input">
                            <label class="label-test" for="name">{{'Last Name' | translate}}</label>
                            <input type="text" value="" formControlName="last_name" maxlength="16" placeholder="{{'Last Name' | translate}}">
                            <div *ngIf="usersubmitted && b.last_name.errors">
                                <div class="error_message text-danger" *ngIf="b.last_name.errors.required">{{'Last Name' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message text-danger" *ngIf="b.last_name.errors.pattern">{{'Last Name' | translate}} {{'should valid' | translate}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input" id="desig_id">
                            <label class="label-test" for="name">{{'Job Position' | translate}}</label>
                            <!-- <input type="text" value="" formControlName="designation"> -->
                            <select formControlName="designation">
                                <option disabled hidden value="" selected>{{'Enter Job Position' | translate}}</option>
                                <option *ngFor="let department of designationlist" value="{{department.id}}">{{department.name}}</option>
                            </select>
                            <div *ngIf="usersubmitted && b.designation.errors">
                                <div class="error_message text-danger" *ngIf="b.designation.errors.required">{{'Job Position' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message text-danger" *ngIf="b.designation.errors.pattern">{{'Job Position' | translate}} {{'should valid' | translate}}</div>
                            </div>
                        </div>
                        
                        <div class="input mt-13">
                            <label for="" class="label-test">{{'Date of birth' | translate}}</label>
                            <div class="input-group d-flex" [owlDateTimeTrigger]="dt30">
                            <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                            </div>
                            <input [owlDateTime]="dt30" class="form-control" [min]="min" formControlName="date_of_birth" readonly placeholder="{{'Date of Birth' | translate}}" [max]="maxDate">
                            </div>
                            <owl-date-time [pickerType]="'calendar'" #dt30></owl-date-time>




                            <!-- <label class="label-test" for="name">{{'Date of Birth' | translate}}</label>
                            <mat-form-field class="profile_detail " appearance="none"> 
                                <input matInput [matDatepicker]="dp1" formControlName="date_of_birth" readonly  [max]="maxDate" [min]="minDate"  placeholder="{{'Date of Birth' | translate}}">
                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1></mat-datepicker>
                            </mat-form-field> -->
                            <div *ngIf="usersubmitted && b.date_of_birth.errors">
                                <div class="error_message text-danger" *ngIf="b.date_of_birth.errors.required">{{'Date of Birth' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message text-danger" *ngIf="b.date_of_birth.errors.pattern">{{'Date of Birth' | translate}} {{'should valid' | translate}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input">
                            <label class="label-test" for="name">{{'Email Address' | translate}}</label>
                            <input type="text" value="" formControlName="email" [readonly]="readonlyMode" placeholder="{{'Email Address' | translate}}"> 
                            <div *ngIf="usersubmitted && b.email.errors">
                                <div class="error_message text-danger" *ngIf="b.email.errors.required">{{'Email Address' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message text-danger" *ngIf="b.email.errors.pattern">{{'Email Address' | translate}} {{'should valid' | translate}}</div>
                            </div>
                        </div>
                        <div class="input">
                            <label class="label-test" for="phone">{{'Mobile Number' | translate}}</label>
                            <input  name="phone" type="text" id="phone" value="" formControlName="mobile" maxlength="12" minlength="8" [readonly]="readonlyMode" placeholder="{{'Mobile Number' | translate}}">
                            <div *ngIf="usersubmitted && b.mobile.errors">
                                <div class="error_message text-danger" *ngIf="b.mobile.errors.required">{{'Mobile Number' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message text-danger" *ngIf="b.mobile.errors.pattern || b.mobile.errors.minlength">{{'Mobile Number' | translate}} {{'should valid' | translate}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="uploadInputfield">
                            <label for="user_photo" id="labeltext1" class="labeltxt">{{'Upload User Photo' | translate}}</label>
                            <label for="user_photo" class="file-upload-card labeltxt">
                                <div class="custom-container UploadIcon"> 
                                  <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                                  <input class="uploadOrdrop" id="user_photo" type="file" formControlName="imageFile" (change)="upload($event, 'user_photo')" data-target="user_photo">
                                  <i class="fa fa-upload " aria-hidden="true"></i>
                                </div>
                            </label>
                            <a *ngIf="user_Photo" id="user_photo" href="{{user_Photo}}" target="_blank" class="viewFile  text-primary">{{'Click to view File' | translate}}</a>
                            <div *ngIf="usersubmitted && b.user_photo.errors">
                                <div class="error_message text-danger text-center" *ngIf="b.user_photo.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                                <div class="error_message text-danger text-center" *ngIf="b.user_photo.errors.required">{{'Upload Employee Photo cannot be blank'|translate}}</div>
                            </div>
                        </div>
                    </div>



                   
                    <!-- <div class="child-container">
                        <label for="employee_Photo" class="labeltxt">{{'Upload Employee Photo' | translate}}</label>
                        <label for="employee_Photo" class="file-upload-card labeltxt">
                          <div class="custom-container UploadIcon"> 
                            <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                            <input class="uploadOrdrop" id="employee_Photo" type="file" formControlName="imageFile" (change)="upload($event, 'employeePhoto')" data-target="employee_Photo">
                            <i class="fa fa-upload " aria-hidden="true"></i>
                           
                          </div>
                        </label>
                        
                        <a id="employeePhoto" href="{{employee_Photo}}" target="_blank" class="viewFile text-center"></a>
                        <div *ngIf="isSubmit && g.employee_Photo.errors" class="invalid-feedback text-center m-0">
                          <div *ngIf="g.employee_Photo.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                          <div *ngIf="g.employee_Photo.errors.required">{{'Upload Employee Photo' | translate}} {{'cannot be blank'|translate}}</div>
                        </div>
                    </div> -->
                   
                    
                    
                    <button type="submit" class="btn_primary bg_blue" (click)="onSubmitnewUser()">{{ buttonText| translate}}</button>
                </form>
                    <!-- data-toggle="modal" data-bs-toggle="modal" data-bs-target="#inviteSuccessModal"                  -->
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Understood</button>
                </div> -->
              </div>
            </div>
          </div>


          <div class="modal fade" id="inviteSuccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <!-- <h5>Add New User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <img src="assets/images/success_pic1.png" alt="assets/images/success_pic1.png">
                    <div class="text">{{'Your invite for' | translate}} <span>{{ b.email.value }}</span> <br>{{'has been sent successfully' | translate}}</div>
                    <button class="btn_primary bg_blue" data-bs-dismiss="modal" aria-label="Close" (click)="closemodal()">{{'Go Home' | translate}}</button>
                </div>
                
              </div>
            </div>
          </div>

          <div class="modal" id="create-group" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="create-groupLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>{{'Create Group' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closseModal()"></button>
                </div>
                <div class="modal-body">

                    <div class="container">
                        <div class="main">
                            <div class="row mt-3">
                                <div class="col-md-12 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">{{'First Name' | translate}}</label>
                                        <input type="text" value="John">
                                    </div>
                                </div>
            
                                <div class="col-md-12 col-12">
                                    <div class="input">
                                        <label class="label-test" for="name">{{'Last  Name' | translate}}</label>
                                        <input type="text" value="Luther">
                                    </div>
                                </div>
                            </div>
                            <div class="row">


                                <div class="col-md-12">
                                    <div class="addMember">

                                        <div class="dropdown show">
                                            {{'Add Member' | translate}}s <i class="fa-solid fa-angle-down"></i>
                                          </div>

                                          <div class="d-flex align-items-center">
                                            <ul class="p-0">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                                <li class="d-inline"><img src="assets/images/sharePic.png" alt="">
                                            </ul>
                                        </div>

                                    </div>


                                    <button data-bs-dismiss="modal" aria-label="Close" id="business-info-change" class="btn-primary">{{'Create Group' | translate}}</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                
              </div>
            </div>
          </div>

          
          <div class="modal fade" id="createProjectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalToggleLabel">{{'Add Group' | translate}}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeGroupmodal()"></button>
                </div>
                
                <form  [formGroup]="addGroupForm" >
                <div class="modal-body">
                    <div class="gro_name-container">
                        <div class="title mt-3">{{'Group Name' | translate}}</div>
                        <input type="text" formControlName="group_name" maxlength="32">
                    </div>
                    <div *ngIf = "groupSubmitted && a.group_name.errors">
                        <div class="error_message text-center" *ngIf="a.group_name.errors.required">{{'Group Name is required' | translate}}</div>
                        <!-- <div class="error_message" *ngIf="a.groupName.errors.pattern">{{'Email should valid' | translate}}</div> -->
                    </div>
                  
                    <div class="input_style">
                        <label for="exampleInputEmail1" class="title mt-4 mb-2">{{'Priority' | translate}}</label>
                            
                        <div class="select-createGroup">
                            <mat-form-field class="custom-form-field2" appearance="none">
                                <mat-select placeholder="{{'Select Priority' | translate}}"   formControlName="tag">
                                    <mat-option value="1"  >{{ 'High'| translate }}</mat-option>
                                    <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                    <mat-option value="3">{{'Low' | translate}}</mat-option>
                                    <mat-option value="{{item.id}}"  *ngFor="let item of priority">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf = "groupSubmitted && a.tag.errors">
                            <div class="error_message " *ngIf="a.tag.errors.required"> {{'Priority' | translate}} {{'is required' | translate}}</div>
                            <!-- <div class="error_message" *ngIf="a.groupName.errors.pattern">{{'Email should valid' | translate}}</div> -->
                        </div>

                        <!-- <div class="tagArea" aria-placeholder="Add Templates">
                            <input type="text" id="tag-input1" placeholder="AddTemplates" #tagInput>
                        </div>
                        <div *ngIf = "groupSubmitted && array == 0">
                            <div class="error_message" >{{'Project Tags is required' | translate}}</div>
                            
                        </div> -->
                        <!-- <div class="error_message" *ngIf="groupSubmitted && tagInput.arr.length == 0">
                            {{ 'Project Tags is required' | translate }}
                        </div> -->
                    </div>
              
                    <div class="title mt-4 mb-2">{{'Group Members' | translate}}</div>
                    <div class="membersList">
                        <div class="members_container">
                            <div class="members" *ngFor="let member of grouMembers; let i = index">
                                <div><img class="userImageadd" [src]="member.profile_pic" alt="">{{member.name}}</div>
                                <img class="closeIconuser" src="assets/images/close_sm.png" alt="" (click)="removeUser(i, member.uuid, member)">
                            </div>
                        </div>
                    </div>
                    <div *ngIf = "groupSubmitted && grouMembers.length == 0">
                        <div class="error_message" >{{'Group Members is required' | translate}}</div>
                        <!-- <div class="error_message" *ngIf="a.groupName.errors.pattern">{{'Email should valid' | translate}}</div> -->
                    </div>
               
                    <div class="title mt-4 mb-2">{{'Add Users' | translate}}</div>
                    <div class="addUserList">
                        <div class="groups">
                            <div class="list"  *ngFor="let item of activeUserlist">
                                <div class="logoArea">
                                    <img class="userImageListing" src="{{item.profile_pic}}" alt="" >
                                    <div>{{item.name}} <span>{{item.email}}</span></div>
                                </div>
                                <button class="btn_primary bg_blue" type="button" (click)="adduser(item)">{{'Add' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- data-bs-toggle="modal" href="" -->
                    <button class="btn_primary bg_blue" type="button"  (click)="ngSubmitcreateGroup()">{{buttonText1 | translate}}</button>
                </div>
                </form>
              </div>
            </div>
          </div>
    
    </div>

    <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Add New Project' | translate}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="projectNameAdd">
                <div class="modal-body">
                    <div class="mt-5">
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Datas">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                    <button id="folderCreate" (click)="folderCreate()" class="btn-primary btn-style" >{{'Create' | translate}}</button>
                </div>
            </div>

            <div id="folderName_And_Tagsection">
                <div class="modal-body">
                    <div class="mt-2">
                        <label for="">{{'Folder Name' | translate}}</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Offer Letter">
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="">{{'Priority' | translate}}</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Priority" name="" id="">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                    <button id="folderCreate" class="btn-primary btn-style"  data-bs-dismiss="modal" aria-label="Close">{{'Create' | translate}}</button>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="deleteModal4" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Delete' | translate}} ?</h5>
              <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="text-center">{{'Do you want to delete' | translate}}</div>
            </div>
            <div id="delete_id" class="btnGrp mt-4">
              <button class="btn_primary bg_blue" id="button-id" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
              <button id="button-id" class="btn_primary bg_red"  (click)="deleteGroup()">{{'Delete' | translate}}</button>
            </div>      
          </div>
        </div>
      </div>


      <div class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Delete' | translate}} ?</h5>
              <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="text-center">{{'Do you want to delete' | translate}}</div>
            </div>
            <div id="delete_id" class="btnGrp mt-4">
              <button class="btn_primary bg_blue" id="button-id" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
              <button id="button-id" class="btn_primary bg_red"  (click)="deleteUser()">{{'Delete' | translate}}</button>
            </div>      
          </div>
        </div>
      </div>
</main>
                              
