import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute,Router,Params } from '@angular/router';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  response:any;
  constructor(private api : ApiService,
              private snackBar: MatSnackBar,
              private router: Router,
              private ngxUiLoaderService: NgxUiLoaderService) 
              { }

  ngOnInit() {
    $('.transition').hover(
      function(){ 
        $(".transition.active").addClass('inactive').removeClass('active');
      },
      function(){ 
        $(".transition.inactive").addClass('active').removeClass('inactive'); 
      }
    );
  }


  choosePlan(id) {
    this.ngxUiLoaderService.start();
    console.log(id)
    let data = {
      plan_id : id
    }
    this.api.choosePlan(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      console.log(data)
      this.response = data;
      if(this.response.status == '200') {
        if(id == "1") {
          this.router.navigate(['/dashboard']);
        }else {
          this.router.navigate(['/business-creation']);
        }
       
   
      }else {
        this.snackBar.open(this.response.message, "Alerte", {
          duration: 3000,
        });
      }
    }, error =>{
      this.ngxUiLoaderService.stop();
    });

  }

}
