<app-sidebar></app-sidebar>
<app-header></app-header>
<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">

                <div class="catalogueFunction mb-3">
                   
                    <div class="toggle_screen">
                        <div class="one tabOption"  >
                            <h5 class="color_blue">{{'Permissions' | translate }}</h5>
                            <div class="customLine"></div>
                        </div>
                    </div>
                    <div class="add_functions">
                        <button  class="btn_primary bg_blue1" (click)="openRoleModal()">{{'Add Roles' | translate}}</button>
                        <button  class="btn_primary bg_blue" (click)="savePermissions()">{{'Save Changes' | translate}}</button>
                    </div>
                </div>

                <div class="permissionTable">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--5cols Rtable--collapse"  id="table-main">
                            <div class="Rtable-row Rtable-row--head">
                                <div class="Rtable-cell role-cell column-heading text-center">{{'Roles' | translate}}</div>
                                <div class="Rtable-cell pemission_title column-heading " *ngFor="let item of permissionlabels">{{ item.value  }}</div>
                                <!-- <div class="Rtable-cell pemission_title column-heading">Mange projects</div>
                                <div class="Rtable-cell pemission_title_user column-heading">Add User</div>
                                <div class="Rtable-cell pemission_title column-heading">Manage users</div>
                                <div class="Rtable-cell pemission_title_grp column-heading">Add Groups</div>
                                <div class="Rtable-cell pemission_title column-heading">Mange Groups</div>
                                <div class="Rtable-cell pemission_title column-heading">Create Report</div> -->
                                <div class="Rtable-cell action_cell column-heading"></div>
                            </div>
                            <form [formGroup]="permissionForm" class="w-100">
                                <ng-container formArrayName="permissions">
                                    <div class="Rtable-row is-striped table-content" *ngFor="let item of permissionList;let i = index" [formGroupName]="i">
                                        <div class="Rtable-cell role-cell text-center permissionedit">
                                            <div class="Rtable-cell--heading text-center">{{'Roles' | translate}}</div>
                                            <div class="Rtable-cell--content name-content permission-heading-head">{{ item.description}} </div>
                                            <div class="edit_button_p" *ngIf="item.name != 'default_permission' "><img src="assets/images/pencil.png" alt="" class="edit_designation" (click)="openDesgnation(item)" ></div>
                                        </div>
                                        <ng-container formArrayName="permissionsforRoles">
                                            <div class="Rtable-cell pemission_title" *ngFor="let data of item.permissions;let key = index" [formGroupName]="key">
                                                <div class="Rtable-cell--heading">{{data.description| translate}}</div>
                                                <div class="Rtable-cell--content replay-link-content">
                                                    <mat-checkbox [checked]="data.value == true" class="example-margin" formControlName="{{ data.name}}" (change)="getvalue($event.checked, data.name, i, key)"></mat-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="Rtable-cell action_cell" (click)="deleteRoleList(item.designation_id)">
                                            <div class="Rtable-cell--heading"></div>
                                            <!-- <div class="Rtable-cell--content date-content"><img src="assets/images/redBin.png" alt=""></div> -->
                                        </div>
                                       
                                    </div>
                                </ng-container>
                            </form>

                          
                        </div>
                    </div>
                </div>
                <!-- <button  class="btn_primary bg_blue endBtn">Save Changes</button> -->
                <div class="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" >
                    <div class="modal-dialog modal-dialog-centered" >
                      <div class="modal-content">
                        <div class="modal_header">
                          <h5>{{'Add Roles' | translate}}</h5>
                          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closseModal()"></button>
                        </div>
                        <form [formGroup]="roleForm" (ngSubmit)="onSubmitnewRole()">
                            <div class="modal-body">
                                <div class="input_style">
                                    <label for="exampleInputEmail1">{{'Roles name' | translate}}</label>
                                    <input class="input" type="text" placeholder="{{'Roles name' | translate}}" formControlName="role_name" maxlength="32"> 
                                </div>
                                <div *ngIf="rolesubmitted && f.role_name.errors">
                                    <div class="error_message" *ngIf="f.role_name.errors.required">{{'Roles name is required' | translate}}</div>
                                    <div class="error_message" *ngIf="f.role_name.errors.pattern">{{'Roles name shoud valid' | translate}}</div>
                                </div>
                            </div>
                            <button type="submit" class="btn_primary bg_blue d-block">{{'Add' | translate}}</button>
                        </form>
                      </div>
                    </div>
                  </div>
                
            </div>
        </div>


    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Delete' | translate}} ?</h5>
              <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="text-center">{{'Do you want to delete' | translate}}</div>
            </div>
            <div id="delete_id" class="btnGrp mt-4">
              <button class="btn_primary bg_blue" id="button-id" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
              <button id="button-id" class="btn_primary bg_red"  (click)="deleteRole()">{{'Delete' | translate}}</button>
            </div>      
          </div>
        </div>
      </div>
</main>
<div class="modal fade" id="deignationEditModal" data-bs-backdrop="static" data-bs-keyboard="false" >
    <div class="modal-dialog modal-dialog-centered" >
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Edit Roles' | translate}}</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closseroleUpdateModal()"></button>
        </div>
        <form [formGroup]="EditroleForm" (ngSubmit)="upDateRolename()">
            <div class="modal-body">
                <div class="input_style">
                    <label for="exampleInputEmail1">{{'Roles name' | translate}}</label>
                    <input class="input" type="text" placeholder="{{'Roles name' | translate}}" formControlName="role_name" maxlength="32"> 
                </div>
                <div *ngIf="rolesubmitted && g.role_name.errors">
                    <div class="error_message" *ngIf="g.role_name.errors.required">{{'Roles name is required' | translate}}</div>
                    <div class="error_message" *ngIf="g.role_name.errors.pattern">{{'Roles name shoud valid' | translate}}</div>
                </div>
            </div>
            <button type="submit" class="btn_primary bg_blue d-block">{{'Update' | translate}}</button>
        </form>
      </div>
    </div>
</div>