<app-sidebar></app-sidebar>
<app-header></app-header>

<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">
                
                <div class="heading_1 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <img class="mr-12" src="assets/images/fav_blue.png" alt="">
                        <h4>Favorites</h4>
                    </div>
                    <div class="d-flex align-items-center">

                        <img class="mr-12" id="lineView" src="assets/images/3line.png"  alt="">
                        <img id="gridViewBtn" src="assets/images/grid.svg" alt="">

                    </div>
                </div>
                <div class="favourite_table">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--5cols Rtable--collapse" id="table-main">
                        <div class="Rtable-row Rtable-row--head">
                            <div class="Rtable-cell name_cell column-heading">Name <i class="fa-solid fa-arrow-up"></i></div>
                            <div class="Rtable-cell size_cell column-heading">File Size</div>
                            <div class="Rtable-cell date-cell column-heading">Last Modified</div>
                            <div class="Rtable-cell shared-cell column-heading">Shared with</div>
                            <div class="Rtable-cell column-nodata column-heading"></div>
                            <div class="Rtable-cell location_cell column-heading">Location</div>
                            
                            
                        </div>
                    
                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell" (click)="getInfo('3')">
                                <div class="Rtable-cell--content title-content">Proposal.docx <img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">3.8 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 10, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption" data-tab="context-menu1">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <ul class="p-0">
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">Downloads</div></a></div>
                            </div>


                            <div id="context-menu1" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>


                        </div>
                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell" (click)="getInfo('3')">
                                <div class="Rtable-cell--content title-content">Background.jpeg <img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">1.2 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 06, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption" data-tab="context-menu2">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    Only me
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">My Files</div></a></div>
                            </div>

                            <div id="context-menu2" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>
                        </div>
                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell" (click)="getInfo('3')">
                                <div class="Rtable-cell--content title-content">Financial App.fig<img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">18.8 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 06, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption"data-tab="context-menu3">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <ul class="p-0">
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">Documents</div></a></div>
                            </div>
                            <div id="context-menu3" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell" (click)="getInfo('3')">
                                <div class="Rtable-cell--content title-content">Data Records.mkv<img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">7.7 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 05, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption" data-tab="context-menu4">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <ul class="p-0">
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">Downloads</div></a></div>
                            </div>
                            <div id="context-menu4" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell" (click)="getInfo('3')">
                                <div class="Rtable-cell--content title-content">Poster.psd<img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">3.8 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 05, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption" data-tab="context-menu5">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <ul class="p-0">
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">My Files</div></a></div>
                            </div>
                            <div id="context-menu5" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell">
                                <div class="Rtable-cell--content title-content">illustration.ai<img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">1.2 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 04, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption" data-tab="context-menu6">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    Only me
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">Personal</div></a></div>
                            </div>
                            <div id="context-menu6" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>
                        </div>

                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell">
                                <div class="Rtable-cell--content title-content">Financial App.fig<img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">18.8 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 03, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center contextShowOption" data-tab="context-menu7">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <ul class="p-0">
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">Documents</div></a></div>
                            </div>
                            <div id="context-menu7" class="context-menu">
                                <ul class="context-menu__items">
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                  </li>
        
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                  </li>
                                  <li class="context-menu__item">
                                    <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                  </li>
                                </ul>
                            </div>
                        </div>
                        <div class="Rtable-row is-striped" (click)="getInfo('1')">
                            <div class="Rtable-cell name_cell">
                                <div class="Rtable-cell--content title-content">Data Records.mkv<img src="assets/images/star_yellow.png" alt=""></div>
                            </div>
                            <div class="Rtable-cell size_cell">
                                <div class="Rtable-cell--heading">File Size</div>
                                <div class="Rtable-cell--content replay-link-content">7.7 MB</div>
                            </div>
                            <div class="Rtable-cell date-cell">
                            <div class="Rtable-cell--heading">Last Modified</div>
                            <div class="Rtable-cell--content date-content"><span class="">January 03, 2023</span></div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot column-nodata">
                                <div class="Rtable-cell--heading">column-nodata</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        ...
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                <div class="Rtable-cell--heading">Shared with</div>
                                <div class="Rtable-cell--content shared-content"> 
                                    <div class="d-flex align-items-center">
                                        <ul class="p-0">
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                            <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Rtable-cell location_cell">
                            <div class="Rtable-cell--heading">Location</div>
                            <div class="Rtable-cell--content access-link-content"><a href="#0"><i class="ion-link"></i><div class="d-flex align-items-center"><img src="assets/images/folder_sm.png" alt="">Videos</div></a></div>
                            </div>
                        </div>
                        
                    
                        </div>
                    </div>
                </div>



                <!-- GRID VIEW  -->
                <div id="gridView" class="gridView">
                    <div class="hFlex">
                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500" (click)="getInfo('1')">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="800" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                                
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>

                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                                
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>

                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                                
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>

                        <div class="card1 p-15" (click)="getInfo('1')" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/docNoColor.png" alt="">
                                <div>
                                    <div class="subText">Project Details</div>
                                </div>
                            </div>
                            <ul class="ul_line">
                                <li class="smText d-inline line-head">Project Plan</li>
                                <li class="smText d-inline">28 Files</li>
                                <li class="smText d-inline">250 MB</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <ul class="ul_line mb-0">
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                    <li class="d-inline"><img src="assets/images/sharePic.png" alt=""></li>
                                </ul>
                                
                            </div>
                            <div class="d-flex justify-content-end">
                                <h6 class="blue_letter">January 10, 2023</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- GRID VIEW CLOSE -->


            </div>
            <aside class="sidebar-second" id="preview_info">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="heading_1">
                        <h4 class="">File preview</h4>
                    </div>
                    <img class="close" (click)="getInfo('2')" src="assets/images/cross.png" alt="">
                </div>
                <div>
                    <img src="assets/images/pic_1.png" alt="" class="img-fluid">
                </div>

                <div class="size-col">
                    <div class="">
                        <img src="assets/images/image.png" alt="">
                        <span>illustration.ai</span>
                    </div>

                    <h5>1.2 MB</h5>
                </div>

                <div class="tabs">
                    <div class="position-relative tabOption current" data-tab="tab-1" id="infoShow">
                        <h6>Info</h6>
                        <div class="line lineExtent"></div>
                    </div>
                    <div class="position-relative tabOption" data-tab="tab-2" id="activityShow">
                        <h6>Activity</h6>
                        <div class="line"></div>
                    </div>
                    <div class="position-relative tabOption" data-tab="tab-3" id="cmtShow">
                        <h6>Comments</h6>
                        <div class="line"></div>
                    </div>
                </div>


                <div class="tabs_contents">
                    <div class="info_content tab-content current" id="tab-1">
                        <div class="text_sm">Who can access</div>
                        <div class="d-flex justify-content-between info-1 margin-lr14">
                            <div class="d-flex justify-content-between align-items-end w-100 share-option">
                                <button class="share" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#shareModal">
                                    <img src="assets/images/upload-sm.png" alt="">
                                    Share
                                </button>
                                <div class="users">
                                    <ul>
                                        <li><img src="assets/images/sharePic.png" alt=""></li>
                                        <li><img src="assets/images/sharePic.png" alt=""></li>
                                        <li><img src="assets/images/sharePic.png" alt=""></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="text_sm">File properties</div>

                        <div class="margin-lr14 info-2">
                            <!-- <div class="d-flex justify-content-between">
                                <h5>Saved location</h5>
                                <h5>My files/illustrations</h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h5>Size</h5>
                                <h5>1.2 MB</h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h5>Modified</h5>
                                <h5>January 04, 2023</h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h5>Type</h5>
                                <h5>Image</h5>
                            </div> -->
                            <div class="row">
                                <div class="col-md-6"> <h5 class="black">Saved location</h5></div>
                                <div class="col-md-6"><h5>My files/illustrations</h5></div>
                                <div class="col-md-6"><h5 class="black">Size</h5></div>
                                <div class="col-md-6"><h5>1.2 MB</h5></div>
                                <div class="col-md-6"><h5 class="black">Modified</h5></div>
                                <div class="col-md-6"><h5>January 04, 2023</h5></div>
                                <div class="col-md-6"><h5 class="black">Type</h5></div>
                                <div class="col-md-6"><h5>Image</h5></div>

                            </div>
                        </div>
                    </div>

                    <div class="activity_content tab-content" id="tab-2">
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                You shared this file
                                <h6>Jan 21th 2023, 03:14 pm</h6>
                            </div>
                        </div>
                        <div class="d-flex shared_2">
                            <img src="assets/images/link.png" alt="">
                            Anyone with the link can view this file
                        </div>
                        <div class="line"></div>
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                You added to favourites
                                <h6>Jan 21th 2023, 03:14 pm</h6>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                You uploaded <span>illustration.ai</span>
                                <h6>Jan 17th 2023, 10:47 am</h6>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>


                    <div class="comments_content tab-content" id="tab-3">
                        <div class="view1">
                            <div class="margin-lr14 mt-24">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/pic-lg1.png" alt="">
                                        <div class="user_text">
                                            James Cameroon
                                            <h5>Cool design & great colors</h5>
                                        </div>
                                    </div>
                                    <i class="fa-regular fa-heart"></i>
                                </div>
                                <div class="d-flex justify-content-between suboptions">
                                    <h6>1 week ago</h6> 
                                    <h6>Like</h6> 
                                    <h6>Reply</h6> 
                                </div>
                            </div>

                            <div class="margin-lr14 mt-24">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/pic-lg3.png" alt="">
                                        <div class="user_text">
                                            Michael Ben Patrick
                                            <h5>so clean & smooth</h5>
                                        </div>
                                    </div>
                                    <i class="fa-regular fa-heart"></i>
                                </div>
                                <div class="suboptions">
                                    <div class="d-flex justify-content-between ">
                                        <h6>2 week ago</h6> 
                                        <h6>Like</h6> 
                                        <h6>Reply</h6> 
                                    </div>

                                    <div class="reply position-relative">
                                        <img class="reply_arrow" src="assets/images/arrow-reply.png" alt="">
                                        <div class="d-flex align-items-center ml-5">
                                            <img src="assets/images/pic-lg.png" alt="">
                                            <div class="user_text">
                                                Jesse Walter
                                                <h5>Thank you</h5>
                                                <div class="d-flex justify-content-between ">
                                                    <h6>1 hour ago</h6> 
                                                    <h6>Like</h6> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="margin-lr14 mt-24">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/pic-lg2.png" alt="">
                                        <div class="user_text">
                                            Ben Affleck
                                            <h5>Awesome Man</h5>
                                        </div>
                                    </div>
                                    <i class="fa-regular fa-heart"></i>
                                </div>
                                <div class="d-flex justify-content-between suboptions">
                                    <h6>2 week ago</h6> 
                                    <h6>Like</h6> 
                                    <h6>Reply</h6> 
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </aside>
        </div>
       
        

        <div class="modal fade" id="shareModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>Share Files</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                  <div class="search-mail"><input type="text" placeholder="Add people or groups"></div>
                  <div class="d-flex ownerInfo">
                    <div class="d-flex">
                        <img class="owner_pic" src="assets/images/pic-lg.png" alt="">
                        <div>
                            <div class="text-1">Jesse Walter</div>
                            <h6>jessewalter00@gmail.com</h6>
                        </div>
                    </div>
                    <h5 class="text-grey-18">Owner(you)</h5>
                  </div>

                <div>
                    <div class="text-1">Give access to</div>
                    <div class="d-flex ownerInfo">
                        <div class="d-flex">
                            <img class="owner_pic" src="assets/images/addPeople.png" alt="">
                            <div>
                                <div class="text-2">The one with link can view</div>
                                <h6>jessewalter00@gmail.com</h6>
                            </div>
                        </div>
                        <a href="#" class="tooltip-test" title="Tooltip"><img src="assets/images/link_sm.png" alt=""></a>
                      </div> 
                </div>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Understood</button>
                </div> -->

                <button class="btn-primary btn-style"  data-bs-dismiss="modal" aria-label="Close">Done</button>
              </div>
            </div>
          </div>
    
    </div>

    <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="folderCreateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>Add New Project</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="projectNameAdd">
                <div class="modal-body">
                    <div class="mt-5">
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Datas">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button id="folderCreate" (click)="folderCreate()" class="btn-primary btn-style" >Create</button>
                </div>
            </div>

            <div id="folderName_And_Tagsection">
                <div class="modal-body">
                    <div class="mt-2">
                        <label for="">Folder Name</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Joining Date" name="" id="" value="Employee Offer Letter">
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="">Tags</label>
                        <div class="position-relative">
                            <input type="text" placeholder="Tags" name="" id="">
                        </div>
                    </div>
                </div>
            
                <div class="btnGrp mt-4">
                    <button class="btn-primary cancel btn-style"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button id="folderCreate" class="btn-primary btn-style"  data-bs-dismiss="modal" aria-label="Close">Create</button>
                </div>
            </div>
          </div>
        </div>
      </div>

</main>