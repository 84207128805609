interface TagsInputOptions {
    selector: string;
    wrapperClass?: string;
    tagClass?: string;
    max?: number | null;
    duplicate?: boolean;
    icon?:string | null;
}

class TagsInput {
    protected orignal_input: HTMLInputElement;
    protected arr: string[];
    protected wrapper: HTMLDivElement;
    protected input: HTMLInputElement;
    protected initialized: boolean;
    protected options: TagsInputOptions;
    protected tagElements = [];

    constructor(opts: TagsInputOptions) {
        this.options = Object.assign(TagsInput.defaults, opts);
        console.log(this.options,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
        
        this.init();
    }

    init(opts?: TagsInputOptions): TagsInput {
        this.options = opts ? Object.assign(this.options, opts) : this.options;
        console.log(this.options);
        
        if (this.initialized) this.destroy();

        if (!(this.orignal_input = document.getElementById(this.options.selector) as HTMLInputElement)) {
            console.error("tags-input couldn't find an element with the specified ID");
            return this;
        }

        this.arr = [];
        this.wrapper = document.createElement('div');
        this.input = document.createElement('input');
        this.initInternal(this);
        this.initEvents(this);

        this.initialized = true;
        return this;
    }

    removeAllTags() {
        // Remove all tag elements from the wrapper
        this.tagElements.forEach(tag => {
            this.wrapper.removeChild(tag);
        });
    
        // Clear the array of tag elements
        this.tagElements = [];
    
        // Clear the array of tags
        this.arr = [];
    
        // Clear the value of the original input
        this.orignal_input.value = '';
    }

    addTag(string: string): TagsInput {
        if (this.anyErrors(string)) return this;
        if(this.arr)
        this.arr.push(string);
        const tagInput = this;

        const tag = document.createElement('span');
        tag.className = this.options.tagClass!;
        tag.innerText = string;

        // const closeIcon = document.createElement('a');
        // closeIcon.innerHTML = '&times;';

        const closeIcon = document.createElement('a');
        const img = document.createElement('img');
        img.src = this.options.icon;
        console.log(img.src,"HHHHHH LINE 59")
         // Replace with the actual path to your image
        img.alt = 'Close'; // Add alt text for accessibility
        closeIcon.appendChild(img);

        closeIcon.addEventListener('click', function (e) {
            e.preventDefault();
            const tag = this.parentNode as HTMLElement;

            for (let i = 0; i < tagInput.wrapper.childNodes.length; i++) {
                if (tagInput.wrapper.childNodes[i] == tag) tagInput.deleteTag(tag, i);
            }
        });

        tag.appendChild(closeIcon);
        if(this.wrapper)
        this.wrapper.insertBefore(tag, this.input);
        if(this.arr)
        this.orignal_input.value = this.arr.join(',');
        this.tagElements.push(tag);
        return this;
    }

    deleteTag(tag: HTMLElement, i: number): TagsInput {
        tag.remove();
        this.arr.splice(i, 1);
        this.orignal_input.value = this.arr.join(',');
        return this;
    }

    anyErrors(string: string): boolean {
        console.log("this.arr", this.arr)
        console.log(this.options ,'11111111111111111111111111111111');
        
        if (this.arr && (this.options.max != null && this.arr.length >= this.options.max!)) {
            console.log('max tags limit reached');
            return true;
        }

        if (this.arr && (!this.options.duplicate && this.arr.indexOf(string) !== -1)) {
            console.log('duplicate found " ' + string + ' " ');
            return true;
        }

        return false;
    }

    addData(array: string[]): TagsInput {

        console.log("123333333", array)
        const plugin = this;

        array.forEach(function (string) {
            plugin.addTag(string);
        });
        return this;
    }

    getInputString(): string {
        return this.arr.join(',');
    }

    destroy(): void {
        console.log(this.orignal_input  )
        if (this.orignal_input) {
            this.orignal_input.removeAttribute('hidden');

            delete this.orignal_input;
            const self = this;
    
            Object.keys(this).forEach(function (key) {
                if (self[key] instanceof HTMLElement) self[key].remove();
    
                if (key !== 'options') delete self[key];
            });
    
            this.initialized = false;
        }
    }

    protected initInternal(tags: TagsInput): void {
        tags.wrapper.append(tags.input);
        tags.wrapper.classList.add(tags.options.wrapperClass!);
        tags.orignal_input.setAttribute('hidden', 'true');
        tags.orignal_input.parentNode!.insertBefore(tags.wrapper, tags.orignal_input);
    }

    protected initEvents(tags: TagsInput): void {
        tags.wrapper.addEventListener('click', function () {
            tags.input.focus();
        });

        tags.input.addEventListener('keydown', function (e) {
            const str = tags.input.value.trim();

            if (![9, 13, 188].includes(e.keyCode)) return;

            e.preventDefault();
            tags.input.value = '';
            if (str !== '') tags.addTag(str);
        });
    }

    static defaults: TagsInputOptions = {
        selector: '',
        wrapperClass: 'tags-input-wrapper',
        tagClass: 'tag',
        max: null,
        duplicate: false,
        icon:null,
    };
}

(window as any).TagsInput = TagsInput;