
<nav>
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/logolg2.png" alt="">
        <ul>

            <li class="mr-4">Home</li>
            <li class="mr-4">Features</li>
            <li class="mr-4">About</li>
            <li class="mr-4">En</li>
            <li routerLink="/signup" class="mr-4">Sign Up</li>
            <li routerLink="/" class="mr-4">Login</li>
                
        </ul>
    </div>

</nav>

<div class="business-employee mt-4 mb-5">
<div class="">
    <div class="main">
        
        <div class="row">
            <div class="col-md-6 col-12" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500">
                <div class="heading-main">Let’s get started</div>
                <form [formGroup]="emplyeeForm" (ngSubmit)="onSubmit()">
                    <div class="input">
                        <label class="label-test" for="name">Business name</label>
                        <input type="text" class="text-capitalize" maxlength="45" formControlName="business_name">
                    </div>

                    <div class="mt-2" *ngIf = "submitted && emplyeeFormControl.business_name.errors">
                        <div class="error_message" *ngIf="emplyeeFormControl.business_name.errors.required">Name is required</div>
                        <div class="error_message" *ngIf="emplyeeFormControl.business_name.errors.pattern">Name shoud valid</div>
                    </div>
                
                    <div class="count_input">

                        <label for="" class="label-test mt-3">Number of employees, including you </label>
                        <mat-radio-group aria-label="Select an option" formControlName="no_of_employees">
                            <mat-radio-button class="mt-3" value="1">Only you</mat-radio-button>
                            <mat-radio-button class="mt-3" value="2">2 - 9</mat-radio-button>

                            <mat-radio-button class="mt-3" value="3">10 - 99</mat-radio-button>
                            <mat-radio-button class="mt-3" value="4">100 - 299</mat-radio-button>
                            <mat-radio-button class="mt-3" value="5">300+</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf = "submitted && emplyeeFormControl.no_of_employees.errors">
                        <div class="error_message" *ngIf="emplyeeFormControl.no_of_employees.errors.required">Number of employees is required</div>
                    </div>

                    <div class="select_input">
                        
                        <!-- <select class="form-select" aria-label="Default select example">
                            <option selected>Open this select menu</option>
                            <option value="1">Central African Republic</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select> -->
                        <!-- <label class="label-test" for="name">Region</label> -->
                        <mat-form-field>
                            <mat-label>Region</mat-label>
                            <mat-select formControlName="region"  panelClass="example-panel">
                              <mat-option value="1">Central African Republic</mat-option>
                              <mat-option value="2">Two</mat-option>
                              <mat-option value="3">Three</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf = "submitted && emplyeeFormControl.region.errors">
                            <div class="error_message" *ngIf="emplyeeFormControl.region.errors.required">Region is required</div>
                        </div>
                    </div>

                 
                    <button type="submit" class="btn-primary">NEXT <i class="fa-solid fa-arrow-right"></i></button>
                </form>
            
            </div>

            <div class="col-md-6 col-12" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500">
                <div class="sec2">
                    <div>

                        <div class="heading2">Do more with <br>In-Flow Business</div>

                        <div class="terms">
                            <div class="d-flex align-items-center">
                                <img src="assets/images/Done.png" alt="">
                                Custom  email with your domain
                            </div>

                            <div class="d-flex align-items-center">
                                <img src="assets/images/Done.png" alt="">
                                Offline services
                            </div>

                            <div class="d-flex align-items-center">
                                <img src="assets/images/Done.png" alt="">
                                Advanced Security
                            </div>

                            <div class="d-flex align-items-center">
                                <img src="assets/images/Done.png" alt="">
                                Admin Features
                            </div>

                            <div class="d-flex align-items-center">
                                <img src="assets/images/Done.png" alt="">
                                24/7 support
                            </div>

                            <div class="d-flex align-items-start">
                                <img src="assets/images/Done.png" alt="">
                                No charge for 7-day free trial;<br>monthly charges afterwards
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
</div>
