<app-sidebar></app-sidebar>

<div id="section_all">

    <app-header></app-header>


    <main>
        <div class="container-custom">
            <div class="columns">
                <div class="main overflow">

                    <div class="title_container d-flex">
                        <div id="title-head-id" class="title_lg">{{'Dashboard' | translate }}</div>
                    </div>

                    <div class="swiper_content" [ngClass]="{'displayNone': showSideBar == true}">

                        <div class="flex1" [ngClass]="{'padingChange': showSideBar == true}">
                            <div class="read_text_sm">{{'Welcome' | translate }}<span> {{ nameforFetch | slice:0:1 | uppercase }}</span>{{ nameforFetch | slice:1 }}</div>
                            <div class="read_text_lg">{{'You can easily manage' | translate}} <br> {{'your projects'|
                                translate}}, {{'templates and files here' | translate}}.</div>
                        </div>
                        <div class="flex2"><img class="" src="assets/images/swiper_pic1.png" alt=""></div>
                    </div>

                    <div class="singnature_content" (click)="navigateWithState()" *ngIf="currentuserdetails && (!currentuserdetails.user_details?.signature)">
                        <div class="singnature_flex">
                            <div class="warning_img">
                                <img src="assets/images/warning.png" alt="">
                            </div>
                            <div class="signature_text w-75">
                                <div>
                                    <div class="signature_text_lg">{{ 'Please Update your Signature to complete register' | translate}}</div>
                                    <div class="signature_text_sm"> {{ 'Click here to go profile page.' | translate}}</div>
                                </div>
                            </div>
                            <div class="signature_img">
                                <img src="assets/images/signatures.png" alt="">
                            </div>
                        </div>
                    </div>
                
                    

                    <div class="sub_title_container" *ngIf="(dashboardRecentfolderlist && dashboardRecentfolderlist.length > 0) || (dashboardRecentfilelist && dashboardRecentfilelist.length > 0)">
                        <div class="title_md d-flex align-items-center"><img src="assets/images/doc_sm.png" alt=""class="title_sm_logo">{{'Recent files and folders' | translate}}</div>
                        </div>
                        <div class="folder-card" >
                            <div class="card1" (click)="openFolder(item,item.project_time_exceeded,item.checkout_status,item.checkout_access)"  *ngFor="let item of dashboardRecentfolderlist" (contextmenu)="open($event, item); $event. preventDefault();">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="mr-12" src="assets/images/folder.png" alt="">
                                    </div>
                                    <div>
                                        <div class="subText">{{ item.name }}</div>
                                        <div class="smText">{{ item.no_of_file }} {{'Files' | translate}} , {{ item.no_of_folder}} {{'Folders' | translate}}</div>
                                    </div>

                                </div>
                                <!-- <div class="mt-1 project_exceeded" *ngIf="item.project_time_exceeded == 1">
                                    <p class="text-danger" >*{{'The timeline of following files have expired' | translate}}</p>
                                </div> -->
                                <div class="mt-1 project_exceeded" *ngIf="item.project_time_exceeded == 1 && item.checkout_status == 0">
                                    <p class="text-danger" *ngIf="item.project_time_exceeded == 1 && item.checkout_status == 0">*{{'The timeline of following files have expired' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(item.checkout_status == 1 && item.checkout_access !== 1) && item.project_time_exceeded !== 1">
                                    <p class="text-danger" *ngIf="(item.checkout_status == 1 && item.checkout_access !== 1) && item.project_time_exceeded !== 1">*{{'The following folder have been checked out' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(item.checkout_status == 1 && item.checkout_access !== 1) && item.project_time_exceeded == 1">
                                    <p class="text-danger" *ngIf="(item.checkout_status == 1 && item.checkout_access !== 1) && item.project_time_exceeded == 1">*{{'The timeline of following folder have expired and checked out' | translate}}</p>
                                  </div>
                            </div>
                        </div>
                    <div class="files-card mt-4 mb-4">
                        <div class="files" *ngFor="let item of dashboardRecentfilelist"  (contextmenu)="open($event, item); $event. preventDefault();" (click)="getImagePreview(item)">
                            <div class="smText d-flex align-items-center"><img src="{{item.preview_image}}" class="img-fluid imgageFile" alt=""><span class="nameForAfile">{{ item.file_name }}</span></div>
                            <div><img class="img-fluid w-100" src="{{ item.preview_url }}" alt="" *ngIf="item.preview_url"></div>
                            <div><img class="img-fluid w-100" src="assets/images/image_preview.png" alt="" *ngIf="!item.preview_url"></div>
                            <div class="smText image-text d-flex align-items-center"><img src="assets/images/timer.png" alt=""><span>{{'You opened this on' | translate}}&nbsp;{{ item.last_updated }}</span></div>
                            <div class="mt-1 project_exceeded" *ngIf="item.checkout_status == 1 && item.checkout_access !== 1">
                              <p class="text-danger" *ngIf="item.checkout_status == 1 && item.checkout_access !== 1">*{{'The following file have been checked out' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="((dashboardRecentfolderlist && dashboardRecentfolderlist.length == 0) &&  (dashboardRecentfilelist && dashboardRecentfilelist.length == 0) &&  (dashboardProjectlist && dashboardProjectlist.length == 0))  " class="text-center mt-2">
                        <img src="assets/images/notemplates.jpg" class="img-fluid mb-2">
                        <p>{{'No Data Found' | translate}}</p>
                    </div>

                    <ng-template #userMenu let-user>
                        <section class="user-menu" [hidden]="(user.project_time_exceeded == '1') && (!currentValue.permissions.archiveDelete)">
                            <!-- <div (click)="delete(user)">Delete {{user.name}}</div> -->
                            <div *ngIf="(user.project_time_exceeded == 0) && (!user.project_id)" (click)="openViewdetails()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/eye_oprtion.png"> {{'View Details' | translate}}</div>
                            <div *ngIf="user.project_time_exceeded == 0" (click)="toShare()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/share_oprtion.png"> {{'Share' | translate}}</div>
                            <div  (click)="getSignatureRequest()" *ngIf="signature && (user.project_time_exceeded == 0)"><img _ngcontent-ofp-c1="" alt="" src="assets/images/signicon.png"> {{'Get Signature' | translate}}</div>
                            <div *ngIf="user.project_time_exceeded == 0" (click)="copyProjectsFile()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/copy_oprtion.png"> {{'Copy' | translate}}</div>
                            <div *ngIf="user.project_time_exceeded == 0" (click)="rename()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/edit_oprtion.png"> {{'Rename' | translate}}</div> 
                            <div *ngIf="moveTo && (user.project_time_exceeded == 0)" (click)="moveProjectsFileorFolder()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/move_to_option.png"> {{'Move to' | translate}}</div>
                            <div *ngIf="user.project_time_exceeded == 0" (click)="deleteModal()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png"> {{'Delete' | translate}}</div>
                            <div (click)="openArchieveModal()" *ngIf="(currentValue && currentValue.permissions.archiveDelete)"><img _ngcontent-ofp-c1="" alt="" src="assets/images/recycle.png">{{'Add to archive' | translate}}</div>
                            <div (click)="checkoutModal()" *ngIf="user.project_time_exceeded == 0"><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' | translate}}</div>
                        </section>
                    </ng-template>


                    <div class="sub_title_container" *ngIf="dashboardProjectlist && dashboardProjectlist.length > 0">
                        <div class="title_md d-flex align-items-center"><img src="assets/images/wheel_doc.png" alt=""
                                class="title_sm_logo">{{'Projects' | translate}}</div>
                    </div>

                    <div class="projects mb-5">
                        <div class="grid">
                            <div class="card1 p-15 cursor_pointer" (click)="openProject(data.uuid,data.project_time_exceeded,data.checkout_status,data.checkout_access)"  *ngFor="let data of dashboardProjectlist index as i" (contextmenu)="open($event, data); $event. preventDefault();">
                                <div >
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div  class="title_md d-flex">
                                            <div><img class="mr-12" src="assets/images/Union.png" alt=""></div>
                                            <div class="projectName-len">{{ data.name}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mt-3 mb-3">
                                        <img class="" src="assets/images/elipse_grey.png" alt="">
                                        <div class="smText"> {{ data.file_count }} {{'Files' | translate}}, {{ data.folder_count }} {{'Folders' | translate}}</div>
                                    </div>
                                   
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center" id="shared_user_id">
                                        <ul class="mb-0 ul_line mr-12" [hidden]="data.projectMembers.length == 0">
                                            <li *ngFor="let img of data.projectMembers | slice:0:limit" class="d-inline"><img class="picUserproject" src="{{img.profile_pic}}" alt="" /></li>
                                        </ul>
                                        <img (click)="toShare(data, $event,data.project_time_exceeded)" class="cursor_pointer" src="assets/images/addUser.png" alt="" />
                                    </div>
                                    <h6 class="blue_letter">{{data.size}}</h6>
                                </div>
                                <!-- <div class="mt-3 project_exceeded"  *ngIf="data.project_time_exceeded == 1">
                                    <p class="text-danger" *ngIf="data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded' | translate}}</p>
                                </div> -->
                                <div class="mt-1 project_exceeded" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">
                                    <p class="text-danger" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'Your project timeline has been exceeded' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">*{{'The following project have been checked out' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded and checked out' | translate}}</p>
                                </div>
                                <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                  <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded' | translate}}</p>
                              </div>
                                <div>
                                    <img class="mr-12 dots" src="assets/images/more_sm2.png" alt="" (click)="open($event, data); $event. preventDefault();">
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="deleteModalforArchieve_open" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="shareModalLabel" aria-hidden="true">
                    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal_header">
                            <h5>{{'Archive' | translate}} ?</h5>
                          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="text-center text-danger">*{{'Your project timeline has been exceeded' | translate}}.{{'Do you want archive' | translate}} ? </div>
                        </div>
                        <div id="delete_id" class="btnGrp mt-4">
                          <button id="button-id" class="btn_primary bg_blue" (click)="updateTimeline()">{{'Update Timeline' | translate}}</button>
                          <button id="button-id" class="btn_primary bg_red"  (click)="openArchieveModal()">{{'Archive' | translate}}</button>
                        </div>      
                      </div>
                    </div>
                </div>
                <app-file-preview></app-file-preview>
            </div>


        </div>
        <app-move-modal></app-move-modal>

        <div class="modal fade" id="deleteModalforArchieve_project" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="shareModalLabel" aria-hidden="true">
            <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header">
                    <h5>{{'Archive' | translate}} ?</h5>
                  <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="text-center">{{'Do you want to archive ?' | translate}} </div>
                </div>
                <div id="delete_id" class="btnGrp mt-4">
                  <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                  <button id="button-id" class="btn_primary bg_red"  (click)="restoreLater()">{{'Archive' | translate}}</button>
                </div>      
              </div>
            </div>
        </div>
        <!-- delete modal -->
        <div class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal_header"> 
                    <h5>{{'Delete' | translate}} ?</h5>
                  <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="text-center">{{'Do you want to delete' | translate}}</div>
                </div>
                <div id="delete_id" class="btnGrp mt-4">
                  <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                  <button id="button-id" class="btn_primary bg_red"  (click)="deleteProjectFolderFile()">{{'Delete' | translate}}</button>
                </div>      
              </div>
            </div>
          </div>
          <div class="modal" id="createProjectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalToggleLabel">{{'Signature Request' | translate}}</h5>
                  <button type="button" class="btn-close"  (click)="closeGroupmodal()"></button>
                </div>
                
                <form   >
                <div class="modal-body">
                    <div class="title mt-4 mb-2">{{'Request To' | translate}}</div>
                    <div class="membersList">
                        <div class="members_container">
                            <div class="members" *ngFor="let member of grouMembers; let i = index">
                                <div><img class="userImageadd" [src]="member.profile_pic" alt="">{{member.name}}</div>
                                <img class="closeIconuser" src="assets/images/close_sm.png" alt="" (click)="removeUser(i, member.uuid, member)">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="groupSubmitted && grouMembers.length == 0">
                        <div class="error_message text-center" >{{'Group Members is required' | translate}}</div>
                    </div>
                    <!-- <div *ngIf="groupSubmitted">
                        <div *ngIf="grouMembers.length == 0" class="error_message text-center">
                            {{ 'Group Members is required' | translate }}
                        </div>
                        <div *ngIf="grouMembers.length >= 2" class="error_message text-center">
                            {{ "Can't Add More Than One Group Member" | translate }}
                        </div>
                    </div> -->
                    
                    <div class="title mt-4 mb-2">{{'Add Users' | translate}}</div>
                    <div class="addUserList">
                        <div class="groups">
                            <div class="list"  *ngFor="let item of activeUserlist">
                                <div class="logoArea">
                                    <img class="userImageListing" src="{{item.profile_pic}}" alt="" >
                                    <div>{{item.name}} <span>{{item.email}}</span></div>
                                </div>
                                <button class="btn_primary bg_blue" type="button"  [disabled]="grouMembers.length >= 1" (click)="adduser(item)">{{'Add' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- data-bs-toggle="modal" href="" -->
                    <button class="btn_primary bg_blue" type="button"  (click)="ngSubmitrequestSugnature()">{{'Send Request' | translate}}</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        
    </main>

</div>
<div class="modal fade" id="timelineModal_open" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Archive' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center text-danger">*{{'The timeline of following files have expired' | translate}}.{{'Do you want archive' | translate}} ? </div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_blue"  (click)="updatefolderTimeline()">{{'Update Timeline' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red"   (click)="openArchieveModal()">{{'Archive' | translate}}</button>
        </div>      
      </div>
    </div>
</div>

<div class="modal fade" id="folderEdit_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Folder details' | translate}}</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form [formGroup]="timelineForm">
        <div class="modal-body">
  
          <div class="input_style mt-4">
                              
            <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
            <input class="input" type="email" aria-describedby="emailHelp" placeholder="{{'Folder Name' | translate}}" readonly formControlName="name">
          </div>
          
          <div class="container_input_div">
            <div class="input_style mt-4 d-flex align-items-center">
                <div class="datePicker-modal">
                    <div class="dateDiv">
                        <label for="">{{'Starts from' | translate}}</label>
                        <mat-form-field class="example-full-width" appearance="none">
                            <input matInput [matDatepicker]="dp10"  [min]="todayDate" [matDatepicker]="picker" readonly  formControlName="start_date">
                            <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                            <mat-datepicker #dp10></mat-datepicker>
                        </mat-form-field>
                        
                    </div>
                    <div>
                        <img class="minus" src="assets/images/minus.png" alt="">
                    </div>
                    <div class="dateDiv">
                        <div>
                            <label for="">{{'Ends on' | translate}}</label>
                            <mat-form-field class="example-full-width" appearance="none">
                                <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly   [min]="c.start_date.value" formControlName="end_date">
                                <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                <mat-datepicker #dp2></mat-datepicker>
                            </mat-form-field>
                            
                        </div>
                    </div> 
                </div>
            </div>
          </div>
  
  
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_red"  (click)="closeTimelinemodal()">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_blue"(click)="updateTimelinefolder()">{{'Update' | translate}}</button>
        </div>
      </form>    
      </div>
    </div>
</div>

