import { Component, ViewContainerRef, OnInit ,ViewChild,EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/_services/api.services';
import { take, filter } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SharedService } from 'src/app/_services/shared.service';
import { ModalService } from 'src/app/_services/modal.service';
declare var $: any;
import * as moment from 'moment-timezone';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexFill,
  ChartComponent,
  ApexStroke
} from "ng-apexcharts";
import { error } from 'console';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
};

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  response: any;
  showSideBar = false;
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Output() sidebarOpened = new EventEmitter<boolean>();
  public chartOptions: any;
  dashboardRecentStoragedetails: any;
  dashboardProjectlist: any;
  dashboardRecentfilelist: any;
  dashboardRecentfolderlist: any;
  dashboardRecentdetails: any;
  userDataObject: any;
  storageType: any;
  viewDetailsData: any;
  viewDetailsList: any;
  metadataDetails: any;
  NometadataAdded: any;
  keyHide: boolean;
  NometadataforFolder: any;
  metadataDetailsDate: any;
  metadatName: any;
  metadataId: any;
  viewDetailsList2: any;
  activityDetails: any;
  profileForm: any;
  file_Id: any;
  infoSection: boolean;
  activitySection: boolean;
  showStoragesection: boolean;
  dataUuid:any;
  storagesectionHide: boolean;
  detailsSection: boolean;
  templateData: any;
  templateList: any;
  isButtonHiddenCancel: boolean = true;
  isButtonHiddenSubmitt: boolean = true;
  UpdateMetadataResponse: any;
  templateresponse: any;
  templateDetails: any;
  file_uiid: any;
  folderdetailsdata: any;
  todayDate: any;
  timelineForm: FormGroup;
  fulldetails: any;
  timelineResponse: any;
  isDisabled: boolean = true;
  isDatePickerDisabled: boolean = true;
  isInputDisabled: boolean = true;
  isReadOnly: boolean = true;
  isButtonHidden: boolean = false;
  file_name: any;



  constructor(private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService,
              public snackBar: MatSnackBar,
              public formBuilder: FormBuilder,
              private sharedService: SharedService,
              private modalService: ModalService) 
              { 
                this.sharedService.currentData.subscribe((show: any) => {
                  console.log("324242342342342323")
                  console.log(show);
                  this.fulldetails = show;
                  this.dataUuid = show.uuid;
                  this.file_name=show.name;
                  console.log(this.dataUuid);
                  
                  if(this.dataUuid){
                    this.showSideBar = true;
                    this.showPreview('info');
                    this.storagesectionHide = false;
                    this.detailsSection = true;
                  }
                  console.log(this.storagesectionHide,this.detailsSection);
                  
                  this.openSideMenu(this.dataUuid)
                 
            
            
                }, error => {
                  console.log(error)
                });
              }
  


  ngOnInit() {
    this.getDashboardDetails();
    let data = localStorage.getItem("currentUserDetails");
    this.userDataObject = JSON.parse(data);
    this.storageType = this.userDataObject.user_details
    console.log(this.storageType.user_type ,'dssdsasadasdsdsadsadsdsad');

    this.profileForm = this.formBuilder.group({
      metadatadetails: this.formBuilder.array([''])
    });
    

    this.timelineForm = this.formBuilder.group({
      name:[''],
      folderuuid:[''],
      start_date:[''],
      end_date:['']

    })
  }
  get c(){
    return this.timelineForm.controls;
  }

  getInfo(key: any) {
    console.log(key);
    setTimeout(()=> {
      this.getStorage();
    }, 1000)

    console.log(key)
    if (key == '1') {
      this.showSideBar = false;
      this.detailsSection = false;
      console.log('aaa');
      this.sidebarOpened.emit(this.showSideBar);
      
    }
    else {
      if(this.storageType.user_type == '2'){
        this.showSideBar = true;
        this.storagesectionHide = true;
        this.detailsSection = false;
        this.sidebarOpened.emit(this.showSideBar);
      }
    }

  }
  getDashboardDetails() {
    this.ngxUiLoaderService.start();
    this.api.getDashboardDetails().subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.response = data;
      console.log(this.response)
      if (this.response.status == 200) {
        this.dashboardProjectlist = this.response.project_list;
        console.log(this.dashboardProjectlist);
        this.dashboardRecentfilelist = this.response.Recent_files;
        this.dashboardRecentfolderlist = this.response.Folder_list;
        console.log(this.dashboardRecentfolderlist);
        console.log(this.response);
        this.dashboardRecentdetails = this.response.details;
        console.log( this.dashboardRecentdetails);
        this.dashboardRecentStoragedetails = this.response.storage;
        console.log( this.dashboardRecentStoragedetails);

        
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getStorage(){
    this.chartOptions = {
      series: [this.dashboardRecentStoragedetails.percentage],
      chart: {
        height: 250,
        type: "radialBar",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: "#fff",
            strokeWidth: "90%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px"
            },
            value: {
              formatter: function(val) {
                return parseInt(val.toString(), 10).toString() + '%';
              },
              color: "#111",
              fontSize: "36px",
              show: true
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#6189F7", "255AEE"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: [""]
    };
  }

  get z() { return this.profileForm.controls.metadatadetails as FormArray; }
  

  openSideMenu(uuid){
    let data = uuid;
    this.file_uiid = uuid;
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.getViewDetails(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      console.log(data, 'joooooooooooooooooooooooo');
      this.viewDetailsData = data;
      console.log(this.viewDetailsData);
      if (this.viewDetailsData.status == 200) {
        console.log(this.viewDetailsData);
        this.viewDetailsList = this.viewDetailsData.file_Preview_result.file_list;
        console.log(this.viewDetailsList);
        this.metadataDetails = this.viewDetailsData.file_Preview_result.metadata_details;

        console.log(this.metadataDetails);
        this.NometadataAdded = this.viewDetailsData.file_Preview_result.key;
        console.log(this.NometadataAdded);
        if (this.NometadataAdded == 1) {
          this.keyHide = true;
        }

        this.NometadataforFolder = this.viewDetailsData.file_Preview_result.folder_key;
        console.log(this.NometadataforFolder, 'aaaaaaaaaaaaaaaa');


        if (this.metadataDetails) {
          this.metadataDetailsDate = this.metadataDetails.keyword;
          this.metadatName = this.metadataDetails.template_details;
          this.metadataId = this.metadataDetails.template_uuid;
        } else {
          this.metadatName = '';
        }

        console.log(this.metadataId);
        if (this.metadataId && this.metadataDetails && this.metadataDetails.keyword) {
          console.log(this.metadataDetails);
          this.updateMetadetailsPreview(this.metadataDetailsDate)
        }
        console.log(this.z.value)
        console.log(this.metadatName);
        this.getTemplateFilter();
        this.viewDetailsList2 = this.viewDetailsData.file_Preview_result.shared_user;
        console.log(this.viewDetailsList2);
        this.activityDetails = this.viewDetailsData.file_Preview_result.activiy;


        if (this.NometadataAdded == 1) {
          console.log("this.z", this.z.controls)
          this.z.clear();
        }

        this.dataUuid = null;


      } else {
        this.snackBar.open(this.viewDetailsData.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  
  }

  showPreview(key) {
    if (key == 'info') {
      this.infoSection = true;
      this.activitySection = false;
    } else {
      this.infoSection = false;
      this.activitySection = true;
    }
  }

  updateMetadetailsPreview(val, preview = null) {
    console.log("fds", val)
    this.profileForm.reset();
    var metadatas = this.profileForm.controls.metadatadetails as FormArray;
    metadatas.clear();
    for (let key in val) {
      console.log(metadatas)
      console.log(metadatas.controls)
      console.log(metadatas.controls)
      metadatas.controls[key] = this.formBuilder.group({
        [val[key].id]: ['', [Validators.required]],
        field_type: ['', [Validators.required]],
        id: ['', [Validators.required]],
        keyword: ['', [Validators.required]],
        keyword_label: ['', [Validators.required]]
      })
      metadatas.controls[key].get('field_type').setValue(val[key].field_type)
      metadatas.controls[key].get('id').setValue(val[key].id)
      metadatas.controls[key].get(val[key].id).setValue(val[key].field_value)
      metadatas.controls[key].get('keyword').setValue(val[key].keyword)
      metadatas.controls[key].get('keyword_label').setValue(val[key].keyword_label)
    }
    this.profileForm.controls.metadatadetails.updateValueAndValidity();
    console.log(this.z.value)
  }
  getTemplateFilter() {
    this.api.getTemplate().subscribe(data => {
      console.log(data);
      this.templateData = data;
      if (this.templateData.status == 200) {
        this.templateList = this.templateData.results;
        console.log(this.templateList);

      } else {
        this.ngxUiLoaderService.stop();
        console.log('wqerttys');
      }
    }, error => {
      console.log('pending');
      this.ngxUiLoaderService.stop();
    });
  }
  enableSelect() {
    this.isDisabled = false;
    this.isDatePickerDisabled = false;
    this.isInputDisabled = false;
    this.isReadOnly = !this.isReadOnly;
    this.isButtonHidden = true;
    this.isButtonHiddenCancel = false;
    this.isButtonHiddenSubmitt = false;
  }

  cancelButton() {
    this.isDisabled = true;
    this.isDatePickerDisabled = true;
    this.isInputDisabled = true;
    this.isReadOnly = true;
    this.isButtonHidden = false;
    this.isButtonHiddenCancel = true;
    this.isButtonHiddenSubmitt = true;
    this.profileForm.patchValue(this.z.value);
  }

  getMetadataDetails() {
    console.log(this.profileForm.getRawValue().metadata)
    // this.metdata_data = {
    //   file_id:this.file_Id,
    //   meta:this.metadataId,
    //   entityType : "1",
    //   ...this.profileForm.getRawValue()
    // }
    const dataArray = [{
      file_id: this.file_uiid,
      meta: this.metadataId,
      ...this.profileForm.getRawValue()
    }];

    console.log(dataArray);
    
    console.log(this.NometadataAdded);
    if (this.NometadataAdded) {
      var entityType = '';
    } else {
      var entityType = '1';
    }
    let data = {
      metadata: dataArray,
      entityType: entityType
    }
    this.ngxUiLoaderService.start();
    this.api.saveUploaddetails(data).subscribe(data => {
      console.log(data);
      this.UpdateMetadataResponse = data;
      if (this.UpdateMetadataResponse.status == 200) {
        this.ngxUiLoaderService.stop()
        this.snackBar.open(this.UpdateMetadataResponse.message, "Dismiss", {
          duration: 3000,
        })
        // this.openSideMenu('close');
        this.cancelButton();

      } else {
        this.ngxUiLoaderService.stop()
        this.snackBar.open(this.UpdateMetadataResponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {
      this.ngxUiLoaderService.stop();

    });
  }
  onSelectionChange(event, key: any, preview = null) {
    this.keyHide = false;
    console.log(event)
    console.log(key);
    
    this.gettemplateData(event, key, preview);
  }

  gettemplateData(value, val, preview) {
    console.log(preview)
    this.ngxUiLoaderService.start();
    this.api.gettemplateData(value).subscribe(data => {
      console.log(data);
      this.templateresponse = data;
      if (this.templateresponse.status == 200) {
        this.ngxUiLoaderService.stop();
        console.log(this.templateresponse.template_details);
        this.templateDetails = this.templateresponse.template_details.keyword;
        console.log(this.templateDetails);
        if (!preview) {
          var metadatadetails = this.z.at(val).get('metadatadetails') as FormArray;
          while (metadatadetails.length) {
            metadatadetails.removeAt(0);
          }
          for (let key in this.templateDetails) {
            console.log(key);
            let jey = key
            console.log(this.z);
            console.log(this.z.at(val))
            var metadatadetails = this.z.at(val).get('metadatadetails') as FormArray;
            console.log(metadatadetails)
            console.log(this.templateDetails)
            metadatadetails.push(this.formBuilder.group({
              [this.templateDetails[key].id]: ['', [Validators.required]],
              id: this.templateDetails[key].id,
              field_type: this.templateDetails[key].field_type,
              keyword_label: this.templateDetails[key].keyword_label
            }));
            this.z.updateValueAndValidity()
            console.log(metadatadetails.controls[key]);
            console.log(this.z.at(val))
            this.z.at(val).get('meta').setValue(value);
            this.z.updateValueAndValidity();
            console.log(this.profileForm)
          }
          this.profileForm.patchValue(this.profileForm.value);
          console.log(this.profileForm);
        } else {
          this.updateMetadetailsPreview(this.templateDetails, preview);
        }
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.templateresponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  updateTimeline(edit=null){
    $('#deleteModalforArchieve_open').modal('hide');

    if (edit == '1') {
      this.folderdetailsdata = this.fulldetails;
    }

    console.log(this.folderdetailsdata);
    console.log(this.folderdetailsdata.start_date);
    this.todayDate = this.folderdetailsdata.start_date;

    this.timelineForm.get('name').setValue(this.folderdetailsdata.name);
    this.timelineForm.get('folderuuid').setValue(this.folderdetailsdata.uuid);
    this.timelineForm.get('start_date').setValue(this.folderdetailsdata.start_date);
    this.timelineForm.get('end_date').setValue(this.folderdetailsdata.end_date);
    $('#folderEdit_modal').modal('show');
    
  }
  
  updateEndDate() {
    if (new Date(this.c.end_date.value) < new Date(this.c.start_date.value)) {
      this.timelineForm.get('end_date').setValue(this.c.start_date.value);
    }
  }
  closeTimelinemodal(){
    $('#folderEdit_modal').modal('hide');
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  updatefolderTimeline(){
    let value= this.timelineForm.value;
    console.log(moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.timelineForm.value.end_date))
    if (moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.timelineForm.value.end_date);
    } 
    if (moment(this.timelineForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.timelineForm.value.start_date);
    } 
    console.log( this.timelineForm.value);

    let data = this.timelineForm.value;
    this.ngxUiLoaderService.start();
    this.api.updateFolderTimeline(data).subscribe(data=>{
      console.log(data);
      this.timelineResponse = data;
      if(this.timelineResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
        $('#folderEdit_modal').modal('hide');
        this.showSideBar = false;
        // this.projectTree = false;
        // this.getProjectData();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
    
  }
  toShare(){
    var data = {
      entity_type: 2,
      id: [this.dataUuid],
      name:this.file_name
    }
    this.modalService.openModal(data, 'folder');
    
  }

  
}
