import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init({ disable: 'mobile'});
    let lineBtn: any =   document.getElementById('lineView');
    let gridBtn: any =    document.getElementById('gridViewBtn'); 
    let table: any = document.getElementById('tableview');
    let gridview: any = document.getElementById('gridView'); 
    lineBtn.style.cssText = 'filter: grayscale(100%);';
    gridBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';

      lineBtn.addEventListener('click', () => {
          console.log('aAAA')
          table.style.cssText = 'display:block;';
          gridview.style.cssText = 'display:none;';
          lineBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';
          gridBtn.style.cssText = 'filter: grayscale(100%);';
          //lineBtn.setAttribute('src','images/3line.png');
      });

      gridBtn.addEventListener('click', () => {
          console.log('aAAA')
          table.style.cssText = 'display:none;';
          gridview.style.cssText = 'display:block;';
          gridBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';
          lineBtn.style.cssText = 'filter: grayscale(100%);';
          //lineBtn.setAttribute('src','images/3dot_black.png');

      })


      $('.tabOption').click(function() {
        var tab_id = $(this).attr('data-tab');
        $('.line').removeClass('lineExtent');
        if(tab_id == 'tab-1') {
            console.log('1111')
            $(this).find('.line').addClass('lineExtent');
            $('.lineExtent').css("width", "71px");
        }else if(tab_id == 'tab-2'){
            console.log('222')
            $(this).find('.line').addClass('lineExtent');
            $('.lineExtent').css("width", "110px");
        }else {
            console.log('000')
            $(this).find('.line').addClass('lineExtent');
            $('.lineExtent').css("width", "110px");
        }
       
        $('.tabOption').removeClass('current');
        $('.tab-content').removeClass('current');
        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
        })
  }

  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }
  
  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }




  getInfo(key) {
    console.log('wwww')
    let myMediaQueryMobile = window.matchMedia('(max-width: 536px)');
    if(key == '1') {
        
        let myMediaQuery = window.matchMedia('(max-width: 1480px)');
        if(myMediaQuery.matches){
            $("#preview_info").css("display", "block");
            $('#tableview').css({
                // 'color' : 'red',
                // 'background-color' : 'yellow',
                // 'font-size' : '24px'
                'width': '500px',
                'overflow-y': 'hidden',
                'overflow-x': 'auto',
                '-webkit-overflow-scrolling': 'touch'
            });
            $('#table-main').css({
                // 'color' : 'red',
                // 'background-color' : 'yellow',
                // 'font-size' : '24px'
                'width': '1000px',
                'margin-bottom': '0',
                'max-width': 'none'
            });
            if(myMediaQueryMobile.matches) {
                console.log('@@@')
                $('#preview_info_mobile').css({
                    // 'color' : 'red',
                    // 'background-color' : 'yellow',
                    // 'font-size' : '24px'
                    'width': '100%',
                    'padding': '23px',
                });
            }
        }
        else {
            $("#preview_info").css("display", "block");
            $('#table-main').css({
                'width': '100%',
            });
            $('#tableview').css({
                'width': '100%',
            }); 
        }
        

        
    }else {

        if(myMediaQueryMobile.matches) {
            console.log('@@@')
            $('#table-main').css({
                // 'color' : 'red',
                // 'background-color' : 'yellow',
                // 'font-size' : '24px'
                'width': '1100px',
            });
            $('#preview_info_mobile').css({
                    // 'color' : 'red',
                    // 'background-color' : 'yellow',
                    // 'font-size' : '24px'
                    'width': '0',
                    'padding': '0',
                });
        }else {
            $("#preview_info").css("display", "none");
            $('#table-main').css({
                'width': '100%',
            });
            $('#tableview').css({
                'width': '100%',
            });
        }
    }
    
}

}
