import { Injectable, ɵConsole } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { tap, catchError,map,switchMap, filter, take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    public currrentLang:string = "en";
    private isRefreshing = false;
    header_txt:any;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private authenticationService: AuthenticationService,
                // private translate: TranslateService,
                private http: HttpClient,
                private router: Router,
                private ngxUiLoaderService: NgxUiLoaderService,
                public snackBar : MatSnackBar) { } 

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
      let authReq = request;
      return next.handle(request).pipe(catchError(err => {
        console.log(err)
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        if (err.status == 403) {
          this.router.navigate(['/dashboard'])
          this.snackBar.open(err.error.message, "Dismiss", {
            duration: 3000,
          })
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      }))
      }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser.token) {
          return request.clone({
              setHeaders: { 
                Authorization: `Bearer ${currentUser.token  }`,
                language: this.currrentLang
              }
          });
        }
        /* for Spring Boot back-end */
        // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        /* for Node.js Express back-end */
      }

    //   privategetAlert(error) {
    //     console.log(error)
    //     var header = 'Account disconnected !';
    //     var button = 'Agree';
    //     const alert = this.alertController.create({
    //       header: header,
    //       message: error.deviceInfo,
    //       backdropDismiss: false,
    //       buttons: [
    //                 {
    //                   text: button,
    //                   handler: () => {
    //                     console.log('Cancel clicked');
    //                     this.authenticationService.logout();
    //                     this.router.navigate(['/signin']);
    //                     //location.reload();
    //                   }
    //                 }
    //               ],        
    //   });
    //    alert.then((_alert: any)=> {
    //       _alert.present();
    //   })

    //   }

}