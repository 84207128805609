import { Component, OnInit, ElementRef, OnDestroy, ViewEncapsulation, ChangeDetectorRef,ViewChild,AfterViewInit  } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, ActivatedRoute, Params  } from '@angular/router';
import '../../_models/tags-input'; 
import { Location } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from '../../_services/api.services';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
declare var $: any;
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment-timezone';
import { error } from 'console';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit,AfterViewInit  {
  @ViewChild('sectionId',{ static: false }) sectionElement: ElementRef;

  public settingsForm: FormGroup;
  currentUrl:any;
  previousUrl:any;
  teamsMembers = false;
  selectedOption:any = "";
  responsemembers: any;
  uuid: any;
  response: any;
  projectData: any;
  submitted = false;
  submittedGroup = false;
  projectMembers: any;
  randomColors: string[] = [];
  projectExistingMembers = [];
  responsedata: any;
  proJectDetails: any;
  proJectDetailsTag: any;
  startDate: string;
  endDate:string;
  proJectDatadetailsData: any;
  responseActivelist: any;
  activeUserlist: any;
  responsegroup: any;
  grouplist: any;
  showInnerDiv: any[];
  groupMembers: any;
  responseProjectTypes: any;
  ProjectTypeslist: any;
  grouMembers:any[]=[];
  submitUpdateresponse: any;
  private tagInput1: any;
  addGroupMembersResponse: any;
  removedMembers:any[]=[];
  private tagInput3: any;
  removeTagArrr: any []=[];
  groupList: any;
  private tagInput: any ; 
  tags: any;
  grouMembersuuid: any []=[];
  useruuid: any;
  colors: any []=[];
  responsedelete: any;
  userList: any;
  colorss: any []=[];
  todayDate:Date = new Date(23, 59, 59);
  min = new Date(1924, 4, 10, 10, 50);
  name: any;
  priorityResponse: any;
  priority: any;

  constructor(private router: Router,
              private elementRef: ElementRef,
              private route : ActivatedRoute,
              private location: Location,
              private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService,
              public formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef,
              public snackBar : MatSnackBar) { 
                this.getProjectSettings();
              }

  ngOnInit() {
    
    
    this.settingsForm = this.formBuilder.group({
      project_name : ['',[Validators.required,Validators.maxLength(32)]],
      project_code : ['',[Validators.required, Validators.pattern("^[0-9]*$")]],
      project_type : ['',[Validators.required]],
      project_description : ['',[Validators.required]],
      project_start_date : ['',[Validators.required]],
      project_end_date : ['',[Validators.required]],
      tags : ['',[Validators.required]],
    });
    this.previousUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        console.log(this.previousUrl,"line 25")
      };
    });

    

  setTimeout(()=> {
    this.tagInput = new TagsInput({
      selector: 'tag-input1',
      duplicate: false,
      max: 10,
      icon:"assets/images/blue_cross.png",
    });
    this.tagInput.addData([]);

    console.log(this.tagInput)
  }, 1000)  

    this.getProjectType();
    this.route.params.subscribe((params: Params) => {
      this.uuid = params['id'];
      this.getProjectSettings();
      //this.getProjectMembers();
    });


    // this.tagInput3 = new TagsInput({
    //   selector: 'tag-input3',
    //   duplicate: false,
    //   max: 10,
    //   icon:"assets/images/blue_cross.png"
    // });
    this.getPriority();
  }

  ngAfterViewInit(): void {
   console.log('kutttaaa');
    this.route.fragment.subscribe(fragment => {
      console.log(fragment);
      if (fragment == 'sectionId') {
        console.log('vayyoo');
        setTimeout(() => {
            this.scrollToSection();
        },1000);
      }
    });
  }

  scrollToSection() {
    console.log(this.sectionElement);
    if (this.sectionElement) {
      console.log(this.sectionElement);
      this.sectionElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  getValueFromInput() {
    const inputValue = this.tagInput.nativeElement.value;
    console.log('Input value:', inputValue);
  }

  get b() {
    return this.settingsForm.controls;
  }

  
 

  showTeams(val) {
    if ($("#members"+val).hasClass("intro") == false) {
      $(".subSection").removeClass("intro")
      $(".mr-12").removeClass("rotateClass")
      $("#members"+val).addClass("intro");
      $("#member"+val).addClass("rotateClass");
    } else {
      $(".subSection").removeClass("intro")
      $(".mr-12").removeClass("rotateClass")
    }

  }

  goBack() {
    this.location.back();
  }
  

  getProjectSettings() {
    this.ngxUiLoaderService.start();
    this.api.getProjectSettings(this.uuid).subscribe(data=> { 
      this.ngxUiLoaderService.stop();
      this.response = data;
      if (this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        this.projectData = this.response.results;
        console.log(this.projectData,'11111111111');
        this.proJectDetails = this.response.results.project_details
        this.groupList = this.response.results.group;
        console.log(this.groupList);
        this.name = this.projectData.project_details.project_name
        this.colorss = this.groupList.map(() => this.generateRandomColor1());
        this.userList = this.response.results.users;
        console.log(this.colorss);
        //this.selectedOption = this.proJectDetails.project_type;
        // console.log(this.proJectDetails,'222222222');
        this.proJectDetailsTag = this.response.results.tags
        console.log(this.proJectDetailsTag); 
        // console.log(this.proJectDetailsTag,'33333333');
        this.proJectDatadetailsData = this.response.results.details_data;
        console.log(this.proJectDatadetailsData,'444444');
        
        // this.tagInput.addData(this.projectData.tags);
        this.updateFormData();
      }
      else{
        this.ngxUiLoaderService.stop();
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  // convertOwl(str) {
  //   if(str == null)
  //         return str;
  //     var mnths = {
  //       Jan: "01",
  //       Feb: "02",
  //       Mar: "03",
  //       Apr: "04",
  //       May: "05",
  //       Jun: "06",
  //       Jul: "07",
  //       Aug: "08",
  //       Sep: "09",
  //       Oct: "10",
  //       Nov: "11",
  //       Dec: "12"
  //     },
      
  //     date = str.toString().split(" ");
  //     console.log(date)
  //     if(date.length == 1)
  //       return str;
  //     else
  //       return [date[2], mnths[date[1]],date[3]].join("-");
  //   }
  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  onSubmitProjectSettings() {
    console.log(this.settingsForm.value)
    this.submitted = true;
    if(this.settingsForm.invalid) {
      console.log('dfsf',this.settingsForm);
      
      return
    }
    var data = this.settingsForm.value;
    if (moment(this.settingsForm.value.project_end_date, 'MM/DD/YYYY',true).isValid()) {
      data['project_end_date'] = this.convertOwl(this.settingsForm.value.project_end_date);
    } 
    if (moment(this.settingsForm.value.project_start_date, 'MM/DD/YYYY',true).isValid()) {
      data['project_start_date'] = this.convertOwl(this.settingsForm.value.project_start_date);
    } 
    console.log(data)
    console.log(this.uuid );
    this.ngxUiLoaderService.start();
    this.api.submitProjectupdate(data,this.uuid).subscribe(data =>{
      this.ngxUiLoaderService.stop();
      console.log(data);
      this.submitUpdateresponse = data;
      if(this.submitUpdateresponse.status == 200){
        this.snackBar.open(this.submitUpdateresponse.message, "Dismiss", {
          duration:3000,
        })
        // this.getProjectSettings();
        this.router.navigate(['/projects']);
      }else{
        this.snackBar.open(this.submitUpdateresponse.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

  updateFormData() {
    this.settingsForm.get('project_name').setValue(this.projectData.project_details.project_name);
    // this.settingsForm.get('project_type').setValue(this.projectData.project_details.project_type);

    console.log(this.projectData.project_details.project_type);
    var val = parseInt(this.projectData.project_details.project_type);
      this.selectedOption = val;
      this.settingsForm.get('project_type').setValue(this.selectedOption);
      console.log("1288888888888888",val);
      this.settingsForm.get('tags').setValue(this.proJectDetailsTag);
    this.settingsForm.get('project_code').setValue(this.projectData.project_details.code);
    this.settingsForm.get('project_description').setValue(this.projectData.project_details.project_description);
    this.settingsForm.get('project_start_date').setValue(this.projectData.project_details.project_start_date);
    this.settingsForm.get('project_end_date').setValue(this.projectData.project_details.project_end_date);
    this.settingsForm.get('tags').setValue(this.projectData.tags);
    

    // this.startDate = this.projectData.project_details.project_start_date;
    // this.endDate = this.projectData.project_details.project_end_date;

  }

  getProjectMembers() {
    var data = {
      ProjectId: this.uuid
    }
    this.ngxUiLoaderService.start();
    this.api.getProjectMembers(data).subscribe(data=> { 
      this.ngxUiLoaderService.stop();
      this.responsemembers = data;
      if (this.responsemembers.status == 200) {
        this.projectMembers = this.responsemembers.results;
        this.projectExistingMembers = this.projectMembers.existing_members;
        this.projectExistingMembers = [
        {
          "name":"addUsers",
          "id":"1",
          "uuid": '123'
        },
        {
            "name":"archiveDelete",
            "id":"1",
            "uuid": '456'
        },
        {
            "name":"createFile",
            "id":"1",
            "uuid": '789'
        }
      ]
        if (this.projectMembers) {
          if (this.projectMembers.group.length != 0){
            this.randomColors = this.projectMembers.group.map(group => this.generateRandomColor());
            this.cdRef.detectChanges();
            console.log(this.randomColors)
          }
          $('#addUserModal').modal('show');
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  generateRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  removeMember(uuid) {
    console.log(uuid)
    console.log(this.projectExistingMembers)
    this.projectExistingMembers = this.projectExistingMembers.filter(member => member.uuid !== uuid);
    console.log(this.projectExistingMembers)
  }

  // addGroup(user) {
  //   const uuidExists = this.projectExistingMembers.some(member => member.uuid === user.uuid);
    
  //   if (!uuidExists) {
  //     this.projectExistingMembers.push(user);
  //   } else {
      
  //     this.snackBar.open('Group or User Already Exists', "Dismiss", {
  //       duration:3000,
  //     })
  //   }
  //   this.ngxUiLoaderService.start();
  //   $('#addUserModal').modal('hide');
  //   location.reload();
  //   this.ngxUiLoaderService.stop();
    
  // }

  updateProjectMmbers() {
    const uuidArray = this.projectExistingMembers.map(member => member.uuid);
    console.log(uuidArray)
    var data = {
      user_id: uuidArray
    }
    this.ngxUiLoaderService.start();
    this.api.updateProjectMembers(data).subscribe(data=>{ 
      this.ngxUiLoaderService.stop();
      this.responsedata = data;
      if (this.responsedata.status == 200) {
        // $('#addUserModal').modal('hide');
        $('#addUserModal').on('hidden.bs.modal', () => {
          this.ngxUiLoaderService.stop();
          }).modal('hide');
        // location.reload();
        // this.ngxUiLoaderService.start();
      }
      // this.ngxUiLoaderService.stop();
      
      this.snackBar.open(this.responsedata.message, "Dismiss", {
        duration:3000,
      })
    },
    error => {
      this.ngxUiLoaderService.stop();
    })
  }
  closeModal() {
    this.ngxUiLoaderService.start();
    $('#addUserModal').on('hidden.bs.modal', () => {
    this.ngxUiLoaderService.stop();
    }).modal('hide');
  }

  openAddGroupModal(){
    // this.getProjectSettings();
    

    console.log(this.grouMembers);
    this.getActiveUsers()
    this.getGroups();
    
  }

  getActiveUsers() {
    
    this.ngxUiLoaderService.start();
 
    var data = {
      entityType : '1',
    }
    console.log(data);
    
    this.api.getActiveUser(data).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responseActivelist = data;
      if (this.responseActivelist.status == 200) {
        this.activeUserlist = this.responseActivelist.results;
        console.log(this.activeUserlist);
        
      }else{
        this.snackBar.open(this.responseActivelist.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 

  getGroups(val=null) {
    this.ngxUiLoaderService.start();
    this.api.getGroups().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsegroup = data;
      if (this.responsegroup.status == 200) {
        this.grouplist = this.responsegroup.results;
        this.colors = this.grouplist.map(() => this.generateRandomColor1());
        console.log(this.grouplist);
        
        this.grouMembers = [];
        if (!val) {
          $('#addUserModal').modal('show');
        }
        this.ngxUiLoaderService.stop();
        console.log(this.grouplist,"ppppp");
        console.log(this.groupMembers,'12333');
        this.showInnerDiv = new Array(this.grouplist.length).fill(false);
      
      }else{
        this.snackBar.open(this.responsegroup.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 

  generateRandomColor1(): string {
    return '#' + Math.floor(Math.random() * 16777215).toString(16); 
  }
  getShortName(group_name) { 
    return group_name.charAt(0);
    // return group_name.split(' ').map(n => n[0]).join('');
    // return group_name.replace(/\s+/g, '').substring(0, 2).toUpperCase();
  }

  getProjectType() {
    this.api.getProjectType().subscribe(data=>{
      this.responseProjectTypes = data;
      if (this.responseProjectTypes.status == 200) {
        this.ProjectTypeslist = this.responseProjectTypes.result;
        console.log(this.ProjectTypeslist);
        // this.selectedOption = this.ProjectTypeslist;
        // console.log(this.selectedOption) 
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }
  adduser(user){
    console.log(user,'clicked');
    const isUserPresent = this.grouMembers.some(member => member.id === user.id);
    if(!isUserPresent){
      console.log(user);
      this.grouMembers.push(user)
      console.log(this.grouMembers,'22222222222');
    }
  }

  removeUser(member,index: number, val) {
    console.log(val);
    this.grouMembers.splice(index, 1);
    console.log(this.grouMembersuuid);
    var indexnumber = this.grouMembersuuid.indexOf(member.uuid);
    if (indexnumber !== -1) {
        this.grouMembersuuid.splice(indexnumber, 1);
    }
    if (val != 1) {
      this.removedMembers.push(member)
    }
    console.log(this.grouMembersuuid);
    

  }

  addGroup(groups){
    console.log('clicked',groups);
   
    const isGroupPresent = this.grouMembers.some(member => member.id === groups.id);
    console.log(isGroupPresent);
    console.log(groups.id);
    if(!isGroupPresent){
      this.grouMembers.push(groups)
      this.grouMembersuuid.push(groups.uuid)
      console.log(this.grouMembersuuid,'22222222222');
    }
  }


  addGroupMembers(){
    console.log(this.grouMembersuuid.length);
    this.submittedGroup = true;

    if(this.grouMembersuuid.length == 0){
      return
    }

    let data = {
        project_id:this.uuid,
        user_id:this.grouMembersuuid,
        removed_user: this.removedMembers
      };
    console.log('click',data);
    this.ngxUiLoaderService.start();
    this.api.addGroupmember(data).subscribe(data=>{
      console.log(data);
      this.addGroupMembersResponse = data;
      if(this.addGroupMembersResponse.status == 200){
        
        console.log(this.addGroupMembersResponse);
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.addGroupMembersResponse.message, "Dismiss", {
          duration:3000,
        })
        this.getProjectSettings();
        
        this.submittedGroup = false;
        $('#addUserModal').modal('hide');
        $(".modal-backdrop").remove();
      }else{
        this.snackBar.open(this.addGroupMembersResponse.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }


  removeTag(){
    let data = this.removeTagArrr
    this.api.removeProjectTag(data).subscribe(data =>{
      console.log(data);
      
    })
  }



  closeAdduserProjectmodal(){
    // console.log('clicked', this.groupList);
    
    // const tagsWrapper = document.querySelector('.members_container');
    //     if (tagsWrapper) {
    //       const spanTags = tagsWrapper.querySelectorAll('div');
    //       spanTags.forEach(div => {
    //         div.parentNode.removeChild(div);
    //       });
    //     }

    this.submittedGroup = false;
    $('#addUserModal').modal('hide');
  }
  deleteUserList(id){
    this.useruuid = id;
    console.log(this.useruuid);
    $('#deleteModal3').modal('show');
  }


  deleteUser() {
    var data = {
      project_id: this.uuid,
      member_id: this.useruuid,
    }
    this.ngxUiLoaderService.start();
    this.api.deleteUserGroup(data).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsedelete = data;
      if (this.responsedelete.status == 200) {
        this.useruuid = null;
        this.getGroups(1);
        this.getProjectSettings();
        $('#deleteModal3').modal('hide'); 
        $(".modal-backdrop").remove();    
      }
      this.snackBar.open(this.responsedelete.message, "Dismiss", {
        duration:3000,
      })
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }
  clickCancel(){
    this.router.navigate(['/projects']);
    this.submitted = false;
  }

  updateEndDate() {
    if (new Date(this.b.project_end_date.value) < new Date(this.b.project_start_date.value)) {
      this.settingsForm.get('project_end_date').setValue(this.b.project_start_date.value);
    }
  }
  getPriority(){
    this.ngxUiLoaderService.start();
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }
}
