
<nav>
       
    <div class="d-flex align-items-center justify-content-between main">
        <img src="assets/images/logolg2.png" alt="">
        <ul>

            <li class="mr-4">Home</li>
            <li class="mr-4">Features</li>
            <li class="mr-4">About</li>
            <li class="mr-4">En</li>
            <li routerLink="/signup" class="mr-4">Sign Up</li>
            <li routerLink="/login" class="mr-4">Login</li>
                
        </ul>
    </div>

</nav>

<div class="domain-permission mb-5">
    <div class="container_1">
        <div class="main">
            <div class="profile-sec">
                <div class="heading-main">Do you have a business domain?</div>
                <div class="subText">You’ll need a domain, like example.com, to setup <br>email and a business account.</div>
    
                <div class="btn_grp">
                    <button class="btn-1" (click)="haveAccount('1')">Yes, I have one that i can use</button>
                    <button class="btn-2" (click)="haveAccount('0')">No, I need one</button>
                </div>
            </div>
        </div>
    
    </div>
    </div>
