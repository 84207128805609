import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
declare var $: any;
import { ApiService } from '../../_services/api.services';
import { MatStepper } from '@angular/material/stepper';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment-timezone';
import { log } from 'console';

@Component({
  selector: 'app-move-modal',
  templateUrl: './move-modal.component.html',
  styleUrls: ['./move-modal.component.scss']
})
export class MoveModalComponent implements OnInit {
  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  options1: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  @Input() modalData: string;
  FolderFileData: any;
  FolderFileList: any;
  dataSource = [];
  matstep = [];
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  steps = [];
  responsemove: any;
  responseMove: any;
  selectedValue: any;
  sharedViewlist: any;
  sharedUserViewlist: any;
  responseSharedView: any;
  movefolderiD: any;
  project_Id: any;
  responselist: any;
  userlist: any;
  userlistdataall: any;
  isSubmitted = false;
  modalSubmitted = false ;
  shareProfileResponse: any;
  public createForm: FormGroup;
  users = [];
  access: string;
  moveProjectFileResponse:any;
  renameResponse: any;
  renameSubmitted: boolean;
  renameForm: any;
  file_Id: any;
  image: any;
  loading: boolean = true;
  fileExt: any;
  projectGetlinkresponse: any;
  getlink: any;
  val = 0;
  filename: any;
  private modalSubscription: Subscription;
  grouMembersuuid : any[]=[];
  grouMembers:any[]=[];
  signatureRequestdetails: any;
  groupSubmitted = false;
  filedetailsResponse: any;
  dataDetials: any;
  metadatName: any;
  metadata: any;
  folder_code: any;
  createArchieveForm: FormGroup;
  filesId: any;
  archievemodalResponse: any;
  usserUUid: any[]=[];
  uUId: any;
  currentValue: any;
  folderSubmitted: boolean;
  currentUser: string;
  ownerUser: any;
  adminUser: any;
  groupList: any;
  mergedList : any[]=[]; 
  nameShare: any;
  comment: any;
  autocompleteItems : any[]=[]; 
  colorss: any;
  colors: any[]=[];
  todayDate = new Date();
  checkOutForm: FormGroup;
  startDate: any;
  checkoutfileResponse: any;
  getLinkForm: FormGroup;
  nextMonthDate: string;
  nameMove: string;

  constructor(private modalService: ModalService,
              private cdr: ChangeDetectorRef,
              private api: ApiService,
              public formBuilder: FormBuilder,
              private ngxUiLoaderService: NgxUiLoaderService,
              public snackBar: MatSnackBar,
              protected _sanitizer: DomSanitizer) {

              }

  ngOnInit() {
    this.getActiveUsers(2);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.createForm = this.formBuilder.group({
      email : [''],
      limit:['',Validators.required],
      comment: ['',[Validators.pattern("^(?! ).*")]]
    });

    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)
    
    this.renameForm = this.formBuilder.group({
      newfile_name: ['', [Validators.required, Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ0-9, .!@#$%^&*()_+-=]+$"),Validators.maxLength(32)]],
      folder_code: [{ value: '', disabled: true }, [Validators.required,Validators.pattern('^[0-9]+$')]]
    })
    this.createArchieveForm = this.formBuilder.group({
      users: [''],
      name: ['', [Validators.required,Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ0-9, !@#$%^&*()_+\\-={}\\[\\]:\";'<>?,./`~|]+$")]],
      comment: ['', [Validators.required,Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ0-9, !@#$%^&*()_+\\-={}\\[\\]:\";'<>?,./`~|]+$")]],

    })

    this.checkOutForm = this.formBuilder.group({
      return_date:['',[Validators.required]],
      comment: [''],
      id:['']
    })

    this.getLinkForm = this.formBuilder.group({
      comment : [''],
      uuid:['']
    });


    console.log(this.modalService)
    this.modalSubscription = this.modalService.showModal.subscribe((show: any) => {
      this.steps = [];
      this.val = 0;
      console.log('Received show value:', show);
      this.movefolderiD = show.id;
      console.log(this.movefolderiD);
      console.log(show.id);
      
      console.log(show.entity_type);
      this.filesId = show.files;

      if (show.entity_type == 1) {
        this.getProjectDetails();
        this.selectedValue = '';
        if(this.movefolderiD.length >= 2){
          this.nameMove = `${this.movefolderiD.length} Documents`
        }else{
          this.nameMove = show.name;
        }
        $('#myModal').modal('show');
        return
      } else if (show.entity_type == 3){
        this.file_Id = show;
        this.folder_code = null;
        console.log(this.file_Id);
        this.renameForm.get('newfile_name').setValue( this.file_Id.data.name);

        if(this.file_Id.data.folder_code){
          if (this.currentValue.permissions.EditFolderCode == '1') {
            this.renameForm.get('folder_code').enable();
          }else{
            this.renameForm.get('folder_code').disable();
          }

          this.a['folder_code'].setValidators([Validators.required,Validators.pattern('^[0-9]+$')]);
          this.a['folder_code'].updateValueAndValidity();
          console.log('vayoooo',this.file_Id.data.folder_code);
          this.folder_code = this.file_Id.data.folder_code;
          this.renameForm.get('folder_code').setValue( this.file_Id.data.folder_code);
          console.log('vayoooo',this.folder_code);
        }else{
          console.log('sdasd');
          this.a['folder_code'].clearValidators();
          this.a['folder_code'].updateValueAndValidity();
        }

        $('#renameCreateModal').modal('show');
        return
      } else if (show.entity_type == 4) {
        console.log(this.image);
        this.image = '';
        console.log(this.image);
        
        this.loading = true;
        this.fileExt = show.data;
        this.image = show.data.file_url;
        console.log(this.image);
        
        console.log(this.fileExt)
        this.filename = show.data.file_name;
        console.log(this.filename);

        this.filePreviewDetails();

        $('#previewModal').modal('show');
        console.log('111111111111111111111');
        return
      }else if (show.entity_type == 5) {
        this.file_Id = show;
        this.getActiveUsers(1);
        this.grouMembers = [];
        this.grouMembersuuid = [];
      }else if (show.entity_type == 6) {
        
        this.file_Id = show;
        console.log(show,'mmmmmmm');
        this.currentUser  = localStorage.getItem("currentUserDetails");
        this.adminUser = JSON.parse(this.currentUser);
        this.ownerUser = this.adminUser.user_details;
        console.log(this.ownerUser);
        $('#createArchieveModal').modal('show');
        // this.getActiveUsers(1);
        

      }else if (show.entity_type == 7) {
        
        this.file_Id = show;
        console.log(show,'mmmmmmm');
        // this.currentUser  = localStorage.getItem("currentUserDetails");
        // this.adminUser = JSON.parse(this.currentUser);
        // this.ownerUser = this.adminUser.user_details;
        // console.log(this.ownerUser);

        // this.startDate = new Date();
        // console.log("this.startDate", this.startDate)
        // this.startDate = moment(this.startDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
        // console.log("this.startDate", this.startDate)
        const currentDate = new Date();
    
        this.nextMonthDate = moment(currentDate).add(1, 'month').utc().tz('Africa/Libreville').format('YYYY-MM-DD');
        console.log("Date Next Month:", this.nextMonthDate);



        this.checkOutForm.get('return_date').setValue(this.nextMonthDate);
        this.checkOutForm.get('id').setValue(show.id);

        $('#CheckoutModal').modal('show');

        

      }else {
        console.log(show);
        console.log(this.movefolderiD.length);
        
        if(this.movefolderiD.length >= 2){
          this.nameShare = `${this.movefolderiD.length} Documents`
        }else{
          this.nameShare = show.name;
        }
        
        this.users = [];
        $('#shareModal').modal('show');
        this.getSharedUsers();
        return
      }
      return;
    });
    
  }

  get b() {
    return this.createArchieveForm.controls;
  }

  get a() {
    return this.renameForm.controls;
  }

  ngAfterViewInit(): void {
    // Ensure stepper is defined before accessing it
    // if (this.stepper) {
    //   this.stepper.selectedIndex = 0; // Set initial step index
    // }
  }
  private _filter(value: string): string[] {
    console.log("dfdfdsfdsfdsfdsfds", value)
    const filterValue = value.toLowerCase();
    this.userlist = this.userlistdataall.filter(option => option.name.toLowerCase().includes(filterValue) || option.email.toLowerCase().includes(filterValue));
    console.log(this.userlist)
    return this.userlist
  }
  get j() {
    return this.createForm.controls;
  }
  getProjectDetails() {
    this.ngxUiLoaderService.start();
    this.api.getProjectDetails().subscribe(data => {
      console.log(data, '0000000000000000000000');
      this.ngxUiLoaderService.stop();
      this.responsemove = data;
      console.log(this.responsemove, '12112');
      if (this.responsemove.status == 200) {
        this.responseMove = this.responsemove.result;
        console.log(this.responseMove)
       
        setTimeout(()=> {
          for (let key in this.responseMove) {
            console.log(this.responseMove[key])
          }
        },1000)
        
      } else {
        this.snackBar.open(this.responsemove.message, "Dismiss", {
          duration: 3000,
        });
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  addStep(item=null) {
    console.log(item)
    if (item.children.length != 0) {
      this.responseMove.push(item);
      console.log(this.responseMove)
      setTimeout(() => {
        this.stepper.next();
        this.selectedValue = '';
      }, 100)
    }
    console.log(this.selectedValue)
  }

  isStepValid(index: number): boolean {
    // Add your validation logic here if needed
    return true;
  }

  ngOnDestroy() {
    console.log('Service destroy')
    this.modalSubscription.unsubscribe();
  }

  filterArray() {
    this.selectedValue = '';
    this.stepper.previous();
    setTimeout(() => {
      this.responseMove.pop();
    }, 100)
    console.log(this.selectedValue)
  }

  isSelected(option: any) {
    return this.selectedValue === option;
  }

  isHovered(option: any) {
    return this.selectedValue === option;
  }

  setHovered(option: any) {
    this.selectedValue = option;
  }
  
  setSelected(option: any) {
    this.selectedValue = option;
  }

  move(item=null) {
    console.log(item)
     var data = { 
      file_id: this.movefolderiD ,
      folder_id: this.selectedValue.uuid,
      // entityType:1
    }
      console.log(item);
      this.ngxUiLoaderService.start();
      this.api.moveProjectFolderOrfile(data).subscribe(data => {
        console.log(data);
        this.moveProjectFileResponse = data;

        if (this.moveProjectFileResponse.status == 200) {
          console.log(this.moveProjectFileResponse, 'qqqq');
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.moveProjectFileResponse.message, "Dismiss", {
            duration: 3000,
          })
          this.responseMove = [];

          this.selectedValue = '';
          // this.stepper.previous();
          this.stepper.selectedIndex = 0;

          $('#myModal').modal('hide');
          this.modalService.emit();
          this.modalService.search1('modalsearch');
          
        } else {
          this.ngxUiLoaderService.stop();
          this.stepper.selectedIndex = 0;
          this.snackBar.open(this.moveProjectFileResponse.message, "Dismiss", {
            duration: 3000,
          })
        }
        

      }, error => {
        this.ngxUiLoaderService.stop();
      });
  }
  locationMenuTrigger() {
    console.log("ASdsad")
  }
  getSharedUsers(){
    this.createForm.get('email').setValue('')
    this.createForm.get('limit').setValue(0)
    console.log(this.project_Id);
    var data = {
      id :this.movefolderiD
    }
    console.log(data)
    this.ngxUiLoaderService.start();
    this.api.getSharedView(data).subscribe(data=>{
    this.ngxUiLoaderService.stop();
    console.log(data);
    this.responseSharedView = data;
    console.log(this.responseSharedView);
    if (this.responseSharedView && this.responseSharedView.status == 200) {
      this.sharedViewlist = this.responseSharedView.results.owner;
      console.log(this.sharedViewlist);
      this.sharedUserViewlist = this.responseSharedView.results.shared_user;
      console.log(this.sharedUserViewlist);
      
    }
  }, error => {
    this.ngxUiLoaderService.stop();
  }); 
  }
  getActiveUsers(val) {
    console.log(val);

    var data = {
      entityType: '2',
    };
    console.log(val);
    this.api.getActiveUser(data).subscribe(data => {
      this.responselist = data;
      if (this.responselist.status == 200) {
        console.log('ncncnc');
        if( this.responselist.results.length != 0){
          this.userlistdataall = this.responselist.results.user;
          console.log(this.userlistdataall);
          
          this.userlist = this.responselist.results.user.map(user => ({
            ...user,
            type: 'user',
            display: user.email || user.name // Use email if it exists, otherwise use name
          }));
          this.groupList = (this.responselist.results.group || [] || null).map(group => ({
            ...group,
            type: 'group',
            display: group.name // Always use name for groups
          }));
          console.log(this.groupList);
          
          // Merge the user and group lists
          
          this.autocompleteItems = [...this.userlist, ...this.groupList];
          console.log(this.autocompleteItems);
          this.colors = this.groupList.map(() => this.generateRandomColor());
          console.log(this.colors);
        }
        
        if (val == 1) {
          $('#createProjectModal').modal('show');
        }
      } else {
        this.snackBar.open(this.responselist.message, "Dismiss", {
          duration: 3000,
        });
      }
    }, error => {
    });
  }

  onItemAdded(event) {
    console.log("sdsadsadsad",event)
    console.log(this.j.email)
    this.users.push(event.email)
    console.log(this.users)
    this.usserUUid.push(event.uuid)
    console.log(this.usserUUid)
  }
  onItemRemoved(event) {
    console.log("asdsadsadsadasds", event)
    if (this.users.includes(event.email)) {
      this.users = this.users.filter(item => item !== event.email);
    }
    console.log(this.users)
    if (this.usserUUid.includes(event.uuid)) {
      this.usserUUid = this.usserUUid.filter(item => item !== event.uuid);
    }
    console.log(this.usserUUid)
  }
  onSelectAccess(selectedValue: string): void {
    // console.log(selectedValue);
    this.access = selectedValue;
    console.log(this.access)
    
  }

  onSubmitModal(){
    this.isSubmitted = true;
    
    console.log(this.createForm.value);
    this.comment = this.createForm.value;
    console.log(this.comment.comment);

    console.log(this.usserUUid[0]);
    if(this.usserUUid.length==0){
      return;
    }
    if (!this.usserUUid[0]) {
      this.snackBar.open('Not allowed to add other users', "Dismiss", {
        duration:3000,
      })
      return;
    }
    var data =  {
      shared_with:this.usserUUid,
      access:this.access,
      id: this.movefolderiD,
      comment: this.comment.comment
      }
  
     console.log(data);
     this.ngxUiLoaderService.start();
     this.api.shareProjectFileFolder(data).subscribe(data=>{
      console.log(data);
      this.shareProfileResponse = data;
      if(this.shareProfileResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.shareProfileResponse);
        this.snackBar.open(this.shareProfileResponse.message, "Dismiss", {
          duration:3000,
        })
        this.createForm.reset();
        $('#shareModal').modal('hide');
        $(".modal-backdrop").remove();
        this.usserUUid = [];
        this.isSubmitted = false;
        this.modalService.emit();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.shareProfileResponse.message, "Dismiss", {
          duration:3000,
        })
      }
     }, error => {
      this.ngxUiLoaderService.stop();
        
      });
     
    }


    projectrenamefileorfolder() {
      // console.log(this.type);
      console.log(this.renameForm);
      
        this.renameSubmitted = true;
        if (this.renameForm.invalid) {
          return
        } else {
          let data = {
            file_id: this.file_Id.id,
            ...this.renameForm.value,
          }
  
          console.log(data, 'clicked');
          this.ngxUiLoaderService.start();
  
          this.api.renameFile(data).subscribe(data => {
            console.log(data);
            this.renameResponse = data;
            if (this.renameResponse.status == 200) {
              this.ngxUiLoaderService.stop();
              this.renameSubmitted = false;
              this.renameForm.get('folder_code').setValue('');
              $('#renameCreateModal').modal('hide');
              $(".modal-backdrop").remove();
              this.modalService.emit();
              this.modalService.search1('modalsearch');
          
              this.snackBar.open(this.renameResponse.message, "Dismiss", {
                duration: 3000,
              })
            } else {
              this.ngxUiLoaderService.stop();
              this.snackBar.open(this.renameResponse.message, "Dismiss", {
                duration: 3000,
              })
            }
          }, error => {
            this.ngxUiLoaderService.stop();
          });
        }
    }

    clossRenamemodal(){
      $('#renameCreateModal').modal('hide');
      this.renameSubmitted = false;
      this.folder_code = null;
    }
    clossSharemodal(){
      this.isSubmitted = false;
      $('#shareModal').modal('hide');
      this.createForm.reset();
    }

    clossmodal() {
      this.image = null;
      $('#previewModal').modal('hide');
    }

    onLoad(e, val=null) {
      console.log(e);
      
      if (val == 1) {
        console.log(this.val)
        if (this.val == 1) {
          this.loading = false;
          console.log("sdsadsa", e)
          return
        }
        this.val++
      } else {
        this.loading = false;
        console.log("sdsadsa", e)
      }
    }

    // getlinkModal(){
    //   $('#getLinkCreateModal').modal('show');
      
    // }
    // closseGetlink(){
    //   $('#getLinkCreateModal').modal('hide');
    //   this.getLinkForm.reset();
    // }

    getLink(){
      let [uuid] = this.movefolderiD;
      console.log(uuid,'clicked');

      let data = {
        uuid:uuid,
        comment: null
      }
      
      this.ngxUiLoaderService.start();
      this.api.getProjectLink(data).subscribe(data =>{
        
        console.log(data);
        this.projectGetlinkresponse = data;
        if(this.projectGetlinkresponse.status == 200){
          this.ngxUiLoaderService.stop();
  
          console.log(this.projectGetlinkresponse);
          this.getlink = this.projectGetlinkresponse.result.link;
          console.log(this.getlink);

          navigator.clipboard.writeText(this.getlink)
          this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
            duration:3000,
          })
          
        }
        else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
            duration:3000,
          })
        }
        
      }, error => {
            this.ngxUiLoaderService.stop();
          });
  
    }

    handleViewerError(e) {
      console.log(e)
    }

    closeGroupmodal(){
      $('#createProjectModal').modal('hide');
    }


    ngSubmitrequestSugnature() {
      this.groupSubmitted = true;
      console.log(this.file_Id)
      if(this.grouMembers.length == 0){
        console.log('asdsaddssdasasdsadadaddsadsdsad')
        return;
      }
      var data =  {
        receiver_user_id: this.grouMembersuuid,
        document: this.file_Id.data.uuid
      }
       console.log(data);
       this.ngxUiLoaderService.start();
       this.api.signatureRequest(data).subscribe(data=>{
        console.log(data);
        this.signatureRequestdetails = data;
        if(this.signatureRequestdetails.status == 200){
          this.ngxUiLoaderService.stop();
          console.log(this.signatureRequestdetails);
          this.snackBar.open(this.signatureRequestdetails.message, "Dismiss", {
            duration:3000,
          })
          this.groupSubmitted = false;
          $('#createProjectModal').modal('hide');
          // this.modalService.emit();
        }else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.signatureRequestdetails.message, "Dismiss", {
            duration:3000,
          })
        }
       }, error => {
        this.ngxUiLoaderService.stop();
          
        });
       
    }

    removeUser(index: number, uuid, member) {
      console.log(member)
      this.grouMembersuuid = this.grouMembersuuid.filter(function(item) {
        // console.log(this.grouMembersuuid);
        return item !== uuid
      })
      console.log(this.grouMembersuuid)
      this.grouMembers.splice(index, 1);
    }

    adduser(user){
      console.log('clicked');
      const isUserPresent = this.grouMembers.some(member => member.id === user.id);
      if (!isUserPresent) {
        if (this.grouMembers.length == 1) {
          this.snackBar.open("Impossible d'ajouter plus d'un membre du groupe", "Dismiss", {
              duration: 3000,
          });
          return;
        }
        user['notinuser'] = 1;
        this.grouMembers.push(user);
        this.grouMembersuuid.push(user.uuid);
        console.log(this.grouMembersuuid, '22222222222');
      }
  }

  filePreviewDetails(){
    let data = this.fileExt.uuid;
    this.api.getViewDetails(data).subscribe(data => {
      console.log(data);
      this.filedetailsResponse = data;
      if(this.filedetailsResponse.status == 200){
        console.log(this.filedetailsResponse);
        this.dataDetials = this.filedetailsResponse.file_Preview_result.file_list;
        console.log(this.dataDetials);
        this.metadatName = this.filedetailsResponse.file_Preview_result.metadata_details.template_details;
        console.log(this.metadatName);
        this.metadata = this.filedetailsResponse.file_Preview_result.metadata_details.keyword;
      }
    })
  }
  onSubmitArchieveModal(){

    let data = this.createArchieveForm.value;
    this.groupSubmitted = true;
        console.log(this.createArchieveForm);
    if (this.createArchieveForm.invalid) {
      console.log('wohooo');
      return
    }


    if(this.movefolderiD){
      this.uUId = this.movefolderiD
    }else{
      this.uUId = this.filesId
    }

    data['item_id'] = this.uUId;
    data['users']=this.usserUUid
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.archievedFiles(data).subscribe(data=>{
      console.log(data);
      this.archievemodalResponse = data;
      if (this.archievemodalResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        console.log('wohoooooo');
        this.snackBar.open(this.archievemodalResponse.message, "Dismiss", {
          duration:3000,
        })
        $('#createArchieveModal').modal('hide');
        this.modalService.emit();
        this.modalService.search1('modalsearch');
        this.createArchieveForm.reset();
        this.groupSubmitted= false;
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.archievemodalResponse.message, "Dismiss", {
          duration:3000,
        })
        $('#createArchieveModal').modal('hide');
        this.createArchieveForm.reset();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
        
      });
    
  }
  clossArchievemodal(){
    $('#createArchieveModal').modal('hide');
    this.createArchieveForm.reset();
    this.groupSubmitted= false;
  }
  generateRandomColor(): string {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }
  getShortName(group_name) {
    return group_name.charAt(0); 
    // return group_name.split(' ').map(n => n[0]).join('');
  }
  getColor(item: any): string {
    const index = this.groupList.findIndex(group => group.name === item.name);
    return this.colors[index] || '#000000'; // Default to white if color is not found
  }

  closeCheckoutmodal(){
    $('#CheckoutModal').modal('hide');
    this.checkOutForm.reset();
  }
  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  checkOut(){

    if (this.checkOutForm.invalid) {
      return
    }

    var data = this.checkOutForm.value;
    data['return_date'] = this.convertOwl(this.checkOutForm.value.return_date);
    console.log(this.checkOutForm.value);

    this.ngxUiLoaderService.start();
    this.api.checkOutfile(data).subscribe(data=>{
      console.log(data);
      this.checkoutfileResponse = data;
      if (this.checkoutfileResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.checkoutfileResponse.message, "Dismiss", {
          duration:3000,
        })
        this.modalService.emit();
        $('#CheckoutModal').modal('hide');
        this.checkOutForm.reset();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.checkoutfileResponse.message, "Dismiss", {
          duration:3000,
        })
      }
    },error => {
      this.ngxUiLoaderService.stop();
        
    });
    
  }
    
}


