<app-sidebar></app-sidebar>
<app-header></app-header>
<main>
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">

                <div class="title_container">
                    <div class="parent-flex">
                        <div class="title_left">
                            <div class="d-flex align-items-center">
                                <img *ngIf="destruction" class="title_img cursor_pointer" src="assets/images/angle_left.png" alt=""
                                    (click)="goBackbutton()">
                                <div class="title_lg" *ngIf="(id == 'restoredeletedfiles') && (!destruction)">{{'Archived files' |translate}}</div>
                                <div class="title_lg" *ngIf="destruction">{{'Destruction Reports' |
                                    translate}}</div>
                                
                            </div>


                            <!-- <div class="d-flex"></div> -->
                        </div>
                        <button class="btn_primary bg_blue margin_0" [hidden]="currentValue && (!currentValue.permissions.viewDestructionReport)" *ngIf="(!destruction)"  (click)="destructionOpen()">{{'Destruction Reports' | translate}}</button>

                    </div>
                </div>

                <div *ngIf="(id == 'restoredeletedfiles') && (!destruction)">
                    <div class="zip_group mb-5">
                        <div class="card1" *ngFor="let files of archievedFolers" (click)="openPasswordModal(files)">
                            <!-- <img class="img-fluid" src="assets/images/secFolder.png" alt="">  (contextmenu)="open($event, files); $event. preventDefault();" -->
                             <div class="inside_card">
                                <div class="name mt-2"><img class="img-fluid" src="assets/images/secFolder.png" alt=""></div>
                                <div><span class="filenameRestore mt-2">{{files.name}}</span></div>
                             </div>
                            
                        </div>
                    </div>

                    <div *ngIf="(noData && noData.length == 0)" class="text-center mt-5">
                        <img src="assets/images/norecycle.jpg" class="img-fluid mb-2">
                        <p>{{'No Archived files Found' | translate}}</p>
                    </div>
                </div>

                <div *ngIf="destruction">
                    <div class="title_md d-flex align-items-center mb-3" *ngIf="destructionReportFile?.files"><img
                            src="assets/images/doc_sm.png" alt="" class="title_sm_logo">&nbsp;{{'Files' | translate}}
                    </div>
                    <div class="generateCard mb-5">
                        <div class="files" *ngFor="let item of destructionReportFile?.files index as i"
                            (click)="downloadPath(item.certificate)">
                            <div class="report_download">
                                <a *ngIf="item.certificate" id="report_slip_upload" target="_blank"
                                    href="{{item.certificate}}"><img src="assets/images/download_oprtion.png"
                                        alt=""></a>
                            </div>
                            <div class="file-card">
                                <img class="img-fluid" src="assets/images/pdf.png" alt="">
                                <span>{{item.file_name}}</span>
                            </div>

                        </div>

                    </div>
                    <div class="title_md d-flex align-items-center mb-3" *ngIf="deletedDataFolder"><img
                            src="assets/images/doc_sm.png" alt="" class="title_sm_logo">&nbsp;{{'Folders' | translate}}
                    </div>
                    <div class="folder-card  destruction_file">
                        <div class="card1" *ngFor="let item of deletedDataFolder index as i"
                            (click)="downloadPath(item.certificate)">
                            <div class="d-flex align-items-center folder-div">

                                <img class="mr-12" src="assets/images/folder.png" alt="">
                                <div>
                                    <div class="subText"> {{item.folder_name}} <br></div>
                                    <!-- <div class="smText">{{data.file_count}} {{'Files' | translate}}</div> -->
                                </div>
                            </div>
                            <div class="report_download-folder">
                                <a *ngIf="item.certificate" id="report_slip_upload" target="_blank"
                                    href="{{item.certificate}}"><img src="assets/images/download_oprtion.png"
                                        alt=""></a>
                            </div>
                        </div>

                    </div>

                    <div class="projects mt-5">
                        <div class="title_md d-flex align-items-center mb-3" *ngIf="deleteDataProject"><img
                                src="assets/images/wheel_doc.png" alt="" class="title_sm_logo">&nbsp;{{'Projects' |
                            translate}}</div>
                        <div class="grid">
                            <div class="card1 p-15 cursor_pointer" *ngFor="let item of deleteDataProject index as i"
                                (click)="downloadPath(item.certificate)">
                                <div class="report_download_project">
                                    <a *ngIf="item.certificate" id="report_slip_upload" target="_blank"
                                        [attr.href]="item.certificate"><img src="assets/images/download_oprtion.png"
                                            alt=""></a>
                                </div>
                                <div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="title_md"><img class="mr-12" src="assets/images/Union.png" alt="">{{
                                            item.name}}</div>
                                    </div>
                                    <div class="d-flex align-items-center mt-3 mb-3">
                                        <img class="" src="assets/images/elipse_grey.png" alt="">
                                        <div class="smText"> {{ item.file_count }} {{'Files' | translate}}, {{
                                            item.folder_count}} {{'Folders' | translate}}</div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="{{user.profile_pic}}"
                                            *ngFor="let user of item.projectMembers| slice:0:limit"
                                            class="picUserImage">
                                    </div>
                                    <h6 class="blue_letter">{{item.size}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(destructionReportFile && destructionReportFile.length == 0)" class="text-center mt-5">
                        <img src="assets/images/norecycle.jpg" class="img-fluid mb-2">
                        <p>{{'No Destruction Reports Found' | translate}}</p>
                    </div>

                </div>

            </div>
        </div>


    </div>
    <div class="modal fade" id="deleteModal2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
        tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div id="modalrestore_id" class="modal-content">
                <div class="modal_header">
                    <h5>{{'Restore' | translate}}?</h5>
                    <button type="button" #closebutton2 class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>{{'Do you want to Restore' | translate}}</div>
                </div>
                <div class="btnGrp mt-4">
                    <button class="btn_primary bg_blue" data-bs-toggle="modal" data-bs-dismiss="modal"
                        href="">{{'Cancel' | translate}}</button>
                    <button class="btn_primary bg_red" (click)="restoreFiles1()">{{'Restore' | translate}}</button>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div id="delete_id" class="modal_header text-center">
                    <h5>{{'Delete forever' | translate }} ?</h5>
                    <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">{{'Do you want to delete' | translate}} {{'permanently ?' | translate}}
                    </div>
                </div>
                <div class="btnGrp mt-4">
                    <button class="btn_primary bg_blue" data-bs-toggle="modal" data-bs-dismiss="modal"
                        href="">{{'Cancel' | translate}}</button>
                    <button *ngIf="currentValue && (currentValue.permissions.permenantDelete)"
                        class="btn_primary bg_red" (click)="bulkdeletePermanentandLater()">{{'Delete Permanently' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>

</main>
<div class="modal fade" id="ModalforArchieve_password" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal_header">
                <!-- <h5>{{folderName}}</h5> -->
                 <h5>{{'Archived folder open' | translate }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closemodal()"></button>
            </div>
            <form [formGroup]="passwordOpenmodalForm">
                <div class="outer_div">
                    <div>
                        <div class="input_style div_flex">
                            <div class="label_div">
                                {{'Folder name' | translate}} :</div>
                            <div class="discription_text">{{folderName}}</div>
                        </div>
                    </div>
    
                    <div>
                        <div class="input_style input_flex_date div_flex1">
                            <div class="label_div">
                                {{'Archived Date' | translate}} :</div>
                            <div class="discription_text1">{{archievedDate}}</div>
                        </div>
                    </div>
    
                    <div>
                        <div class="input_style">
                            <div class="label_div1">
                                {{'Description' | translate}} :
                            </div>
                           
                            <div class="discriptionn">{{discription}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="mt-3" *ngIf="currentValue && !(currentValue.permissions.AccessArchivedFile)">
                    <p class="text-center text-danger">*{{'You have no permission to access the file' | translate}}</p>
                </div>

                <div  class="mt-5" *ngIf="currentValue && (currentValue.permissions.AccessArchivedFile)">
                    <div >
                        <div class="passwordField">
                            <div class="input_style">
                                <label for="exampleInputEmail1">{{'Password' | translate}}</label>
                                <input type="{{ passwordFieldType}}" class="input password_input" type="password"
                                    aria-describedby="emailHelp" placeholder="{{'Password' | translate}}"
                                    formControlName="password">
                                <span (click)="setShowPassword('a')"><i
                                        class="{{ showPasswordIcon }} eye_Icon"></i></span>
                            </div>
                            <div id="passwordRequired" *ngIf="folderSubmitted && a.password.errors">
                                <div id="passwordRequired" class="error_message" *ngIf="a.password.errors.required">
                                    {{'Password is required' | translate}}</div>
                            </div>
                        </div>

                    </div>
                    <div id="delete_id" class="btnGrp mt-4">
                        <button id="button-id" class="btn_light btn" (click)="cancelhidden()">{{'Cancel' |
                            translate}}</button>
                        <button id="button-id" class="btn_primary bg_blue" (click)="openAchievedList()">{{'Open' |
                            translate}}</button>
                    </div>
                </div>
            </form>

        </div>
    </div>


</div>