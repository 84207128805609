import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../_models/language';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  data: any = {};
  public currentLanguage: BehaviorSubject<Language>;
  constructor(private http: HttpClient) {
	this.currentLanguage = new BehaviorSubject<Language>({language: localStorage.getItem('language') || "fr"})
  }

	use(lang: string): Promise<{}> {
	return new Promise<{}>((resolve, reject) => {
	  const langPath = `assets/i18n/${lang || 'fr'}.json`;
	  this.http.get<{}>(langPath).subscribe(
	    translation => {
	      this.data = Object.assign({}, translation || {});
		  resolve(this.data);
		},
	    error => {
	      this.data = {};
	      resolve(this.data);
	    }
	  );
	});
	}


	public get currentLanguageValue(): Language {
	    return this.currentLanguage.value;
    }

    setCurrentLangage(lang){
  	   this.currentLanguage.next(lang);
    }


}
