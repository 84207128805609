<app-sidebar></app-sidebar>
<app-header></app-header>
<main >
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">

                <div class="title_container">
                    <div class="parent-flex">
                        <div class="title_left">
                            <div class="d-flex align-items-center">
                                <img class="title_img cursor_pointer" src="assets/images/angle_left.png" alt="" routerLink="/folder/1">
                                <div class="title_lg" >Data 1</div>
                            </div>
                            <div class="d-flex"></div>
                        </div>
                       
                    </div>
                </div>

                <div>
                    <!-- <div class="sub_title_container">
                        <div class="title_md d-flex align-items-center"><img src="assets/images/wheel_doc.png" alt=""
                                class="title_sm_logo">Archieve Projects</div>
                        <div class="filters"><img src="assets/images/filter_sm.png" alt="" class="cursor_pointer"><img
                                src="assets/images/more_sm.png" alt="" class="cursor_pointer"></div>
                    </div> -->

                    <!-- <div class="projects mb-5">
                        <div class="grid">
                            <div class="card1 p-15">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="title_md"><img class="mr-12" src="assets/images/Union.png"
                                            alt="">Project 1</div>
                                    <img class="mr-12" src="assets/images/more_sm2.png" alt="">
                                </div>
                                <div class="d-flex align-items-center mt-3 mb-3">
                                    <img class="" src="assets/images/elipse_grey.png" alt="">
                                    <div class="smText"> 28 Files, 3 Folders</div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <ul class="mb-0 ul_line mr-12">
                                            <li  class="d-inline"><img src="assets/images/employees1.png" alt=""></li>
                                            <li  class="d-inline"><img src="assets/images/employees2.png" alt=""></li>
                                            <li  class="d-inline"><img src="assets/images/employees3.png" alt=""></li>
                                            <li  class="d-inline"><img src="assets/images/employees4.png" alt=""></li>
                                        </ul>
                                        
                                    </div>
                                    <h6 class="blue_letter">0.98 GB</h6>
                                </div>
                            </div>
                            <div class="card1 p-15">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="title_md"><img class="mr-12" src="assets/images/Union.png"
                                            alt="">Project 1</div>
                                    <img class="mr-12" src="assets/images/more_sm2.png" alt="">
                                </div>
                                <div class="d-flex align-items-center mt-3 mb-3">
                                    <img class="" src="assets/images/elipse_grey.png" alt="">
                                    <div class="smText"> 28 Files, 3 Folders</div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <ul class="mb-0 ul_line mr-12">
                                            <li  class="d-inline"><img src="assets/images/employees1.png" alt=""></li>
                                            <li  class="d-inline"><img src="assets/images/employees2.png" alt=""></li>
                                            <li  class="d-inline"><img src="assets/images/employees3.png" alt=""></li>
                                            <li  class="d-inline"><img src="assets/images/employees4.png" alt=""></li>
                                        </ul>
                                        
                                    </div>
                                    <h6 class="blue_letter">0.98 GB</h6>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="sub_title_container">
                        <div class="title_md d-flex align-items-center"><img src="assets/images/folder_grey.png" alt=""
                                class="title_sm_logo">Archieve Folders</div>
                        <div class="filters"><img src="assets/images/filter_sm.png" alt="" class="cursor_pointer"><img
                                src="assets/images/more_sm.png" alt="" class="cursor_pointer"></div>
                    </div>

                    <div class="folder-card mb-5">
                        <div class="card1">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/zip.png" alt="">
                                <div>
                                    <div class="subText">Data 1</div>
                                    <div class="smText">112 Files</div>
                                </div>

                            </div>
                        </div>
                        <div class="card1">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/zip.png" alt="">
                                <div>
                                    <div class="subText">Data 2</div>
                                    <div class="smText">112 Files</div>
                                </div>

                            </div>
                        </div>
                        <div class="card1">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/zip.png" alt="">
                                <div>
                                    <div class="subText">Data 3</div>
                                    <div class="smText">112 Files</div>
                                </div>

                            </div>
                        </div>
                        <div class="card1">
                            <div class="d-flex align-items-center">
                                <img class="mr-12" src="assets/images/zip.png" alt="">
                                <div>
                                    <div class="subText">Data 4</div>
                                    <div class="smText">112 Files</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="sub_title_container">
                        <div class="title_md d-flex align-items-center"><img src="assets/images/document_grey.png" alt=""
                                class="title_sm_logo">Archieve Files</div>
                        <div class="filters"><img src="assets/images/filter_sm.png" alt="" class="cursor_pointer"><img
                                src="assets/images/more_sm.png" alt="" class="cursor_pointer"></div>
                    </div>

                    <div class="zip_group mb-5">
                        <div class="card1">
                                <img class="" src="assets/images/zip_doc.png" alt="">
                                <div class="name">hasgh.zip</div>
                        </div>
                        <div class="card1">
                                <img class="" src="assets/images/zip_doc.png" alt="">
                                <div class="name">hasgh.zip</div>
                        </div>
                        <div class="card1">
                                <img class="" src="assets/images/zip_doc.png" alt="">
                                <div class="name">hasgh.zip</div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        
    
    </div>


</main>
