<app-sidebar></app-sidebar>
<app-header></app-header>

<main>
    <div class="container-custom">
      <div class="columns">
        <div class="main">
  
          <div class="heading_1  align-items-center justify-content-between" data-aos="fade-right" data-aos-delay="400"
            data-aos-duration="1500">
  
  
            <div class="title_container main1-div">
              <div class="parent-flex main2-div">
                <div class="title_left">
                  <div class="d-flex align-items-center">
                    <img class="title_img" src="assets/images/recycle_blue.png" alt="">
                    <div class="title_lg">{{'Restore Archived files' | translate}}</div>
                    <!-- <div class="settingsBtn"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                  </div>
                  <div class="d-flex"></div>
                </div>
                <div class="endChild mt-2">
                  <button class="btn bg_blue margin_0" *ngIf="selection" (click)="cancel()">{{'Cancel' | translate}}</button>
                  <button class="btn_primary bg_grey" *ngIf="(!selection) "(click)="selectAction()">{{'Select' | translate}}</button>
                  <button class="btn_primary bg_grey" *ngIf="selection" (click)="selectAll()">{{'Select All' |translate}}</button>
                  <!-- <button class="btn_primary bg_blue" [hidden]="currentValue && (!currentValue.permissions.restoreFile)" *ngIf="selection" (click)="openRestorebulkModal()" [disabled]="folderOrFilesID.length == 0">{{'Restore' | translate}}</button> -->
                  <button class="btn_primary bg_red" *ngIf="(selection) &&  (currentValue && (currentValue.permissions.permenantDelete))" [disabled]="folderOrFilesID.length == 0" (click)="opendeletePermanentModal()" >{{'Delete' | translate}}</button>
                  <!-- <button class="btn_primary bg_blue margin_0" [hidden]="currentValue && (!currentValue.permissions.viewDestructionReport)" *ngIf="!selection" routerLink="/restorefiles/deletepermanentfiles">{{'Destruction Reports' | translate}}</button> -->
                  <!-- <img (click)="gridActive('listActive')" *ngIf="gridView" class="cursor_pointer" src="assets/images/threeLine.png" alt="">
                  <img *ngIf="gridView" class="cursor_pointer" src="assets/images/gridActive.png" alt="">
                  <img *ngIf="!gridView" class="cursor_pointer" src="assets/images/threeLineActive.png" alt="">
                  <img (click)="gridActive('gridActive')" *ngIf="!gridView" class="cursor_pointer" src="assets/images/grid.png" alt=""> -->
                </div>
              </div>
            </div>
          </div>
          <!-- GRID VIEW  -->
          <div id="listView" class="binTable">
            <div class="wrapper">
              <div class="Rtable Rtable--5cols Rtable--collapse">
                <div>
                  <h5>
                    {{'Archived Files' | translate}}
                  </h5>
                </div>
                <div class="Rtable-row Rtable-row--head">
                  <div class="Rtable-cell name-cell column-heading">{{'Name' | translate}}&nbsp;<i
                      class="fa-solid fa-arrow-up"></i></div>
                  <div class="Rtable-cell size-cell column-heading">{{'File Size' | translate}}</div>
                  <div class="Rtable-cell date-cell column-heading">{{'Archieved date' | translate}}</div>
                  <div class="Rtable-cell location-cell column-heading">{{'Orginal location' | translate}}</div>
                </div>
  
                <div class="clickable" *ngFor="let data of Archievedfiles index as i">
                  <div class="Rtable-row is-striped" (contextmenu)="open($event, data); $event. preventDefault();">
                    <div class="Rtable-cell name-cell">
                      <div class="Rtable-cell--content title-content">
                        <mat-checkbox [(ngModel)]="data.completed"
                          (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                          class="binSelect"></mat-checkbox> 
                          {{data.name}} </div>
                    </div>
                    <div class="Rtable-cell size-cell">
                      <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                      <div class="Rtable-cell--content replay-link-content">{{data.size}} </div>
                    </div>
                    <div class="Rtable-cell date-cell">
                      <div class="Rtable-cell--heading">{{'Archieved date' | translate}}</div>
                      <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                    </div>
                    <div class="Rtable-cell location-cell">
                      <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                      <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                        <div class="Rtable-cell--content d-flex align-items-center"><img src="assets/images/folder_sm.png"
                            alt="">{{data.original_location}}
                        </div>
                      </div>
                    </div>
  
                  </div>
                  <ng-template #userMenu let-user >
                    <section class="user-menu" [hidden]="currentValue && (!currentValue.permissions.permenantDelete)">
                      <!-- <div *ngIf="currentValue && (currentValue.permissions.restoreFile)" (click)="restoreFileorFolder(user.uuid)"><img src="assets/images/time.png" alt=""> {{'Restore' |
                        translate}}</div> -->
                      <div *ngIf="currentValue && (currentValue.permissions.permenantDelete)" (click)="deleteForever(user.uuid)"><img src="assets/images/delete-sm.png" alt=""> {{'Delete
                        Forever' | translate}}</div>
                    </section>
                  </ng-template>
  
                </div>
  
              </div>
              <div class="text-center" *ngIf="Archievedfiles && Archievedfiles.length == 0">
                <img src="assets/images/doc.png" class="img-fluid mb-2">
                <p>{{'No Archieved files Found' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</main>
<div class="modal fade" id="deleteModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="renameCreateModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div id="modalrestore_id" class="modal-content">
        <div class="modal_header">
          <h5>{{'Restore' | translate}} ?</h5>
          <button type="button" #closebutton2 class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModaal()"></button>
        </div>
        <div class="modal-body">
          <div>{{'Do you want to Restore' | translate}}</div>
        </div>
        <div class="btnGrp mt-4">
          <button class="btn_primary bg_blue" (click)="closeModaal()" >{{'Cancel' |
            translate}}</button>
          <button class="btn_primary bg_red" *ngIf="currentValue && (currentValue.permissions.restoreFile)" (click)="restoreFiles()">{{'Restore' | translate}}</button>
        </div>
      </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="renameCreateModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" >
      <div id="delete_id" class="modal_header">
        <h5 class="text-center">{{'Delete forever' | translate }} ?</h5>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="text-center">{{'Do you want to delete' | translate}} {{'permanently' |
          translate}}?</div>
      </div>
      <div class="btnGrp mt-4">
        <!-- <button class="btn_primary bg_blue" *ngIf="currentValue && (currentValue.permissions.archiveDelete)" (click)="restoreLater()">{{'Restore later' | translate}}</button> -->
        <button  class="btn_primary bg_red" *ngIf="(currentValue && (currentValue.permissions.permenantDelete))"
          (click)="bulkdeletePermanentandLater()">{{'Delete Permanently' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteModal5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="renameCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div id="modalrestore_id" class="modal-content">
      <div class="modal_header">
        <h5>{{'Restore' | translate}} ?</h5>
        <button type="button" #closebutton2 class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeRModal()"></button>
      </div>
      <div class="modal-body">
        <div>{{'Do you want to Restore' | translate}}</div>
      </div>
      <div class="btnGrp mt-4">
        <button class="btn_primary bg_blue" (click)="closeRModal()" >{{'Cancel' |
          translate}}</button>
        <button class="btn_primary bg_red" (click)="restoreFiles('bulk')">{{'Restore' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteModal6"data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="renameCreateModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" >
      <div id="delete_id" class="modal_header">
        <h5 class="text-center">{{'Delete forever' | translate }} ?</h5>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="text-center">{{'Do you want to delete' | translate}} {{'permanently' |
          translate}}?</div>
      </div>
      <div class="btnGrp mt-4">
        <!-- <button class="btn_primary bg_blue" *ngIf="currentValue && (currentValue.permissions.archiveDelete)" (click)="restoreLater()">{{'Restore later' | translate}}</button> -->
        <button  class="btn_primary bg_red"
          (click)="bulkdeletePermanentandLater('bulk')">{{'Delete Permanently' | translate}}</button>
      </div>
    </div>
  </div>
</div>


